import { v } from "./v";
import { stm, tr, flashMessage } from '@7willows/sw-lib';
import _ from 'lodash';
import { match, P } from 'ts-pattern';

type Msg
  = [type: 'AttributeChange', name: string, value: string]
  | [type: 'RemoveRequest', role: string]


type State = {
  roles: string[];
  labels: Record<string, string>;
};

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: 'sec-roles-list',
  debug: false,
  shadow: false,
  propTypes: {
    roles: Array,
    labels: Object
  },
  attributeChangeFactory: (name, value) => msg('AttributeChange', name, value),
  init: () => {
    return [
      {
        roles: [],
        labels: {}
      },
      null
    ]
  },
  update: (state: State, msg: Msg) => {
    return match<Msg, [State, stm.Cmd<Msg>]>(msg)
      .with(['AttributeChange', 'labels', P.select()], labels => [
        { ...state, labels: (labels as any) },
        null
      ])
      .with(['AttributeChange', 'roles', P.select()], roles => {
        if (!_.isArray(roles)) {
          return [state, null];
        }
        return [
          { ...state, roles },
          null
        ];
      })
      .with(['AttributeChange', P._, P._], () => [state, null])
      .with(['RemoveRequest', P.select()], role => [
        state,
        new CustomEvent('remove', { detail: role, bubbles: true })
      ])
      .exhaustive();
  },
  view
});

function view(state: State) {
  return v<Msg>('ul.sec-roles-list',
    ...state.roles
      .sort((a, b) => a.localeCompare(b))
      .map(role => v.li<Msg>(
        v.span(state.labels?.[role] ?? role),
        v('i.icon.icon-cross', {
          onclick: msg('RemoveRequest', role)
        })
      ))
  );
}
