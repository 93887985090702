import _ from 'lodash';
import { v } from "./v";
import { stm, tr, flashMessage, modal } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';

type Msg
  = [type: 'RolesLoadFailed']
  | [type: 'RolesLoadSuccess', groups: Record<string, string[]>, roles: string[]]
  | [type: 'AddGroup']
  | [type: 'Cancelled']
  | [type: 'AddRole', groupName: string, role: string]
  | [type: 'GroupSaveFailed']
  | [type: 'GroupSaveSuccess', groupName: string, roles: string[]]
  | [type: 'EditGroupName', groupName: string]
  | [type: 'EditGroupNameFailed']
  | [type: 'EditGroupNameSuccess', old: string, groupName: string]
  | [type: 'RemoveGroup', groupName: string]
  | [type: 'RemoveGroupFailed']
  | [type: 'RemoveGroupSuccess', groupName: string]
  | [type: 'RemoveRoleFromGroup', groupName: string, role: string]

type State = {
  groups: Record<string, string[]>;
  roles: string[];
  isLoading: boolean;
};

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: 'sec-role-group-form',
  debug: false,
  shadow: false,
  init: () => [
    {
      roles: [],
      groups: {},
      isLoading: true
    },
    loadRoles()
  ],
  update: (state: State, msg: Msg) => {
    return match<Msg, [State, stm.Cmd<Msg>]>(msg)
      .with(['RolesLoadFailed'], () => {
        flashMessage(tr('sec.rolesLoadFailed'), 'error');
        return [{ ...state, isLoading: false }, null];
      })
      .with(['RolesLoadSuccess', P.select('groups'), P.select('roles')], ({ groups, roles }) => [
        { ...state, isLoading: false, groups, roles },
        null
      ])
      .with(['AddGroup'], () => [
        { ...state, isLoading: true },
        addNewGroup()
      ])
      .with(['Cancelled'], () => [
        { ...state, isLoading: false },
        null
      ])


      .with(['AddRole', P.select('groupName'), P.select('role')], ({ groupName, role }) => {
        if (!role) {
          return [state, null];
        }

        const roles = _.uniq([...state.groups[groupName], role]);

        return [
          { ...state, isLoading: true },
          saveGroup(groupName, roles)
        ];
      })
      .with(['GroupSaveFailed'], () => {
        flashMessage(tr('secRoleGroupForm.groupSaveFailed'), 'error');
        return [{ ...state, isLoading: false }, null];
      })
      .with(['GroupSaveSuccess', P.select('groupName'), P.select('roles')], ({ groupName, roles }) => [
        _.set(state, ['groups', groupName], roles),
        null
      ])


      .with(['EditGroupName', P.select()], groupName => [
        { ...state, isLoading: true },
        editGroupName(state, groupName)
      ])
      .with(['EditGroupNameFailed'], () => {
        flashMessage(tr('secRoleGroupForm.editGroupNameFailed'), 'error');
        return [
          { ...state, isLoading: false },
          null
        ];
      })
      .with(['EditGroupNameSuccess', P.select('old'), P.select('groupName')], ({ old, groupName }) => {
        const roles = state.groups[old];
        delete state.groups[old];
        state.groups[groupName] = roles;
        return [state, null];
      })

      .with(['RemoveGroup', P.select()], groupName => [
        state,
        removeGroup(groupName)
      ])
      .with(['RemoveGroupFailed'], () => {
        flashMessage(tr('secRoleGroupForm.removeGroupFailed'), 'error');
        return [
          { ...state, isLoading: false },
          null
        ];
      })
      .with(['RemoveGroupSuccess', P.select()], groupName => {
        delete state.groups[groupName];
        return [state, null];
      })


      .with(['RemoveRoleFromGroup', P.select('groupName'), P.select('role')], ({ groupName, role }) => [
        state,
        saveGroup(groupName, state.groups[groupName].filter(r => r !== role))
      ])
      .exhaustive();
  },
  view
});

async function editGroupName(state: State, old: string) {
  const groupName: string = await modal.prompt({
    title: tr('secRoleGroupForm.groupName'),
    text: '',
    placeholder: tr('secRoleGroupForm.groupName'),
    initialValue: old
  }) as string;

  if (!groupName) {
    return msg('Cancelled');
  }

  try {
    await grow.plant('Security').removeRoleGroup(old);
  } catch (err) {
    console.error('removing group failed', err);
    return msg('EditGroupNameFailed');
  }

  try {
    await grow.plant('Security').saveRoleGroup(groupName, state.groups[old]);
  } catch (err) {
    console.error('saving group failed', err);
    return msg('EditGroupNameFailed');
  }

  return msg('EditGroupNameSuccess', old, groupName);
}

async function removeGroup(groupName: string) {

  const isConfirmed = await modal.confirm({
    text: tr('secRoleGroupForm.confirmRemoval', { groupName }),
    title: ''
  })

  if (!isConfirmed) {
    return msg('Cancelled');
  }

  try {
    await grow.plant('Security').removeRoleGroup(groupName);
    return msg('RemoveGroupSuccess', groupName);
  } catch (err) {
    console.error('removing group failed', err);
    return msg('RemoveGroupFailed');
  }
}

async function saveGroup(groupName: string, roles: string[]) {
  try {
    await grow.plant('Security').saveRoleGroup(groupName, roles);
    return msg('GroupSaveSuccess', groupName, roles);
  } catch (err) {
    console.error('saving group failed', err);
    return msg('GroupSaveFailed');
  }
}

async function addNewGroup(): Promise<Msg> {
  const name = await modal.prompt({
    title: tr('secRoleGroupForm.groupName'),
    text: '',
    placeholder: tr('secRoleGroupForm.groupName')
  }) as string;

  if (!name) {
    return msg('Cancelled');
  }

  return await saveGroup(name, []);
}

async function loadRoles(): Promise<Msg> {
  try {
    const result = await grow.plant('Security').roles();
    if (result.err) {
      console.error('loading roles returned an error', result.err);
      return msg('RolesLoadFailed');
    }

    let roles: string[] = [];
    let groups: Record<string, string[]> = {};

    Object.keys(result).forEach(name => {
      if (result[name] === null) {
        roles.push(name);
      } else {
        groups[name] = result[name];
      }
    });

    return msg('RolesLoadSuccess', groups, roles);
  } catch (err) {
    console.error('loading roles failed', err);
    return msg('RolesLoadFailed');
  }
}

function view(state: State) {
  return v<Msg>('.sec-role-group-form',
    { className: state.isLoading ? 'disabled' : '' },
    v('button.button', {
      onclick: msg('AddGroup')
    }, tr('secRoleGroupForm.addNewGroup')),
    ...Object.keys(state.groups).map(groupName => editGroupView(state, groupName))
  );
}

function editGroupView(state: State, groupName: string) {
  return v<Msg>('.role-group',
    v('.role-group-header',
      v.h3(groupName),

      v('i.icon.icon-pen', {
        onclick: msg('EditGroupName', groupName),
        title: tr('secRoleGroupForm.editGroupName')
      }),
      v('i.icon.icon-trash', {
        onclick: msg('RemoveGroup', groupName),
        title: tr('secRoleGroupForm.removeGroup')
      }),
      v.swSelect({
        title: tr('secRoleGroupForm.addRoleDescription'),
        onupdate: (event: any) => msg('AddRole', groupName, event.detail?.value?.value ?? ''),
        config: {
          name: groupName + '-select',
          showLabel: false,
          disabled: state.isLoading,
          minimumCharLengthTrigger: 0,
          placeholder: tr('secRoleGroupForm.addRole'),
          options: state.roles
            .filter(r => !state.groups[groupName].includes(r))
            .map(role => ({
              label: role,
              value: role
            }))
        }
      })
    ),
    v('.role-group-roles'),
    v.ul(
      ...state.groups[groupName].map(role => v.li<Msg>(
        v.span(role),
        v('i.icon.icon-cross', { onclick: msg('RemoveRoleFromGroup', groupName, role) })
      ))
    )
  );
}
