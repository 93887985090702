import { v } from "./v";
import { dates, flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import _ from "lodash";

type LoadData = any;
const ALERTS_LIST_TO_DISPLAY_LENGTH = 5;

const metricsManager = grow.plant("MetricsManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "LoadDataFailed"]
  | [type: "LoadDataSuccess", installation: LoadData]
  | [type: "LoadPage", route: string]
  | [type: "SetAlertsListToDisplayLength"];

interface State {
  isLoading: boolean;
  installationWithAlarms?: LoadData;
  installationId: string;
  viewState: "params" | "info" | "logs" | "thresholds" | "config";
  route: string;
  alertsListToDisplayLength: number;
}

function msg(...args: Msg): Msg {
  return args;
}

stm.component({
  tagName: "fib-tech-home-panel",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
    viewState: String,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  willUnmount(cmp: any) {
    if (cmp.unsub) {
      cmp.unsub();
    }
  },
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        installationId: "",
        viewState: "info",
        route: "",
        alertsListToDisplayLength: ALERTS_LIST_TO_DISPLAY_LENGTH,
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg, cmp: any, dispatch: stm.Dispatch<Msg>) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      if (!_.isString(installationId)) {
        return [state, null];
      }
      state.isLoading = true;
      state.alertsListToDisplayLength = ALERTS_LIST_TO_DISPLAY_LENGTH;
      state.installationId = installationId;
      return [state, loadData(installationId)];
    })
    .with(["Attr", "viewState", P.select()], (viewState: any) => {
      if (viewState === "managerDashboardsBuilder") {
        state.route = "techHomeDashboardBuilder";
        viewState = "techHomeDashboardBuilder";
      }
      return [
        {
          ...state,
          viewState: [
              "params",
              "info",
              "logs",
              "thresholds",
              "config",
              "techHomeDashboardBuilder",
              "techHomeDashboards",
            ].includes(
              viewState,
            )
            ? viewState
            : "info",
        },
        null,
      ];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["LoadPage", P.select()], (route: any) => {
      state.route = route;
      return [{ ...state, isLoading: false }, null];
    })
    .with(["LoadDataFailed"], () => {
      flashMessage(tr("fibAdminInstallation.loadDataFailed"), "error");
      return [{ ...state, isLoading: false }, null];
    })
    .with(["LoadDataSuccess", P.select()], (data) => {
      state.installationWithAlarms = data;
      state.isLoading = false;
      return [state, null];
    })
    .with(["SetAlertsListToDisplayLength"], () => {
      state.alertsListToDisplayLength = state.installationWithAlarms?.alerts.length ??
        ALERTS_LIST_TO_DISPLAY_LENGTH;
      return [state, null];
    })
    .exhaustive();
}

async function loadData(installationId: string): Promise<Msg> {
  try {
    const installation = await metricsManager.installationState(installationId);

    return msg("LoadDataSuccess", installation);
  } catch (err) {
    console.error("loading installation failed", err);
    return msg("LoadDataFailed");
  }
}

function view(state: State) {
  if (state.isLoading || !state.installationId) {
    return (
      <div class="fib-edit-org">
        <div class="loader-big"></div>
      </div>
    );
  }

  return (
    <>
      {renderNav(state)}
      <div class="installation-title">
        <div class="h500">{state.installationWithAlarms.name ?? ""}</div>
      </div>
      {techPanelDetails(state)}
    </>
  );
}

function renderNav(state: State) {
  const dataPanel = state.route === "" ? "techHomePanel" : state.route as string;

  return (
    <nav class="main-nav" role="menu">
      <div class="hbox left tech-panel">
        <a href={router.getRouteUrl("techInstallsList")} class="square-button button-primary">
          <i class="icon-chevron-left"></i>
        </a>
        <div class="vbox left">
          <div class="h400">{tr("fibTechPanel.installationsList")}</div>
        </div>
      </div>
      <div class="center ">
        <ul class="main-menu h300">
          <li>
            <div
              class={dataPanel === "techHomePanel" ? "active" : ""}
              onClick={() => msg("LoadPage", "techHomePanel")}
            >
              {tr("fibTechHomePanel.info")}
            </div>
          </li>
          <li>
            <div
              onClick={() => msg("LoadPage", "techHomeParams")}
              class={dataPanel === "techHomeParams" ? "active" : ""}
            >
              {tr("fibTechHomePanel.measures")}
            </div>
          </li>
          <li>
            <div
              onClick={() => msg("LoadPage", "techHomeDashboards")}
              class={dataPanel === "techHomeDashboards" ? "active" : ""}
            >
              {tr("fib.manager.dashboard")}
            </div>
          </li>
          <li>
            <div
              onClick={() => msg("LoadPage", "managerReports")}
              class={dataPanel === "managerReports" ? "active" : ""}
            >
              {tr("fib.manager.reports")}
            </div>
          </li>

          <li>
            <div
              onClick={() => msg("LoadPage", "techHomeConfig")}
              class={dataPanel === "techHomeConfig" ? "active" : ""}
            >
              {tr("fibTechHomePanel.config")}
            </div>
          </li>
        </ul>
      </div>
      <div class="right-empty-box"></div>
    </nav>
  );
}

function techPanelDetails(state: State) {
  const installationId = state.installationId;
  const route = state.route as string;

  const installation = {
    installationId,
    platform: state.installationWithAlarms.platform,
    machineId: state.installationWithAlarms.secret,
  };

  switch (route) {
    case "techHomeParams":
      return (
        <fib-installation-parameters installation={installation}>
        </fib-installation-parameters>
      );
    case "techHomeThresholds":
      return (
        <fib-tech-home-thresholds
          installation-id={installationId}
          view-state={"thresholds"}
        />
      );
    case "techHomeConfig":
      return (
        <fib-tech-home-config
          installation-id={installationId}
          view-state={"config"}
          installation-name={state.installationWithAlarms.name ?? ""}
        />
      );
    case "techHomeDashboards":
      return <fib-manager-dashboards />;
    case "techHomeDashboardBuilder":
      return <fib-manager-dashboards-builder user-state={installation} />;
    case "dashFullscreen":
      return <fib-manager-dashboard-fullscreen user-state={installation} />;
    case "managerLogs":
      return <fib-manager-logs installation-id={installationId} />;
    case "managerReports":
      return <fib-manager-reports installation-id={installationId} user-state={installation} />;
    default:
      return installationContactDetails(state);
  }
}

function installationContactDetails(state: State) {
  const installation = state.installationWithAlarms;
  const hasAlerts = !!(state.installationWithAlarms.alerts.length > 0);
  const more = installation.alerts?.length - state.alertsListToDisplayLength;
  let isOnline = true;
  const lastUpdate = new Date(installation.installationUpdatedAt).valueOf() ?? 0;
  const lastUpdateFormatted = dates.formatDateTime(installation.installationUpdatedAt);
  const timeOffline = Date.now() - (1000 * 60 * 5);

  const installationConnection =
    (installation.installationUpdatedAt !== 0 && installation.installationUpdatedAt !== "0")
      ? ""
      : ` [${tr("fibHome.connectionNotActive")}] `;

  if (lastUpdate < timeOffline || installationConnection !== "") {
    isOnline = false;
  }

  return (
    <>
      <div class="tech-contact-panel">
        <div class="install-address">
          <div class="install-title h400">{tr("fibTechPanel.installationAddressLong")}</div>
          <div class="install-street">
            {`${installation.street ?? ""} ${installation.houseNumber ?? ""}`}
          </div>
          <div class="install-city">
            {`${installation.postcode ?? "00-000"} ${installation.city ?? ""}`}
          </div>
        </div>
        <div class="tech-install-contacts">
          <div class="install-title h400">{tr("fibTechPanel.installationContactTitle")}</div>
          <div class="install-phone h300">
            {tr("fibTechPanel.installationContactOrg")}
            <div className={"install-user-phone"}>{installation.organization[0].name}</div>
            <div className={"install-user-phone"}>
              {`${tr("fibTechPanel.installationPhone")} ${installation.organization[0].phone}`}
            </div>
          </div>
          {contactsList(installation.managers, "technician")}
          {contactsList(installation.managers, "manager")}
          {contactsList(installation.managers, "user")}
        </div>
      </div>
      <div class="grid grid-adjustment">
        <div class="box3"></div>
        <div class="box12 alerts-list">
          <div class="instal-status">
            <div class="h300 margin-bottom">
              {tr("fibTechPanel.installationStatus")}:

              {isOnline
                ? (
                  <span class="h300 margin-left install-online">
                    {tr("fibTechPanel.installationIsWorking")}
                  </span>
                )
                : (
                  <span class="h300 margin-left install-offline">
                    {tr("fibTechPanel.installationIsOffline")}
                    {installationConnection}
                  </span>
                )}
            </div>
            <div class="h300">
              {`${tr("fibTechPanel.installationLastUpdate")}: [ ${lastUpdateFormatted} ]`}
            </div>
          </div>
          {hasAlerts && (
            <div className="fib-map-details-row">
              <p className="h300">{tr("fib.map.errorCodes")}:</p>
              <div className="fib-map-alerts-wrapper">
                <>
                  {installation.alerts.map((alert, index) =>
                    index < state.alertsListToDisplayLength &&
                    (
                      <div
                        className={`chip body-medium tooltip error-level-${alert.level} ${
                          alert.status === "accepted" ? "accepted" : ""
                        }`}
                        title={`${alert.name} ${
                          alert.status === "accepted" ? "\n[" + alert.status + "]" : ""
                        }`}
                      >
                        {alert.code}
                      </div>
                    )
                  )}
                </>
                {more > 0 && (
                  <a
                    className="text-button-primary h300"
                    onClick={() => msg("SetAlertsListToDisplayLength")}
                  >
                    <i className="icon-plus" />
                    {more} {tr("fib.map.showMore")}
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
        <div class="box3"></div>
      </div>
    </>
  );
}

function contactsList(usersList: any, role: string) {
  let users = usersList.filter((x: any) => x.licenseType.startsWith(role));

  if (users.length === 0) {
    return <></>;
  }

  let roleDescription = "";
  if (role === "technician") {
    roleDescription = tr("fibTechPanel.installationContactTechnicians");
  }
  if (role === "manager") {
    roleDescription = tr("fibTechPanel.installationContactManagers");
  }
  if (role === "user") {
    roleDescription = tr("fibTechPanel.installationContactUsers");
  }

  users = users.map((user: any) => {
    return (
      <>
        <div className={"user-list"}>
          {`${user.firstName} ${user.lastName}: (${
            tr("fibTechPanel.installationPhone")
          } ${user.phoneNumber})`}
        </div>
      </>
    );
  });

  return (
    <div className={"install-user h300"}>
      {roleDescription}
      <div className={"install-user-phone"}>{users}</div>
    </div>
  );
}
