import { v } from "./v";
import { stm, tr, flashMessage } from '@7willows/sw-lib';
import _ from 'lodash';
import { match, P } from 'ts-pattern';

interface Identity {
  roles: string[]
}

type Msg
  = [type: 'AttributeChange', name: string, value: string]
  | [type: 'RolesLoadFailed']
  | [type: 'RolesLoadSuccess', roles: string[]]
  | [type: 'IdentityLoadSuccess', identity: Identity]
  | [type: 'IdentityLoadFailed']
  | [type: 'RemoveRole', role: string]
  | [type: 'RemoveRoleFailed']
  | [type: 'RemoveRoleSuccess', role: string]
  | [type: 'AddRole', role: string]
  | [type: 'AddRoleFailed']
  | [type: 'AddRoleSuccess', role: string]

  | [type: 'SetRoles', roles: string[]]
  | [type: 'SetRolesFailed']
  | [type: 'SetRolesSuccess', roles: string[]]

  | [type: 'AddRoles', roles: string[]]
  | [type: 'AddRolesFailed']
  | [type: 'AddRolesSuccess', roles: string[]]


type State = {
  identityId: string;
  roles?: string[];
  identity?: Identity;
  isLoading: boolean;
  labels: Record<string, Record<string, string>>;
};

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: 'sec-identity-roles-form',
  debug: false,
  propTypes: {
    identityId: String
  },
  attributeChangeFactory: (name, value) => msg('AttributeChange', name, value),
  shadow: false,
  init: (dispatch: any, onRefChange) => {
    onRefChange((ref: HTMLElement, oldRef?: HTMLElement) => {
      if (!ref && oldRef) {
        oldRef.removeEventListener('addroles', onAddRoles as EventListener);
        oldRef.removeEventListener('setroles', onSetRoles as EventListener);
      } else if (ref) {
        ref.addEventListener('addroles', onAddRoles as EventListener);
        ref.addEventListener('setroles', onSetRoles as EventListener);
      }
    });

    function onSetRoles(event: CustomEvent) {
      dispatch(msg('SetRoles', event.detail));
    }

    function onAddRoles(event: CustomEvent) {
      dispatch(msg('AddRoles', event.detail));
    }

    return [
      {
        identityId: '',
        isLoading: false,
        labels: {
          'roles:read': {
            pl: 'Przeglądanie ról'
          }
        }
      },
      loadRoles()
    ]
  },
  update: (state: State, msg: Msg) => {
    return match<Msg, [State, stm.Cmd<Msg>]>(msg)
      .with(['AttributeChange', 'identityId', P.select()], identityId => {
        if (identityId) {
          return [
            { ...state, identityId },
            loadIdentity(identityId)
          ];
        }
        return [
          { ...state, identityId },
          null
        ];
      })
      .with(['AttributeChange', P._, P._], () => [state, null])
      .with(['RolesLoadFailed'], () => {
        flashMessage(tr('sec.rolesLoadFailed'), 'error');
        return [state, null];
      })
      .with(['RolesLoadSuccess', P.select()], roles => [
        { ...state, roles },
        null
      ])
      .with(['IdentityLoadFailed'], () => {
        flashMessage(tr('secIdentityRolesForm.rolesLoadFailed'), 'error')
        return [state, null];
      })
      .with(['IdentityLoadSuccess', P.select()], identity => [
        { ...state, identity },
        null
      ])
      .with(['RemoveRole', P.select()], role => [
        { ...state, isLoading: true },
        removeRole(state.identityId, role)
      ])
      .with(['RemoveRoleFailed'], () => {
        flashMessage(tr('secIdentityRolesForm.removeFailed'), 'error')
        return [
          { ...state, isLoading: false },
          null
        ];
      })
      .with(['RemoveRoleSuccess', P.select()], role => [
        {
          ...state,
          isLoading: false,
          identity: {
            ...state.identity,
            roles: state.identity?.roles.filter(r => role !== r) ?? []
          }
        },
        null
      ])

      .with(['AddRole', P.select()], role => [
        { ...state, isLoading: true },
        addRole(state.identityId, role)
      ])
      .with(['AddRoleFailed'], () => {
        flashMessage(tr('secIdentityRolesForm.addFailed'), 'error')
        return [
          { ...state, isLoading: false },
          null
        ];
      })
      .with(['AddRoleSuccess', P.select()], role => [
        {
          ...state,
          isLoading: false,
          identity: {
            ...state.identity,
            roles: (state.identity?.roles ?? []).concat([role]) ?? []
          }
        },
        null
      ])

      .with(['SetRoles', P.select()], roles => [
        { ...state, isLoading: true },
        setRoles(state.identityId, roles)
      ])
      .with(['SetRolesFailed'], () => {
        flashMessage(tr('secIdentityRolesForm.setRolesFailed'), 'error')
        return [
          { ...state, isLoading: false },
          null
        ];
      })
      .with(['SetRolesSuccess', P.select()], roles => [
        {
          ...state, isLoading: false,
          identity: {
            ...state.identity,
            roles
          }
        },
        null
      ])

      .with(['AddRoles', P.select()], roles => [
        { ...state, isLoading: true },
        addRoles(state.identityId, roles)
      ])
      .with(['AddRolesFailed'], () => {
        flashMessage(tr('secIdentityRolesForm.addRolesFailed'), 'error')
        return [
          { ...state, isLoading: false },
          null
        ];
      })
      .with(['AddRolesSuccess', P.select()], roles => [
        {
          ...state, isLoading: false,
          identity: {
            ...state.identity,
            roles: _.uniq([...(state.identity?.roles ?? []), ...roles])
          }
        },
        null
      ])

      .exhaustive();
  },
  view
});

async function setRoles(identityId: string, roles: string[]): Promise<Msg> {
  try {
    const result = await grow.proxy('Security').replaceRoles(roles, identityId);
    return msg('SetRolesSuccess', roles);
  } catch (err) {
    console.error('set roles failed', err);
    return msg('SetRolesFailed');
  }
}

async function addRoles(identityId: string, roles: string[]): Promise<Msg> {
  try {
    const result = await grow.proxy('Security').addRole(roles, identityId);
    if (result.err) {
      console.error('adding roles returned error', result.err);
      return msg('AddRoleFailed');
    }
    return msg('AddRolesSuccess', roles);
  } catch (err) {
    console.error('add roles failed', err);
    return msg('AddRoleFailed');
  }
}


async function addRole(identityId: string, role: string): Promise<Msg> {
  try {
    const result = await grow.proxy('Security').addRole(role, identityId);
    if (result.err) {
      console.error('adding role returned error', result.err);
      return msg('AddRoleFailed');
    }
    return msg('AddRoleSuccess', role);
  } catch (err) {
    console.error('add role failed', err);
    return msg('AddRoleFailed');
  }
}

async function removeRole(identityId: string, role: string): Promise<Msg> {
  try {
    const result = await grow.proxy('Security').removeRole(role, identityId);
    if (result.err) {
      console.error('removing role returned error', result.err);
      return msg('RemoveRoleFailed');
    }
    return msg('RemoveRoleSuccess', role);
  } catch (err) {
    console.error('removing role failed', err);
    return msg('RemoveRoleFailed');
  }
}

async function loadRoles(): Promise<Msg> {
  try {
    const result = await grow.proxy('Security').roles();
    if (result.err) {
      console.error('loading roles returned an error', result.err);
      return msg('RolesLoadFailed');
    }
    return msg('RolesLoadSuccess', Object.keys(result));
  } catch (err) {
    console.error('loading roles failed', err);
    return msg('RolesLoadFailed');
  }
}

async function loadIdentity(identityId: string): Promise<Msg> {
  try {
    const identity = await grow.proxy('Security').getIdentityById(identityId);
    return msg('IdentityLoadSuccess', identity);
  } catch (err) {
    console.log('loading identity failed', err);
    return msg('IdentityLoadFailed');
  }
}

function view(state: State, children: any) {
  return match<[boolean, boolean], v.View<Msg>>([!!state.identity, !!state.roles])
    .with([true, true], () => rolesManagementView(state))
    .with([P._, P._], () => v.swLoader())
    .exhaustive();
}

function rolesManagementView(state: State) {
  const userRoles = (state.identity?.roles ?? [])
    .sort((a: string, b: string) => a.localeCompare(b));

  const allRoles = (state.roles ?? []).filter(r => !userRoles.includes(r))
    .sort((a: string, b: string) => a.localeCompare(b));

  return v<Msg>('.sec-identity-roles-form',
    { className: state.isLoading ? 'disabled loading' : '' },
    v('.roles-sides',
      v('.half',
        v.h4(tr('secIdentityRolesForm.missingRoles')),
        allRoles.length === 0
          ? tr('secIdentityRolesForm.userHasAllRoles')
          : v('ul.identity-roles', ...allRoles.map(role => v.li<Msg>(
            v.span({ title: role }, role),
            v('i.icon.icon-plus', {
              onclick: msg('AddRole', role),
              title: tr('secIdentityRolesForm.addRole')
            })
          )))
      ),
      v('.half',
        v.h4(tr('secIdentityRolesForm.ownRoles')),
        userRoles.length === 0
          ? tr('secIdentityRolesForm.userHasNoRoles')
          : v('ul.identity-roles', ...userRoles.map(role => v.li<Msg>(
            v.span({ title: role }, role),
            v('i.icon.icon-cross', {
              onclick: msg('RemoveRole', role),
              title: tr('secIdentityRolesForm.removeRole')
            })
          )))
      )
    ),
    v.slot()
  );
}
