import _ from 'lodash';
import { router, stm, tr, dates } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';
import { UserData } from 'nexus/node/ResourceManager';
import { modalWrapper, techNav } from './view-utils';
import { UserState } from 'nexus/node/MetricsManager';

const resourceManager = grow.plant('ResourceManager');

type Msg
  = [type: 'SearchPhrase', phrase: string]
  | [type: 'LoadDataSuccess', data: UserData[], accessType: string]
  | [type: 'LoadDataFailed']
  | [type: 'SortBy', sortedBy: string[]]
  | [type: 'CloseAllModals']
  | [type: 'UrlChange']
  | [type: 'CloseAllModalsAndReloadOrgList']
  | [type: 'Attr', name: string, value: unknown]
  | [type: 'CancelClose']

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  route: router.Route;
  isLoading: boolean;
  userState?: UserState;
  searchPhrase: string;
  userData: UserData[];
  sortedBy: string[];
  accessType: string;
}

stm.component({
  tagName: 'fib-tech-installs-users',
  shadow: false,
  debug: false,
  propTypes: {
    userState: Object
  },
  attributeChangeFactory: (name, value) => msg('Attr', name, value),
  willMount(cmp: any, dispatch: stm.Dispatch<Msg>) {
    cmp.urlChangeListener = () => dispatch(msg('UrlChange'));
    router.addEventListener('routeChange', cmp.urlChangeListener);
  },
  willUnmount(cmp: any) {
    router.removeEventListener('routeChange', cmp.urlChangeListener);
  },
  init() {
    return [
      {
        route: router.getCurrentRoute(),
        isLoading: true,
        searchPhrase: '',
        userData: [],
        sortedBy: ['userFirstName'],
        accessType: ''
      },
      loadData()
    ];
  },
  update,
  view
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(['Attr', 'userState', P.select()], userState => {
      if (!userState || !_.isObject(userState)) {
        return [state, null];
      }
      state.userState = userState as any;
      return [state, null];
    })
    .with(['Attr', P._, P._], () => [state, null])
    .with(['SearchPhrase', P.select()], searchPhrase => [
      { ...state, searchPhrase }, null
    ])
    .with(['LoadDataFailed'], () => {
      return [{ ...state, isLoading: false }, null];
    })
    .with(['LoadDataSuccess', P.select('data'), P.select('access')], (data) => [
      {
        ...state,
        userData: data.data,
        isLoading: false,
        accessType: data.access
      }, null
    ])
    .with(['SortBy', P.select()], (sortedBy) => [
      {
        ...state,
        sortedBy
      }, null
    ])
    .with(['UrlChange'], () => [
      { ...state, route: router.getCurrentRoute() },
      null
    ])
    .with(['CloseAllModals'], () => {
      navigateToClosedModals(state.route.name);
      return [state, null];
    })
    .with(['CloseAllModalsAndReloadOrgList'], () => {
      navigateToClosedModals(state.route.name);
      return [state, loadData()];
    })
    .with(['CancelClose'], () => [state, null])
    .exhaustive();
}

async function loadData(): Promise<Msg> {
  try {
    const data = await resourceManager.getManagableInstallationIds();
    return msg('LoadDataSuccess', data.managersWithInstallation, data.userAccess);
  } catch (err) {
    console.error('loading userList data failed', err);
    return msg('LoadDataFailed');
  }
}

function navigateToClosedModals(routeName: string) {
  const newRouteName = match<string, string | null>(routeName)
    .with('techUserAdd', () => 'techInstallsUsers')
    .with(P._, () => 'techInstallsList')
    .exhaustive();
  if (newRouteName) {
    router.navigate(newRouteName);
  }
}


function view(state: State): stm.View<Msg> {
  return <div class="fib-tech-installs-users">
    {techNav(state.userState)}

    {techUsersList(state)}

    {state.route.name === 'techUserAdd' && modalWrapper<Msg>(
      <fib-user-create
        orgId={state.route.params['orgId']} // 'all' for technician, and ['all' || selected] for admina
        basePanel="technician"
        oncancel={(e: Event) => (e as CustomEvent).detail.unsaved
          ? confirm(tr('general.unsaved'))
            ? msg('CloseAllModals')
            : msg('CancelClose')
          : msg('CloseAllModals')}
        onsaved={msg('CloseAllModalsAndReloadOrgList')}></fib-user-create>
    )}

  </div>;
}

function isLoading(state: State) {
  return !state.userState || state.isLoading || !state.userData;
}

function techUsersList(state: State) {
  return (
    <main class="vbox fib-tech-users-list">
      <div class="grid">
        <div class="box1"></div>
        <nav class="secondary-nav box10">
          <h1 class="h600 install-list-margin">
            {tr('fibTechPanel.installationUsers')}
          </h1>
        </nav>
        <div class="box1"></div>
      </div>
      <div class="grid">
        <div class="box1"></div>
        <div class="box10">
          <div class="hbox tech-list-header">
            <span class="input-icon-after box3 ">
              <input class="body-medium"
                type="text"
                value={state.searchPhrase}
                placeholder={tr('general.search')}
                onInput={(event: any) => msg('SearchPhrase', event.target.value)} />
              <i class="icon-search"></i>
            </span>
            <a href={router.getRouteUrl('techUserAdd', { orgId: 'all', basePanel: 'technician' })}
              class="button-secondary h200">
              {tr('fibOrgList.addUser')}
              <i class="icon-plus"></i>
            </a>
          </div>
          {isLoading(state) && <div class="loader-big"></div>}
          {!isLoading(state) && usersTable(state)}
        </div>
        <div class="box1"></div>
      </div>

    </main>
  );
}


function usersTable(state: State) {
  /*
   * get list of users [zarzadcy budynku] connected to installation in technician area - limit to technician access area
   *
   */
  return (<>
    <table>
      <thead>
        <tr class="h200">
          <th>
            <div class="hbox">
              {tr('fibTechPanel.tblHeaderUser')}
              <i class="icon-sort-down"
                onClick={msg('SortBy', ['user.userFirstName', 'user.userLastName', 'user.userEmail']) as any}></i>
            </div>
          </th>
          <th>
            <div class="hbox">
              {tr('fibTechPanel.tblHeaderNotification')}
              <i class="icon-sort-down"></i>
            </div>
          </th>
          <th>
            <div class="hbox">
              {tr('fibTechPanel.tblHeaderInstallation')}
              <i class="icon-sort-down"
                onClick={msg('SortBy', ['installation.installationName']) as any}></i>
            </div>
          </th>
          <th>
            <div class="hbox">
              {tr('fibTechPanel.tblHeaderLicense')}
              <i class="icon-sort-down"
                onClick={msg('SortBy', ['userLicenseValidUntil']) as any}></i>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="body-large">
        <>
          {
            _.sortBy(state.userData, [...state.sortedBy])
              .filter(createFilter(state))
              .map(user => {
                return <tr className="fib-org-list">
                  <td>
                    <a href={router.getRouteUrl('techUserEdit', {
                      userId: user.userId,
                      basePanel: 'technician'
                    })} className="text-button h200">
                      {user.userFirstName} {user.userLastName} &lt;{user.userEmail}&gt;
                    </a>
                  </td>
                  <td>
                    {
                      user.userNotificationType?.map(notify => {
                        return <div
                          className="badge-gray80 body-small tech-user-list-notify h100">{notify}</div>
                      })
                    }
                  </td>
                  <td>
                    <div className="body-medium">
                      {user.installation.installationName}
                    </div>
                  </td>
                  <td className="license">
                    {
                      (user.userLicenseValidUntil > Date.now() && user.userLicenseValidUntil)
                        ? (user.userLicenseValidUntil < Date.now() + twoWeeks()
                          ? <div
                            className="badge-orange body-small">{dates.formatDate(user.userLicenseValidUntil)}</div>
                          : <div
                            className="badge-green body-small">{dates.formatDate(user.userLicenseValidUntil)}</div>
                        )
                        : <div className="badge-red body-small">{tr("license.inactive")}</div>
                    }
                  </td>
                </tr>
              })
          }
        </>
      </tbody>
    </table>
  </>);
}


function createFilter(state: State) {
  const phrase = state.searchPhrase.toLowerCase();
  return function(userData: UserData) {
    if (!state.searchPhrase) {
      return true;
    }

    if (userData.userEmail.toLowerCase().includes(phrase) ||
      userData.userFirstName.toLowerCase().includes(phrase) ||
      userData.userLastName.toLowerCase().includes(phrase)) {
      return true;
    }

    if (userData.installation.installationName.toLowerCase().includes(phrase)) {
      return true;
    }

    if (userData.userEmail.toLowerCase().includes(phrase.replace(' ', ''))) {
      return true;
    }

    return false;
  };
}

function twoWeeks() {
  return 14 * 24 * 60 * 60 * 1000;
}
