import _ from "lodash";
import { flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { AdvancedSettings } from "../../nexus/node/InstallationManager";

const installationManager = grow.plant("InstallationManager");
const advancedManager = grow.plant("AdvancedManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "InputChange", name: string, value: string]
  | [type: "LoadingAdvancedSettingsSuccess", settings: AdvancedSettings]
  | [type: "Error", errMsg: string]
  | [type: "SaveSpeed"]
  | [type: "SaveSuccess"]
  | [type: "Tab", name: string];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  isLoading: boolean;
  installationId: string;
  installationName: string;
  viewState: "params" | "info" | "logs" | "thresholds" | "config";
  tab: string;
  inputs: {
    [key: string]: string;
  };
  errMsg: string;
  settings: AdvancedSettings;
  isInProgress: boolean;
}

stm.component({
  tagName: "fib-tech-home-config",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
    viewState: String,
    installationName: String,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        installationId: "",
        installationName: "",
        viewState: "config",
        tab: "",
        settings: {
          drainSpeed: 0,
        },
        inputs: {},
        errMsg: "",
        isInProgress: false,
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      if (!_.isString(installationId)) {
        return [state, null];
      }
      state.isLoading = true;
      state.installationId = installationId;
      return [state, loadAdvancedSettings()];
    })
    .with(["Attr", "viewState", P.select()], (viewState: any) => [
      {
        ...state,
        viewState: ["params", "info", "logs", "thresholds", "config"].includes(viewState)
          ? viewState
          : "config",
      },
      null,
    ])
    .with(["Attr", "installationName", P.select()], (name) => {
      if (!_.isString(name)) {
        return [state, null];
      }
      state.installationName = name;
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(
      ["LoadingAdvancedSettingsSuccess", P.select()],
      (settings: AdvancedSettings) => {
        if (!settings.drainSpeed) {
          flashMessage(tr("fib.advanced.drainSpeedError"), "error");
          return [state, null];
        }
        state.inputs.speed = settings.drainSpeed.toString();
        return [state, null];
      },
    )
    .with(["Tab", P.select()], (tab) => {
      state.tab = tab;
      state.errMsg = "";
      return [state, null];
    })
    .with(["InputChange", P._, P._], ([, name, value]) => {
      state.inputs[name] = value;
      state.errMsg = "";
      return [state, null];
    })
    .with(["SaveSpeed"], () => {
      state.isInProgress = true;
      flashMessage(tr("general.inProgress"), "info");
      return [state, saveSpeed(state, state.inputs.speed)];
    })
    .with(["SaveSuccess"], () => {
      flashMessage(tr("gcrud.saveSuccess"), "success");
      state.isInProgress = false;
      return [state, null];
    })
    .with(["Error", P.select()], (errMsg) => {
      flashMessage(errMsg, "error");
      state.isInProgress = false;
      state.errMsg = errMsg;
      return [state, null];
    })
    .exhaustive();
}

async function loadAdvancedSettings() {
  try {
    const settings = await installationManager.displayAdvancedSettings();
    return msg("LoadingAdvancedSettingsSuccess", settings);
  } catch (err) {
    console.error("loading settings failed", err);
    return msg("Error", tr("general.saveFailed"));
  }
}

async function saveSpeed(state: State, value: string) {
  try {
    await installationManager.setDrainSpeed(Number(value));
    return msg("SaveSuccess");
  } catch (err) {
    console.error("saving drain speed failed", err);
    return msg("Error", tr("general.saveFailed"));
  }
}

function view(state: State): stm.View<Msg> {
  return (
    <>
      <main class={"vbox"}>
        <div className="fib-advanced config-panel">
          <div className="fib-advanced-col">
            <p class={"h500"}>{tr("fibTechPanel.deviceConfig")}</p>
            <ul className="list">
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "logs")}
              >
                {tr("fibTechHomePanel.logs")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "thresholds")}
              >
                {tr("fibTechHomePanel.thresholdValues")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "speed")}
              >
                {tr("fib.advanced.speed")}
              </li>

              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "alerts")}
              >
                {tr("fib.advanced.alertsConfirm")}
              </li>
            </ul>
          </div>

          <div className="fib-advanced-col plate">
            <div class={"fib-advanced-content"}>
              <form
                class={"fib-advanced-form"}
                encType="multipart/form-data"
                onSubmit={(event: Event) => event.preventDefault()}
              >
                {renderContent(state)}
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

function renderContent(state: State) {
  const forAlerts = {
    installations: [{
      id: state.installationId,
      name: state.installationName,
    }],
  };

  switch (state.tab) {
    case "speed":
      return (
        <>
          <div class={"form-element"}>
            <p className={"h400"}>{tr("fib.advanced.speedHeading")}</p>
          </div>
          <div class={"form-element"}>
            <input
              class={"body-large"}
              type="number"
              placeholder={tr("fib.advanced.inputPlaceholder")}
              min={1}
              step={.1}
              value={state.inputs.speed}
              onInput={(e: Event) =>
                msg(
                  "InputChange",
                  "speed",
                  (e.target as HTMLInputElement).value,
                )}
            />
          </div>
          <div className={"form-element"}>
            <span className={"error"}>{state.errMsg}</span>
          </div>
          <div class={"form-element"}>
            <button
              class={"button-primary"}
              disabled={state.isInProgress}
              onClick={() => msg("SaveSpeed")}
            >
              {tr("general.saveChanges")}
            </button>
          </div>
        </>
      );
    case "alerts":
      return <fib-alerts-list user-state={forAlerts}></fib-alerts-list>;
    case "logs":
      return (
        <>
          <fib-manager-logs installation-id={state.installationId} />
        </>
      );
    case "thresholds":
      return (
        <>
          <fib-tech-home-thresholds
            installation-id={state.installationId}
            view-state={"thresholds"}
          />
        </>
      );
  }
}
