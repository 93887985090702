import routes from './routes.json';
import translations from './translations.json';
import { router, tr, modal, flashMessage } from '@7willows/sw-lib';
import * as realtime from "./realtime";

modal.disableDefaultStyles();
flashMessage.disableDefaultStyles();
flashMessage.disableLevelIcon();

tr.setLang('pl');

routes.forEach((r: any) => router.addRoute(r as any));

realtime.init();

Object.keys(translations).forEach(phrase => tr.addTranslation(phrase, (translations as any)[phrase]));

if (localStorage.getItem('fibcloud.debug') === 'yes') {
  (window as any).debug = true;
  localStorage.removeItem('fibcloud.debug');

}
(window as any).reloadWithDebug = function() {
  localStorage.setItem('fibcloud.debug', 'yes');
  window.location.reload();
}
