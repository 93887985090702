import { flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { ChartParameters } from "nexus/node/MetricsAccess";
import {
  ChartSlotConfig,
  ChartSlotData,
  DashboardConfig,
  DashboardOpener,
  DeviceParameter,
  MetricData,
  Param,
} from "nexus/node/contracts";
import { UserState } from "nexus/node/MetricsManager";
import { unitConversion } from "./view-utils";

const metricsManager = grow.plant("MetricsManager");
const dashboardManager = grow.plant("DashboardManager");
const installationManager = grow.plant("InstallationManager");
const lang = tr.getLang();

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "OpenModal"]
  | [type: "CloseModal"]
  | [type: "LoadDataFailed"]
  | [type: "LoadChartDataSuccess", data: { metricsData: MetricData; param: string }]
  | [
    type: "LoadDataSuccess",
    data: {
      devices: any;
      dashboardOpener: DashboardOpener;
      lastMetrics: any;
      paramsDescription: any;
      token: string;
    },
  ]
  | [
    type: "CreateChart",
    value: { marker: string; sn: string; param: Param; installationId: string },
  ]
  | [
    type: "Zoom",
    value: { chart: { config: ChartSlotConfig; data: ChartSlotData }; timeFrom: number },
  ]
  | [type: "RemoveChart", chart: { config: ChartSlotConfig; data: ChartSlotData }]
  | [type: "DownloadReports"]
  | [type: "DownloadReportsSuccess"]
  | [type: "DownloadReportsFailed"]
  | [type: "SaveDashboardError"]
  | [type: "SaveDashboardSuccess"];

interface State {
  installationId: string;
  userState?: UserState;
  devices: any;
  isChartDataLoading: { [paramName: string]: boolean };
  dashboardOpener: DashboardOpener;
  lastMetrics: Record<string, number>;
  paramsDescription: DeviceParameter[];
  token: string;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-reports",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
    userState: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(): [State, stm.Cmd<Msg>] {
    const state: State = {
      installationId: "",
      devices: [],
      isChartDataLoading: {},
      dashboardOpener: {
        config: {
          type: "reports",
          dashboardName: "reports",
          bgImage: { contentType: "", data: "" },
          slots: {},
        },
        data: {},
        streamId: "",
      },
      lastMetrics: {},
      paramsDescription: [],
      token: "",
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      state.installationId = installationId as string;
      return [state, loadData(installationId as string)];
    })
    .with(["Attr", "userState", P.select()], (userState: UserState) => {
      if (!userState.platform) {
        userState.platform = "fibrain";
      }
      state.userState = userState;
      return [state, null];
    })
    .with(["LoadDataFailed"], () => {
      flashMessage.error(tr("general.loadFailed"));

      Object.keys(state.isChartDataLoading).map((param) => {
        state.isChartDataLoading[param] = false;
      });

      return [state, null];
    })
    .with(
      ["LoadDataSuccess", P.select()],
      ({ devices, dashboardOpener, lastMetrics, paramsDescription, token }) => {
        state.devices = devices;
        state.dashboardOpener = dashboardOpener;
        state.lastMetrics = lastMetrics;
        state.paramsDescription = paramsDescription;
        state.token = token;
        Object.keys(state.isChartDataLoading).map((param) => {
          state.isChartDataLoading[param] = false;
        });

        return [state, null];
      },
    )
    .with(["Attr", P._, P._], () => [state, null])
    .with(["OpenModal"], () => [state, openModal(state)])
    .with(["CloseModal"], () => [state, null])
    .with(["CreateChart", P.select()], ({ marker, sn, param, installationId }) => {
      const timeFrom = Date.now() - 1000 * 60 * 60 * 24 * 5;
      const timeTo = Date.now();

      const paramsParam = findParam(state.paramsDescription, param.param, marker);
      const newSlotName = `${param.param}-${marker}`;
      state.dashboardOpener.config.slots[newSlotName] = {
        type: "chart",
        order: 0,
        title: tr(paramsParam?.name),
        deviceSn: sn,
        unit: tr(paramsParam?.unit),
        prop: param.param,
        color: "#33EE67",
        duration: timeTo - timeFrom,
        deviceMarker: marker,
        timeFrom,
        timeTo,
      };

      const chartParameters: ChartParameters = {
        installationId,
        sn: sn,
        params: [param.param],
        timeFrom,
        timeTo,
        marker,
      };

      state.isChartDataLoading[param.param] = true;

      return [state, loadChartData(chartParameters)];
    })
    .with(["LoadChartDataSuccess", P.select()], (data) => {
      state.dashboardOpener.data[data.param] = data.metricsData.values;

      state.isChartDataLoading[data.param] = false;
      return [state, saveDashboard(state)];
    })
    .with(["Zoom", P.select()], (value) => {
      if (value.timeFrom === Number.POSITIVE_INFINITY) {
        flashMessage(tr("fib.chart.zoomIsMin"), "info");
        return [state, null];
      }

      if (value.timeFrom === Number.NEGATIVE_INFINITY) {
        flashMessage(tr("fib.chart.zoomIsMax"), "info");
        return [state, null];
      }

      const param = value.chart.data.param;
      state.isChartDataLoading[param] = true;
      const timeTo = Date.now();
      const chartParameters: ChartParameters = {
        installationId: state.installationId,
        sn: value.chart.config.deviceSn,
        params: [param],
        timeFrom: value.timeFrom,
        timeTo,
        marker: value.chart.config.deviceMarker,
      };
      const duration = timeTo - value.timeFrom;

      if (state.dashboardOpener.config.slots[param]) {
        state.dashboardOpener.config.slots[param].duration = duration;
      }

      return [state, loadChartData(chartParameters)];
    })
    .with(["RemoveChart", P.select()], (chart) => {
      const prop = `${chart.config.prop}-${chart.config.deviceMarker}`;
      const propOld = chart.config.prop;
      delete state.dashboardOpener.config.slots[prop];
      delete state.dashboardOpener.data[prop];
      delete state.isChartDataLoading[prop];
      delete state.dashboardOpener.config.slots[propOld];
      delete state.dashboardOpener.data[propOld];
      delete state.isChartDataLoading[propOld];
      return [state, saveDashboard(state)];
    })
    .with(["SaveDashboardSuccess"], () => {
      flashMessage(tr("gcrud.saveSuccess"), "success");
      return [state, null];
    })
    .with(["SaveDashboardError"], () => {
      flashMessage(tr("general.saveFailed"), "error");
      return [state, null];
    })
    .with(["DownloadReports"], () => {
      return [state, downloadChart(state)];
    })
    .with(["DownloadReportsSuccess"], () => {
      return [state, null];
    })
    .with(["DownloadReportsFailed"], () => {
      flashMessage(tr("fib.tech.loadingError"), "error");
      return [state, null];
    })
    .exhaustive();
}

function downloadChart(state: State) {
  let allSeries: string = "";

  for (const [key, configuration] of Object.entries(state.dashboardOpener.config?.slots ?? {})) {
    const config = configuration as ChartSlotConfig;
    const data = convertToCSV(state.dashboardOpener.data[config.prop]);
    const csv = `${config.prop}\r\n${config.title}\r\ntimestamp;value\r\n${data}`;
    allSeries += csv;
  }

  try {
    const blob = new Blob([allSeries], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "export.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return msg("DownloadReportsSuccess");
  } catch (err) {
    return msg("DownloadReportsFailed");
  }
}

function convertToCSV(array: any[]) {
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      if (line != "") {
        line += ";";
      }
      line += array[i][index];
    }
    str += line + "\r\n";
  }

  return str;
}

async function loadData(installationId: string) {
  try {
    const data = await metricsManager.technicianParams(installationId);
    if (!data) {
      return msg("LoadDataFailed");
    }

    const lastMetrics = data.lastMetrics;

    const paramsDescription = await installationManager.readInstallationParams(
      {
        instId: installationId,
        platform: "",
        onlyVisible: true,
      },
    );

    data.devices.map((device: any) => {
      device.installationId = installationId;
      device.params = [];
      const paramsFiltered = paramsDescription.filter((p: DeviceParameter) =>
        p.deviceMarker === device.marker
      )[0].params;
      Object.keys(device).forEach((key) => {
        const param = paramsFiltered.find((param: any) => param.param === key);
        if (param) {
          device.params.push(param);
        }
      });
    });

    const dashboards = await dashboardManager.loadDashboards(installationId, {
      fields: ["token"],
      includeHidden: false,
    });
    const token = dashboards.find((dashboard: any) => dashboard.dashboardId === "reports")
      ?.["token"];
    if (!token) {
      return msg("LoadDataSuccess", {
        devices: data.devices,
        dashboardOpener: {
          config: {
            type: "reports",
            dashboardName: "reports",
            bgImage: {
              contentType: "",
              data: "",
            },
            slots: {},
          },
          data: {},
          streamId: "",
        },
        lastMetrics,
        paramsDescription,
        token,
      });
    }
    const dashboardOpener = await dashboardManager.openDashboard({
      itemId: "reports",
      workspaceId: installationId,
    }, token);
    return msg("LoadDataSuccess", {
      devices: data.devices,
      dashboardOpener,
      lastMetrics,
      paramsDescription,
      token,
    });
  } catch (error) {
    return msg("LoadDataFailed");
  }
}

function findParam(params: Param[], searchParam: string, marker: string): Param {
  const paramFiltered = params
    .filter((p: Param) => p.deviceMarker === marker)[0]
    .params.find((param: Param) => searchParam === param.param);

  if (paramFiltered.name[lang] === "not defined") {
    paramFiltered.name[lang] = `[${searchParam}]`;
  }

  if (paramFiltered.unit === "") {
    paramFiltered.unit = ".";
  }

  return paramFiltered;
}

async function openModal(state: State) {
  const { devices } = state;
  if (!devices) {
    return msg("LoadDataFailed");
  }

  const devicesExt = { devices, params: state.paramsDescription };

  const result = await modal({
    header: tr("fib.manager.otherReport"),
    large: true,
    body: (close) => (
      <fib-select-parameter devices={devicesExt} closeFn={close}></fib-select-parameter>
    ),
  }) as {
    marker: string;
    deviceSn: string;
    param: string;
    installationId: string;
  };

  if (result) {
    const selectedDevice = devices.find((device: any) => device.marker === result.marker);

    const param: Param = selectedDevice.params.find((param: Param) => param.param === result.param);

    return msg("CreateChart", {
      marker: result.marker,
      sn: result.deviceSn,
      param,
      installationId: selectedDevice.installationId,
    });
  } else {
    return msg("CloseModal");
  }
}

async function saveDashboard(state: State) {
  try {
    const imageUrl = `./static/img/dashboards/300x300.png`;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const reader = new FileReader();
    const imgBase64 = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    if (typeof imgBase64 !== "string") {
      return msg("SaveDashboardError");
    }

    const key = { workspaceId: router.getCurrentRoute().params.installationId, itemId: "reports" };
    const config: DashboardConfig = {
      type: "reports",
      dashboardName: "reports",
      bgImage: {
        contentType: "",
        data: imgBase64,
      },
      slots: state.dashboardOpener.config.slots,
    };

    const params = Object.keys(state.dashboardOpener.config.slots);

    for (const [param, values] of Object.entries(state.dashboardOpener.config.slots)) {
      if (!values.deviceMarker) {
        const foundDevice = state.devices.find((dev: any) => dev.sn === values.deviceSn);
        if (foundDevice) {
          config.slots[param].deviceMarker = foundDevice.marker;
        }
      }
      if (!values.deviceSn && values.deviceMarker) {
        const foundDevice = state.devices.find((dev: any) => dev.marker === values.deviceMarker);
        if (foundDevice) {
          config.slots[param].deviceSn = foundDevice.sn;
        }
      }
    }

    const dashboardId = await dashboardManager.configureDashboard(key, config);
  } catch (err) {
    return msg("SaveDashboardError");
  }
  return msg("SaveDashboardSuccess");
}

async function loadChartData(chartParameters: ChartParameters) {
  let metricsData;

  try {
    metricsData = await metricsManager.getMetricsInTime(chartParameters);
  } catch (error) {
    return msg("LoadDataFailed");
  }
  return msg("LoadChartDataSuccess", {
    metricsData: metricsData[0],
    param: chartParameters.params[0],
  });
}

function view(state: State): stm.View<Msg> {
  let sum1 = 0, sum2 = 0, sum3 = 0, sum4 = 0;

  let lastUpdate = "";

  state.devices.map(
    ({ accumulatedEnergyYield, dailyEnergyYield, activePower, co2Reduction, updatedAt }: any) => {
      if (accumulatedEnergyYield && typeof accumulatedEnergyYield === "number") {
        sum1 += accumulatedEnergyYield;
        lastUpdate = updatedAt;
      }

      if (dailyEnergyYield && typeof dailyEnergyYield === "number") {
        sum2 += dailyEnergyYield;
      }

      if (activePower && typeof activePower === "number") {
        sum3 += activePower;
      }

      if (co2Reduction && typeof co2Reduction === "number") {
        sum4 += co2Reduction;
      }
    },
  );

  sum4 = state.lastMetrics.co2Reduction ?? 0;

  if (state.lastMetrics.dailyEnergyYield) {
    sum2 = state.lastMetrics.dailyEnergyYield;
  }

  if (state.lastMetrics.accumulatedEnergyYield) {
    sum1 = state.lastMetrics.accumulatedEnergyYield;
  }

  if (state.lastMetrics.activePower) {
    sum3 = state.lastMetrics.activePower;
  }

  const { value: sumAccumEnergy, unit: sumAccumEnergyUnit } = unitConversion(
    tr("fib.manager.reportTopUnitsperHour"),
    sum1,
  );
  const { value: sumDailyEnergy, unit: sumDailyEnergyUnit } = unitConversion(
    tr("fib.manager.reportTopUnitsperHour"),
    sum2,
  );
  const { value: sumActivePower, unit: sumActivePowerUnit } = unitConversion(
    tr("fib.manager.reportTopUnits"),
    sum3,
  );
  const { value: sumEcology, unit: sumEcologyUnit } = unitConversion(
    tr("fib.manager.reportTopUnitKobize"),
    sum4,
  );

  const charts: { config: ChartSlotConfig; data: ChartSlotData }[] = [];
  for (const [key, config] of Object.entries(state.dashboardOpener.config?.slots ?? {})) {
    if (config.type === "chart") {
      const data = {
        param: config.prop,
        values: state.dashboardOpener.data[config.prop] as ChartSlotData,
      };

      charts.push({ config, data });
    }
  }

  if (!state.userState || !state.installationId) {
    return <div class={"loader-big"}></div>;
  }
  const installation = {
    installationId: state.installationId,
    platform: state.userState.platform,
    machineId: state.userState.platform === "home"
      ? state.userState.machineId
      : state.userState.installations.find((inst) => inst.id === state.installationId).machineId,
    token: state.token,
  };

  return (
    <>
      {
        /* <div className={"hbox mgr-download-wrapper"}>
        <div className={"box9"}></div>
        <div className={"box3"}>
          <button className={"button-primary"} onclick={msg("DownloadReports")}>
            <i className={"icon-download"} />
            {tr("fib.manager.downloadAll")}
          </button>
        </div>
      </div> */
      }

      <div class={"mgr-reports-wrapper"}>
        <div class={"hbox"}>
          <div class={"mgr-report sum1"}>
            <span class={"mgr-report-title"}>{tr("params.accumulatedEnergyYield")}</span>
            <div>
              <span class={"mgr-report-value"}>{sumAccumEnergy}</span>
              <span class={"mgr-report-unit"}>{sumAccumEnergyUnit}</span>
            </div>
          </div>

          <div class={"mgr-report sum2"}>
            <span class={"mgr-report-title"}>{tr("params.dailyEnergyYield")}</span>
            <div>
              <span class={"mgr-report-value"}>{sumDailyEnergy}</span>
              <span class={"mgr-report-unit"}>{sumDailyEnergyUnit}</span>
            </div>
          </div>

          <div class={"mgr-report sum3"}>
            <span class={"mgr-report-title"}>{tr("params.activePower")}</span>
            <div>
              <span class={"mgr-report-value"}>{sumActivePower}</span>
              <span class={"mgr-report-unit"}>{sumActivePowerUnit}</span>
            </div>
          </div>

          <div class={"mgr-report sum4"}>
            <span class={"mgr-report-title"}>{tr("params.ecology")}</span>
            <div>
              <span class={"mgr-report-value"}>{sumEcology}</span>
              <span class={"mgr-report-unit"}>
                {sumEcologyUnit}
              </span>
            </div>
          </div>
        </div>

        <div class={"hbox align-stretch"}>
          <>
            {charts.length > 0 && charts.map((chart) => (
              <div className={"mgr-chart-wrapper-extra"}>
                <div className={"mgr-chart-title-wrapper"}>
                  <span>{tr(`params.${chart.config.prop}`)}</span>
                  <div
                    className="button-tertiary h200 square-button close-button "
                    onClick={() => msg("RemoveChart", chart)}
                  >
                    <i className="icon-cross" style="line-height:30px" />
                  </div>
                </div>

                {state.isChartDataLoading[chart.config.prop]
                  ? (
                    <div class={"loader-wrapper"}>
                      <div class={"loader-big"}></div>
                    </div>
                  )
                  : (
                    // <fib-manager-chart-slot
                    //   chart-slot-config={chart.config}
                    //   chart-slot-data={chart.data}
                    //   // onzoom={(e: CustomEvent) => msg("Zoom", { chart: chart, timeFrom: e.detail })}
                    // />
                    <fib-manager-chart-builder
                      installation={{ ...installation, chartConfig: chart.config }}
                    >
                    </fib-manager-chart-builder>
                  )}
              </div>
            ))}
          </>

          <div className={"mgr-new-chart"}>
            <div className={"button-tertiary"} onClick={() => msg("OpenModal")}>
              <i className={"icon-plus"}></i>
              {tr("fib.manager.createNewChat")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
