import { flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { DashboardOpener, ValueSlotConfig } from "nexus/node/contracts";
import params from "./params.json";
import presets from "./presets.json";

const dashboardManager = grow.plant("DashboardManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "ChooseBg", value: string]
  | [type: "ChoosePreset", value: string]
  | [type: "SaveDashboard"]
  | [type: "SaveDashboardSuccess"]
  | [type: "SaveDashboardError"]
  | [type: "None"]
  | [type: "UploadImg", file: Blob]
  | [type: "UploadImgError", errMsg: string]
  | [type: "ConvertSuccess", imgBase64: string]
  | [type: "UpdateDashboardTitle", title: string]
  | [type: "UpdateCircleTitle", circletitle: string];

interface State {
  preset: string;
  dashboardOpener: DashboardOpener;
  dashboardName: string;
  displayBgInput: boolean;
  installationId: string;
  isInProgress: boolean;
  platform: string;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-dashboards-builder",
  shadow: false,
  debug: false,
  propTypes: {
    userState: Object,
  },
  attributeChangeFactory: (name: string, value: any) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      preset: "",
      dashboardOpener: {
        streamId: "",
        config: {
          type: "1",
          dashboardName: "",
          bgImage: {
            contentType: "",
            data: "",
          },
          slots: {
            circles: {
              type: "circles",
              order: 1,
              props: [
                {
                  deviceSn: "sum:inverter",
                  deviceMarker: "sum:inverter",
                  label: tr(
                    params.find((param) => param.param === "accumulatedEnergyYield")?.name as any,
                  ),
                  prop: "accumulatedEnergyYield",
                  unit: tr(
                    params.find((param) => param.param === "accumulatedEnergyYield")?.unit as any,
                  ),
                },
              ],
              title: "title",
            },
            doughnut: {
              type: "doughnut",
              order: 1,
              title: "active",
              subtitle: "subtitle",
              prop: "activePower",
              deviceKind: "inverter",
              deviceMarkers: [],
              unit: "kW",
              color: "#33EE67",
            },
            chart: {
              type: "chart",
              order: 2,
              title: tr(
                params.find((param) => param.param === "accumulatedEnergyYield")
                  ?.name as any,
              ),
              deviceSn: "sum:summary",
              deviceMarker: "sum:inverter",
              unit: tr(
                params.find((param) => param.param === "accumulatedEnergyYield")
                  ?.unit as any,
              ),
              prop: "sumOfAccumulatedEnergyYield",
              color: "#33EE67",
              duration: 1000 * 60 * 60,
            },
            weather: {
              type: "weather",
              order: 0,
              title: tr("params.weather"),
            },
            ecology: generateValueSlot(
              "kobizeSavedCO2",
              "kg",
              tr("params.ecology.subtitle"),
              "#33EE67",
            ),
            dailyEnergyYield: generateValueSlot("dailyEnergyYield"),
            accumulatedEnergyYield: generateValueSlot("accumulatedEnergyYield"),
            activePower: generateValueSlot("activePower"),
            reactivePower: generateValueSlot("reactivePower"),
            efficiency: generateValueSlot("efficiency"),
            pv1Voltage: generateValueSlot("pv1Voltage"),
            pv1Current: generateValueSlot("pv1Current"),
            activeExportedEnergy: generateValueSlot("activeExportedEnergy"),
            activeImportedEnergy: generateValueSlot("activeImportedEnergy"),
            apparentEnergy: generateValueSlot("apparentEnergy"),
            nominalCurrent1: generateValueSlot("nominalCurrent1"),
            nominalCurrent2: generateValueSlot("nominalCurrent2"),
            nominalVoltage1: generateValueSlot("nominalVoltage1"),
            reactiveInductiveEnergy: generateValueSlot(
              "reactiveInductiveEnergy",
            ),
            reactiveCapacitiveEnergy: generateValueSlot(
              "reactiveCapacitiveEnergy",
            ),
          },
        },
        data: {
          circles: [{
            alerts: [],
            value: between(1, 9999),
            length: 1,
            speed: 1,
          }],
          chart: {
            param: "sumOfAccumulatedEnergyYield",
            values: generateChartValues(),
          },
          doughnut: {
            label: "activePower",
            backgrounds: [],
            data: [{
              value: 1,
              label: "total ",
              alerts: [],
            }],
          },
          ecology: between(1, 9998),
          dailyEnergyYield: between(1, 9999),
          accumulatedEnergyYield: between(1, 9999),
          activePower: between(1, 9999),
          reactivePower: between(1, 9999),
          efficiency: between(1, 9999),
          pv1Voltage: between(1, 9999),
          pv1Current: between(1, 9999),
          activeExportedEnergy: between(1, 9999),
          activeImportedEnergy: between(1, 9999),
          apparentEnergy: between(1, 9999),
          nominalCurrent1: between(1, 9999),
          nominalCurrent2: between(1, 9999),
          nominalVoltage1: between(1, 9999),
          reactiveInductiveEnergy: between(1, 9999),
          reactiveCapacitiveEnergy: between(1, 9999),
        },
      },
      dashboardName: "Dashboard Title",
      displayBgInput: false,
      installationId: router.getCurrentRoute().params.installationId,
      isInProgress: false,
      platform: "fibrain",
    };
    state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 3;
    state.dashboardOpener.config.slots.ecology.order = 4;
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "userState", P.select()], (userState: any) => {
      state.platform = userState.platform ?? "fibrain";
      return [state, null];
    })
    .with(["Attr", P._, P._], () => {
      return [state, null];
    })
    .with(["ChooseBg", P.select()], (value: string) => {
      if (value === "custom") {
        state.dashboardOpener.config.bgImage.contentType = "";
        state.dashboardOpener.config.bgImage.data = "";
        state.displayBgInput = true;
      } else {
        state.displayBgInput = false;
        const imageUrl = `./static/img/dashboards/bg${value}.png`;
        return [state, convertUrlToBlob(imageUrl)];
      }
      return [state, null];
    })
    .with(["UploadImg", P.select()], (file) => {
      return [state, convertImgFileToBase64(file)];
    })
    .with(["ConvertSuccess", P.select()], (imgBase64) => {
      const mimeType = imgBase64.substring(0, 22);
      const contentType = mimeType.replace("data:", "").replace(";base64,", "");
      const data = imgBase64.substring(22, imgBase64.length);
      const char = data.charAt(0);
      if (
        !contentType || !data ||
        char !== "/" && char !== "i" && char !== "R" && char !== "U" ||
        data.includes(" ")
      ) {
        flashMessage(tr("fib.dash.template.fileCorrupted"), "error");
        return [state, null];
      }

      state.dashboardOpener.config.bgImage.contentType = contentType;
      state.dashboardOpener.config.bgImage.data = data;
      return [state, null];
    })
    .with(["UploadImgError", P.select()], (errMsg) => {
      flashMessage(errMsg, "error");
      return [state, null];
    })
    .with(["ChoosePreset", P.select()], (value: string) => {
      state.preset = value;

      // reset order for all slots
      for (
        const [key, value] of Object.entries(
          state.dashboardOpener.config?.slots ?? {},
        )
      ) {
        if (value.type !== "chart" && value.type !== "circles") {
          value.order = 0;
        }
      }

      if (value === "1.1") {
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 3;
        state.dashboardOpener.config.slots.ecology.order = 4;
      }
      if (value === "1.2") {
        state.dashboardOpener.config.slots.dailyEnergyYield.order = 3;
        state.dashboardOpener.config.slots.ecology.order = 4;
      }
      if (value === "2.1") {
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 3;
        state.dashboardOpener.config.slots.ecology.order = 4;
        state.dashboardOpener.config.slots.activePower.order = 5;
        state.dashboardOpener.config.slots.efficiency.order = 6;
      }
      if (value === "2.2") {
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 3;
        state.dashboardOpener.config.slots.weather.order = 4;
        state.dashboardOpener.config.slots.activePower.order = 5;
        state.dashboardOpener.config.slots.efficiency.order = 6;
      }
      if (value === "2.3") {
        state.dashboardOpener.config.slots.dailyEnergyYield.order = 3;
        state.dashboardOpener.config.slots.ecology.order = 4;
        state.dashboardOpener.config.slots.activePower.order = 5;
        state.dashboardOpener.config.slots.efficiency.order = 6;
      }
      if (value === "3.1") {
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 3;
        state.dashboardOpener.config.slots.weather.order = 4;
        state.dashboardOpener.config.slots.ecology.order = 5;
        state.dashboardOpener.config.slots.activePower.order = 6;
        state.dashboardOpener.config.slots.efficiency.order = 7;
        state.dashboardOpener.config.slots.pv1Voltage.order = 8;
        state.dashboardOpener.config.slots.reactivePower.order = 9;
        state.dashboardOpener.config.slots.activeExportedEnergy.order = 10;
        state.dashboardOpener.config.slots.activeImportedEnergy.order = 11;
        state.dashboardOpener.config.slots.apparentEnergy.order = 12;
      }
      if (value === "3.2") {
        state.dashboardOpener.config.slots.weather.order = 3;
        state.dashboardOpener.config.slots.ecology.order = 4;
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 5;
        state.dashboardOpener.config.slots.dailyEnergyYield.order = 6;
        state.dashboardOpener.config.slots.reactivePower.order = 7;
        state.dashboardOpener.config.slots.activePower.order = 8;
        state.dashboardOpener.config.slots.efficiency.order = 9;
        state.dashboardOpener.config.slots.activeExportedEnergy.order = 10;
        state.dashboardOpener.config.slots.activeImportedEnergy.order = 11;
        state.dashboardOpener.config.slots.apparentEnergy.order = 12;
      }
      if (value === "3.3") {
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 3;
        state.dashboardOpener.config.slots.weather.order = 4;
        state.dashboardOpener.config.slots.ecology.order = 5;
        state.dashboardOpener.config.slots.activePower.order = 6;
        state.dashboardOpener.config.slots.efficiency.order = 7;
        state.dashboardOpener.config.slots.nominalCurrent1.order = 8;
        state.dashboardOpener.config.slots.nominalCurrent2.order = 9;
        state.dashboardOpener.config.slots.nominalVoltage1.order = 10;
        state.dashboardOpener.config.slots.reactiveInductiveEnergy.order = 11;
        state.dashboardOpener.config.slots.reactiveCapacitiveEnergy.order = 12;
      }
      if (value === "4.1") {
        state.dashboardOpener.config.slots.chart = {
          type: "chart",
          order: 2,
          title: tr(
            params.find((param) => param.param === "dailyEnergyYield")
              ?.name as any,
          ),
          deviceSn: "sum:inverter",
          deviceMarker: "sum:inverter",
          unit: tr(
            params.find((param) => param.param === "dailyEnergyYield")
              ?.unit as any,
          ),
          prop: "dailyEnergyYield",
          color: "#33EE67",
          duration: 1000 * 60 * 60 * 24 * 30,
        };

        state.dashboardOpener.config.slots.doughnut = {
          type: "doughnut",
          order: 1,
          prop: "activePower",
          deviceKind: "inverter",
          deviceMarkers: [],
          title: "", //tr(
          //   params.find((param) => param.param === "activePower")?.name as any,
          // ),
          subtitle: "",
          unit: tr(
            params.find((param) => param.param === "activePower")?.unit as any,
          ),
          color: "#33EE67",
        };
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 3;
        state.dashboardOpener.config.slots.ecology.order = 4;
        state.dashboardOpener.data.chart = {
          param: "dailyEnergyYield",
          values: generateChartValues(),
        };
      }
      if (value === "4.2") {
        state.dashboardOpener.config.slots.chart = {
          type: "chart",
          order: 2,
          title: tr(
            params.find((param) => param.param === "accumulatedEnergyYield")
              ?.name as any,
          ),
          deviceSn: "sum:inverter",
          deviceMarker: "sum:inverter",
          unit: tr(
            params.find((param) => param.param === "accumulatedEnergyYield")
              ?.unit as any,
          ),
          prop: "sumOfAccumulatedEnergyYield",
          color: "#33EE67",
          duration: 1000 * 60 * 60 * 24 * 30,
        };

        state.dashboardOpener.config.slots.doughnut = {
          type: "doughnut",
          order: 1,
          prop: "activePower",
          deviceKind: "inverter",
          deviceMarkers: [],
          title: "", //tr(
          //   params.find((param) => param.param === "activePower")?.name as any,
          // ),
          subtitle: "",
          unit: tr(
            params.find((param) => param.param === "activePower")?.unit as any,
          ),
          color: "#33EE67",
        };
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 3;
        state.dashboardOpener.config.slots.ecology.order = 4;
        state.dashboardOpener.data.chart = {
          param: "sumOfAccumulatedEnergyYield",
          values: generateChartValues(),
        };
      }

      if (value === "4.3") {
        state.dashboardOpener.config.slots.chart = {
          type: "chart",
          order: 2,
          title: tr(
            params.find((param) => param.param === "dailyEnergyYield")
              ?.name as any,
          ),
          deviceSn: "sum:inverter",
          deviceMarker: "sum:inverter",
          deviceKind: "inverter",
          unit: tr(
            params.find((param) => param.param === "dailyEnergyYield")
              ?.unit as any,
          ),
          prop: "dailyEnergyYield",
          color: "#33EE67",
          duration: 1000 * 60 * 60 * 24 * 30,
        };

        state.dashboardOpener.config.slots.doughnut = {
          type: "doughnut",
          order: 1,
          prop: "activePower",
          deviceKind: "inverter",
          deviceMarkers: [],
          title: "", //tr(
          //   params.find((param) => param.param === "activePower")?.name as any,
          // ),
          subtitle: "",
          unit: tr(
            params.find((param) => param.param === "activePower")?.unit as any,
          ),
          color: "#33EE67",
        };
        state.dashboardOpener.config.slots.dailyEnergyYield.order = 3;
        state.dashboardOpener.config.slots.accumulatedEnergyYield.order = 4;
        state.dashboardOpener.config.slots.ecology.order = 5;
        state.dashboardOpener.data.chart = {
          param: "dailyEnergyYield",
          values: generateChartValues(),
        };
      }
      return [state, null];
    })
    .with(["SaveDashboard"], () => {
      if (!state.preset) {
        flashMessage(tr("fib.dash.noPreset"), "error");
        return [state, null];
      }
      if (!state.dashboardOpener.config.bgImage.data) {
        flashMessage(tr("fib.dash.noBackground"), "error");
        return [state, null];
      }
      state.isInProgress = true;
      return [state, saveDashboard(state)];
    })
    .with(["SaveDashboardSuccess"], () => {
      flashMessage(tr("gcrud.saveSuccess"), "success");
      state.isInProgress = false;

      if (state.platform === "home") {
        return [
          state,
          redirect(state.installationId),
        ];
      }
      return [state, null];
    })
    .with(["SaveDashboardError"], () => {
      flashMessage(tr("general.saveFailed"), "error");
      state.isInProgress = false;
      return [state, null];
    })
    .with(["UpdateDashboardTitle", P.select()], (title) => {
      state.dashboardName = title;
      return [state, null];
    })
    .with(["UpdateCircleTitle", P.select()], (circletitle) => {
      state.dashboardOpener.config.slots.circles.title = circletitle;
      state.dashboardOpener.config.slots.doughnut.title = circletitle;
      return [state, null];
    })
    .with(["None"], () => {
      return [state, null];
    })
    .exhaustive();
}
async function redirect(instId: string) {
  await router.navigate("techHomePanel", {
    installationId: instId,
    viewState: "techHomeDashboards",
  });
  return msg("None");
}

function generateValueSlot(
  prop: string,
  unit?: string,
  subtitle?: string,
  color?: string,
): ValueSlotConfig {
  const titleFromParam = tr(
    params.find((param) => param.param === prop)?.name as any,
  );
  const unitFromParam = tr(
    params.find((param) => param.param === prop)?.unit as any,
  );
  const device = tr(
    params.find((param) => param.param === prop)?.deviceKind as any,
  );

  return {
    type: "value",
    deviceSn: `sum:${device}`,
    deviceMarker: `sum:${device}`,
    order: 0,
    title: titleFromParam ?? tr(`params.${prop}`),
    prop,
    unit: unitFromParam ?? unit,
    subtitle: subtitle || "",
    color: color || "",
  };
}

const between = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

const generateChartValues = () => {
  let values = [];
  for (let i = 0; i < 200; i++) {
    values.push({
      timestamp: Date.now() + 1000 * 60 * 24 * 30 * i,
      value: between(1, 9999),
    });
  }
  return values;
};

async function convertUrlToBlob(imageUrl: string) {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return msg("UploadImg", blob);
  } catch (err) {
    console.error("convert to blob error", err);
    return msg("UploadImgError", tr("general.loadingFailed"));
  }
}

async function convertImgFileToBase64(image: Blob) {
  const reader = new FileReader();
  try {
    const imgBase64 = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(image);
    });

    return typeof imgBase64 !== "string"
      ? msg("UploadImgError", tr("general.loadingFailed"))
      : msg("ConvertSuccess", imgBase64);
  } catch (err) {
    console.error("convert to base64 error", err);
    return msg("UploadImgError", tr("general.loadingFailed"));
  }
}

async function saveDashboard(state: State) {
  try {
    const key = { workspaceId: router.getCurrentRoute().params.installationId };
    const config = {
      type: state.preset.charAt(0) as "1" | "2" | "3" | "4",
      dashboardName: state.dashboardName,
      bgImage: state.dashboardOpener.config.bgImage,
      slots: state.dashboardOpener.config.slots,
    };

    //TODO missing marker in data
    await dashboardManager.configureDashboard(key, config);
  } catch (err) {
    console.error("saving error", err);
    return msg("SaveDashboardError");
  }
  return msg("SaveDashboardSuccess");
}

function view(state: State) {
  return (
    <>
      <div class={"mgr-dash-builder-wrapper"}>
        <div class={"mgr-dash-builder-item"}>
          <h2 class={"h200"}>{tr("fib.dash.templateKind")}</h2>
          <select
            class="fib-org-select full-width body-medium text-color-primary50"
            onChange={(e: Event) => msg("ChoosePreset", (e.target as HTMLSelectElement).value)}
          >
            <option selected={true} disabled={true}>
              {tr("select.prompt")}
            </option>
            <>
              {presets.map((preset) =>
                preset.type === "templateKind" &&
                <option value={preset.value}>{tr(preset.label)}</option>
              )}
            </>
          </select>
        </div>

        <div class={"mgr-dash-builder-item"}>
          <h2 class={"h200"}>{tr("fib.dash.templateBackground")}</h2>
          {state.displayBgInput && (
            <input
              type="file"
              accept="image/*"
              id="bg-image"
              class={"body-medium"}
              onChange={(e: Event) => {
                const img = (e.target as HTMLInputElement).files?.[0];
                if (img && img.size < 1_048_576) {
                  return msg("UploadImg", img);
                }
                (e.target as HTMLInputElement).value = "";
                return msg("UploadImgError", tr("fib.dash.fileTooBig"));
              }}
            />
          )}

          <select
            onChange={(e: Event) => msg("ChooseBg", (e.target as HTMLSelectElement).value)}
            class="fib-org-select full-width body-medium text-color-primary50"
          >
            <option selected={true} value="initial" disabled={true}>
              {tr("select.prompt")}
            </option>
            <>
              {presets.map((preset) =>
                preset.type === "templateBackground" &&
                (
                  <option value={preset.value}>
                    {`${tr(preset.label)} ${preset.value !== "custom" ? preset.value : ""}`}
                  </option>
                )
              )}
            </>
          </select>
        </div>

        <div class={"mgr-dash-builder-item"}>
          <h2 class={"h200"}>{tr("fib.dash.templateParams")}</h2>
          <select
            class="fib-org-select full-width body-medium text-color-primary50"
            onChange={(e: Event) => msg("ChoosePreset", (e.target as HTMLSelectElement).value)}
          >
            <>
              {!state.preset
                ? (
                  <option disabled={true} selected={true}>
                    {tr("wybierz rodzaj szablonu")}
                  </option>
                )
                : presets
                  .filter((preset) => preset.type === "templateParams")
                  .map((preset) =>
                    preset.value.charAt(0) === state.preset.charAt(0) &&
                    (
                      <option
                        value={preset.value}
                        selected={state.preset === preset.value}
                      >
                        {tr(preset.label)}
                      </option>
                    )
                  )}
            </>
          </select>
        </div>

        <div class={"mgr-dash-builder-item"}>
          <h2 class={"h200"}>{tr("fib.dash.DashTitle")}</h2>
          <input
            class={"body-medium"}
            type={"text"}
            placeholder={tr("fib.dash.DashTitlePlaceholder")}
            onInput={(e: Event) =>
              msg("UpdateDashboardTitle", (e.target as HTMLInputElement).value)}
          />
        </div>

        <div class={"mgr-dash-builder-item"}>
          <h2 class={"h200"}>{tr("fib.dash.CircleTitle")}</h2>
          <input
            class={"body-medium"}
            type={"text"}
            placeholder={tr("fib.dash.CircleTitlePlaceholder")}
            onInput={(e: Event) => msg("UpdateCircleTitle", (e.target as HTMLInputElement).value)}
          />
        </div>

        <div class={"mgr-dash-builder-actions"}>
          <div
            class={`button-primary nowrap ${state.isInProgress ? "disabled" : ""}`}
            onClick={() => !state.isInProgress && msg("SaveDashboard")}
          >
            {tr("general.saveChanges")}
          </div>
          {state.isInProgress && <div className={"loader-medium"} />}
        </div>
      </div>

      <fib-dashboard
        name={state.dashboardName}
        type={state.preset.charAt(0)}
        bg-image={state.dashboardOpener.config.bgImage}
        slots={JSON.stringify(state.dashboardOpener.config.slots)}
        slots-data={JSON.stringify(state.dashboardOpener.data)}
        stream-key={state.dashboardOpener.streamId}
        weather-timer="true"
        is-ziebice="false"
        is-lublin="false"
      />
    </>
  );
}
