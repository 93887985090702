import { v } from "./v";
import { dates, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";

type Msg =
  | [type: "Switch"]
  | [type: "AttributeChanged", name: string, value: unknown]
  | [type: "DateChange", timestamp: number];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  isValid: boolean;
  validUntil: number;
  disabled: boolean;
}

stm.component({
  tagName: "fib-license",
  shadow: false,
  debug: false,
  propTypes: {
    isValid: Boolean,
    validUntil: Number,
    disabled: Boolean,
  },
  attributeChangeFactory: (name, value) => msg("AttributeChanged", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        isValid: false,
        validUntil: 0,
        disabled: false,
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChanged", "isValid", P.select()], (isValid) => [
      { ...state, isValid: !!isValid && isValid !== "false" },
      null,
    ])
    .with(["AttributeChanged", "validUntil", P.select()], (validUntil) => [
      { ...state, validUntil: parseInt(validUntil as any, 10) },
      null,
    ])
    .with(["AttributeChanged", "disabled", P.select()], (disabled) => [
      { ...state, disabled: !!disabled && disabled !== "false" },
      null,
    ])
    .with(["AttributeChanged", P._, P._], () => [state, null])
    .with(["Switch"], () => {
      if (state.disabled) {
        return [state, null];
      }
      const newIsValid = !isValid(state);
      return [
        { ...state, isValid: newIsValid },
        new CustomEvent("switch", { bubbles: true, detail: newIsValid }),
      ];
    })
    .with(["DateChange", P.select()], (validUntil) => [
      { ...state, validUntil },
      new CustomEvent("date", { bubbles: true, detail: validUntil }),
    ])
    .exhaustive();
}

function isValid(state: State) {
  return state.isValid && state.validUntil > Date.now();
}

function view(state: State) {
  return v<Msg>(
    ".fib-license",
    { className: state.disabled ? "disabled" : "" },
    v(
      ".form-element.hbox.left",
      v(
        ".hbox.full-width",
        v(".switch-alt.switch-big", {
          className: (isValid(state) ? "switch-on " : " ") +
            (state.disabled ? "disabled" : ""),
          onClick: msg("Switch"),
        }),
        v(
          ".vbox.left.full-width",
          v(
            ".h300",
            tr(
              isValid(state)
                ? "fibLicense.installationLicenseActive"
                : "fibLicense.installationLicenseInactive",
            ),
          ),
          isValid(state) &&
            v(
              "label.body-small",
              { htmlFor: "valid-until" },
              tr("fibLicense.until", {
                date: dates.formatDate(state.validUntil),
              }),
            ),
        ),
        !state.disabled && isValid(state) &&
          v(".button-tertiary.square-button.h100", v("i.icon-license")),
        !state.disabled && isValid(state) && v("input", {
          id: "valid-until",
          type: "date",
          onInput: (event: any) => msg("DateChange", new Date(event.target.value).getTime()),
        }),
      ),
    ),
  );
}
