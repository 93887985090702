import { dates, flashMessage, stm, tr } from "@7willows/sw-lib";
import { Credentials } from "nexus/node/contracts";
import { match, P } from "ts-pattern";
import { Coords, InstallationWithAlarms, UserState } from "nexus/node/MetricsManager";
import { textElement } from "./view-utils";

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "Activate"]
  | [type: "Deactivate"]
  | [type: "None"]
  | [type: "Authorize"]
  | [type: "AuthorizationState", isUnlocked: boolean]
  | [type: "ActivationFailed", error: string]
  | [type: "Status", data: string | boolean]
  | [type: "Input", field: string, value: string]
  | [type: "UpdateState", data: any]
  | [type: "LoadingWeatherError"]
  | [type: "UpdateWeather", data: any];

interface State {
  installationId?: string;
  userState?: UserState;
  temperature?: number;
  activationError?: string;
  data: Partial<Credentials>;
  errors: Partial<
    {
      [key in keyof Credentials]: string;
    }
  >;
  isAuthorized: boolean;
  coords?: Coords;
}

const msg = (...args: Msg): Msg => args;

const installationManager = grow.plant("InstallationManager");

stm.component({
  tagName: "fib-manager-defrosting",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
    userState: Object,
    coords: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      temperature: 25,
      errors: {},
      data: {
        password: "",
      },
      isAuthorized: false,
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      state.installationId = installationId as string;
      return [state, null];
    })
    .with(["Attr", "userState", P.select()], (userState) => {
      state.userState = userState as UserState;
      return [state, null];
    })
    .with(["Attr", "coords", P.select()], (coords) => {
      state.coords = coords as Coords;
      return [state, loadWeather(state.coords)];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["Authorize"], () => {
      const insta = currentInstallation(state);

      return [state, unlockDefrosting(insta.id, state.data.password)];
    })
    .with(["AuthorizationState", P.select()], (status) => {
      if (!status) {
        flashMessage(tr("fib.manager.defrostingWrongCode"), "error");
        state.isAuthorized = false;
      } else {
        state.isAuthorized = true;
      }
      return [state, checkInstallationState(state)];
    })
    .with(["Activate"], () => {
      return [state, switchDefrosting(state, "run")];
    })
    .with(["Deactivate"], () => {
      return [state, switchDefrosting(state, "stop")];
    })
    .with(["UpdateState", P.select()], (update: any) => {
      const insta = currentInstallation(state);
      if (insta.machineId === update.id) {
        insta.updatedAt = update.updatedAt;
        insta.defrostingStatus = update.defrosting ? "active" : "inactive";
        insta.runningStatus = update.online ? "active" : "inactive";
      }
      return [state, null];
    })
    .with(["ActivationFailed", P.select()], (error) => {
      state.activationError = error;
      const insta = currentInstallation(state);

      if (error === "wrong authorization code") {
        flashMessage(tr("fib.manager.defrostingWrongCode"), "error");
        state.activationError = tr("fib.manager.defrostingWrongCode");
      }
      if (error === "installation is not connected") {
        flashMessage(tr("fib.manager.installationConnectionError"), "error");
        state.activationError = tr("fib.manager.installationConnectionError");
      }

      return [state, null];
    })
    .with(["Status", P.select()], (status) => {
      const insta = currentInstallation(state);
      state.activationError = "";

      if (insta) {
        if (status) {
          insta.defrostingStatus = status;
        }
      }
      return [state, null];
    })
    .with(["None"], () => [state, null])
    .with(["UpdateWeather", P.select()], (weather: any) => {
      state.temperature = weather?.main?.temp ?? 0;
      return [state, null];
    })
    .with(["LoadingWeatherError"], () => {
      flashMessage(tr("general.loadFailed"), "error");
      return [state, null];
    })
    .with(["Input", "password", P.select()], (password) => {
      delete state.errors.password;
      state.data.password = password;
      return [state, null];
    })
    .with(["Input", P._, P._], () => [state, null])
    .exhaustive();
}

async function unlockDefrosting(instId: string, password: string): Promise<Msg> {
  try {
    const results = await installationManager.validateAdvancedCode(
      instId,
      password,
    );
    return msg("AuthorizationState", results ? true : false);
  } catch (err) {
    return msg("None");
  }
}

async function checkInstallationState(state: State) {
  try {
    const insta = currentInstallation(state);

    const results = await installationManager.checkInstallationStatus(
      state.installationId,
      insta.machineId,
    );

    return msg("UpdateState", results);
  } catch (err) {
    return msg("None");
  }
}

async function switchDefrosting(state: State, option: "run" | "stop") {
  try {
    const results = await installationManager.switchDefrosting(
      state.data.password,
      state.installationId ?? "",
      option,
    );

    if (results && results.defrosting) {
      const result = results.defrosting ? "active" : "inactive";
      return msg("Status", result);
    }
    return msg("ActivationFailed", results);
  } catch (err) {
    console.error("switchDefrosting failed", err);
    return msg("ActivationFailed", (err as any)?.message ?? "");
  }
}

function currentInstallation(state: State) {
  return state.userState?.installations.find((insta: InstallationWithAlarms) =>
    insta.id === state.installationId
  );
}

async function loadWeather(coords: Coords) {
  // todo(karol): generate Fibrain's appid and set it as a environment variable
  const appid = "5dcdbb6df36458d5e138d0e16fc5965e";
  let weather;
  const { lat, lng } = coords;

  try {
    const response = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${appid}&units=metric&lang=pl`,
    );
    weather = await response.json();
  } catch (err) {
    return msg("LoadingWeatherError");
  }
  return msg("UpdateWeather", weather);
}

function view(state: State) {
  const temp = state.temperature ?? 0;
  const insta = currentInstallation(state);
  const location = insta?.name ?? "";

  const readedDefrostingLimit = Number(insta?.defrostingTemp);
  const defrostingTemp = isNaN(readedDefrostingLimit) ? 7 : readedDefrostingLimit;

  if (state.isAuthorized) {
    return layoutView(state, {
      content: activationPanel(insta),
      title: tr("fib.manager.defrostingAbove", { location, temp }),
      subtitle: tr("fib.manager.defrostingAboveInfo"),
      icon: "static/img/defrosting-above.svg",
    });
  }

  if (state.temperature === undefined) {
    return loadingView(state);
  }

  if (state.temperature > defrostingTemp) {
    return layoutView(state, {
      title: tr("fib.manager.defrostingAbove", { location, temp }),
      subtitle: tr("fib.manager.defrostingAboveInfo"),
      icon: "static/img/defrosting-above.svg",
    });
  }

  return layoutView(state, {
    content: activateView(state),
    title: tr("fib.manager.defrostingBelow", { location, temp }),
    subtitle: tr("fib.manager.defrostingBelowInfo"),
    icon: "static/img/defrosting-below.svg",
  });
}

function activationPanel(insta: any) {
  const lastUpdate = dates.formatDateTime(insta.updatedAt);
  const defrostingState = insta.defrostingStatus === "active" ? true : false;
  const installationState = insta.runningStatus === "active" ? true : false;

  return (
    <>
      <div class="hbox">
        <span>{tr("fib.manager.installationState")}</span>
        <h6 class={`${installationState ? "defrosting-on" : "defrosting-off"}`}>
          {installationState ? tr("fib.manager.defrostingOn") : tr("fib.manager.defrostingOff")}
        </h6>
      </div>
      <div class="hbox">
        <span>{tr("fib.manager.defrostingState")}</span>
        <h6 class={`${defrostingState ? "defrosting-on" : "defrosting-off"}`}>
          {defrostingState ? tr("fib.manager.defrostingOn") : tr("fib.manager.defrostingOff")}
        </h6>
      </div>
      <div class="hbox">
        <span>{tr("fib.manager.lastUpdate")}</span>
        <h6 class={`${installationState ? "defrosting-on" : "defrosting-off"} updated`}>
          {lastUpdate}
        </h6>
      </div>

      <div class="switch-panel">
        <div class="switch-panel-left">
          <span class="switch-state-on">
            {defrostingState
              ? tr("fib.manager.defrostingDeactivate")
              : tr("fib.manager.defrostingActivate")}
          </span>
        </div>
        <div class="switch-panel-center">
          <div
            class={`switch-big ${defrostingState ? "switch-on" : "switch-off"} switch-state-on`}
            onClick={defrostingState ? () => msg("Deactivate") : () => msg("Activate")}
          >
          </div>
        </div>
      </div>
    </>
  );
}

function layoutView(state: State, config: {
  content?: stm.View<Msg>;
  title: stm.View<Msg>;
  subtitle: stm.View<Msg>;
  icon: string;
}) {
  return (
    <div class="fib-manager-defrosting vbox">
      <div class="grid title-container">
        <div class="box3"></div>
        <div class="box6">
          <div class="hbox defrosting-title">
            <img alt="" src={config.icon} />
            <div class="vbox left">
              <h2 class={"h500"}>{config.title}</h2>
              <p>{config.subtitle}</p>
            </div>
          </div>
        </div>
        <div class="box3"></div>
      </div>
      {!!config.content && (
        <div class="grid">
          <div class="box3"></div>
          <div class="box6 vbox">
            <div class="defrosting-activate-form">
              {config.content}
            </div>
          </div>
          <div class="box3"></div>
        </div>
      )}
    </div>
  );
}

function loadingView(_state: State) {
  return (
    <div class="vbox defrosting-title">
      <h2 class="h400">{tr("fib.manager.defrostingChcekingTemperature")}</h2>
      <div class="loader-big"></div>
    </div>
  );
}

function activateView(state: State) {
  return (
    <>
      <h3 class="h300">{tr("fib.manager.defrostingActivateInfo")}</h3>

      {!!state.activationError && <p class="error">{state.activationError}</p>}
      <p>&nbsp;</p>
      {textElement({
        type: "password",
        field: "password",
        label: "secLoginForm.passwordLabel",
        data: state.data,
        realData: { password: "" },
        errors: state.errors,
        isDisabled: false,
      })}

      <div class="vbox">
        <button class="button-primary" onClick={msg("Authorize") as any}>
          {tr("fib.manager.defrostingAuthorize")}
        </button>
      </div>
    </>
  );
}
