import { v } from "./v";
import * as realtime from "./realtime";
import { dates, flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import _ from "lodash";
import { InstallationWithAlarms, MiniInstallation } from "nexus/node/MetricsManager";
import { accessesEmails, accessesPhoneNumbers } from "./view-utils";
import { MetricsStored } from "nexus/node/MetricsAccess";

type LoadData = any;
interface extMiniInstallation extends MiniInstallation {
  switchAlerts: string[];
}

const metricsManager = grow.plant("MetricsManager");

const installationManager = grow.plant("InstallationManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "LoadDataFailed"]
  | [type: "LoadDataSuccess", installation: LoadData]
  | [type: "InstallationSwitch", system: string]
  | [type: "SwitchConfirmed", name: string]
  | [type: "SwitchInstallationFailure"]
  | [type: "SwitchAntiPumpFailure"]
  | [type: "SwitchCosinusFailure"]
  | [type: "SwitchResign"]
  | [type: "SwitchPending", option: string]
  | [type: "UpdateInstallation", data: extMiniInstallation];

interface State {
  isLoading: boolean;
  installationWithAlarms?: LoadData;
  installationId: string;
  viewState: "params" | "info";
  isConnected: boolean;
  lastUpdate: number;
}

function msg(...args: Msg): Msg {
  return args;
}

stm.component({
  tagName: "fib-tech-install-panel",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
    viewState: String,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  willUnmount(cmp: any) {
    if (cmp.unsub) {
      cmp.unsub();
    }
  },
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        installationId: "",
        viewState: "info",
        isConnected: false,
        lastUpdate: 0,
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg, cmp: any, dispatch: stm.Dispatch<Msg>) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      if (!_.isString(installationId)) {
        return [state, null];
      }
      state.isLoading = true;
      state.installationId = installationId;

      if (cmp.unsub) {
        cmp.unsub();
      }

      realtime.subscribe(`installation.update.${installationId}`, (_props: any, insta: any) => {
        dispatch(msg("UpdateInstallation", insta));
      }).then((unsub: any) => cmp.unsub = unsub);

      return [state, loadData(installationId)];
    })
    .with(["Attr", "viewState", P.select()], (viewState) => [
      {
        ...state,
        viewState: viewState === "params" ? "params" : "info",
      },
      null,
    ])
    .with(["Attr", P._, P._], () => [state, null])
    .with(["UpdateInstallation", P.select()], (insta) => {
      const alertsCount = insta.switchAlerts === undefined ? 0 : insta.switchAlerts.length;

      if (alertsCount > 0) {
        for (let i = 0; i < alertsCount; i++) {
          if (insta.switchAlerts[i] === "cosinusPhi") {
            if (insta.cosinusStatus === "inactive") {
              flashMessage(tr("fibTechPanel.cosinusOnFailure"), "error");
            }
            if (insta.cosinusStatus === "active") {
              flashMessage(tr("fibTechPanel.cosinusOffFailure"), "error");
            }
          }
          if (insta.switchAlerts[i] === "antiPump") {
            if (insta.cosinusStatus === "inactive") {
              flashMessage(tr("fibTechPanel.antiPumpingSystemOnFailure"), "error");
            }
            if (insta.cosinusStatus === "active") {
              flashMessage(tr("fibTechPanel.antiPumpingSystemOffFailure"), "error");
            }
          }
          if (insta.switchAlerts[i] === "running") {
            if (insta.cosinusStatus === "inactive") {
              flashMessage(tr("fibTechPanel.StartupFailure"), "error");
            }
            if (insta.cosinusStatus === "active") {
              flashMessage(tr("fibTechPanel.ShutdownFailure"), "error");
            }
          }
        }
      }

      if (!state.installationWithAlarms) {
        return [state, null];
      }
      state.installationWithAlarms.antiPumpStatus = insta.antiPumpStatus;
      state.installationWithAlarms.runningStatus = insta.runningStatus;
      state.installationWithAlarms.alerts = insta.alerts;
      state.installationWithAlarms.cosinusStatus = insta.cosinusStatus;
      return [state, null];
    })
    .with(["LoadDataFailed"], () => {
      flashMessage(tr("fibAdminInstallation.loadDataFailed"), "error");
      return [{ ...state, isLoading: false }, null];
    })
    .with(["LoadDataSuccess", P.select()], (data) => {
      state.installationWithAlarms = data;
      state.isLoading = false;
      state.lastUpdate = data.installationUpdatedAt !== "0"
        ? new Date(data.installationUpdatedAt).valueOf()
        : 0;
      // state.isConnected = (data.devices !== null && data.devices.length > 0) ? true : false;
      state.isConnected = data.online;

      if (
        state.installationWithAlarms.runningStatus === "pending" ||
        state.installationWithAlarms.cosinusStatus === "pending" ||
        state.installationWithAlarms.antiPumpStatus === "pending"
      ) {
        return [state, refresh(state.installationId)];
      }

      return [state, null];
    })
    .with(["InstallationSwitch", P.select()], (system) => {
      state.isLoading = true;
      return [state, switchOnOffConfirmation(state, system)];
    })
    .with(["SwitchConfirmed", "installation"], () => {
      if (state.installationWithAlarms?.runningStatus === "active") {
        flashMessage(tr("fibTechPanel.pendingShutdown"), "warning");
      } else {
        flashMessage(tr("fibTechPanel.pendingStartup"), "warning");
      }
      state.isLoading = false;
      return [state, shutdownInstallation(state.installationWithAlarms as InstallationWithAlarms)];
    })
    .with(["SwitchConfirmed", "antiPump"], () => {
      if (state.installationWithAlarms?.antiPumpStatus === "active") {
        flashMessage(tr("fibTechPanel.antiPumpingSystemOffPending"), "warning");
      } else {
        flashMessage(tr("fibTechPanel.antiPumpingSystemOnPending"), "warning");
      }
      state.isLoading = false;

      return [state, antiPumpOnOff(state.installationWithAlarms as InstallationWithAlarms)];
    })
    .with(["SwitchConfirmed", "cosinus"], () => {
      if (state.installationWithAlarms?.cosinusStatus === "active") {
        flashMessage(tr("fibTechPanel.cosinusOffPending"), "warning");
      } else {
        flashMessage(tr("fibTechPanel.cosinusOnPending"), "warning");
      }
      state.isLoading = false;

      return [state, cosinusOnOff(state.installationWithAlarms as InstallationWithAlarms)];
    })
    .with(["SwitchConfirmed", P._], () => [state, null])
    .with(["SwitchPending", P.select()], (option) => {
      if (state.installationWithAlarms) {
        if (option === "cosinus") {
          state.installationWithAlarms.cosinusStatus = "pending";
        }
        if (option === "antiPump") {
          state.installationWithAlarms.antiPumpStatus = "pending";
        }
        if (option === "installation") {
          state.installationWithAlarms.runningStatus = "pending";
        }
      }
      return [state, loadData(state.installationId)];
    })
    .with(["SwitchResign"], () => [{ ...state, isLoading: false }, null])
    .with(["SwitchInstallationFailure"], () => {
      state.installationWithAlarms?.runningStatus === "active"
        ? flashMessage(tr("fibTechPanel.ShutdownFailure"), "error")
        : flashMessage(tr("fibTechPanel.StartupFailure"), "error");

      return [{ ...state, isLoading: false }, null];
    })
    .with(["SwitchAntiPumpFailure"], () => {
      state.installationWithAlarms?.antiPumpStatus === "active"
        ? flashMessage(tr("fibTechPanel.antiPumpingSystemOffFailure"), "error")
        : flashMessage(tr("fibTechPanel.antiPumpingSystemOnFailure"), "error");

      return [{ ...state, isLoading: false }, null];
    })
    .with(["SwitchCosinusFailure"], () => {
      state.installationWithAlarms?.cosinusStatus === "active"
        ? flashMessage(tr("fibTechPanel.cosinusOffFailure"), "error")
        : flashMessage(tr("fibTechPanel.cosinusOnFailure"), "error");

      return [{ ...state, isLoading: false }, null];
    })
    .exhaustive();
}
async function refresh(installationId: string): Promise<Msg> {
  const sleep = (delay: any) => new Promise((resolve) => setTimeout(resolve, delay));
  await sleep(5000);

  try {
    const installation = await metricsManager.installationState(installationId);

    return msg("LoadDataSuccess", installation);
  } catch (err) {
    console.error("loading installation failed", err);
    return msg("LoadDataFailed");
  }
}

async function loadData(installationId: string): Promise<Msg> {
  // added to display correctly status after click on switch to get correct results wit some delays
  const sleep = (delay: any) => new Promise((resolve) => setTimeout(resolve, delay));
  await sleep(1000);

  // TODO need to return data info about installation:
  // - adresses, managers, etc.
  // - instalaltion status - working, cosinus,
  // - alerts from devices - not working yet

  try {
    // const installation = await metricsManager.installationWithAlarms(installationId);
    const installation = await metricsManager.installationState(installationId);

    return msg("LoadDataSuccess", installation);
  } catch (err) {
    console.error("loading installation failed", err);
    return msg("LoadDataFailed");
  }
}

async function shutdownInstallation(installation: InstallationWithAlarms): Promise<Msg> {
  try {
    await installationManager.toggleIsRunning(installation.id);
    return msg("SwitchPending", "installation");
  } catch (err) {
    console.error("shutdown installation failed", err);
    return msg("SwitchInstallationFailure");
  }
}

async function antiPumpOnOff(installation: InstallationWithAlarms): Promise<Msg> {
  try {
    await installationManager.toogleAntiPumping(installation.id);
    return msg("SwitchPending", "antiPump");
  } catch (err) {
    console.error("shutdown antiPump system failed", err);
    return msg("SwitchAntiPumpFailure");
  }
}

async function cosinusOnOff(installation: InstallationWithAlarms): Promise<Msg> {
  try {
    await installationManager.toggleCosinus(installation.id);
    return msg("SwitchPending", "cosinus");
  } catch (err) {
    console.error("shutdown cosinus failed", err);
    return msg("SwitchCosinusFailure");
  }
}

function view(state: State) {
  return (
    <>
      {renderNav(state)}
      {state.isLoading
        ? v<Msg>(".loader-big")
        : state.viewState === "info"
        ? installationDetailsView(state)
        : <fib-tech-install-parameters installation={state.installationWithAlarms ?? ""} />}
    </>
  );
}

function renderNav(state: State) {
  return (
    <>
      <nav class="main-nav" role="menu">
        <div class="hbox left tech-panel">
          <a href={router.getRouteUrl("techInstallsList")} class="square-button button-primary">
            <i class="icon-chevron-left"></i>
          </a>
          <div class="vbox left">
            <div class="h400">{state.installationWithAlarms?.name}</div>
            <div class="body-medium">
              <div class="hbox left">
                <div>
                  <span class="margin-right">{state.installationWithAlarms?.street ?? ""}</span>
                  <span class="margin-right">
                    {state.installationWithAlarms?.houseNumber ?? ""},
                  </span>
                  <span class="margin-right">{state.installationWithAlarms?.postcode ?? ""}</span>
                  <span>{state.installationWithAlarms?.city ?? ""}</span>
                </div>
                <div class="tech-panel-space">
                  <i class="icon-phone"></i>
                  {accessesPhoneNumbers(state.installationWithAlarms?.managers || [])}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center menu-center">
          <ul class="main-menu h300">
            <li>
              <a
                href={router.getRouteUrl("techInstallPanel", {
                  installationId: state.installationId,
                })}
                class={state.viewState === "info" ? "active" : ""}
              >
                {tr("fibTechPanel.info")}
              </a>
            </li>
            <li title={!state.isConnected ? tr("fibTechPanel.notConnected") : ""}>
              <a
                href={router.getRouteUrl("techInstallParameters", {
                  installationId: state.installationId,
                })}
                class={state.viewState === "params" ? "active" : ""}
              >
                {tr("fibTechPanel.params")}
              </a>
            </li>
          </ul>
        </div>
        <div class="right-empty-box"></div>
      </nav>
    </>
  );
}

function installationDetailsView(state: State) {
  const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;

  const shouldBeRed = state.lastUpdate < fiveMinutesAgo;

  const installationStatus: string[] = [];

  if (state.installationWithAlarms?.systemFailure) {
    installationStatus.push(tr("fibTechPanel.installationBreakdown"));
  }

  if (!state.isConnected) {
    installationStatus.push(tr("fibTechPanel.notConnected"));
  } else {
    installationStatus.push(tr("fibTechPanel.installationIsWorking"));
  }

  return (
    <>
      <div class="grid install-details">
        <div class="box1"></div>
        <div class="box10">
          <div class="vbox">
            <div class="container install-details-location">
              <div class="vbox left">
                <div class="hbox">
                  {installationStatus.map((message: string) => {
                    return (
                      <div class="badge-gray body-small">
                        {message}
                      </div>
                    );
                  })}
                </div>

                <div class="h500">{state.installationWithAlarms?.name}</div>
                <div class="hbox install-details-gap">
                  <div class="vbox left">
                    <div class="body-medium">
                      <span class="margin-right">{state.installationWithAlarms?.street ?? ""}</span>
                      <span>{state.installationWithAlarms?.houseNumber ?? ""},</span>
                    </div>
                    <div class="body-medium">
                      <span class="margin-right">
                        {state.installationWithAlarms?.postcode ?? ""}
                      </span>
                      <span>{state.installationWithAlarms?.city ?? ""}</span>
                    </div>
                  </div>
                  <div class="vbox left">
                    <div class="body-medium">
                      tel: {accessesPhoneNumbers(state.installationWithAlarms?.managers ?? [])}
                    </div>
                    <div class="body-medium">
                      mail: {accessesEmails(state.installationWithAlarms?.managers ?? [])}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hbox install-details-switches full-width">
              {switchComponent(
                state.installationWithAlarms?.runningStatus ?? "",
                "installation",
                tr("fibTechPanel.installationStatus"),
              )}
              {switchComponent(
                state.installationWithAlarms?.antiPumpStatus ?? "",
                "antiPump",
                tr("fibTechPanel.antiPumpingSystem"),
              )}
              {switchComponent(
                state.installationWithAlarms?.cosinusStatus ?? "",
                "cosinus",
                tr("fibTechPanel.cosinus"),
              )}
            </div>
            <div className={"h100 install-details-location"} title={tr("fibTechPanel.lastUpdate")}>
              <div className={shouldBeRed ? "red" : ""}>
                {state.lastUpdate !== 0
                  ? `${
                    tr("fibTechPanel.installationLastUpdate")
                  }:   ${(dates.formatDateTime(state.lastUpdate ?? 0))}`
                  : tr("fibTechPanel.notConnectedString")}
              </div>
            </div>
            <div class="h300 tech-error-header left">
              {tr("fibTechPanel.errorCodes")}
            </div>
            <div class="hbox tech-error-list">
              <>
                {(state.installationWithAlarms?.alerts ?? []).map((alert: any) => {
                  return (
                    <>
                      <div className={`chip body-medium tooltip error-level-${alert.level}`}>
                        {alert.code}
                        <span class="tooltip-text">{alert.name}</span>
                      </div>
                    </>
                  );
                })}
              </>
            </div>
          </div>
        </div>
        <div class="box1"></div>
      </div>
    </>
  );
}

function generateDescription(runningStatus: string, system: string) {
  switch (runningStatus) {
    case "active":
      if (system === "installation") return tr("fibTechPanel.installationOn");
      if (system === "antiPump") return tr("fibTechPanel.antiPumpingSystemOn");
      if (system === "cosinus") return tr("fibTechPanel.cosinusOn");
    case "pending":
      if (system === "installation") return tr("fibTechPanel.installationPending");
      if (system === "antiPump") return tr("fibTechPanel.antiPumpingPending");
      if (system === "cosinus") return tr("fibTechPanel.cosinusPending");
    default:
      // error & inactive - on that moment both are displayed as OFF
      if (system === "installation") return tr("fibTechPanel.installationOff");
      if (system === "antiPump") return tr("fibTechPanel.antiPumpingSystemOff");
      if (system === "cosinus") return tr("fibTechPanel.cosinusOff");
  }
}

function switchComponent(runningStatus: string, system: string, title: string) {
  const description = generateDescription(runningStatus, system);

  return (
    <div>
      <span class="h400">{title}</span>
      <div class="container hbox install-switch-gap">
        <div>{runningStatus === "pending" && v<Msg>(".loader-small")}</div>
        <div
          className={`switch-big switch-disabled 
                    ${runningStatus === "active" ? "switch-on" : "switch-off"}
                    ${runningStatus === "pending" && "disabled"}`}
        >
        </div>
        <div class="h300 left">
          {description}
        </div>
      </div>
    </div>
  );
}

async function switchOnOffConfirmation(_state: State, system: string): Promise<Msg> {
  // switch OnOFF installation base on status and here we will run function to make changes in databases and devices
  let message = _state.installationWithAlarms?.name ?? "";
  switch (system) {
    case "antiPump":
      message = _state.installationWithAlarms?.antiPumpStatus === "active"
        ? tr("fibTechPanel.antiPumpingSystemOffConfirm")
        : tr("fibTechPanel.antiPumpingSystemOnConfirm");
      break;
    case "installation":
      message = _state.installationWithAlarms?.runningStatus === "active"
        ? tr("fibTechPanel.confirmShutdown", { name: message as any })
        : tr("fibTechPanel.confirmStartup", { name: message as any });
      break;
    case "cosinus":
      message = _state.installationWithAlarms?.cosinusStatus === "active"
        ? tr("fibTechPanel.cosinusOffConfirm")
        : tr("fibTechPanel.cosinusOnConfirm");
      break;
    default:
  }

  const result = await modal.confirm({
    text: message,
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  return result ? msg("SwitchConfirmed", system) : msg("SwitchResign");
}
