import { v } from "./v";
import { dates, flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { Organization } from "nexus/node/OrganizationAccess";
import { match, P } from "ts-pattern";
import { InstallationAccess } from "nexus/node/contracts";

const resourceManager = grow.plant("ResourceManager");

type Msg =
  | [type: "LoadOrgsFailed"]
  | [type: "LoadOrgsSuccess", orgs: Organization[]]
  | [type: "ChangeFilterOrgs", phrase: string];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  isOrgsLoading: boolean;
  orgs: Organization[];
  selectedOrg?: Organization;
  filterOrgs: string;
}

stm.component({
  tagName: "fib-admin-org",
  shadow: false,
  debug: false,
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isOrgsLoading: false,
        orgs: [],
        filterOrgs: "",
      },
      loadOrgs(),
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["ChangeFilterOrgs", P.select()], (filterOrgs) => [
      { ...state, filterOrgs },
      null,
    ])
    .with(["LoadOrgsFailed"], () => {
      if (grow.sessionId !== null) {
        flashMessage(tr("fibOrgList.loadOrgsFailed"), "error");
      }
      return [
        { ...state, isOrgsLoading: false },
        null,
      ];
    })
    .with(["LoadOrgsSuccess", P.select()], (orgs) => [
      {
        ...state,
        isOrgsLoading: false,
        orgs,
      },
      null,
    ])
    .exhaustive();
}

async function loadOrgs() {
  try {
    const result = await resourceManager.listAllOrgs();
    return msg("LoadOrgsSuccess", result);
  } catch (err) {
    console.error("loading orgs failed", err);
    return msg("LoadOrgsFailed");
  }
}

function view(state: State) {
  return (
    <div class="fib-org-list vbox">
      <nav class="grid secondary-nav">
        <div class="box8 hbox">
          <h1 class="h600">
            {tr("fibAdminPanel.navOrgs")}
          </h1>
          <a
            class="button-secondary square-button add-client-button"
            href={router.getRouteUrl("adminAddOrg")}
          >
            <i class="icon-plus-bare"></i>
            {tr("gcrud.addToList")}
          </a>
        </div>
      </nav>
      <div class="grid">
        <div class="box8">
          <div class="search search-panel">
            <span class="input-icon-after">
              <input
                class="body-medium"
                type="text"
                value={state.filterOrgs}
                onInput={(event: any) => msg("ChangeFilterOrgs", event.target.value)}
                placeholder={tr("general.search")}
              />
              <i class="icon-search"></i>
            </span>
          </div>

          <ul class="list box8 org-list">
            <>
              {state.orgs
                .filter((org) => {
                  if (state.filterOrgs) {
                    return org.name.toLowerCase().includes(state.filterOrgs.toLowerCase());
                  }
                  return true;
                })
                .map((org) => (
                  <li>
                    <a
                      class={"well hbox " +
                        (state.selectedOrg?.id === org.id ? "active h300" : "body-large")}
                      href={state.selectedOrg?.id === org.id
                        ? router.getRouteUrl("adminListUsers")
                        : router.getRouteUrl("adminOrg", { orgId: org.id })}
                    >
                      {org.name}
                      <div class="edit-org">
                        {`${tr("fibAdminPanel.licenseFilter")} `}
                        {org.licenseActive
                          ? tr("fibAdminPanel.licenseActive").toLocaleLowerCase()
                          : tr("fibAdminPanel.licenseInactive").toLocaleLowerCase()}
                        <a
                          class="text-button"
                          href={router.getRouteUrl("adminOrg", { orgId: org.id })}
                        >
                          <i class="icon-pen"></i>
                        </a>
                      </div>
                      <i class="icon-check"></i>
                    </a>
                  </li>
                ))}
            </>
          </ul>
        </div>
      </div>
    </div>
  );
}
