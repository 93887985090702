import { stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";

type Msg = [type: "AttributeChanged", name: string, value: unknown];

function msg(...args: Msg): Msg {
  return args;
}

interface State {}

stm.component({
  tagName: "fib-404",
  shadow: false,
  debug: false,
  propTypes: {},
  attributeChangeFactory: (name, value) => msg("AttributeChanged", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {},
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChanged", P._, P._], () => [state, null])
    .exhaustive();
}

function view(state: State) {
  return (
    <main
      class="background-404"
      style={`background-image: url(../../static/img/dashboards/bg${
        Math.floor(Math.random() * 4) + 1
      }.webp); `}
    >
      <div class="fib-404">
        {tr("fib.manager.page404")}
      </div>
    </main>
  );
}
