import Quill from 'quill';
import { tr } from '@7willows/sw-lib';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

class FibQuill extends HTMLElement {
    private quill: any;
    private container: any;
    private myAttrs: any;

    static get observedAttributes() {
        return ['reset'];
    }

    constructor() {
        super();
        this.myAttrs = {};
    }

    attributeChangedCallback(attrName: any, previous: any, current: any) {
        this.myAttrs[attrName] = current;
        if (this.quill && this.myAttrs.reset === 'true') {
            this.quill.setText('');
        }
    }

    connectedCallback() {
        // create an element
        if (this.querySelector('#editor')) {
            return;
        }

        this.container = document.createElement('div');
        this.container.id = 'editor';
        this.append(this.container);

        // create a quill
        this.quill = new Quill('#editor', {
            modules: {
                toolbar: [
                    [{ 'header': [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'align': [] }],
                    ['clean'],
                    ['link', 'image']
                ]
            },
            placeholder: tr('fibTechPanel.composeContentPlaceholder'),
            theme: 'snow'
        });
        this.quill.on('text-change', () => {
            const delta = this.quill.getContents().ops;
            const converter = new QuillDeltaToHtmlConverter(delta);
            const html = converter.convert();

            this.dispatchEvent(new CustomEvent('update', {
                bubbles: true,
                detail: html
            }));
        });
    }

    disconnectedCallback() {
        delete this.quill;
        this.removeChild(this.container);
        delete this.container;
    }
}

const isElementDefined = customElements.get('fib-quill');

if (!isElementDefined) {
    customElements.define('fib-quill', FibQuill);
}
