import { v } from "./v";
import { stm, tr, flashMessage } from '@7willows/sw-lib';
import _ from 'lodash';
import { match, P } from 'ts-pattern';

type Msg
  = [type: 'AttributeChange', name: string, value: string]
  | [type: 'RolesLoadFailed']
  | [type: 'RolesLoadSuccess', roles: string[]]
  | [type: 'Select', role: string]


type State = {
  isLoading: boolean;
  roles: string[];
  skipRoles: string[];
  labels: Record<string, Record<string, string>>;
};

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: 'sec-roles-select',
  debug: false,
  propTypes: {
    skipRoles: Array,
    labels: Object
  },
  attributeChangeFactory: (name, value) => msg('AttributeChange', name, value),
  shadow: false,
  init: () => {
    return [
      {
        identityId: '',
        isLoading: false,
        skipRoles: [],
        roles: [],
        labels: {}
      },
      loadRoles()
    ]
  },
  update: (state: State, msg: Msg) => {
    return match<Msg, [State, stm.Cmd<Msg>]>(msg)
      .with(['AttributeChange', 'skipRoles', P.select()], skipRoles => [
        { ...state, skipRoles: (skipRoles as any) },
        null
      ])
      .with(['AttributeChange', 'labels', P.select()], labels => [
        { ...state, labels: (labels as any) },
        null
      ])
      .with(['AttributeChange', P._, P._], () => [state, null])
      .with(['RolesLoadFailed'], () => {
        flashMessage(tr('sec.rolesLoadFailed'), 'error');
        return [state, null];
      })
      .with(['RolesLoadSuccess', P.select()], roles => [
        { ...state, roles },
        null
      ])
      .with(['Select', P.select()], role => [
        state,
        new CustomEvent('addroles', { detail: [role] })
      ])

      .exhaustive();
  },
  view
});

async function loadRoles(): Promise<Msg> {
  try {
    const result = await grow.plant('Security').roles();
    if (result.err) {
      console.error('loading roles returned an error', result.err);
      return msg('RolesLoadFailed');
    }
    return msg('RolesLoadSuccess', Object.keys(result));
  } catch (err) {
    console.error('loading roles failed', err);
    return msg('RolesLoadFailed');
  }
}

function view(state: State) {
  return v<Msg>('.sec-roles-select',
    v.swSelect({
      onupdate: (event: any) => msg('Select', event.detail?.value?.value ?? ''),
      config: {
        name: 'select-role',
        label: tr('secRolesSelect.chooseRole'),
        disabled: false,
        showLabel: true,
        options: state.roles
          .filter(r => !state.skipRoles.includes(r))
          .map(r => ({
            label: state.labels?.[r]?.[tr.getLang()] ?? r,
            value: r
          })),
        minimumCharLengthTrigger: 0,
      },
    })
  )
}
