import { stm } from "@7willows/sw-lib";
import * as realtime from "./realtime";
import { match, P } from "ts-pattern";
import { SlotConfig, SlotData } from "nexus/node/contracts";

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "Data", data: { [slotId: string]: SlotData }];

type State = {
  type: string;
  bgImage: { contentType: string; data: string };
  name: string;
  slots: { [slot: string]: SlotConfig };
  slotsData: { [slot: string]: SlotData };
  streamKey: string;
  weatherTimer: boolean;
  isZiebice: boolean;
  isLublin: boolean;
};

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-dashboard",
  shadow: false,
  debug: false,
  propTypes: {
    type: String,
    bgImage: String,
    slotsData: String,
    slots: String,
    name: String,
    streamKey: String,
    weatherTimer: String,
    isZiebice: String,
    isLublin: String,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  willUnmount(cmp) {
    if (cmp.unsubPromise) {
      cmp.unsubPromise.then((unsub: any) => unsub());
    }
  },
  init() {
    const state = {
      type: "0",
      bgImage: {
        contentType: "",
        data: "",
      },
      name: "",
      slots: {},
      slotsData: {},
      streamKey: "",
      weatherTimer: false,
      isZiebice: false,
      isLublin: false,
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg, cmp: any, dispatch: stm.Dispatch<Msg>) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "type", P.select()], (type) => {
      if (!type) {
        type = "0";
      }
      state.type = type as any;
      return [state, null];
    })
    .with(["Attr", "bgImage", P.select()], (bgImage) => {
      state.bgImage = bgImage as any;
      return [state, null];
    })
    .with(["Attr", "slotsData", P.select()], (slotsData) => {
      try {
        // state.slotsData = slotsData as any;
        state.slotsData = JSON.parse(slotsData as any);
      } catch (err: any) {
      }
      return [state, null];
    })
    .with(["Attr", "slots", P.select()], (slots) => {
      try {
        // state.slots = slots as any;
        state.slots = JSON.parse(slots as any);
      } catch {
      }

      if (state.slots.weather) {
        state.slots.weather.refresh = false;
      }
      return [state, null];
    })
    .with(["Attr", "name", P.select()], (name) => {
      state.name = name as string;
      return [state, null];
    })
    .with(["Attr", "isZiebice", P.select()], (isZiebice) => {
      state.isZiebice = isZiebice === "true" ? true : false;
      return [state, null];
    })
    .with(["Attr", "isLublin", P.select()], (isLublin) => {
      state.isLublin = isLublin === "true" ? true : false;
      return [state, null];
    })
    .with(["Attr", "streamKey", P.select()], (streamKey) => {
      state.streamKey = streamKey as any;
      if (cmp.unsubPromise) {
        cmp.unsubPromise.then((unsub: any) => unsub());
      }

      cmp.unsubPromise = realtime.subscribe(streamKey as string, (_props: any, content: any) => {
        dispatch(msg("Data", content));
      });

      return [state, null];
    })
    .with(["Attr", "weatherTimer", P.select()], (name) => {
      state.weatherTimer = name as boolean;
      state.slots.weather.refresh = state.weatherTimer;
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["Data", P.select()], (data) => {
      state.slotsData = data;
      return [state, null];
    })
    .exhaustive();
}

function view(state: State) {
  if (!Object.keys(state.slots).length || !Object.keys(state.slotsData).length) {
    return <></>;
  }
  // language=CSS
  const isZiembice = state.isZiebice;
  // let style = "";
  //   if (isZiembice) {
  //     style = `
  //     html, body {
  //         height: 100%;
  //     }

  //     .mgr-dash-wrapper {
  //         height: 100%;
  //         background: linear-gradient(180deg, rgba(42, 42, 42, 0.97) 33.94%, rgba(42, 42, 42, 0) 100%),
  //           url("/static/img/dashboards/bg6.png");
  //         background-size: cover;
  //     }
  // `;
  //   } else {
  const style = `
        html, body {
            height: 100%;
        }

        .mgr-dash-wrapper {
            height: 100%;
            background: linear-gradient(180deg, rgba(42, 42, 42, 0.97) 33.94%, rgba(42, 42, 42, 0) 100%),
            ${
    state.bgImage ? `url(data:${state.bgImage.contentType};base64,${state.bgImage.data});` : ""
  }
            background-size: cover;
        }
    `;
  // }

  return (
    <>
      <style>{style}</style>

      {state.type === "1" && dashboardOne(state.slots, state.slotsData, state.name, state.isLublin)}
      {state.type === "2" && dashboardTwo(state.slots, state.slotsData, state.name, state.isLublin)}
      {state.type === "3" &&
        dashboardThree(state.slots, state.slotsData, state.name, state.isLublin)}
      {state.type === "4" &&
        dashboardFour(state.slots, state.slotsData, state.name, state.isLublin)}
      {!["0", "1", "2", "3", "4"].includes(state.type) && <fib-404></fib-404>}
    </>
  );
}

function dashboardOne(
  slots: { [slotId: string]: SlotConfig },
  slotsData: { [slotId: string]: SlotData },
  name: string,
  isLublin: boolean,
) {
  let circlesSlotConfig,
    circlesSlotData,
    chartSlotConfig,
    chartSlotData,
    slot3Config,
    slot4Config,
    slot3Data,
    slot4Data;

  for (const [key, value] of Object.entries(slots)) {
    if (value.order === 1) {
      circlesSlotConfig = value;
      circlesSlotData = slotsData[key];
    }
    if (value.order === 2) {
      chartSlotConfig = value;
      chartSlotData = slotsData[key];
    }
    if (value.order === 3) {
      slot3Config = value;
      slot3Data = slotsData[key];
    }
    if (value.order === 4) {
      slot4Config = value;
      slot4Data = slotsData[key];
    }
  }

  return (
    <>
      <div class={"mgr-dash-wrapper"}>
        <div class={"mgr-dash-data-wrapper"}>
          <span class={"h700 mgr-dash-color"}>{name}</span>

          <div class="mgr-dash-cols">
            <div class="mgr-dash-circle-col">
              <fib-manager-circles-slot
                circles-slot-config={circlesSlotConfig}
                circles-slot-data={circlesSlotData}
              />
            </div>

            <div class="mgr-dash-data-col">
              <div class="mgr-dash-row-top">
                <fib-manager-inverters-slot
                  circles-slot-config={circlesSlotConfig}
                  circles-slot-data={circlesSlotData}
                />

                {renderSlot(slot3Config, slot3Data)}

                {renderSlot(slot4Config, slot4Data)}
              </div>

              <div class="mgr-dash-chart-slot">
                <fib-manager-chart-slot
                  chart-slot-config={chartSlotConfig}
                  chart-slot-data={chartSlotData}
                />
              </div>
            </div>
          </div>

          <div class="mgr-dash-logos-wrapper">
            {isLublin && (
              <>
                <img src="./static/img/dashboards/dash-ZCK.png" alt={"ZCK"} />
                <img src="./static/img/dashboards/dash-UE.png" alt={"UE"} />
              </>
            )}
            <img src="./static/img/dashboards/dash-fibrain.png" alt={"fibrain"} />
          </div>
        </div>
      </div>
    </>
  );
}

function dashboardTwo(
  slots: { [slotId: string]: SlotConfig },
  slotsData: { [slotId: string]: SlotData },
  name: string,
  isLublin: boolean,
) {
  let circlesSlotConfig,
    circlesSlotData,
    chartSlotConfig,
    chartSlotData,
    slot3Config,
    slot4Config,
    slot5Config,
    slot6Config,
    slot3Data,
    slot4Data,
    slot5Data,
    slot6Data;

  for (const [key, value] of Object.entries(slots)) {
    if (value.order === 1) {
      circlesSlotConfig = value;
      circlesSlotData = slotsData[key];
    }
    if (value.order === 2) {
      chartSlotConfig = value;
      chartSlotData = slotsData[key];
    }
    if (value.order === 3) {
      slot3Config = value;
      slot3Data = slotsData[key];
    }
    if (value.order === 4) {
      slot4Config = value;
      slot4Data = slotsData[key];
    }
    if (value.order === 5) {
      slot5Config = value;
      slot5Data = slotsData[key];
    }
    if (value.order === 6) {
      slot6Config = value;
      slot6Data = slotsData[key];
    }
  }

  return (
    <>
      <div class={"mgr-dash-wrapper"}>
        <div class={"mgr-dash-data-wrapper"}>
          <span className={"h700 mgr-dash-color"}>{name}</span>

          <div class="mgr-dash-cols">
            <div class={"mgr-dash-circle-col"}>
              <fib-manager-circles-slot
                circles-slot-config={circlesSlotConfig}
                circles-slot-data={circlesSlotData}
              />
            </div>

            <div className="mgr-dash-data-col">
              <div className="mgr-dash-row-top">
                <fib-manager-inverters-slot
                  circles-slot-config={circlesSlotConfig}
                  circles-slot-data={circlesSlotData}
                />

                {renderSlot(slot3Config, slot3Data)}

                {renderSlot(slot4Config, slot4Data)}
              </div>

              <div className="mgr-dash-row-with-chart">
                <div className="mgr-dash-chart-slot">
                  <fib-manager-chart-slot
                    chart-slot-config={chartSlotConfig}
                    chart-slot-data={chartSlotData}
                  />
                </div>

                <div className={"mgr-dash-side-slots"}>
                  {renderSlot(slot5Config, slot5Data)}

                  {renderSlot(slot6Config, slot6Data)}
                </div>
              </div>
            </div>
          </div>

          <div class="mgr-dash-logos-wrapper">
            {isLublin && (
              <>
                <img src="./static/img/dashboards/dash-ZCK.png" alt={"ZCK"} />
                <img src="./static/img/dashboards/dash-UE.png" alt={"UE"} />
              </>
            )}
            <img src="./static/img/dashboards/dash-fibrain.png" alt={"fibrain"} />
          </div>
        </div>
      </div>
    </>
  );
}

function dashboardThree(
  slots: { [slotId: string]: SlotConfig },
  slotsData: { [slotId: string]: SlotData },
  name: string,
  isLublin: boolean,
) {
  let circlesSlotConfig,
    circlesSlotData,
    chartSlotConfig,
    chartSlotData,
    slot3Config,
    slot4Config,
    slot5Config,
    slot6Config,
    slot7Config,
    slot8Config,
    slot9Config,
    slot10Config,
    slot11Config,
    slot12Config,
    slot3Data,
    slot4Data,
    slot5Data,
    slot6Data,
    slot7Data,
    slot8Data,
    slot9Data,
    slot10Data,
    slot11Data,
    slot12Data;

  // if (isZiebice) {
  //   for (const [key, value] of Object.entries(slots)) {
  //     if (value.order === 1) {
  //       circlesSlotConfig = value;
  //       const modPower = Math.random() * 1;
  //       const isActive = localStorage.getItem("installationActive") === "1";

  //       circlesSlotData = [
  //         {
  //           "alerts": [
  //             null,
  //           ],
  //           "value": isActive ? 5.42 + modPower : 0,
  //           "speed": 50,
  //           "length": 70,
  //         },
  //       ];
  //     }
  //     if (value.order === 2) {
  //       chartSlotConfig = value;
  //       chartSlotData = slotsData[key];
  //     }
  //     if (value.order === 3) {
  //       slot3Config = value;
  //       slot3Data = 4.9; //slotsData[key];
  //     }
  //     if (value.order === 4) {
  //       slot4Config = value;
  //       slot4Data = slotsData[key];
  //     }
  //     if (value.order === 5) {
  //       slot5Config = value;
  //       slot5Data = 5.29; //slotsData[key];
  //     }
  //     if (value.order === 6) {
  //       slot6Config = value;
  //       slot6Data = 0.5; //slotsData[key]; activePower
  //     }
  //     if (value.order === 7) {
  //       slot7Config = value;
  //       slot7Data = 6.54; //slotsData[key];//wydajnosc
  //     }
  //     if (value.order === 8) {
  //       slot8Config = value;
  //       slot8Data = slotsData[key];
  //     }
  //     if (value.order === 9) {
  //       slot9Config = value;
  //       slot9Data = slotsData[key];
  //     }
  //     if (value.order === 10) {
  //       slot10Config = value;
  //       slot10Data = slotsData[key];
  //     }
  //     if (value.order === 11) {
  //       slot11Config = value;
  //       slot11Data = 1.5; //slotsData[key];
  //     }
  //     if (value.order === 12) {
  //       slot12Config = value;
  //       slot12Data = slotsData[key];
  //     }
  //   }
  // } else {
  for (const [key, value] of Object.entries(slots)) {
    if (value.order === 1) {
      circlesSlotConfig = value;
      circlesSlotData = slotsData[key];
    }
    if (value.order === 2) {
      chartSlotConfig = value;
      chartSlotData = slotsData[key];
    }
    if (value.order === 3) {
      slot3Config = value;
      slot3Data = slotsData[key];
    }
    if (value.order === 4) {
      slot4Config = value;
      slot4Data = slotsData[key];
    }
    if (value.order === 5) {
      slot5Config = value;
      slot5Data = slotsData[key];
    }
    if (value.order === 6) {
      slot6Config = value;
      slot6Data = slotsData[key];
    }
    if (value.order === 7) {
      slot7Config = value;
      slot7Data = slotsData[key];
    }
    if (value.order === 8) {
      slot8Config = value;
      slot8Data = slotsData[key];
    }
    if (value.order === 9) {
      slot9Config = value;
      slot9Data = slotsData[key];
    }
    if (value.order === 10) {
      slot10Config = value;
      slot10Data = slotsData[key];
    }
    if (value.order === 11) {
      slot11Config = value;
      slot11Data = slotsData[key];
    }
    if (value.order === 12) {
      slot12Config = value;
      slot12Data = slotsData[key];
    }
  }
  // }

  return (
    <>
      <div class={"mgr-dash-wrapper"}>
        <div class={"mgr-dash-data-wrapper"}>
          <span className={"h700 mgr-dash-color"}>{name}</span>

          <div class="mgr-dash-cols">
            <div class={"mgr-dash-circle-col"}>
              <fib-manager-circles-slot
                circles-slot-config={circlesSlotConfig}
                circles-slot-data={circlesSlotData}
              />
            </div>

            <div className="mgr-dash-data-col">
              <div className="mgr-dash-row-top">
                <fib-manager-inverters-slot
                  circles-slot-config={circlesSlotConfig}
                  circles-slot-data={circlesSlotData}
                />

                {renderSlot(slot3Config, slot3Data)}

                {renderSlot(slot4Config, slot4Data)}
              </div>

              <div className="mgr-dash-row-with-slots">
                <div className={"mgr-dash-side-slots"}>
                  {renderSlot(slot5Config, slot5Data)}

                  {renderSlot(slot9Config, slot9Data)}
                </div>

                <div className={"mgr-dash-side-slots"}>
                  {renderSlot(slot6Config, slot6Data)}

                  {renderSlot(slot10Config, slot10Data)}
                </div>

                <div className={"mgr-dash-side-slots"}>
                  {renderSlot(slot7Config, slot7Data)}

                  {renderSlot(slot11Config, slot11Data)}
                </div>

                <div className={"mgr-dash-side-slots"}>
                  {renderSlot(slot8Config, slot8Data)}

                  {renderSlot(slot12Config, slot12Data)}
                </div>
              </div>
            </div>
          </div>

          <div class="mgr-dash-logos-wrapper">
            {isLublin && (
              <>
                <img src="./static/img/dashboards/dash-ZCK.png" alt={"ZCK"} />
                <img src="./static/img/dashboards/dash-UE.png" alt={"UE"} />
              </>
            )}

            <img src="./static/img/dashboards/dash-fibrain.png" alt={"fibrain"} />
          </div>
        </div>
      </div>
    </>
  );
}

function dashboardFour(
  slots: { [slotId: string]: SlotConfig },
  slotsData: { [slotId: string]: SlotData },
  name: string,
  isLublin: boolean,
) {
  let doughnutSlotConfig,
    doughnutSlotData,
    chartSlotConfig,
    chartSlotData,
    slot3Config,
    slot4Config,
    slot5Config,
    slot3Data,
    slot4Data,
    slot5Data;

  for (const [key, value] of Object.entries(slots)) {
    if (value.type === "doughnut") {
      doughnutSlotConfig = value;
      doughnutSlotData = slotsData[key];
    }
    if (value.order === 2) {
      chartSlotConfig = value;
      chartSlotData = slotsData[key];
    }
    if (value.order === 3) {
      slot3Config = value;
      slot3Data = slotsData[key];
    }
    if (value.order === 4) {
      slot4Config = value;
      slot4Data = slotsData[key];
    }
    if (value.order === 5) {
      slot5Config = value;
      slot5Data = slotsData[key];
    }
  }
  const backgroundColors = [
    "rgba(11,180,255,.7)",
    "rgba(230,0,73,.7)",
    "rgba(80,233,145,.7)",
    "rgba(232,217,0,.7)",
    "rgba(155,25,245,.7)",
    "rgba(255,163,0,.7)",
    "rgba(220,10,180,.7)",
    "rgba(179,212,255,.7)",
    "rgba(0,191,160,.7)",
    "rgba(234,94,69,.7)",
    "rgba(100,181,234,.7)",
    "rgba(143,210,67,.7)",
    "rgba(229,202,85,.7)",
    "rgba(179,61,198,.7)",
    "rgba(239,155,32,.7)",
    "rgba(244,106,155,.7)",
    "rgba(155,186,236,.7)",
    "rgba(0,191,111,.7)",
    "rgba(237,191,51,.7)",
    "rgba(189,207,50,.7)",
  ];

  if (doughnutSlotData) {
    doughnutSlotData.backgrounds = backgroundColors;
  }

  return (
    <>
      <div class={"mgr-dash-wrapper"}>
        <div class={"mgr-dash-data-wrapper-dough"}>
          <span class={"h700 mgr-dash-color"}>{name}</span>

          <div class="mgr-dash-cols">
            <div class="mgr-dash-circle-col">
              <fib-manager-doughnut-chart
                doughnut-slot-config={doughnutSlotConfig}
                doughnut-slot-data={doughnutSlotData}
              />
            </div>

            <div class="mgr-dash-data-col">
              <div class="mgr-dash-row-top">
                <fib-manager-inverters-dough-slot
                  doughnut-slot-config={doughnutSlotConfig}
                  doughnut-slot-data={doughnutSlotData}
                />

                {renderSlot(slot3Config, slot3Data)}

                {renderSlot(slot4Config, slot4Data)}

                {renderSlot(slot5Config, slot5Data)}
              </div>

              <div class="mgr-dash-chart-slot">
                <fib-manager-chart-slot
                  chart-slot-config={chartSlotConfig}
                  chart-slot-data={chartSlotData}
                />
              </div>
            </div>
          </div>

          <div class="mgr-dash-logos-wrapper">
            {isLublin && (
              <>
                <img src="./static/img/dashboards/dash-ZCK.png" alt={"ZCK"} />
                <img src="./static/img/dashboards/dash-UE.png" alt={"UE"} />
              </>
            )}

            <img src="./static/img/dashboards/dash-fibrain.png" alt={"fibrain"} />
          </div>
        </div>
      </div>
    </>
  );
}

function renderSlot(slotConfig: any, slotData: any) {
  return (
    <>
      {slotConfig?.type === "weather"
        ? (
          <fib-manager-weather-slot
            weather-slot-config={slotConfig}
          />
        )
        : (slotConfig &&
          (
            <fib-manager-value-slot
              value-slot-config={slotConfig}
              value-slot-data={typeof slotData === "number" ? slotData.toFixed(2) : slotData}
            />
          ))}
    </>
  );
}
