/* global H */
import { truncate } from 'lodash';

export default function makeLog(ns, forceDebugMode = false) {
    return function(msg, ...args) {
        if ((window).debug || forceDebugMode) {
            console.log(ns + ': ' + msg, ...args);
        }
    };
}

const log = makeLog('fib-map');

function createNode(text) {
    const node = document.createElement('div');
    node.innerHTML = text;
    return node.children[0];
}

const makeIconLabel = (iconlabel, icontype, status) => {
    let fontWeight = 'normal';
    let indicator;

    if (icontype === 'selected') {
        fontWeight = 'bold';
        const warning = `<path transform="translate(128,0)" d="M8.40193 1.5C9.55663 -0.500003 12.4434 -0.5 13.5981 1.5L21.3923 15C22.547 17 21.1036 19.5 18.7942 19.5H3.20577C0.896367 19.5 -0.547005 17 0.607695 15L8.40193 1.5Z" fill="#FF9900"/>`;
        const error = `<rect transform="translate(128,2)"  width="19" height="19" rx="4" fill="#DE1C22"/>`;
        if (status === 'warning') {
            indicator = warning;
        }
        if (status === 'error') {
            indicator = error;
        }
    }

    return `<svg width="150" height="75" viewBox="0 0 150 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <text transform="translate(75,7)" dominant-baseline="hanging" text-anchor="middle" font-size=".8rem" font-family="Arial" font-weight="${fontWeight}" fill="black">${iconlabel}</text>
    ${indicator}
    </svg>`;
};

const makeIcon = (iconType) => {
    if (iconType === 'active' || iconType === 'normal') {
        return `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M48 22C48 34.3888 31.624 47.7313 27.1293 51.16C26.4524 51.6764 25.5476 51.6764 24.8707 51.16C20.376 47.7313 4 34.3888 4 22C4 7.6406 13.8497 0 26 0C38.1503 0 48 7.6406 48 22Z" fill="white"/>
            <path d="M34.087 10H17.1304C16.5061 10 16 10.5061 16 11.1304V34.8696C16 35.4939 16.5061 36 17.1304 36H34.087C34.7113 36 35.2174 35.4939 35.2174 34.8696V11.1304C35.2174 10.5061 34.7113 10 34.087 10Z" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M19.9563 35.9999V33.1738H31.2606V35.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M22.2173 33.1738V35.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M24.478 33.1738V35.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M26.739 33.1738V35.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M29 33.1738V35.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M32.9566 19.0439H18.261V24.6961H32.9566V19.0439Z" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M18.2608 12.8257C18.5729 12.8257 18.826 12.5727 18.826 12.2605C18.826 11.9484 18.5729 11.6953 18.2608 11.6953C17.9486 11.6953 17.6956 11.9484 17.6956 12.2605C17.6956 12.5727 17.9486 12.8257 18.2608 12.8257Z" fill="#2A2A2A" fill-opacity="0.946187"/>
            <path d="M32.9568 12.8257C33.269 12.8257 33.522 12.5727 33.522 12.2605C33.522 11.9484 33.269 11.6953 32.9568 11.6953C32.6447 11.6953 32.3916 11.9484 32.3916 12.2605C32.3916 12.5727 32.6447 12.8257 32.9568 12.8257Z" fill="#2A2A2A" fill-opacity="0.946187"/>
            <path d="M18.2608 34.3043C18.5729 34.3043 18.826 34.0512 18.826 33.739C18.826 33.4269 18.5729 33.1738 18.2608 33.1738C17.9486 33.1738 17.6956 33.4269 17.6956 33.739C17.6956 34.0512 17.9486 34.3043 18.2608 34.3043Z" fill="#2A2A2A" fill-opacity="0.946187"/>
            <path d="M32.9568 34.3043C33.269 34.3043 33.522 34.0512 33.522 33.739C33.522 33.4269 33.269 33.1738 32.9568 33.1738C32.6447 33.1738 32.3916 33.4269 32.3916 33.739C32.3916 34.0512 32.6447 34.3043 32.9568 34.3043Z" fill="#2A2A2A" fill-opacity="0.946187"/>
        </g>
        </svg>`;
    }

    if (iconType === 'inactive') {
        return `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M48 22C48 34.3888 31.624 47.7313 27.1293 51.16C26.4524 51.6764 25.5476 51.6764 24.8707 51.16C20.376 47.7313 4 34.3888 4 22C4 7.6406 13.8497 0 26 0C38.1503 0 48 7.6406 48 22Z" fill="#838791"/>
            <path d="M34.087 10H17.1304C16.5061 10 16 10.5061 16 11.1304V34.8696C16 35.4939 16.5061 36 17.1304 36H34.087C34.7113 36 35.2174 35.4939 35.2174 34.8696V11.1304C35.2174 10.5061 34.7113 10 34.087 10Z" stroke="white" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M19.9564 35.9999V33.1738H31.2608V35.9999" stroke="white" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M22.2173 33.1738V35.9999" stroke="white" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M24.4781 33.1738V35.9999" stroke="white" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M26.739 33.1738V35.9999" stroke="white" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M28.9999 33.1738V35.9999" stroke="white" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M32.9565 19.0439H18.2609V24.6961H32.9565V19.0439Z" stroke="white" stroke-width="0.75" stroke-linejoin="round"/>
            <path d="M18.2608 12.8257C18.5729 12.8257 18.826 12.5727 18.826 12.2605C18.826 11.9484 18.5729 11.6953 18.2608 11.6953C17.9486 11.6953 17.6956 11.9484 17.6956 12.2605C17.6956 12.5727 17.9486 12.8257 18.2608 12.8257Z" fill="white"/>
            <path d="M32.9567 12.8257C33.2689 12.8257 33.5219 12.5727 33.5219 12.2605C33.5219 11.9484 33.2689 11.6953 32.9567 11.6953C32.6445 11.6953 32.3915 11.9484 32.3915 12.2605C32.3915 12.5727 32.6445 12.8257 32.9567 12.8257Z" fill="white"/>
            <path d="M18.2608 34.3043C18.5729 34.3043 18.826 34.0512 18.826 33.739C18.826 33.4269 18.5729 33.1738 18.2608 33.1738C17.9486 33.1738 17.6956 33.4269 17.6956 33.739C17.6956 34.0512 17.9486 34.3043 18.2608 34.3043Z" fill="white"/>
            <path d="M32.9567 34.3043C33.2689 34.3043 33.5219 34.0512 33.5219 33.739C33.5219 33.4269 33.2689 33.1738 32.9567 33.1738C32.6445 33.1738 32.3915 33.4269 32.3915 33.739C32.3915 34.0512 32.6445 34.3043 32.9567 34.3043Z" fill="white"/>
        </g>
        </svg>`;
    }

    if (iconType === 'warning') {
        return `<svg width="66" height="57" viewBox="0 0 66 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="scale(.9) translate(0,6)">
            <path d="M30.4019 1.5C31.5566 -0.499998 34.4434 -0.500002 35.5981 1.5L65.0429 52.5C66.1976 54.5 64.7543 57 62.4449 57H3.55514C1.24574 57 -0.197642 54.5 0.957058 52.5L30.4019 1.5Z" fill="#FF9900"/>
            <path d="M41.087 25H24.1304C23.5061 25 23 25.5061 23 26.1304V49.8696C23 50.4939 23.5061 51 24.1304 51H41.087C41.7113 51 42.2174 50.4939 42.2174 49.8696V26.1304C42.2174 25.5061 41.7113 25 41.087 25Z" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-linejoin="round"/>
            <path d="M26.9564 50.9999V48.1738H38.2608V50.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-linejoin="round"/>
            <path d="M29.2173 48.1738V50.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-linejoin="round"/>
            <path d="M31.4781 48.1738V50.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-linejoin="round"/>
            <path d="M33.739 48.1738V50.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-linejoin="round"/>
            <path d="M35.9999 48.1738V50.9999" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-linejoin="round"/>
            <path d="M39.9565 34.0439H25.2609V39.6961H39.9565V34.0439Z" stroke="#2A2A2A" stroke-opacity="0.946187" stroke-linejoin="round"/>
            <path d="M25.2608 27.8257C25.5729 27.8257 25.826 27.5727 25.826 27.2605C25.826 26.9484 25.5729 26.6953 25.2608 26.6953C24.9486 26.6953 24.6956 26.9484 24.6956 27.2605C24.6956 27.5727 24.9486 27.8257 25.2608 27.8257Z" fill="#2A2A2A" fill-opacity="0.946187"/>
            <path d="M39.9567 27.8257C40.2689 27.8257 40.5219 27.5727 40.5219 27.2605C40.5219 26.9484 40.2689 26.6953 39.9567 26.6953C39.6445 26.6953 39.3915 26.9484 39.3915 27.2605C39.3915 27.5727 39.6445 27.8257 39.9567 27.8257Z" fill="#2A2A2A" fill-opacity="0.946187"/>
            <path d="M25.2608 49.3043C25.5729 49.3043 25.826 49.0512 25.826 48.739C25.826 48.4269 25.5729 48.1738 25.2608 48.1738C24.9486 48.1738 24.6956 48.4269 24.6956 48.739C24.6956 49.0512 24.9486 49.3043 25.2608 49.3043Z" fill="#2A2A2A" fill-opacity="0.946187"/>
            <path d="M39.9567 49.3043C40.2689 49.3043 40.5219 49.0512 40.5219 48.739C40.5219 48.4269 40.2689 48.1738 39.9567 48.1738C39.6445 48.1738 39.3915 48.4269 39.3915 48.739C39.3915 49.0512 39.6445 49.3043 39.9567 49.3043Z" fill="#2A2A2A" fill-opacity="0.946187"/>
        </g>
        </svg>`;
    }

    if (iconType === 'error') {
        return `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="scale(1.22)">
            <rect width="42" height="42" rx="4" fill="#DE1C22"/>
            <path d="M29.087 8H12.1304C11.5061 8 11 8.50611 11 9.13043V32.8696C11 33.4939 11.5061 34 12.1304 34H29.087C29.7113 34 30.2174 33.4939 30.2174 32.8696V9.13043C30.2174 8.50611 29.7113 8 29.087 8Z" stroke="white" stroke-linejoin="round"/>
            <path d="M14.9563 33.9999V31.1738H26.2606V33.9999" stroke="white" stroke-linejoin="round"/>
            <path d="M17.2173 31.1738V33.9999" stroke="white" stroke-linejoin="round"/>
            <path d="M19.478 31.1738V33.9999" stroke="white" stroke-linejoin="round"/>
            <path d="M21.739 31.1738V33.9999" stroke="white" stroke-linejoin="round"/>
            <path d="M24 31.1738V33.9999" stroke="white" stroke-linejoin="round"/>
            <path d="M27.9566 17.0439H13.261V22.6961H27.9566V17.0439Z" stroke="white" stroke-linejoin="round"/>
            <path d="M13.326 10.2605C13.326 10.2965 13.2968 10.3257 13.2608 10.3257C13.2248 10.3257 13.1956 10.2965 13.1956 10.2605C13.1956 10.2245 13.2248 10.1953 13.2608 10.1953C13.2968 10.1953 13.326 10.2245 13.326 10.2605Z" fill="#2A2A2A" fill-opacity="0.946187" stroke="white"/>
            <path d="M28.022 10.2605C28.022 10.2965 27.9928 10.3257 27.9568 10.3257C27.9208 10.3257 27.8916 10.2965 27.8916 10.2605C27.8916 10.2245 27.9208 10.1953 27.9568 10.1953C27.9928 10.1953 28.022 10.2245 28.022 10.2605Z" fill="#2A2A2A" fill-opacity="0.946187" stroke="white"/>
            <path d="M13.326 31.739C13.326 31.7751 13.2968 31.8043 13.2608 31.8043C13.2248 31.8043 13.1956 31.7751 13.1956 31.739C13.1956 31.703 13.2248 31.6738 13.2608 31.6738C13.2968 31.6738 13.326 31.703 13.326 31.739Z" fill="#2A2A2A" fill-opacity="0.946187" stroke="white"/>
            <path d="M28.022 31.739C28.022 31.7751 27.9928 31.8043 27.9568 31.8043C27.9208 31.8043 27.8916 31.7751 27.8916 31.739C27.8916 31.703 27.9208 31.6738 27.9568 31.6738C27.9928 31.6738 28.022 31.703 28.022 31.739Z" fill="#2A2A2A" fill-opacity="0.946187" stroke="white"/>
        </g>
        </svg>`;
    }

    if (iconType === 'selected') {
        return `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
        <path d="M48 22C48 34.3888 31.624 47.7313 27.1293 51.16C26.4524 51.6764 25.5476 51.6764 24.8707 51.16C20.376 47.7313 4 34.3888 4 22C4 7.6406 13.8497 0 26 0C38.1503 0 48 7.6406 48 22Z" fill="#1380E4"/>
        <path d="M34.087 10H17.1304C16.5061 10 16 10.5061 16 11.1304V34.8696C16 35.4939 16.5061 36 17.1304 36H34.087C34.7113 36 35.2174 35.4939 35.2174 34.8696V11.1304C35.2174 10.5061 34.7113 10 34.087 10Z" stroke="white" stroke-linejoin="round"/>
        <path d="M19.9564 35.9999V33.1738H31.2608V35.9999" stroke="white" stroke-linejoin="round"/>
        <path d="M22.2173 33.1738V35.9999" stroke="white" stroke-linejoin="round"/>
        <path d="M24.4781 33.1738V35.9999" stroke="white" stroke-linejoin="round"/>
        <path d="M26.739 33.1738V35.9999" stroke="white" stroke-linejoin="round"/>
        <path d="M28.9999 33.1738V35.9999" stroke="white" stroke-linejoin="round"/>
        <path d="M32.9565 19.0439H18.2609V24.6961H32.9565V19.0439Z" stroke="white" stroke-linejoin="round"/>
        <path d="M18.326 12.2605C18.326 12.2965 18.2968 12.3257 18.2608 12.3257C18.2248 12.3257 18.1956 12.2965 18.1956 12.2605C18.1956 12.2245 18.2248 12.1953 18.2608 12.1953C18.2968 12.1953 18.326 12.2245 18.326 12.2605Z" fill="#1380E4" stroke="white"/>
        <path d="M33.0219 12.2605C33.0219 12.2965 32.9927 12.3257 32.9567 12.3257C32.9207 12.3257 32.8915 12.2965 32.8915 12.2605C32.8915 12.2245 32.9207 12.1953 32.9567 12.1953C32.9927 12.1953 33.0219 12.2245 33.0219 12.2605Z" fill="#1380E4" stroke="white"/>
        <path d="M18.326 33.739C18.326 33.7751 18.2968 33.8043 18.2608 33.8043C18.2248 33.8043 18.1956 33.7751 18.1956 33.739C18.1956 33.703 18.2248 33.6738 18.2608 33.6738C18.2968 33.6738 18.326 33.703 18.326 33.739Z" fill="#1380E4" stroke="white"/>
        <path d="M33.0219 33.739C33.0219 33.7751 32.9927 33.8043 32.9567 33.8043C32.9207 33.8043 32.8915 33.7751 32.8915 33.739C32.8915 33.703 32.9207 33.6738 32.9567 33.6738C32.9927 33.6738 33.0219 33.703 33.0219 33.739Z" fill="#1380E4" stroke="white"/>
    </g>
    </svg>`;
    }
};

async function ensureJsIsLoaded() {
    if (!window['H']) {
        await import('https://js.api.here.com/v3/3.1/mapsjs-core.js');
        await import('https://js.api.here.com/v3/3.1/mapsjs-service.js');
        await import('https://js.api.here.com/v3/3.1/mapsjs-mapevents.js');
        await import('https://js.api.here.com/v3/3.1/mapsjs-ui.js');
        await new Promise(resolve => setTimeout(resolve, 100));
    }
}

class FibMap extends HTMLElement {
    static get observedAttributes() {
        return ['api-key', 'apiKey', 'apikey', 'selectable', 'zoom', 'center-lat', 'center-lng', 'centerLat', 'centerlat', 'centerLng', 'centerlng'];
    }

    constructor() {
        super();
        this.myAttrs = {};
        this.attachShadow({ mode: 'open' });
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.styleNode = createNode(`<style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
            }
            </style>`);
        this.shadowRoot.appendChild(this.styleNode);
        this.shadowRoot.appendChild(createNode('<link href="https://js.api.here.com/v3/3.1/mapsjs-ui.css" rel="stylesheet"/>'));
        this.observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList' && this.map) {
                    this.syncMarkers();
                }
            }
        });
        this.addedMarkers = [];
        this.addEventListener('marker', () => this.syncMarkers());
    }

    onMarkerClick(event) {
        if (!event.target.data.mid.includes('-label')) {
            const selectedEvent = new CustomEvent('select', {
                bubbles: true,
                detail: event.target.getData().mid
            });
            log('dispatching event', selectedEvent);
            this.dispatchEvent(selectedEvent);
        }
    }

    async attributeChangedCallback(attrName, _previous, current) {
        this.myAttrs[attrName] = current;
        log('got attribute', attrName);

        if (attrName === 'selectable') {
            const coords = (current || '').split('x');
            if (this.selectedCoords && coords[0] && coords[1]) {
                log('change location');
                this.setLocation({ lat: coords[0], lng: coords[1] });
            }
            return;
        }

        const apiKey = this.myAttrs['api-key'] || this.myAttrs['apiKey'] || this.myAttrs['apikey'];

        if (!apiKey) {
            return;
        }
        log('establishing new here connection for key:' + current);
        await ensureJsIsLoaded();

        if (this.addedMarkers && this.addedMarkers.length > 0) {
            this.removeMarkers(this.addedMarkers);
            this.addedMarkers = [];
        }
        this.markersGroup = new H.map.Group();

        if (this.mainNode) {
            this.mainNode.remove();
            if (this.map) {
                this.map.removeEventListener('tap', this.onMapClick);
                this.markersGroup.removeEventListener('tap', this.onMarkerClick);
            }
        }

        this.mainNode = createNode('<div class="map-container"></div>');
        this.mainNode.style.width = this.clientWidth + 'px';
        this.mainNode.style.height = this.clientHeight + 'px';
        this.shadowRoot.appendChild(this.mainNode);

        this.platform = new H.service.Platform({
            apikey: apiKey
        });

        log('drawing a map');
        const defaultLayers = this.platform.createDefaultLayers({ lg: 'pl' });
        this.map = new H.Map(
            this.mainNode,
            defaultLayers.vector.normal.map,
            {
                zoom: this.myAttrs['zoom'] ?? 6,
                center: { lat: this.myAttrs['centerlat'] ?? 52, lng: this.myAttrs['centerlng'] ?? 19 }
            }
        );

        this.map.addObject(this.markersGroup);

        this.ui = H.ui.UI.createDefault(this.map, defaultLayers, 'pl-PL');
        this.ui.removeControl('mapsettings');

        this.syncMarkers();

        var mapEvents = new H.mapevents.MapEvents(this.map);
        new H.mapevents.Behavior(mapEvents);

        this.map.addEventListener('tap', this.onMapClick);
        this.markersGroup.addEventListener('tap', this.onMarkerClick);

        if (this.getAttribute('selectable') !== null) {
            const split = (this.getAttribute('selectable') || '').split('x');
            if (split[0] && split[1]) {
                const coords = { lat: split[0], lng: split[1] };
                this.setLocation(coords);
                this.map.setCenter(coords);
            }
        }
    }

    onMapClick(event) {
        if (this.getAttribute('selectable') !== null) {
            const coords = this.map.screenToGeo(
                event.currentPointer.viewportX,
                event.currentPointer.viewportY
            );
            this.setLocation(coords);
            this.triggerUpdateEvent(coords);
        }
    }

    triggerUpdateEvent(coords) {
        const updateEvent = new CustomEvent('update', {
            bubbles: true,
            detail: { value: coords }
        });
        log('Dispatching update event', updateEvent);
        this.dispatchEvent(updateEvent);
    }

    setLocation(coords) {
        if (!this.map) {
            log('Map is not initialized');
            return;
        }
        log('setting location', coords);
        this.selectedCoords = coords;

        if (!this.marker) {
            const icon = new H.map.Icon(makeIcon());
            this.marker = new H.map.Marker(coords, { icon: icon });
            this.map.addObject(this.marker);
        } else {
            this.marker.setGeometry(coords);
        }
    }

    connectedCallback() {
        this.observer.observe(this, { childList: true });
        window.addEventListener('resize', this.onResize);
    }

    disconnectedCallback() {
        this.observer.disconnect();
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        if (this.map) {
            const mapDetailWrapper = document.querySelector('.fib-map-details-wrapper');
            const mapAlertsWrapper = document.querySelector('.fib-map-notifications-wrapper');
            const html = document.querySelector('html');
            const subtract = html.clientWidth < 1920 ? 560 : 720;
            let targetWidth =
                html.clientWidth - (mapDetailWrapper ? subtract : 0) - (mapAlertsWrapper?.clientWidth ?? 0);
            this.mainNode.style.width = targetWidth + 'px';
            this.mainNode.style.height = this.clientHeight + 'px';
            this.map.getViewPort().resize();
        }
    }

    syncMarkers() {
        requestAnimationFrame(() => {
            const children = Array.from(this.children);
            children.forEach(child => {
                const lat = child.getAttribute('lat');
                const lng = child.getAttribute('lng');
                const icontype = child.getAttribute('icontype');
                let iconlabel = child.getAttribute('iconlabel');
                const status = child.getAttribute('status');
                const mid = child.getAttribute('mid');

                if (!lat) {
                    this.onResize();
                    return;
                }

                if (child instanceof FibMapMarker) {
                    let marker = this.addedMarkers.find(marker => marker.mid === mid);

                    let icon;
                    if (iconlabel) {
                        iconlabel = truncate(iconlabel, { length: 16 });
                        icon = new H.map.Icon(makeIconLabel(iconlabel, icontype, status));
                    } else {
                        icon = new H.map.Icon(makeIcon(icontype, status));
                    }

                    if (!marker) {
                        marker = new H.map.Marker(
                            { lat, lng },
                            {
                                icon: icon,
                                data: { mid }
                            }
                        );
                        this.markersGroup.addObject(marker);
                        marker.addEventListener('click', this.onMarkerClick);
                        const m = { lat, lng, marker, mid, icontype, status };
                        log('adding marker', m);
                        this.addedMarkers.push(m);
                    } else {
                        marker.marker.setGeometry({ lat, lng });
                        marker.marker.setIcon(icon);
                    }
                }
            });

            const markersToRemove = this.addedMarkers.filter(m =>
                !children.find(child => {
                    return child.getAttribute('mid') === m.mid;
                }));
            this.removeMarkers(markersToRemove);
            this.onResize();
        });
    }

    removeMarkers(markers) {
        markers.forEach(m => {
            const marker = m.marker;
            log('removing marker', m);
            marker.removeEventListener('click', this.onMarkerClick);
            if (this.markersGroup.getObjects().includes(marker)) {
                this.addedMarkers = this.addedMarkers.filter(item => {
                    return item.mid !== m.mid;
                });
                this.markersGroup.removeObject(marker);
            }
        });
    }
}

class FibMapMarker extends HTMLElement {
    static get observedAttributes() {
        return ['lat', 'lng', 'mid', 'icontype', 'iconlabel', 'status'];
    }

    attributeChangedCallback(attrName, _previous, current) {
        this.dispatchEvent(new CustomEvent('marker', { bubbles: true }));
    }
}

const isElementDefined = customElements.get('fib-map');

if (!isElementDefined) {
    customElements.define('fib-map', FibMap);
    customElements.define('fib-map-marker', FibMapMarker);
}
