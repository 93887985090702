import _ from "lodash";
import { dates, flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import type { ChartSlotConfig, ChartValue, Param } from "nexus/node/contracts";
import type { Device, LatestMetrics } from "nexus/node/MetricsAccess";
import timestampToDate from "timestamp-to-date";

type LoadData = any;

type InstallationDetails = {
  installationId: string;
  platform: string;
  machineId: string;
  token: string;
  chartConfig: ChartSlotConfig;
};

interface ChartParameters {
  installationId: string;
  params: string[];
  marker: string;
  timeFrom: number;
  timeTo: number;
}

export type FibChart = { dateFrom: number; dateTo: number };
export type FibSeries = { param: string; paramLabel: string; values: ChartValue[] };
export type ChartFor = { marker: string; param: string };
export type Chart = {
  chartId: string;
  chartFor: ChartFor;
  param: Param;
  fibChart: FibChart;
  fibSeries: FibSeries[];
  errMsg: string;
};

const offlineAfterTime = 1000 * 60 * 5;
const lang = tr.getLang();
const installationManager = grow.plant("InstallationManager");
const metricsManager = grow.plant("MetricsManager");
const dashboardManager = grow.plant("DashboardManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "SelectDevice", marker: string]
  | [type: "None"]
  | [type: "SearchPhrase", phrase: string]
  | [type: "CreateChart", value: { marker: string; param: Param[] }]
  | [type: "RemoveChart", chartFor: ChartFor]
  | [type: "LoadChartDataSuccess", chart: Chart]
  | [type: "ChartFromChange", value: { from: string; errMsg: string; chartFor: ChartFor }]
  | [type: "ChartToChange", value: { to: string; errMsg: string; chartFor: ChartFor }]
  | [
    type: "Zoom",
    value: { chartFor: ChartFor; targetDate: { targetDateFrom: number; targetDateTo: number } },
  ]
  | [type: "RemoveSeries", value: { chartFor: ChartFor; param: string }]
  | [type: "AddSeries", chartFor: ChartFor]
  | [
    type: "ChartPredefined",
    data: { chartSelected: Chart; range: "hour" | "day" | "week" | "month" | "year" },
  ]
  | [type: "ChartRangeMove", data: { changedChart: Chart; direction: "left" | "right" }]
  | [type: "DownloadChart", chart: Chart]
  | [type: "DownloadChartFailed"]
  | [type: "DownloadChartSuccess"]
  | [type: "SaveDashboardError"]
  | [type: "SaveDashboardSuccess"]
  | [type: "LoadDeviceDetails", data: any]
  | [type: "LoadDataFailed", errMsg: string]
  | [type: "LoadDataSuccess", { data: LatestMetrics; params: Param[] }];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  isLoading: boolean;
  loadedData?: LatestMetrics;
  paramsDescription: Param[];
  installation?: InstallationDetails;
  selectedDeviceMarker: string;
  lastMetricsCreatedAt: number;
  isDeviceRefreshing: boolean;
  charts: Chart[];
  isChartDataLoading: { [chartId: string]: boolean };
  searchPhrase: string;
}

stm.component({
  tagName: "fib-manager-chart-builder",
  shadow: false,
  debug: false,
  propTypes: {
    installation: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        paramsDescription: [],
        selectedDeviceMarker: "",
        lastMetricsCreatedAt: 0,
        isDeviceRefreshing: false,
        charts: [],
        isChartDataLoading: {},
        searchPhrase: "",
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installation", P.select()], (attr) => {
      if (!attr) {
        return [state, null];
      }

      const installation = attr as InstallationDetails;

      return [
        { ...state, isLoading: true, installation: installation },
        loadData(_.omit(installation, ["chartConfig"])),
      ];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["LoadDataFailed", P.select()], (errMsg) => {
      flashMessage(errMsg, "error");
      state.isLoading = false;
      if (state.isDeviceRefreshing) {
        flashMessage(tr("fibTechPanel.deviceRefreshError"), "error");
        state.isDeviceRefreshing = false;
      }
      return [state, null];
    })
    .with(["LoadDataSuccess", P.select()], (loadedData: LoadData) => {
      state.isLoading = false;
      state.lastMetricsCreatedAt = 0;

      if (!loadedData || loadedData.data.length === 0) {
        flashMessage(tr("fibTechPanel.notConnectedString"), "error");
        state.loadedData = { devices: [] };
        return [state, null];
      }

      state.paramsDescription = loadedData.params;
      let timeOffline = Date.now() - offlineAfterTime;
      const len = loadedData.data.length;

      for (let i = 0; i < len; i++) {
        const updateDate = new Date(loadedData.data[i].updatedAt).valueOf() ?? 0;
        if (updateDate < timeOffline) {
          loadedData.data[i].deviceStatus = "offline";
        }
      }

      state.loadedData = { devices: loadedData.data };

      return [state, loadChart(state)];
    })
    .with(["SelectDevice", P.select("deviceMarker")], (device) => {
      state.selectedDeviceMarker = device.deviceMarker === state.selectedDeviceMarker
        ? ""
        : device.deviceMarker;
      return [
        state,
        state.selectedDeviceMarker !== "" && state.installation?.machineId
          ? loadDeviceDetails(state.installation, state.selectedDeviceMarker)
          : null,
      ];
    })
    .with(["LoadDeviceDetails", P.select()], (loadedDetails: any) => {
      state.isLoading = false;

      state.loadedData.devices = state.loadedData.devices.map((dev) => {
        return (loadedDetails && loadedDetails.marker && dev.marker === loadedDetails?.marker)
          ? { ...dev, updatedAt: loadedDetails.updatedAt, ...loadedDetails.detail }
          : dev;
      });

      state.lastMetricsCreatedAt = (loadedDetails && loadedDetails.updatedAt)
        ? new Date(loadedDetails?.updatedAt).valueOf()
        : 0;
      if (state.isDeviceRefreshing) {
        flashMessage(tr("fibTechPanel.deviceRefreshEnd"), "info");
        state.isDeviceRefreshing = false;
      }

      return [state, null];
    })
    .with(["None"], () => [state, null])
    .with(["CreateChart", P.select()], ({ marker, param }) => {
      state.isLoading = true;
      const chartFor: ChartFor = { marker, param: param[0].param };

      if (!findChart(chartFor)) {
        const dateFrom = state.installation?.chartConfig.timeFrom ??
          Date.now() - 1000 * 60 * 60 * 24 * 7;
        const dateTo = state.installation?.chartConfig.timeTo ?? Date.now();
        const fibSeries = param.map((parameter) => {
          const paramTitle = parameter.name[lang] === "not defined"
            ? `[${parameter.param}]`
            : parameter.name[lang];

          return {
            param: parameter.param,
            paramLabel: paramTitle,
            values: [],
          };
        });
        const chart: Chart = {
          chartId: Date.now().toString(36) + Math.random().toString(),
          param,
          chartFor: {
            marker,
            param: param[0].param,
          },
          fibChart: {
            dateFrom,
            dateTo,
          },
          fibSeries,
          errMsg: "",
        };

        const paramsList = param.map((v) => v.param);
        state.charts.unshift(chart);
        state.isChartDataLoading[state.charts.length - 1] = true;
        const chartParameters: ChartParameters = {
          installationId: state.installation?.installationId ?? "",
          params: paramsList,
          marker,
          timeFrom: dateFrom,
          timeTo: dateTo,
        };

        return [state, loadChartData(chart, chartParameters, state.paramsDescription)];
      }
      return [state, msg("RemoveChart", chartFor)];
    })
    .with(["RemoveChart", P.select()], (chartFor) => {
      _.remove(
        state.charts,
        (chart) =>
          chart.chartFor.marker === chartFor.marker && chart.chartFor.param === chartFor.param,
      );
      return [state, null];
    })
    .with(["LoadChartDataSuccess", P.select()], (chart) => {
      state.isLoading = false;
      state.isChartDataLoading[chart.chartId] = false;
      return [state, state.isDeviceRefreshing ? saveDashboard(state) : null];
    })
    .with(["SaveDashboardSuccess"], () => {
      flashMessage(tr("gcrud.saveSuccess"), "success");
      state.isDeviceRefreshing = false;
      state.isLoading = false;
      return [state, null];
    })
    .with(["SaveDashboardError"], () => {
      state.isDeviceRefreshing = false;
      state.isLoading = false;
      flashMessage(tr("general.saveFailed"), "error");
      return [state, null];
    })
    .with(["Zoom", P.select()], ({ chartFor, targetDate }) => {
      const chart = findChart(chartFor);
      if (!chart) {
        return [state, null];
      }

      if (targetDate.targetDateFrom === Number.POSITIVE_INFINITY) {
        flashMessage(tr("fib.chart.zoomIsMin"), "info");
        state.isLoading = false;
        return [state, null];
      }

      if (targetDate.targetDateFrom === Number.NEGATIVE_INFINITY) {
        flashMessage(tr("fib.chart.zoomIsMax"), "info");
        state.isLoading = false;
        return [state, null];
      }

      chart.fibChart.dateFrom = targetDate.targetDateFrom;
      chart.fibChart.dateTo = targetDate.targetDateTo;
      state.isChartDataLoading[chart.chartId] = true;
      const chartParameters: ChartParameters = extractChartParameters(chart, chartFor);
      state.isDeviceRefreshing = true;

      return [state, loadChartData(chart, chartParameters, state.paramsDescription)];
    })
    .with(["RemoveSeries", P.select()], ({ chartFor, param }) => {
      const chart = findChart(chartFor);
      if (!chart) {
        return [state, null];
      }
      state.isDeviceRefreshing = true;
      const series = chart.fibSeries.filter((series) => series.param !== param);

      if (chart.fibSeries.length > 1) {
        chart.fibSeries = series;
      } else {
        return [state, null];
      }
      // chart.fibSeries.length <= 1
      //   ? state.charts = filterCharts(chartFor)
      //   : chart.fibSeries = series;
      state.isLoading = true;
      return [state, saveDashboard(state)];
    })
    .with(["AddSeries", P.select()], (chartFor) => {
      // limit params to the same device
      const devices = state.loadedData?.devices.map((device) => ({
        ...device,
        params: state.paramsDescription.find((paramsByDevice) =>
          device.marker === paramsByDevice.deviceMarker
        ).params,
      })).filter((dev) =>
        dev.marker === state.installation?.chartConfig.deviceMarker
      );

      const chart = findChart(chartFor);
      if (!chart) {
        return [state, null];
      }
      state.isDeviceRefreshing = true;
      const chartParameters: ChartParameters = extractChartParameters(chart, chartFor);
      state.isLoading = true;
      return [state, openModal(chart, chartParameters, devices, state.paramsDescription)];
    })
    .with(["ChartFromChange", P.select()], ({ from, errMsg, chartFor }) => {
      const fromAsNumber = Date.parse(from);
      const chart = findChart(chartFor);
      if (!chart) {
        return [state, null];
      }
      if (errMsg) {
        chart.errMsg = errMsg;
        return [state, null];
      }
      if (fromAsNumber > chart.fibChart.dateTo) {
        chart.errMsg = tr("fib.chart.dateError");
      } else if (chart.fibChart.dateTo - fromAsNumber < 1000) {
        chart.errMsg = tr("fib.chart.dateBetweenError");
      } else {
        chart.fibChart.dateFrom = fromAsNumber;
        chart.errMsg = "";
      }

      state.isChartDataLoading[chart.chartId] = true;
      state.isDeviceRefreshing = true;
      const chartParameters: ChartParameters = extractChartParameters(chart, chartFor);
      state.isLoading = true;
      return [state, loadChartData(chart, chartParameters, state.paramsDescription)];
    })
    .with(["ChartToChange", P.select()], ({ to, errMsg, chartFor }) => {
      const chart = findChart(chartFor);

      if (!chart) {
        return [state, null];
      }

      if (state.isChartDataLoading[chart.chartId]) {
        flashMessage(tr("general.inProgress"), "info");
        return [state, null];
      }

      state.isChartDataLoading[chart.chartId] = true;

      const toAsNumber = Date.parse(to);

      if (errMsg) {
        chart.errMsg = errMsg;
        return [state, null];
      }
      if (toAsNumber < chart.fibChart.dateFrom) {
        chart.errMsg = tr("fib.chart.dateError");
      } else if (toAsNumber - chart.fibChart.dateFrom < 1000) {
        chart.errMsg = tr("fib.chart.dateBetweenError");
      } else {
        chart.fibChart.dateTo = toAsNumber;
        chart.errMsg = "";
      }
      state.isDeviceRefreshing = true;
      const chartParameters: ChartParameters = extractChartParameters(chart, chartFor);
      state.isLoading = true;
      return [state, loadChartData(chart, chartParameters, state.paramsDescription)];
    })
    .with(["ChartRangeMove", P.select()], (data) => {
      const { changedChart, direction } = data;
      const duration = changedChart.fibChart.dateTo - changedChart.fibChart.dateFrom + 1000;
      let newFrom, newTo;

      if (direction === "left") {
        newFrom = changedChart.fibChart.dateFrom - duration;
        newTo = changedChart.fibChart.dateTo - duration;
      } else {
        newFrom = changedChart.fibChart.dateFrom + duration;
        newTo = changedChart.fibChart.dateTo + duration;
      }

      const chart = findChart(changedChart.chartFor);

      if (!chart) {
        return [state, null];
      }

      if (state.isChartDataLoading[chart.chartId]) {
        flashMessage(tr("general.inProgress"), "info");
        return [state, null];
      }

      state.isChartDataLoading[chart.chartId] = true;

      chart.fibChart.dateTo = newTo;
      chart.fibChart.dateFrom = newFrom;
      chart.errMsg = "";
      state.isDeviceRefreshing = true;

      const chartParameters: ChartParameters = extractChartParameters(chart, chart.chartFor);
      state.isLoading = true;
      return [state, loadChartData(chart, chartParameters, state.paramsDescription)];
    })
    .with(["ChartPredefined", P.select()], (data) => {
      const { chartSelected, range } = data;

      const chart = findChart(chartSelected.chartFor);

      let newFrom, newTo;

      const currentDate = new Date();
      const cYear = currentDate.getFullYear();
      const cMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const cDay = currentDate.getDate().toString().padStart(2, "0");
      const cHour = currentDate.getHours().toString().padStart(2, "0");

      if (range === "year") {
        // if we put all year range data are aggregated to one number instead of months
        newFrom = cYear + "-01-01 02:00:00";
        newTo = cYear + "-12-25 22:59:59";
      } else if (range === "month") {
        newFrom = cYear + "-" + cMonth + "-01 00:00:00";
        const lastMonthDay = new Date(cYear, currentDate.getMonth() + 1, 0).getDate();
        newTo = cYear + "-" + cMonth + "-" + lastMonthDay + " 23:59:59";
      } else if (range === "week") {
        // its working if sunday is the beginning of month, need to adjust
        const cWeekStart = currentDate.getDate() - currentDate.getDay();
        const cWeekEnd = cWeekStart + 6;

        newFrom = cYear + "-" + cMonth + "-" + cWeekStart.toString().padStart(2, "0") + " 00:00:00";
        newTo = cYear + "-" + cMonth + "-" + cWeekEnd.toString().padStart(2, "0") + " 23:59:59";
      } else if (range === "day") {
        newFrom = cYear + "-" + cMonth + "-" + cDay + " 00:00:00";
        newTo = cYear + "-" + cMonth + "-" + cDay + " 23:59:59";
      } else if (range === "hour") {
        newFrom = cYear + "-" + cMonth + "-" + cDay + " " + cHour + ":00:00";
        newTo = cYear + "-" + cMonth + "-" + cDay + " " + cHour + ":59:59";
      }

      if (!chart) {
        return [state, null];
      }

      if (state.isChartDataLoading[chart.chartId]) {
        flashMessage(tr("general.inProgress"), "info");
        return [state, null];
      }

      state.isChartDataLoading[chart.chartId] = true;

      chart.fibChart.dateTo = Date.parse(newTo as string);
      chart.fibChart.dateFrom = Date.parse(newFrom as string);
      chart.errMsg = "";
      state.isDeviceRefreshing = true;

      const chartParameters: ChartParameters = extractChartParameters(chart, chart.chartFor);
      state.isLoading = true;
      return [state, loadChartData(chart, chartParameters, state.paramsDescription)];
    })
    .with(["DownloadChart", P.select()], (chart) => {
      return [state, downloadChart(chart)];
    })
    .with(["DownloadChartFailed"], () => {
      flashMessage(tr("fib.tech.loadingError"), "error");
      return [state, null];
    })
    .with(["DownloadChartSuccess"], () => {
      return [state, null];
    })
    .with(["SearchPhrase", P.select()], (searchPhrase) => [
      { ...state, searchPhrase },
      null,
    ])
    .exhaustive();

  function loadChart(state: State) {
    const marker = state.installation?.chartConfig.deviceMarker ?? "";
    const params = [state.installation?.chartConfig.prop];
    const extraProps = state.installation?.chartConfig.extraProps ?? [];
    const paramDesc = state.paramsDescription.find((device) => device.deviceMarker === marker)
      .params as Param[];
    const paramDetails = paramDesc.filter((param) => params?.includes(param.param));

    if (extraProps.length > 0) {
      const extraParamsDesc = state.paramsDescription.find((device) =>
        device.params.find((devParam: any) => {
          return extraProps.includes(devParam.param);
        })
      ).params as Param[];
      const extraParamDetails = extraParamsDesc.filter((param) => extraProps.includes(param.param));
      paramDetails.push(...extraParamDetails);
    }

    return msg("CreateChart", { marker, param: paramDetails });
  }

  function findChart(chartFor: ChartFor) {
    return state.charts.find((chart) =>
      chart.chartFor.marker === chartFor.marker && chart.chartFor.param === chartFor.param
    );
  }

  function filterCharts(chartFor: ChartFor) {
    return state.charts.filter((chart) =>
      chart.chartFor.marker !== chartFor.marker && chart.chartFor.param !== chartFor.param
    );
  }

  function extractChartParameters(chart: Chart, chartFor: ChartFor): ChartParameters {
    return {
      installationId: state.installation?.installationId ?? "",
      params: chart.fibSeries.map((series) => series.param),
      marker: chartFor.marker,
      timeFrom: chart.fibChart.dateFrom,
      timeTo: chart.fibChart.dateTo,
    };
  }
}

async function saveDashboard(state: State) {
  try {
    const key = { workspaceId: router.getCurrentRoute().params.installationId, itemId: "reports" };
    const chartProp = state.installation?.chartConfig.prop;
    const chartPropWithMarker = `${chartProp}-${state.installation?.chartConfig.deviceMarker}`;

    const extraProps: string[] = [];
    if (state.charts[0].fibSeries.length > 1) {
      state.charts[0].fibSeries.forEach((param) => {
        if (!extraProps.includes(param.param) && param.param !== chartProp) {
          extraProps.push(param.param);
        }
      });
    }

    const loadDashboard = await dashboardManager.openDashboard(key, state.installation?.token);

    loadDashboard.config.slots[chartPropWithMarker] = {
      ...state.installation?.chartConfig,
      timeFrom: state.charts[0].fibChart.dateFrom,
      timeTo: state.charts[0].fibChart.dateTo,
      extraProps,
    };
    const dashboardId = await dashboardManager.configureDashboard(key, loadDashboard.config);
  } catch (err) {
    return msg("SaveDashboardError");
  }
  return msg("SaveDashboardSuccess");
}

function downloadChart(chart: Chart) {
  try {
    let allSeries: string = "";

    chart.fibSeries.map((serie) => {
      const dateFormat = "yyyy-MM-dd HH:mm:ss";
      const serieWithHumanDate = serie.values.map((value) => ({
        timestamp: timestampToDate(new Date(value.timestamp).getTime() as number, dateFormat),
        value: value.value,
      }));
      const data = convertToCSV(serieWithHumanDate);
      const csv = `${serie.param}\r\n${
        serie.paramLabel ?? serie.param
      }\r\ntimestamp;value\r\n${data}`;
      allSeries += csv;
    });

    const blob = new Blob([allSeries], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "export.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return msg("DownloadChartSuccess");
  } catch (err) {
    return msg("DownloadChartFailed");
  }
}

function convertToCSV(array: any[]) {
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      if (line != "") {
        line += ";";
      }
      line += array[i][index];
    }
    str += line + "\r\n";
  }

  return str;
}

async function openModal(
  chart: Chart,
  chartParameters: ChartParameters,
  devices: any,
  params: Param[],
) {
  if (!devices) {
    return msg("LoadDataFailed", tr("fib.tech.noDevices"));
  }

  const devicesExt = { devices, params };

  const result = await modal({
    header: tr("fib.chart.newParameter"),
    large: true,
    body: (close) => (
      <fib-select-parameter devices={devicesExt} closeFn={close}></fib-select-parameter>
    ),
  }) as { param: string };

  if (!result) {
    return msg("LoadDataFailed", tr("fib.chart.addingSeriesFailed"));
  }

  if (chartParameters.params.find((param) => param === result.param) === undefined) {
    chartParameters.params.push(result.param);
    return loadChartData(chart, chartParameters, params);
  }
  return msg("LoadDataFailed", tr("fibAdminInstallation.duplicateParamChoosen"));
}

async function loadData(
  cfg: { installationId: string; machineId: string; platform: string },
): Promise<Msg> {
  const { installationId, machineId, platform } = cfg;

  try {
    const devicesList = await installationManager.getDevices(
      {
        installationId,
        machineId,
      },
    );

    if (!devicesList) {
      return msg("LoadDataFailed", tr("fibAdminInstallation.loadDataFailed"));
    }

    const paramsDescription = await installationManager.readInstallationParams(
      {
        instId: installationId,
        platform,
        onlyVisible: true,
      },
    );

    return msg("LoadDataSuccess", { data: devicesList, params: paramsDescription });
  } catch (error) {
    return msg("LoadDataFailed", tr("fibAdminInstallation.loadDataFailed"));
  }
}

async function loadDeviceDetails(
  installation: InstallationDetails,
  deviceMarker: string,
): Promise<Msg> {
  try {
    const data = await installationManager.getDeviceParams(
      {
        installationId: installation.installationId,
        machineId: installation.machineId,
        marker: deviceMarker,
      },
    );

    return msg("LoadDeviceDetails", data);
  } catch (error) {
    return msg("LoadDataFailed", tr("fibAdminInstallation.loadDataFailed"));
  }
}

function getSelectedDevice(state: State): Device {
  return (state.loadedData?.devices ?? []).find((device) =>
    device.marker === state.selectedDeviceMarker
  ) ?? {
    modelName: "",
    marker: "",
    kind: "",
    deviceStatus: "inactive",
  };
}

async function loadChartData(chart: Chart, chartParameters: ChartParameters, params: Param[]) {
  let res;
  try {
    res = await metricsManager.getMetricsInTime(chartParameters);
  } catch (error) {
    return msg("LoadDataFailed", tr("fibAdminInstallation.loadDataFailed"));
  }

  let chartData: FibSeries[] = res.map((item: any) => ({
    ...item,
    paramLabel: tr(findDescription(params, item.param)),
  }));

  if (!chartData.length) {
    chart.fibSeries = chartParameters.params.map((param) => ({
      param,
      paramLabel: tr(findDescription(params, param)),
      values: [],
    }));
  } else {
    chart.fibSeries = chartData;
  }

  return msg("LoadChartDataSuccess", chart);
}

function view(state: State): stm.View<Msg> {
  if (state.isLoading) {
    return <div class={"loader-big"}></div>;
  }
  const selectedDevice = getSelectedDevice(state);
  const { deviceStatus, kind } = selectedDevice;

  const threatAsOffline = Date.now() - offlineAfterTime;

  const shouldDisplayRedDot = deviceStatus !== "ok" && deviceStatus !== "active" &&
    deviceStatus !== "online" && deviceStatus !== "On-grid (Off-grid mode: running)";

  const shouldBeRed = (state.lastMetricsCreatedAt !== 0 &&
    state.lastMetricsCreatedAt < threatAsOffline) || shouldDisplayRedDot;

  const lastUpdate = selectedDevice.marker !== "" && state.lastMetricsCreatedAt !== 0
    ? `${tr("fibTechPanel.updatedAt")} ${dates.formatDateTime(state.lastMetricsCreatedAt)}`
    : "";

  let parameters: any[] = [];

  type DevParam = { device: Device; params: Param };

  if (selectedDevice.marker !== "") {
    parameters = state.paramsDescription
      .filter((p) =>
        p.deviceKind === selectedDevice.kind && p.deviceMarker === selectedDevice.marker
      )
      .map((param) => {
        const a = param.params;
        return { device: selectedDevice, params: a };
      })
      .sort((a: DevParam, b: DevParam) => {
        if (a.params.chart != b.params.chart) {
          return Number(a.params.chart) - Number(b.params.chart);
        }
        return (a.params.name[lang] ?? "").localeCompare(b.params.name[lang] ?? "");
      });
  }

  return (
    <div class="tech-home-panel">
      <div className="grid hbox install-params-main-extra">
        <div className="vbox box6 tech-list-current">
          {renderCharts(state)}
        </div>
      </div>
    </div>
  );
}

function renderDevices(devices: any[], selectedDevice: string) {
  if (!devices || (devices && devices.length === 0)) {
    return <></>;
  }

  return (
    <>
      {devices.map((device) => {
        const flagSelected = device.marker === selectedDevice;
        const deviceType = tr(`fib.dash.template.${device.kind}`);
        const deviceName = `${device.modelName}`;
        const deviceOffline = device.deviceStatus === "offline";
        return (
          <li
            class={`well ${flagSelected ? "h300 active" : "body-large"} device-title-box`}
            onClick={msg("SelectDevice", device.marker) as any}
          >
            <div className="device-name-line">
              <span>{deviceName.charAt(0).toUpperCase() + deviceName.slice(1)}</span>
              <span
                class={`${deviceOffline ? "small-red-dot" : ""}`}
              >
              </span>
              {flagSelected && <i className="icon-check-fat margin-left"></i>}
            </div>
            <div className="device-kind-line">[{deviceType}]</div>
          </li>
        );
      })}
    </>
  );
}

function findDescription(params: Param[], searchParam: string): string {
  for (const p of params) {
    const param = p.params.find((param: any) =>
      param.param === searchParam && param.name[lang] !== "not defined"
    ) as any;

    if (param) {
      return `${param.name[lang]} [${param.unit}]`;
    }
  }

  return `[${searchParam}]`;
}

function renderCharts(state: State) {
  return (
    <>
      {state.charts.map((chart) => {
        const dateFormat = "yyyy-MM-ddTHH:mm:ss";
        const from = timestampToDate(chart.fibChart.dateFrom, dateFormat);
        const to = timestampToDate(chart.fibChart.dateTo, dateFormat);
        return (
          <>
            <div className="tech-list-current-wrap">
              <div className="hbox tech-list-header">
              </div>
            </div>

            <div className="container chart-top-extra container-extra">
              <div className="chart-row">
                <div className="chart-group">
                  <div className="chart-col">
                    <input
                      type={"datetime-local"}
                      className={"body-medium"}
                      value={from}
                      step="1"
                      onChange={(e: Event) => {
                        return msg("ChartFromChange", {
                          from: (e.target as HTMLInputElement).value,
                          errMsg: (e.target as HTMLInputElement).validationMessage,
                          chartFor: chart.chartFor,
                        });
                      }}
                    />
                  </div>
                  <div className="chart-col">
                    <input
                      type={"datetime-local"}
                      className={"body-medium"}
                      value={to}
                      step="1"
                      onChange={(e: Event) =>
                        msg("ChartToChange", {
                          to: (e.target as HTMLInputElement).value,
                          errMsg: (e.target as HTMLInputElement).validationMessage,
                          chartFor: chart.chartFor,
                        })}
                    />
                  </div>
                </div>
                <div className="chart-group">
                  {
                    <button
                      class={"button button-download"}
                      onClick={() => msg("DownloadChart", chart)}
                    >
                      <i class={"icon-download"} />
                    </button>
                  }
                </div>
              </div>
              <div className="chart-row chart-ranges">
                <div class={"chart-left"}>
                  <button
                    class={"button chart-button"}
                    onClick={() =>
                      msg("ChartRangeMove", { changedChart: chart, direction: "left" })}
                  >
                    <i class={"arrow arrow-left"} />
                  </button>
                </div>
                <div className="chart-group">
                  <div class={"chart-hour"}>
                    <button
                      class={"button chart-button"}
                      onClick={() =>
                        msg("ChartPredefined", { chartSelected: chart, range: "hour" })}
                    >
                      {tr("fib.chart.hour")}
                    </button>
                  </div>
                  <div class={"chart-today"}>
                    <button
                      class={"button chart-button"}
                      onClick={() => msg("ChartPredefined", { chartSelected: chart, range: "day" })}
                    >
                      {tr("fib.chart.day")}
                    </button>
                  </div>
                  <div class={"chart-month"}>
                    <button
                      class={"button chart-button"}
                      onClick={() =>
                        msg("ChartPredefined", { chartSelected: chart, range: "month" })}
                    >
                      {tr("fib.chart.month")}
                    </button>
                  </div>
                  <div class={"chart-year"}>
                    <button
                      class={"button chart-button"}
                      onClick={() =>
                        msg("ChartPredefined", { chartSelected: chart, range: "year" })}
                    >
                      {tr("fib.chart.year")}
                    </button>
                  </div>
                </div>
                <div class={"chart-right"}>
                  <button
                    class={"button chart-button"}
                    onClick={() =>
                      msg("ChartRangeMove", { changedChart: chart, direction: "right" })}
                  >
                    <i class={"arrow arrow-right"} />
                  </button>
                </div>
              </div>
              {
                /* <div className={"chart-row"}>
                <div className={"fib-chart-error"}>{chart.errMsg}</div>
              </div> */
              }
              {state.isChartDataLoading[chart.chartId]
                ? (
                  <div class={"loader-wrapper"}>
                    <div class={"loader-big"}></div>
                  </div>
                )
                : (
                  <div class={"chart-navigation"}>
                    <fib-chart
                      date-from={chart.fibChart.dateFrom}
                      date-to={chart.fibChart.dateTo}
                      onzoom={(e: CustomEvent) =>
                        state.isChartDataLoading[chart.chartId]
                          ? null
                          : msg("Zoom", { chartFor: chart.chartFor, targetDate: e.detail })}
                    >
                      <>
                        {chart.fibSeries.map((series) =>
                          series.values && series.param
                            ? (
                              <fib-chart-series
                                param={series.paramLabel ?? series.param}
                                values={JSON.stringify(series.values.map((v) => {
                                  return { x: v.timestamp, y: v.value };
                                }))}
                              />
                            )
                            : <></>
                        )}
                      </>
                    </fib-chart>
                  </div>
                )}
              <div className="chart-row">
                <div className={"chart-col"}>
                  <span>{tr("fib.chart.devices")}</span>
                  <div className={"chip-group"}>
                    <>
                      {chart.fibSeries.map((series) => (
                        <div
                          className={"chip body-medium"}
                          onClick={() =>
                            msg("RemoveSeries", {
                              chartFor: chart.chartFor,
                              param: series.param,
                            })}
                        >
                          <span className={"big-blue-dot"}></span>
                          <span>{series.paramLabel ?? series.param}</span>
                        </div>
                      ))}
                    </>
                    <div
                      className={"chip body-medium"}
                      onClick={() => msg("AddSeries", chart.chartFor)}
                    >
                      {tr("fib.chart.addNew")}
                      <i className={"icon-plus-bare"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}
