import _ from "lodash";
import { v } from "./v";
import { flashMessage, modal, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { InstallationWithAlarms } from "nexus/node/MetricsManager";
import { LatestMetrics } from "nexus/node/MetricsAccess";

const installationManager = grow.plant("InstallationManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "RestartDevice"]
  | [type: "RestartRouter"]
  | [type: "RestartRouterConfirmation"]
  | [type: "RestartRouterSuccess"]
  | [type: "RestartDeviceSuccess"]
  | [type: "RestartDeviceFailed"]
  | [type: "RestartDeviceConfirmation"]
  | [type: "RestartDeviceResign"];

interface State {
  isLoading: boolean;
  loadedData?: LatestMetrics;
  deviceSnMarked: string;
  deviceMarkerMarked: string;
  installation?: InstallationWithAlarms;
  isDeviceRestarting: boolean;
  isRouterRestarting: boolean;
}

function msg(...args: Msg): Msg {
  return args;
}

stm.component({
  tagName: "fib-tech-install-parameters",
  shadow: false,
  debug: false,
  propTypes: {
    installation: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        deviceSnMarked: "",
        deviceMarkerMarked: "null",
        isDeviceRestarting: false,
        isRouterRestarting: false,
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installation", P.select()], (attr) => {
      if (!attr) {
        return [state, null];
      }
      const installation = attr as InstallationWithAlarms;
      return [
        { ...state, isLoading: false, installation: installation },
        null,
      ];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["RestartDeviceConfirmation"], () => {
      return [state, restartConfirmation(state)];
    })
    .with(["RestartDeviceResign"], () => [{ ...state, isLoading: false }, null])
    .with(["RestartDevice"], () => {
      state.isDeviceRestarting = true;
      if (!state.installation?.id && !state.deviceSnMarked) {
        return [state, null];
      }
      return [
        state,
        restartDevice(state.deviceSnMarked, state.installation?.id ?? "", state.deviceMarkerMarked),
      ];
    })
    .with(["RestartRouterConfirmation"], () => {
      return [state, restartRouterConfirmation()];
    })
    .with(["RestartRouter"], () => {
      if (!state.installation?.id) {
        return [state, null];
      }
      state.isRouterRestarting = true;
      return [state, restartRouter(state.installation?.id ?? "")];
    })
    .with(["RestartRouterSuccess"], () => {
      flashMessage(tr("fibTechPanel.deviceRestartSuccess"), "success");
      return [
        { ...state, isLoading: false, isRouterRestarting: false },
        null,
      ];
    })
    .with(["RestartDeviceSuccess"], () => {
      flashMessage(tr("fibTechPanel.deviceRestartSuccess"), "success");
      return [
        { ...state, isLoading: false, isDeviceRestarting: false },
        null,
      ];
    })
    .with(["RestartDeviceFailed"], () => {
      flashMessage(tr("fibTechPanel.deviceRestartFailed"), "error");
      return [
        { ...state, isLoading: false, isDeviceRestarting: false },
        null,
      ];
    })
    .exhaustive();
}

async function restartDevice(
  deviceSn: string,
  installationId: string,
  marker: string,
): Promise<Msg> {
  if (marker === "null") {
    flashMessage(tr("fibTechPanel.deviceRestartNull"), "info");
    return msg("RestartDeviceFailed");
  }

  try {
    await installationManager.restartDevice(deviceSn, installationId, marker);
    return msg("RestartDeviceSuccess");
  } catch (error) {
    return msg("RestartDeviceFailed");
  }
}

async function restartRouter(installationId: string): Promise<Msg> {
  try {
    await installationManager.restartRouter(installationId);
    return msg("RestartRouterSuccess");
  } catch (error) {
    return msg("RestartDeviceFailed");
  }
}

async function restartConfirmation(_state: State): Promise<Msg> {
  const model = _.find(_state.loadedData?.devices, { marker: _state.deviceMarkerMarked });

  const result = await modal.confirm({
    text: tr("fibTechPanel.deviceRestartConfirm", {
      name: model?.modelName + " [" + _state.deviceMarkerMarked + "]",
    }),
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  return result ? msg("RestartDevice") : msg("RestartDeviceResign");
}

async function restartRouterConfirmation(): Promise<Msg> {
  const result = await modal.confirm({
    text: tr("fibTechPanel.deviceRestartConfirm", { name: " Router" }),
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  return result ? msg("RestartRouter") : msg("RestartDeviceResign");
}

function view(state: State) {
  if (state.isLoading) {
    return v<Msg>(".loader-big");
  }

  const installation = {
    installationId: state.installation.id,
    platform: state.installation.platform,
    machineId: state.installation.secret,
  };

  return (
    <fib-installation-parameters installation={installation}>
    </fib-installation-parameters>
  );
}
