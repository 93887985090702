import { v } from "./v";
import { stm, tr, flashMessage } from '@7willows/sw-lib';
import { Organization } from 'nexus/node/OrganizationAccess';
import { match, P } from 'ts-pattern';
import { previously } from './view-utils';

const resourceManager = grow.plant('ResourceManager')

type Msg
  = [type: 'LoadOrgsFailed']
  | [type: 'LoadOrgsSuccess', orgs: Organization[]]
  | [type: 'AttributeChanged', name: string, value: string]
  | [type: 'Input', orgId: string]

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  orgs: Organization[];
  isLoading: boolean;
  classes: string;
  orgId: string;
  disabled: boolean;
  initialOrgId: string;
  error: string;
}

stm.component({
  tagName: 'fib-org-select',
  shadow: false,
  debug: false,
  propTypes: {
    class: String,
    orgId: String,
    initialOrgId: String,
    disabled: Boolean,
    error: String
  },
  attributeChangeFactory: (name, value) => msg('AttributeChanged', name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: true,
        orgs: [],
        classes: '',
        orgId: '',
        disabled: false,
        initialOrgId: '',
        error: ''
      },
      loadOrgs()
    ];
  },
  update,
  view
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(['AttributeChanged', 'class', P.select()], classes => [
      { ...state, classes },
      null
    ])
    .with(['AttributeChanged', 'error', P.select()], error => [
      { ...state, error },
      null
    ])
    .with(['AttributeChanged', 'initialOrgId', P.select()], initialOrgId => [
      { ...state, initialOrgId },
      null
    ])
    .with(['AttributeChanged', 'orgId', P.select()], orgId => {
      return [{ ...state, orgId }, null];
    })
    .with(['AttributeChanged', 'disabled', P.select()], disabled => [
      { ...state, disabled: !!disabled && disabled !== 'false' },
      null
    ])
    .with(['AttributeChanged', P._, P._], () => [state, null])
    .with(['LoadOrgsFailed'], () => {
      flashMessage(tr('fibOrgSelect.loadFailed'), 'error');
      return [state, null];
    })
    .with(['LoadOrgsSuccess', P.select()], orgs => [
      { ...state, isLoading: false, orgs },
      null
    ])
    .with(['Input', P.select()], orgId => [
      { ...state, orgId },
      new CustomEvent('update', {
        bubbles: true,
        detail: orgId
      })
    ])
    .exhaustive();
}

async function loadOrgs() {
  try {
    const orgs = await resourceManager.listAllOrgs()
    return msg('LoadOrgsSuccess', orgs);
  } catch (err) {
    console.error('loading orgs failed', err);
    return msg('LoadOrgsFailed');
  }
}

function view(state: State) {
  if (state.isLoading) {
    return v<Msg>('.loader-small.fib-org-select');
  }
  return v<Msg>('.form-element',
    v('label.h200', { htmlFor: 'orgId' }, tr('fibCreateInstallation.installationClient')),
    v<Msg>('select.fib-org-select.full-width',
      {
        className: state.classes,
        onInput: (event: any) => msg('Input', event.target.value),
        disabled: state.disabled
      },
      v.option({ value: '' }, ''),
      ...state.orgs.map(org => {
        const options: any = {
          value: org.id
        };

        if (org.id === state.orgId) {
          options.selected = 'selected';
        }

        return v.option<Msg>(options, org.name)
      })
    ),
    previously({
      field: 'orgId',
      data: state,
      realData: { orgId: state.initialOrgId },
      valueLabel: val => state.orgs.find(org => org.id === val)?.name ?? ''
    }),
    !!state.error && v('p.error.body-small', state.error)
  );
}
