import _ from "lodash";
import { flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { UserState } from "nexus/node/MetricsManager";
import { modalWrapper } from "./view-utils";
import { LicenseType } from "nexus/node/contracts";

type Msg =
  | [type: "Logout"]
  | [type: "PassChange"]
  | [type: "PassChanged"]
  | [type: "CloseAllModals"]
  | [type: "Attr", name: string, value: unknown];

function msg(...args: Msg): Msg {
  return args;
}

type Panel = "admin" | "technician" | "manager" | "user_home" | "technician_home";

interface State {
  userState?: UserState;
  panel: Panel;
  route: router.Route;
  showChangePasswordModal: boolean;
}

stm.component({
  tagName: "fib-user-menu",
  shadow: false,
  debug: false,
  propTypes: {
    userState: Object,
    panel: String,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        route: router.getCurrentRoute(),
        panel: "technician",
        showChangePasswordModal: false,
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "userState", P.select()], (userState) => {
      if (!userState || !_.isObject(userState)) {
        return [state, null];
      }
      state.userState = userState as any;
      return [state, null];
    })
    .with(["Attr", "panel", P.select()], (panel) => {
      if (!panel || !_.isString(panel)) {
        return [state, null];
      }
      state.panel = panel as Panel;
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["Logout"], () => {
      flashMessage(tr("general.logoutSession"), "error");
      router.navigate(router.findDefaultRoute().name);
      grow.sessionId = null;
      return [state, null];
    })
    .with(["PassChange"], () => {
      state.showChangePasswordModal = true;
      return [state, null];
    })
    .with(["PassChanged"], () => {
      flashMessage.success(tr("secPasswordChanged.success"));
      state.showChangePasswordModal = false;
      router.navigate(state.route.name);
      return [state, null];
    })
    .with(["CloseAllModals"], () => {
      state.showChangePasswordModal = false;
      router.navigate(state.route.name);
      return [state, null];
    })
    .exhaustive();
}

function view(state: State): stm.View<Msg> {
  return (
    <div class="fib-user-menu">
      {dropDown(state)}
      {state.showChangePasswordModal && modalWrapper<Msg>(
        <sec-profile-password-change-form
          onsaved={msg("PassChanged")}
          oncancel={msg("CloseAllModals")}
        />,
      )}
    </div>
  );
}

function dropDown(state: State) {
  return (
    <ul className="list dropdown">
      <li className="well body-large">
        <div
          className="text-button-primary full-width profile-row-button"
          title={state.userState?.identityId ?? ""}
        >
          <div>
            {tr("fibUserMenu.loggedInAs")}
          </div>
          <div>
            {state.userState?.userName ?? ""}
          </div>
        </div>
      </li>
      <li className="well body-large">
        <div className="text-button-primary full-width" onClick={msg("PassChange") as any}>
          {tr("secChangePassword.title")}
        </div>
      </li>
      <>
        {match<[LicenseType, Panel], stm.View<Msg>[]>([
          state.userState?.licenseType ?? "guest",
          state.panel,
        ])
          .with(["admin", "manager"], () => [
            goToTechnician(),
            goToAdmin(),
          ])
          .with(["admin_home", "manager"], () => [
            goToTechnician(),
            goToAdmin(),
            goToUser(),
          ])
          .with(["admin", "technician"], () => [goToAdmin()])
          .with(["admin_home", "technician"], () => [
            goToAdmin(),
            goToUser(),
          ])
          .with(["admin", "admin"], ["technician", "manager"], () => [goToTechnician()])
          .with(["admin_home", "technician"], ["admin_home", "admin"], () => [
            goToTechnician(),
            goToUser(),
          ])
          .with(P._, () => [])
          .run()}
      </>
      <li class="well body-large">
        <div class="text-button-primary full-width" onClick={msg("Logout") as any}>
          {tr("general.logout")}
        </div>
      </li>
    </ul>
  );
}

function goToAdmin() {
  return (
    <li class="well body-large ">
      <a
        href={router.getRouteUrl("adminListInstallations")}
        className="text-button-primary full-width"
      >
        {tr("fibMainPanel.adminPanel")}
      </a>
    </li>
  );
}

function goToTechnician() {
  return (
    <li class="well body-large ">
      <a href={router.getRouteUrl("techInstallsList")} className="text-button-primary full-width">
        {tr("fibMainPanel.technicianPanel")}
      </a>
    </li>
  );
}

function goToUser() {
  return (
    <li class="well body-large ">
      <a
        href="/home"
        className="text-button-primary full-width"
      >
        {tr("fibTechPanel.goToCustomer")}
      </a>
    </li>
  );
}

function redirectToHome() {
  window.location.href = "/home";
}
