import { dates, flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { UserState, UserStateWithMap } from "nexus/node/MetricsManager";
import {
  InstallationSupportData,
  ManagerSupport,
  TechnicianSupport,
} from "nexus/node/ResourceManager";
import { ResourceAction } from "nexus/node/contracts";

const resourceManager = grow.plant("ResourceManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "ToggleInfo"]
  | [type: "ToggleHelper"]
  | [type: "CloseHelper"]
  | [type: "LoadInstallationInfoError"]
  | [type: "LoadInstallationInfoSuccess", data: InstallationSupportData];

interface State {
  route: router.Route | null;
  userState?: UserState;
  isInfoVisible: boolean;
  installationTechnicians: TechnicianSupport[];
  installationLicenceUntil: number;
  installationManagers: ManagerSupport[];
  userActions: ResourceAction[];
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-panel",
  shadow: false,
  debug: false,
  propTypes: {
    route: Object,
    userState: Object,
  },
  attributeChangeFactory: (name: string, value: any) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      route: null,
      isInfoVisible: false,
      installationTechnicians: [],
      installationLicenceUntil: 0,
      installationManagers: [],
      userActions: [],
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "route", P.select()], (route) => {
      state.route = route as router.Route;
      return [state, null];
    })
    .with(["Attr", "userState", P.select()], (userState) => {
      state.userState = userState as UserStateWithMap;

      const installationId = router.getCurrentRoute().params.installationId;
      const actions = state.userState.installations.find((x) => x.id === installationId)?.actions;
      state.userActions = actions as ResourceAction[];

      return [state, loadData(state)];
    })
    .with(["Attr", P._, P._], () => {
      return [state, null];
    })
    .with(["ToggleInfo"], () => {
      state.isInfoVisible = !state.isInfoVisible;
      return [state, null];
    })
    .with(["ToggleHelper"], () => {
      return [state, renderHelper(state)];
    })
    .with(["CloseHelper"], () => [state, null])
    .with(["LoadInstallationInfoError"], () => {
      if (router.getCurrentRoute().name !== "dashFullscreen") {
        flashMessage("general.loadFailed", "error");
      }
      return [state, null];
    })
    .with(["LoadInstallationInfoSuccess", P.select()], (data) => {
      state.installationTechnicians = data.technicians;
      state.installationLicenceUntil = data.installationLicenceUntil;
      state.installationManagers = data.managers;
      return [state, null];
    })
    .exhaustive();
}

async function loadData(state: State) {
  let installationSupportData;
  if (!state.userState) {
    return msg("LoadInstallationInfoError");
  }
  const installationId = router.getCurrentRoute().params.installationId;
  try {
    installationSupportData = await resourceManager.getSupportData(installationId);
    return msg("LoadInstallationInfoSuccess", installationSupportData);
  } catch (err) {
    return msg("LoadInstallationInfoError");
  }
}

function view(state: State) {
  if (!state.userState || !state.route) {
    return (
      <div className="loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }

  if (state.route?.name === "dashFullscreen") {
    return <fib-manager-dashboard-fullscreen user-state={state.userState} />;
  }

  const installationId = state.route.params.installationId;
  const found = state.userState.installations.find((i: any) => i.id === installationId);
  let coords = {};
  if (found) {
    coords.lat = found.coords.lat;
    coords.lng = found.coords.lng;
  }
  const machineId =
    state.userState.installations.find((inst) => inst.id === installationId).machineId ?? "";

  const isZiebiceInstallation = machineId === "2eed28ecc2751a48";

  return (
    <>
      {renderManagerNav(state, isZiebiceInstallation)}
      {state.route?.name === "managerDashboards" && <fib-manager-dashboards />}
      {state.route?.name === "managerDashboardsBuilder" &&
        <fib-manager-dashboards-builder user-state={state.userState} />}
      {state.route?.name === "managerReports" &&
        (
          <fib-manager-reports
            installation-id={state.route.params.installationId}
            user-state={state.userState}
          />
        )}
      {state.route?.name === "managerDefrosting" &&
        (
          <fib-manager-defrosting
            installation-id={state.route.params.installationId}
            user-state={state.userState}
            coords={coords}
          />
        )}
      {state.route?.name === "managerLogs" &&
        <fib-manager-logs installation-id={state.route.params.installationId} />}
      {state.route?.name === "illuminations" &&
        (
          <fib-manager-illuminations
            installation-id={state.route.params.installationId}
            ziebice-options={isZiebiceInstallation}
            coords={coords}
          />
        )}
    </>
  );
}

export function renderManagerNav(
  state: State,
  isZiebiceInstallation: boolean,
) {
  const routeName = router.getCurrentRoute().name;
  const installationId = state.route?.params?.installationId as string;

  return (
    <nav className="main-nav" role={"menu"}>
      <div className="fib-logo" />
      <ul className="main-menu h300">
        <li>
          {(state.userActions.includes("dashboards") || state.userActions.includes("any")) &&
            (
              <a
                href={router.getRouteUrl("managerDashboards", { installationId })}
                class={routeName === "managerDashboards" || routeName === "managerDashboardsBuilder"
                  ? "active"
                  : ""}
              >
                {tr("fib.manager.dashboard")}
              </a>
            )}
        </li>
        <li>
          {(state.userActions.includes("reports") || state.userActions.includes("any")) &&
            (
              <a
                href={router.getRouteUrl("managerReports", { installationId })}
                class={routeName === "managerReports" ? "active" : ""}
              >
                {tr("fib.manager.reports")}
              </a>
            )}
        </li>
        <li>
          {(state.userActions.includes("defrosting") || state.userActions.includes("any")) &&
            (
              <a
                href={router.getRouteUrl("managerDefrosting", { installationId })}
                class={routeName === "managerDefrosting" ? "active" : ""}
              >
                {tr("fib.manager.defrosting")}
              </a>
            )}
        </li>

        <li>
          <a
            href={router.getRouteUrl("illuminations", { installationId })}
            class={routeName === "illuminations" ? "active" : ""}
          >
            {isZiebiceInstallation ? "Instalacja" : tr("fib.manager.illuminations")}
          </a>
        </li>
        <li>
          <a
            href={router.getRouteUrl("managerLogs", { installationId })}
            class={routeName === "managerLogs" ? "active" : ""}
          >
            {tr("fib.manager.logs")}
          </a>
        </li>
      </ul>

      <ul className="alt-menu" role="menu">
        <li class="mgr-nav-info-icon">
          <a onClick={() => msg("ToggleHelper")}>
            {helperIcon()}
          </a>
        </li>
        <li class="mgr-nav-info-icon">
          <a onClick={() => msg("ToggleInfo")}>
            <i className="icon-info" />
          </a>
          {state.isInfoVisible && renderInfo(state)}
        </li>
        <li className="dropdown-trigger">
          <a>
            <i className="icon-profile" />
          </a>
          <fib-user-menu user-state={state.userState} panel="manager" />
        </li>
      </ul>
    </nav>
  );
}

function renderInfo(state: State) {
  return (
    <div className={"mgr-nav-info-wrapper container"}>
      <div class="mgr-nav-info-row">
        <strong class="text-bold">{tr("fib.manager.techSupervisor")}</strong>
        <>
          {state.installationTechnicians.map(({ email, firstName, lastName, phoneNumber }) => (
            <div className="mgr-nav-row">
              <p>{firstName?.length ? firstName : "?"} {lastName?.length ? lastName : "?"}</p>
              <span>
                <i className="icon-phone text-initial" />
                {phoneNumber ?? "?"}
              </span>
              <span>
                <i className="icon-envelope text-initial" />
                {email ?? "?"}
              </span>
            </div>
          ))}
        </>
      </div>
      <div class="mgr-nav-info-row">
        <strong class="text-primary text-bold">{tr("fib.manager.activeLicences")}</strong>
        <>
          <div class="mgr-nav-licences">
            <div class={`${getBadgeClass(state.installationLicenceUntil)} body-small`}>
              {dates.formatDate(state.installationLicenceUntil)}
            </div>
            <span>{tr("fib.manager.wholeInstallation")}</span>
          </div>
          {state.installationManagers.map((manager) => (
            <div class="mgr-nav-licences">
              <div class={`${getBadgeClass(manager.validUntil as number)} body-small`}>
                {dates.formatDate(manager.validUntil as number)}
              </div>
              <span>{tr("fib.manager.managerAccount")} {manager.firstName} {manager.lastName}</span>
            </div>
          ))}
        </>
      </div>
    </div>
  );
}

function getBadgeClass(licenseUntil: number) {
  const twoWeeks = 14 * 24 * 60 * 60 * 1000;
  let badgeClass;
  if (licenseUntil < Date.now()) {
    badgeClass = "badge-red";
  }
  if (
    licenseUntil + twoWeeks < Date.now() &&
    licenseUntil > Date.now()
  ) {
    badgeClass = "badge-orange";
  }
  if (licenseUntil + twoWeeks >= Date.now()) {
    badgeClass = "badge-green";
  }
  return badgeClass;
}

function helperIcon() {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#000"
        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
      />
      <path
        fill="#fff"
        d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm0 632c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm62.9-219.5a48.3 48.3 0 0 0-30.9 44.8V620c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-21.5c0-23.1 6.7-45.9 19.9-64.9 12.9-18.6 30.9-32.8 52.1-40.9 34-13.1 56-41.6 56-72.7 0-44.1-43.1-80-96-80s-96 35.9-96 80v7.6c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V420c0-39.3 17.2-76 48.4-103.3C430.4 290.4 470 276 512 276s81.6 14.5 111.6 40.7C654.8 344 672 380.7 672 420c0 57.8-38.1 109.8-97.1 132.5z"
      />
      <path
        fill="#000"
        d="M472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0zm151.6-415.3C593.6 290.5 554 276 512 276s-81.6 14.4-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.2 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5 0-39.3-17.2-76-48.4-103.3z"
      />
    </svg>
  );
}

async function renderHelper(state: State) {
  const headerDesc = (
    <h3 className={"helper-title"}>Instrukcja obsługi systemu S.Z.E.F. Poziom ZARZĄDCA</h3>
  );
  const footerDesc = <button>Close</button>;
  const result = await modal({
    header: headerDesc,
    large: true,
    body: (close: Function) => (
      <div class="modal-helper">
        {helper()}
      </div>
    ),
    footer: (close) => (
      <button
        class={"button button-primary h200 helper-close"}
        onClick={() => close(true)}
      >
        {tr("general.close")}
      </button>
    ),
  }) as boolean;
  return msg("CloseHelper");
}

function helper() {
  return (
    <div className={"helper-container"}>
      <div class="helper-close">
      </div>
      <h2>
        <a
          href="./static/manual/FIBRAIN_SZEF_-_Obsuga_konta_zarzadcy_.pdf"
          target="_blank"
          title="FIBRAIN_SZEF_-_Obsluga_konta_zarzadcy"
          download="Fibrain manual - obsluga konta zarzadcy"
        >
          Pobierz dokument [pdf]
        </a>
      </h2>
      <h3 class="helper-topic-header">Ekran logowania</h3>
      <p>
        Komputer wymaga dostępu do Internetu i zainstalowanej przeglądarki. Interfejs jest dostępny
        pod adresem <strong>www.szef.fibrain.pl</strong>
      </p>
      <div class="">
        <img src="./static/manual/helper-login.png" class="helper-half-img"></img>
        <p>
          Wprowadź login zarządcy obiektu <br />
          (adres email ***@***.**)<br />
          <br />
          Wprowadź hasło<br />
          <br />
          W przypadku zagubienia hasła naciśnij [przypomnij hasło]<br />
          <br />
          <strong>Nie przechowuj hasła w przeglądarce!</strong>
          <br />
          <br />
        </p>
      </div>
      <h3 class="helper-topic-header">Widok okna</h3>
      <p>
        Ekran jest podzielony na dwa obszary: pasek menu i zakładek oraz obszar konfiguracji
        zakładki
      </p>
      <img src="./static/manual/helper-config.png" class="helper-full-img"></img>
      <ol>
        <li>
          A - Ekran konfiguracji i udostępniania skórek wizualizacji (dashboard).
        </li>
        <li>
          B - Ekran podglądu raportów pracy.
        </li>
        <li>
          C - Ekran obsługi odladzania.
        </li>
        <li>
          D - Ekran obsługi iluminacji.
        </li>
        <li>
          E - Podgląd logów systemu.
        </li>
        <li>
          F - Podgląd danych kontaktowych obsługi obiektu.
        </li>
        <li>
          G - Menu konta.
        </li>
      </ol>
      <h3 class="helper-topic-header">Dashboardy</h3>
      <p>
        W celu udostępnienia przejrzystych, dynamicznych danych z pracy instalacji (np. podgląd na
        niechronionych monitorach) należy przejść do zakładki [Dashbordy] i kliknąć [Kopiuj link].
        Tak skopiowany link należy wkleić do pasku adresu przeglądarki internetowej zainstalowanej
        na tablicy dynamicznej
        <br />
        <br />
        Okno służy do zarządzania ekranami wizualizacji. Okno wizualizacji nie pozwala na
        jakąkolwiek ingerencję w instalację dlatego swobodnie można je udostępniać. Natomiast nie
        wolno udostępniać loginu i hasła użytkownika systemu SZEF
      </p>
      <img src="./static/manual/helper-dashboard.png" class="helper-full-img"></img>
      <h3 class="helper-topic-header">Okno edycji szablonu</h3>
      <img src="./static/manual/helper-szablony.png" class="helper-full-img"></img>
      <p>
        Korzystając z menu skonfiguruj wygląd nowego dashbordu. Po wybraniu parametrów naciśnij
        zapisz zmiany.
      </p>
      <h3 class="helper-topic-header">Raporty</h3>
      <p>
        Użytkownik ma możliwość przeglądania danych szczegółowych raportów w skonfigurowanym przez
        siebie oknie.
      </p>
      <img src="./static/manual/helper-raporty.png" class="helper-full-img"></img>
      <p>
        Aby dodać wykres należy kliknąć [stwórz nowy wykres] a następnie wybrać z listy interesujący
        nas parametr Aby usunąć wykres należy kliknąć przynależny mu znak kasowania X
      </p>
      <img src="./static/manual/helper-parametry.png" class="helper-full-img"></img>
      <p>
        Po wybraniu urządzenia a następnie parametru raport zostanie dodany do ekranu Raporty.
        <br />
        <br />
        Dane można wyeksportować do pliku .csv
      </p>
      <h3 class="helper-topic-header">System odladzania</h3>
      <p>
        System kontroluje aktualną temperaturę otoczenia i automatycznie{" "}
        <strong>uniemożliwia</strong>{" "}
        uruchomienie w przypadku zbyt wysokiej temperatury otoczenia (wyższej niż 7°C). Jeśli
        warunki na to pozwalają operator może uruchomić odladzanie po wprowadzeniu hasła. UWAGA:
        Hasło do <strong>odladzania jest inne</strong> niż do logowania się na koncie.
      </p>
      <img src="./static/manual/helper-defrosting.png" class="helper-full-img"></img>
      <img src="./static/manual/helper-defrosting-run.png" class="helper-full-img"></img>
      <p>
        Włączenie systemu odladzania inicjuje sekwencję polegającą na załączaniu po sobie kolejnych
        obwodów grzewczych. Odladzanie uruchamiane jest na Wiacie 1, 2, 3 i świetliku jednocześnie z
        tym, że w trakcie pracy na jednej wiacie/świetliku jest załączona tylko jedna sekcja
        grzewcza w danym momencie.
      </p>
      <p>
        Po zakończeniu jednej sekwencji odladzanie zostaje automatycznie wyłączone. Jeśli nie
        usunięto całej pokrywy śnieżnej sekwencję należy uruchomić ponownie.
      </p>
      <p>
        Sekwencję można przerwać w dowolnej chwili poprzez przełączenie suwaka [włącz system
        odladzania]
      </p>
      <p>
        Ponowne uruchomienie odladzania rozpoczyna sekwencję od początku a nie w miejscu w którym
        wyłączono odladzanie.
      </p>
      <h3 class="helper-topic-header">
        Sterowanie iluminacjami
      </h3>
      <p>
        Iluminacja dekoracyjna może pracować w trybie automatycznym synchronizowanym z zegarem
        astronomicznym lub w trybie ręcznym zależnym tylko od reakcji operatora.
      </p>
      <img src="./static/manual/helper-config-iluminacje.png" class="helper-full-img"></img>
      <h3 class="helper-topic-header">Przegląd logów systemu</h3>
      <img src="./static/manual/helper-logs.png" class="helper-full-img"></img>
      <p>
        Ekran pozwala prześledzić aktywność użytkowników np.: uruchomienie odladzania, automatyczne
        uruchomienie oświetlenia itd.
        <br />
        <br />
        Dane można wyeksportować do pliku .CSV
      </p>
      <h3 class="helper-topic-header">Podgląd danych</h3>
      <p>
        Menu pozwala na podgląd danych licencji oraz danych kontaktowych użytkowników obiektu
      </p>
      <img src="./static/manual/helper-opiekun.png" class="helper-full-img"></img>
      <h3 class="helper-topic-header">Zarządzanie kontem użytkownika</h3>
      <p>
        Menu pozwala na zmianę hasła i wylogowanie
      </p>
      <img src="./static/manual/helper-konto.png" class="helper-full-img"></img>
    </div>
  );
}
