import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default function makeLog(ns, forceDebugMode = false) {
  return function (msg, ...args) {
    if (window.debug || forceDebugMode) {
      console.log(ns + ": " + msg, ...args);
    }
  };
}

const log = makeLog("fib-chart");
const backgroundColors = [
  "rgba(11,180,255,.2)",
  "rgba(230,0,73,.2)",
  "rgba(80,233,145,.2)",
  "rgba(232,217,0,.2)",
  "rgba(155,25,245,.2)",
  "rgba(255,163,0,.2)",
  "rgba(220,10,180,.2)",
  "rgba(179,212,255,.2)",
  "rgba(0,191,160,.2)",
  "rgba(234,94,69,.2)",
  "rgba(100,181,234,.2)",
  "rgba(143,210,67,.2)",
  "rgba(229,202,85,.2)",
  "rgba(179,61,198,.2)",
  "rgba(239,155,32,.2)",
  "rgba(244,106,155,.2)",
  "rgba(155,186,236,.2)",
  "rgba(0,191,111,.2)",
  "rgba(237,191,51,.2)",
  "rgba(189,207,50,.2)",
];
const borderColors = [
  "rgb(11,180,255)",
  "rgb(230,0,73)",
  "rgb(80,233,145)",
  "rgb(232,217,0)",
  "rgb(155,25,245)",
  "rgb(255,163,0)",
  "rgb(220,10,180)",
  "rgb(179,212,255)",
  "rgb(0,191,160)",
  "rgb(234,94,69)",
  "rgb(100,181,234)",
  "rgb(143,210,67)",
  "rgb(229,202,85)",
  "rgb(179,61,198)",
  "rgb(239,155,32)",
  "rgb(244,106,155)",
  "rgb(155,186,236)",
  "rgb(0,191,111)",
  "rgb(237,191,51)",
  "rgb(189,207,50)",
];
const normalize = (val, max, min) => (val - min) / (max - min);
const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 31;
const year = month * 12;

class FibChart extends HTMLElement {
  static get observedAttributes() {
    return ["date-from", "date-to"];
  }

  constructor() {
    super();
    this.myAttrs = {};
    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          this.syncSeries();
        }
      }
    });
    this.onValuesChange = this.onValuesChange.bind(this);
  }

  onValuesChange() {
    this.syncSeries();
  }

  attributeChangedCallback(attrName, _previous, current) {
    this.myAttrs[attrName] = current;
    log("got attribute", attrName);
    this.syncSeries();
  }

  connectedCallback() {
    this.addEventListener("values", this.onValuesChange);
    this.observer.observe(this, { childList: true });

    if (this.querySelector("canvas")) {
      return;
    }
    this.canvas = document.createElement("canvas");
    this.container = document.createElement("div");
    this.container.style = "position: relative; height: 50vh; padding: 0.5em;";
    this.container.append(this.canvas);
    this.append(this.container);
    let wheelEventInProgress;

    this.canvas.addEventListener("wheel", (e) => {
      e.preventDefault();
      if (e.deltaX !== 0) {
        return;
      }

      if (!wheelEventInProgress) {
        wheelEventInProgress = true;

        const dateFrom = Number(this.getAttribute("date-from"));
        const dateTo = Number(this.getAttribute("date-to"));

        // calculate duration for chart
        const duration = dateTo - dateFrom;
        let targetDateFrom, targetDateTo;

        const xCoor = e.offsetX;
        const { data, scales: { x, y } } = this.chart;
        const index = x.getValueForPixel(xCoor);

        let pointedDate;

        if (data.labels[index] === undefined) {
          // if mouse is out of chart (ex. on y-scale)
          return;
        }

        pointedDate = data.labels[index];
        const decodedDate = processDate(pointedDate);

        let targetDate;

        if (e.deltaY < 0) {
          targetDate = generateRange(decodedDate, "in", duration);
        } else {
          targetDate = generateRange(decodedDate, "out", duration);
        }

        targetDateFrom = targetDate.targetDateFrom ?? dateFrom;
        targetDateTo = targetDate.targetDateTo ?? dateTo;

        this.dispatchEvent(
          new CustomEvent("zoom", {
            bubbles: true,
            detail: { targetDateFrom, targetDateTo },
          }),
        );
        wheelEventInProgress = false;
      }
    });

    // create a chart
    this.chart = new Chart(this.canvas, {
      type: "line",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              padding: 8,
              align: "center",
              maxRotation: 45,
              minRotation: 45,
            },
            grid: {
              display: true,
              drawTicks: true,
              tickLength: 6,
            },
            parsing: false,
          },
          y: {
            beginAtZero: false,
            ticks: {
              padding: 10,
            },
            title: {
              display: true,
              // text: "Units", // Label for the Y-axis
            },
          },
        },
        elements: {
          spanGaps: false,
        },
        plugins: {
          legend: {
            display: true,
            position: "top", // Position of the legend
          },
        },
        interaction: {
          mode: "nearest",
        },
        onClick: (e) => {
          const xCoor = e.x;
          const { data, scales: { x, y } } = this.chart;
          const index = x.getValueForPixel(xCoor);

          if (data.labels[index] !== undefined) {
            // if mouse is on chart only (ex. on y-scale)
            const dateFrom = Number(this.getAttribute("date-from"));
            const dateTo = Number(this.getAttribute("date-to"));
            const duration = dateTo - dateFrom;

            const decodedDate = processDate(data.labels[index]);
            const targetDate = generateRange(decodedDate, "in", duration);
            const targetDateFrom = targetDate.targetDateFrom ?? dateFrom;
            const targetDateTo = targetDate.targetDateTo ?? dateTo;

            this.dispatchEvent(
              new CustomEvent("zoom", {
                bubbles: true,
                detail: { targetDateFrom, targetDateTo },
              }),
            );
          }
        },
      },
      data: {
        datasets: this?.chart?.data?.datasets,
      },
    });

    function mousemoveHandler(chart, mousemove) {
      const xCoor = mousemove.offsetX;
      const yCoor = mousemove.offsetY;
      const { data, scales: { x, y } } = chart;
      const index = x.getValueForPixel(xCoor);
    }

    // this.canvas.addEventListener("mousemove", (e) => {
    //   mousemoveHandler(this.chart, e);
    // });
  }

  disconnectedCallback() {
    this.observer.disconnect();
    this.removeEventListener("values", this.onValuesChange);
    delete this.chart;
    this.removeChild(this.container);
    delete this.canvas;
    delete this.container;
  }

  syncSeries() {
    let index = 0;
    if (!this.chart) {
      return;
    }
    if (this?.chart?.data) {
      this.chart.data.datasets = [];
    }
    const children = Array.from(this.children);
    const dateFrom = Number(this.myAttrs["date-from"]);
    const dateTo = Number(this.myAttrs["date-to"]);
    const duration = dateTo - dateFrom;

    // set axis X proper labels format
    let dateAxisFormat = "dd.MM.yyyy  HH:mm:ss";
    this.chart.config._config.type = "line";

    if (duration >= year + month - 2 * day) {
      dateAxisFormat = "yyyy";
      this.chart.config._config.type = "bar";
    } else if (duration >= year) {
      dateAxisFormat = "yyyy.MM";
      this.chart.config._config.type = "bar";
    } else if (duration >= 3 * month) {
      dateAxisFormat = "yyyy.MM";
      this.chart.config._config.type = "bar";
    } else if (duration > month) {
      dateAxisFormat = "yyyy.MM.dd";
      this.chart.config._config.type = "bar";
    } else if (duration > week) {
      dateAxisFormat = "dd.MM.yyyy";
      this.chart.config._config.type = "bar";
    } else if (duration > day) {
      dateAxisFormat = "dd.MM.yyyy  HH:00";
      this.chart.config._config.type = "line";
    } else if (duration > hour) {
      dateAxisFormat = "dd.MM.yyyy  HH:mm";
      this.chart.config._config.type = "line";
    }

    children.forEach((child) => {
      if (child instanceof FibChartSeries) {
        const values = child.getAttribute("values");
        const param = child.getAttribute("param");

        let valueWithLabels = JSON.parse(values) ?? [];
        let max = 0;
        let min = 0;

        if (children.length > 2) {
          const values = valueWithLabels.map((value) => value.y);
          min = Math.min(...values);
          max = Math.max(...values);
        }
        // in case there is just 1 record to display it correctly it require to change chart type from line to bar
        let recordsWithValueCount = 0;
        if (valueWithLabels.length > 0) {
          recordsWithValueCount = valueWithLabels.filter((v) => v.y !== null).length ?? 0;
        }

        if (recordsWithValueCount < 8) {
          this.chart.config._config.type = "bar";
        }

        if (valueWithLabels.length > 0) {
          valueWithLabels = valueWithLabels
            .map((row) => ({
              x: typeof row.x === "string"
                ? formatDate(row.x, dateAxisFormat)
                : formatDate(row.x, dateAxisFormat),
              y: children.length > 2
                ? normalize(row.y, max, min)
                : row.y !== null
                ? Number(Number(row.y).toFixed(4))
                : Number.NaN,
            }));
        }

        this.chart.data.datasets.push({
          fill: true,
          label: param,
          data: valueWithLabels,
          backgroundColor: backgroundColors[index],
          borderColor: borderColors[index],
          borderWidth: 1,
          pointBorderColor: "transparent",
          pointBackgroundColor: "transparent",
          spanGaps: false,
        });

        // setup chart to display min/max value little above/below of chart values
        const calcMin = this.chart.data.datasets[0].data.reduce(
          (minY, obj) => isNaN(obj.y) ? minY : Math.min(minY, obj.y),
          Infinity,
        );
        const calcMax = this.chart.data.datasets[0].data.reduce(
          (maxY, obj) => isNaN(obj.y) ? maxY : Math.max(maxY, obj.y),
          -Infinity,
        );

        this.chart.config.options.scales.y.suggestedMin = calcMin !== 0
          ? calcMin - calcMin * 0.02
          : 0;

        this.chart.config.options.scales.y.suggestedMax = calcMax !== 0
          ? calcMax + calcMax * 0.02
          : calcMax;

        this?.chart.update();
        index++;
      }
      this?.chart.update();
    });
  }
}

class FibChartSeries extends HTMLElement {
  static get observedAttributes() {
    return ["values", "param"];
  }

  attributeChangedCallback(attrName, _previous, current) {
    if (attrName === "values") {
      this.dispatchEvent(new CustomEvent("values", { bubbles: true, detail: current }));
    }
  }
}

const isElementDefined = customElements.get("fib-chart");

if (!isElementDefined) {
  customElements.define("fib-chart", FibChart);
  customElements.define("fib-chart-series", FibChartSeries);
}

function formatDate(time, format) {
  //input format = '2023-12-01T00:00:00Z' output as format definition

  if (typeof time === "number") {
    // for dashboard creation used template date
    time = new Date(time).toISOString();
  }

  const splitDateTime = time.split("T");
  const splitDate = splitDateTime[0].split("-");
  const splitTime = splitDateTime[1].split(":");

  var dateObj = {};
  dateObj.yyyy = splitDate[0];
  dateObj.MM = splitDate[1];
  dateObj.dd = splitDate[2];
  dateObj.HH = splitTime[0];
  dateObj.mm = splitTime[1];
  dateObj.ss = splitTime[2];
  for (var variable in dateObj) {
    if (dateObj.hasOwnProperty(variable)) {
      format = format.replace(variable, dateObj[variable]);
    }
  }
  return format;
}

function processDate(pointedDate) {
  let decodedYear, decodedMonth, decodedDay, decodedHour, decodedMinute, decodedLastDay;

  const splittedDateTime = pointedDate.split(" ");
  if (splittedDateTime.length > 1) {
    const splitTime = splittedDateTime[2].split(":");
    decodedHour = splitTime[0];
    decodedMinute = splitTime[1];
  }
  const splittedDate = splittedDateTime[0].split(".");
  if (splittedDate.length > 1) {
    if (splittedDate.length === 2) {
      if (splittedDate[0].length > 2) {
        decodedYear = splittedDate[0];
        decodedMonth = splittedDate[1];
      } else {
        decodedYear = splittedDate[1];
        decodedMonth = splittedDate[0];
      }
    } else {
      if (splittedDate[0].length > 2) {
        decodedYear = splittedDate[0];
        decodedMonth = splittedDate[1];
        decodedDay = splittedDate[2];
      } else {
        decodedYear = splittedDate[2];
        decodedMonth = splittedDate[1];
        decodedDay = splittedDate[0];
      }
    }
    decodedLastDay = new Date(decodedYear, decodedMonth, 0).getDate();
  } else {
    decodedYear = splittedDate[0];
    decodedLastDay = new Date(decodedYear, 12, 0).getDate();
  }

  return {
    decodedYear,
    decodedMonth,
    decodedDay,
    decodedHour,
    decodedMinute,
    decodedLastDay,
  };
}

function generateRange(decodedDate, zoomDirection, duration) {
  let targetDateFrom, targetDateTo;
  const {
    decodedYear,
    decodedMonth,
    decodedDay,
    decodedHour,
    decodedMinute,
    decodedLastDay,
  } = decodedDate;

  if (zoomDirection === "in") {
    // going down with zoom to get more details
    if (duration > year) {
      // display selected year by months
      targetDateFrom = decodedYear + "-01-01 00:00:00";
      targetDateTo = decodedYear + "-12-25 23:59:59"; //temporary till fix on backend
    } else if (duration > month) {
      // display selected month by days
      targetDateFrom = decodedYear + "-" + decodedMonth + "-01 00:00:00";
      targetDateTo = decodedYear + "-" + decodedMonth + "-" + decodedLastDay + " 23:59:59";
    } else if (duration > day) {
      // display selected day by hours
      targetDateFrom = decodedYear + "-" + decodedMonth + "-" + decodedDay +
        " 00:00:00";
      targetDateTo = decodedYear + "-" + decodedMonth + "-" + decodedDay +
        " 23:59:59";
    } else if (duration > hour) {
      // display selected hour by minutes
      targetDateFrom = decodedYear + "-" + decodedMonth + "-" + decodedDay +
        " " + decodedHour + ":00:00";
      targetDateTo = decodedYear + "-" + decodedMonth + "-" + decodedDay +
        " " + decodedHour + ":59:59";
    } else {
      targetDateFrom = Number.POSITIVE_INFINITY;
    }
  } else {
    // going up with zoom to get broader range
    if (duration > year * 6) {
      targetDateFrom = Number.NEGATIVE_INFINITY;
    } else if (duration >= (year - month)) {
      // display years starting from 2023 till now
      targetDateFrom = "2023-01-01 00:00:00";
      targetDateTo = new Date().getFullYear() + "-12-31 23:59:59";
    } else if (duration >= month - (4 * day)) {
      // potrzeba naprawic zeby caly rok sie lapal
      // display year by months
      targetDateFrom = decodedYear + "-01-01 00:00:00";
      targetDateTo = decodedYear + "-12-25 23:59:59";
    } else if (duration >= day - hour) {
      // display month by days
      targetDateFrom = decodedYear + "-" + decodedMonth + "-01 00:00:00";
      targetDateTo = decodedYear + "-" + decodedMonth + "-" + decodedLastDay + " 23:59:59";
    } else if (duration >= hour) {
      // display day by hours
      targetDateFrom = decodedYear + "-" + decodedMonth + "-01 00:00:00";
      targetDateTo = decodedYear + "-" + decodedMonth + "-" + decodedLastDay + " 23:59:59";
    } else if (duration >= minute) {
      // display day by hours
      targetDateFrom = decodedYear + "-" + decodedMonth + "-" + decodedDay +
        " " + "00:00:00";
      targetDateTo = decodedYear + "-" + decodedMonth + "-" + decodedDay +
        " " + "23:59:59";
    } else {
      // display hour by minutes
      targetDateFrom = decodedYear + "-" + decodedMonth + "-" + decodedDay +
        " " + decodedHour + ":00:00";
      targetDateTo = decodedYear + "-" + decodedMonth + "-" + decodedDay +
        " " + decodedHour + ":59:59";
    }
  }

  targetDateFrom = targetDateFrom === Number.POSITIVE_INFINITY
    ? Number.POSITIVE_INFINITY
    : targetDateFrom === Number.NEGATIVE_INFINITY
    ? Number.NEGATIVE_INFINITY
    : Date.parse(targetDateFrom);
  targetDateTo = Date.parse(targetDateTo);

  return {
    targetDateFrom,
    targetDateTo,
  };
}
