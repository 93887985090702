import _ from "lodash";
import { dates, flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { InstallationWithAlarms } from "nexus/node/MetricsManager";
import { accessesEmails, accessesPhoneNumbers, managersDetails } from "./view-utils";

const ALERTS_LIST_TO_DISPLAY_LENGTH = 3;

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [
    type: "LoadDataSuccess",
    installation: InstallationWithAlarms,
  ]
  | [type: "LoadDataFailed"]
  | [type: "SetAlertsListToDisplayLength"];

interface State {
  isLoading: boolean;
  installationWithAlarms?: InstallationWithAlarms;
  alertsListToDisplayLength: number;
  isHomeVersion: boolean;
}

const metricsManager = grow.plant("MetricsManager");

function msg(...msg: Msg): Msg {
  return msg;
}

stm.component({
  tagName: "fib-tech-install-details",
  debug: false,
  propTypes: {
    installationId: String,
    isShowMoreOn: Boolean,
    isHomeVersion: Boolean,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(): [State, stm.Cmd<Msg>] {
    const state = {
      isLoading: false,
      alertsListToDisplayLength: ALERTS_LIST_TO_DISPLAY_LENGTH,
      isHomeVersion: false,
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      if (!_.isString(installationId) || !installationId) {
        return [state, null];
      }
      state.isLoading = true;
      state.alertsListToDisplayLength = ALERTS_LIST_TO_DISPLAY_LENGTH;
      return [state, loadData(installationId)];
    })
    .with(["Attr", "isHomeVersion", P.select()], (isHomeVersion) => {
      state.isHomeVersion = !!isHomeVersion;
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["LoadDataFailed"], () => {
      flashMessage.error(tr("general.loadFailed"));
      state.isLoading = true;
      return [state, null];
    })
    .with(["LoadDataSuccess", P.select()], (data) => {
      state.isLoading = false;
      state.installationWithAlarms = data;
      return [state, null];
    })
    .with(["SetAlertsListToDisplayLength"], () => {
      state.alertsListToDisplayLength = state.installationWithAlarms?.alerts.length ??
        ALERTS_LIST_TO_DISPLAY_LENGTH;
      return [state, null];
    })
    .exhaustive();
}

async function loadData(installationId: string): Promise<Msg> {
  try {
    const installation = await metricsManager.installationWithAlarms(
      installationId,
    );
    return msg("LoadDataSuccess", installation);
  } catch (err) {
    console.error("loading userList data failed", err);
    return msg("LoadDataFailed");
  }
}

function view(state: State) {
  if (state.isLoading || !state.installationWithAlarms) {
    return (
      <div class="loader-wrapper">
        <div class="loader-big"></div>
      </div>
    );
  }

  const hasAlerts = state.installationWithAlarms.alerts.length > 0;
  const installation = state.installationWithAlarms;

  const more = installation.alerts?.length - state.alertsListToDisplayLength;
  const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;
  const shouldBeRed = installation.installationUpdatedAt ??
    0 < fiveMinutesAgo;
  let listDevices = installation.devices !== null ? installation.devices : [];

  listDevices = listDevices.map((dev: any) => {
    const dateConverted = new Date(dev.updatedAt).valueOf();
    if (dateConverted < fiveMinutesAgo) {
      dev.deviceStatus = "offline";
    }
    return dev;
  });

  return (
    <>
      <div className="box4 fib-map-details">
        <div className="fib-map-details-row">
          <div className={hasAlerts ? "hbox" : "fib-map-flex-end"}>
            {hasAlerts && (
              <div className="badge-gray80 body-small">
                {tr("fib.map.detailsError")}
              </div>
            )}
            <a
              href={state.isHomeVersion
                ? router.getRouteUrl("techHomePanel", {
                  installationId: state.installationWithAlarms.id ?? "#",
                  viewState: "info",
                })
                : router.getRouteUrl("techInstallPanel", {
                  installationId: state.installationWithAlarms.id ?? "#",
                })}
              className="button-secondary h100"
            >
              {tr("fib.map.detailsButton")}
            </a>
          </div>
          <p className="h500">{installation.name}</p>
          <div className="fib-map-details-address">
            <div className={"service-details"}>
              <span>{tr("fibTechPanel.installationAddress")}</span>
              <p>{installation.street} {installation.houseNumber},</p>
              <p>{installation.postcode} {installation.city}</p>
            </div>
            <div className={"service-details"}>
              <span>{tr("fibTechPanel.installationManagers")}</span>
              {managersDetails(installation.managers)}
            </div>
          </div>
        </div>

        <div
          className={"h100 last-update"}
          title={tr("fibTechPanel.lastUpdate")}
        >
          <div className={shouldBeRed ? "red" : ""}>
            {`${tr("fibTechPanel.installationLastUpdate")}:  `}
            {dates.formatDateTime(installation.installationUpdatedAt ?? 0)}
          </div>
        </div>
        {hasAlerts && (
          <div className="fib-map-details-row">
            <p className="h300">{tr("fib.map.errorCodes")}</p>
            <div className="fib-map-alerts-wrapper">
              <>
                {installation.alerts.map((alert, index) =>
                  index < state.alertsListToDisplayLength &&
                  (
                    <div
                      className={`chip body-medium tooltip error-level-${alert.level}`}
                      title={alert.name}
                    >
                      {alert.code}
                    </div>
                  )
                )}
              </>
              {more > 0 && (
                <a
                  className="text-button-primary h300"
                  onClick={() => msg("SetAlertsListToDisplayLength")}
                >
                  <i className="icon-plus" />
                  {more} {tr("fib.map.showMore")}
                </a>
              )}
            </div>
          </div>
        )}

        <div className="fib-map-details-row">
          <p className="h300">{tr("fib.map.devices")}</p>
          <ul className="list">
            <>
              {listDevices.map((device) => (
                <li className="well body-large">
                  {(device.deviceStatus === "online" ||
                    device.deviceStatus.startsWith("On-grid")) && (
                    <div className="small-green-dot" />
                  )}
                  {(device.deviceStatus !== "online" &&
                    !device.deviceStatus.startsWith("On-grid")) &&
                    <div className="small-red-dot" />}
                  {device.modelName}
                </li>
              ))}
            </>
          </ul>
        </div>
      </div>
    </>
  );
}
