import _ from "lodash";
import { v } from "./v";
import { router, tr, TypeAheadSuggestion } from "@7willows/sw-lib";
import { UserState } from "../../MetricsManager";
import { UserContactInfo } from "../../contracts";

type InputSize = "small" | "medium" | "large";

export function techNav(userState: UserState | undefined) {
  const showAlertsDot = !!userState && userState.installations.some((insta) => {
    return insta.installationState === "warning" || insta.installationState === "error";
  });

  const currentRoute = router.getCurrentRoute();

  return (
    <nav className="main-nav" role={"menu"}>
      <ul className="menu-logo">
        <div className="fib-logo" />
      </ul>
      {["admin", "technician"].includes(userState.licenseType) &&
        (
          <ul className="main-menu h300">
            <li>
              <a
                href={router.getRouteUrl("techMapView", { installationId: "all" })}
                className={["techMapView"].includes(currentRoute.name) ? "active" : ""}
              >
                {tr("fibTechPanel.mapView")}
              </a>
            </li>
            <li>
              <a
                href={router.getRouteUrl("techInstallsUsers")}
                className={["techInstallsUsers"].includes(currentRoute.name) ? "active" : ""}
              >
                {tr("fibTechPanel.installationsUsers")}
              </a>
            </li>
            <li>
              <a
                href={router.getRouteUrl("techInstallsList")}
                className={"techInstallsList" === currentRoute.name ? "active" : ""}
              >
                {tr("fibTechPanel.installationsList")}
              </a>
            </li>
          </ul>
        )}
      {["admin_home", "technician_home"].includes(userState.licenseType) &&
        (
          <ul className="main-menu h300">
            <li>
              <a
                href={router.getRouteUrl("techInstallsList")}
                className={"techInstallsList" === currentRoute.name ? "active" : ""}
              >
                {tr("fibTechPanel.installationsList")}
              </a>
            </li>
            <li>
              <a
                href={router.getRouteUrl("techMapView", { installationId: "all" })}
                className={["techMapView"].includes(currentRoute.name) ? "active" : ""}
              >
                {tr("fibTechPanel.mapView")}
              </a>
            </li>
          </ul>
        )}
      <ul className="alt-menu" role="menu">
        {["admin", "technician", "admin_home", "technician_home"].includes(userState.licenseType) &&
          (
            <li>
              <a href={router.getRouteUrl("techAdvanced")}>
                <i className="icon-dots" />
              </a>
            </li>
          )}
        <li className="has-alarms-wrapper">
          <a
            href={currentRoute.name === "techMapViewWithAlerts"
              ? router.getRouteUrl("techMapView", {
                installationId: currentRoute.params["installationId"] ?? "all",
              })
              : router.getRouteUrl("techMapViewWithAlerts", {
                installationId: currentRoute.params["installationId"] ?? "all",
              })}
          >
            <i className="icon-bell" />
            {showAlertsDot && <span className="small-red-dot has-alarms" />}
          </a>
        </li>
        <li className="dropdown-trigger">
          <a href="">
            <i className="icon-profile" />
          </a>
          <fib-user-menu user-state={userState} panel="technician" />
        </li>
      </ul>
    </nav>
  );
}

export function textElement<Msg>(config: {
  field: string;
  label: string;
  data: Record<string, unknown>;
  realData: Record<string, any>;
  errors: Record<string, string | undefined>;
  isDisabled: boolean;
  dataList?: string;
  size?: InputSize;
  className?: string;
  rightSlot?: v.View<Msg>;
  type?: string;
}): v.View<Msg> {
  const hasError = !!config.errors[config.field];
  const fieldId = config.field.replace(".", "-");
  const value = _.get(config.data, config.field, _.get(config.realData, config.field));

  return (
    <div class={"form-element " + (hasError ? "error" : "")}>
      <label class="h200" for={fieldId}>{tr(config.label)}</label>
      <div class="hbox margins">
        <input
          type={config.type ? config.type : "text"}
          class={"body-" + (config.size ?? "medium") + " full-width " + config.className}
          id={fieldId}
          list={config.dataList}
          value={value}
          onInput={(event: any) => ["Input", config.field, event.target.value] as any as Msg}
          disabled={config.isDisabled}
        />
      </div>
      {previously(config)}
      {hasError && (
        <p class="error body-small">
          {tr(config.errors[config.field] || "", {
            name: tr(config.label),
          })}
        </p>
      )}
    </div>
  );
}

export function previously<Msg>(config: {
  field: string;
  data: any;
  realData: any;
  valueLabel?: (val: any) => string;
}): v.View<Msg> {
  const changedValue = config.data[config.field];
  const previousValue = config.realData[config.field];
  const showPreviously = !!changedValue && previousValue && changedValue !== previousValue;
  const value = config.valueLabel ? config.valueLabel(previousValue) : previousValue;

  if (!showPreviously) {
    return <></>;
  }
  return (
    <p class="previously h100">
      {tr("general.previously", { value: value ?? "" })}
    </p>
  );
}

export function selectElement<Msg>(config: {
  field: string;
  label: string;
  options: { value: string; label: string }[];
  data: Record<string, unknown>;
  realData: Record<string, any>;
  errors: Record<string, string>;
  msgFactory?: (value: unknown) => Msg;
  isDisabled: boolean;
}): v.View<Msg> {
  const hasError = !!config.errors[config.field];
  const fieldId = config.field.replace(".", "-");
  const value = _.get(config.data, config.field, _.get(config.realData, config.field));
  return v<Msg>(
    ".form-element",
    { className: hasError ? "error" : "" },
    v("label.h200", { htmlFor: fieldId }, tr(config.label)),
    v(
      "select.body-medium.full-width",
      {
        id: fieldId,
        onInput: (event: any) => {
          return config.msgFactory
            ? config.msgFactory(event.target.value)
            : ["Input", config.field, event.target.value] as any as Msg;
        },
        disabled: config.isDisabled,
      },
      !value && v.option(),
      ...config.options.map((opt) =>
        v.option<Msg>({
          value: opt.value,
          selected: value === opt.value,
        }, opt.label)
      ),
    ),
    previously({
      ...config,
      valueLabel: (val) => config.options.find((opt) => opt.value === val)?.label ?? "",
    }),
    hasError && v(
      "p.error.body-small",
      tr(config.errors[config.field], {
        name: tr(config.label),
      }),
    ),
  );
}

export function checkboxElement<Msg>(config: {
  field: string;
  label: string;
  data: Record<string, unknown>;
  realData: Record<string, any>;
  errors: Record<string, string | undefined>;
  isDisabled: boolean;
}): v.View<Msg> {
  const hasError = !!config.errors[config.field];
  const label = tr(config.label);
  const value = _.get(config.data, config.field, _.get(config.realData, config.field));

  const onClickOptions: any = config.isDisabled
    ? {}
    : { onClick: () => ["Input", config.field, !value] as any as Msg };

  return v<Msg>(
    ".form-element.checkbox-element",
    { className: hasError ? "error" : "" },
    v(
      "label",
      v(
        ".hbox.left",
        onClickOptions,
        v(".checkbox-medium", {
          className: (config.isDisabled ? "disabled " : " ") +
            (value ? "active" : ""),
        }),
        v(".body-medium", label),
      ),
    ),
    hasError && v(
      "p.error.body-small",
      tr(config.errors[config.field] || "", {
        name: label,
      }),
    ),
  );
}

export function typeAheadElement<Msg>(config: {
  field: string;
  label: string;
  isDisabled: boolean;
  suggestions: TypeAheadSuggestion[];
  realData: Record<string, unknown>;
  data: Record<string, unknown>;
  errors: Record<string, string>;
  size?: InputSize;
}): v.View<Msg> {
  const hasError = !!config.errors[config.field];
  const label = tr(config.label);
  const value = _.get(config.data, config.field, _.get(config.realData, config.field));

  return v<Msg>(
    ".form-element",
    { className: hasError ? "error" : "" },
    v("label.h200", { htmlFor: config.field }, label),
    v.swTypeAhead({
      className: `body-${config.size ?? "medium"} full-width`,
      id: config.field,
      disabled: config.isDisabled,
      value,
      suggestions: config.suggestions,
      onupdate: (event: any) => {
        event.stopPropagation();
        return ["Input", config.field, event.detail] as any as Msg;
      },
    }),
    hasError && v(
      "p.error.body-small",
      tr(config.errors[config.field], {
        name: label,
      }),
    ),
  );
}

export function userEmailElement<Msg>(config: {
  isDisabled: boolean;
  emails: string[];
  realData: Record<string, unknown>;
  data: Record<string, unknown>;
  errors: Record<string, string>;
  size?: InputSize;
}): v.View<Msg> {
  const field = "email";
  const label = "identity.email";

  return typeAheadElement({
    field,
    label,
    size: config.size,
    suggestions: config.emails
      .filter((t) =>
        typeof config.data[field] === "string" &&
        t.toLowerCase().includes((config as any).data[field].toLowerCase())
      )
      .map((t) => ({
        suggestion: t,
        value: t,
      })),
    ...config,
  });
}

export function itemTopBar<Msg>(config: {
  goBackUrl: string;
  goBackLabel: string;
  isSaveEnabled: boolean;
  isSaving: boolean;
  title: string;
}): v.View<Msg> {
  return (
    <nav class={"main-nav hbox"}>
      <div class={"hbox"}>
        <span
          class={"text-button-primary h300 go-back"}
          onClick={() =>
            config.isSaveEnabled
              ? confirm(tr("general.unsaved")) ? window.location.href = config.goBackUrl : null
              : window.location.href = config.goBackUrl}
        >
          <i class={"icon-arrow-left"} />
          {tr(config.goBackLabel)}
        </span>
        <div
          className={`button-primary h200 save-button ${config.isSaveEnabled ? "" : "disabled"}`}
          onClick={() => ["Save"] as any as Msg}
        >
          {tr("general.saveChanges")}
        </div>
        {config.isSaving && v(".loader-mini")}
      </div>
      <div class={"middle"}>
        <h2 class={"subtitle text-center"}>{config.title}</h2>
      </div>
      <div class={"right"} />
    </nav>
  );
}

export function nextYear() {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
}

export function modalWrapper<Msg>(content: v.View<Msg>): v.View<Msg> {
  return v<Msg>(".modal-backdrop", v(".modal", content));
}

export function managersDetails(accesses: UserContactInfo[]) {
  return (
    <>
      {accesses.map((access) => {
        return (
          <>
            <div className={"contact-line"}>
              <p>{`${tr("fibTechPanel.installationPhone")} ${access.phoneNumber}`}</p>
              <p>{`${access.email}`}</p>
            </div>
          </>
        );
      })}
    </>
  );
}

export function accessesPhoneNumbers(accesses: UserContactInfo[]) {
  return accesses.map((access) => access.phoneNumber).filter((x) => x).join(", ");
}

export function accessesEmails(accesses: UserContactInfo[]) {
  return accesses.map((access) => access.email).filter((x) => x).join(", ");
}

export function setCookie(name: string, value: string, days: number) {
  let expires = "";

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

export function eraseCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function unitConversion(unit: string, value: number) {
  if (value > 1000) {
    value = value / 1000;

    if (unit.startsWith("MWh")) {
      unit = tr("fib.manager.reportGigaUnitsperHour");
    }
    if (unit.startsWith("kWh")) {
      unit = tr("fib.manager.reportTopUnitsMegaperHour");
    }
    if (unit.startsWith("kW")) {
      unit = tr("fib.manager.reportTopUnitsMega");
    }
    if (unit.startsWith("W")) {
      unit = `k${unit}`;
    }
    if (unit.startsWith("kg")) {
      unit = tr("fib.manager.reportTopUnitKobizeTone");
    }
    if (unit.startsWith("t")) {
      unit = tr("fib.manager.reportTopUnitKobizeKiloTone");
    }
  }
  return { value: value && typeof value === "number" ? value.toFixed(2) : value, unit };
}
