import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import _ from "lodash";
import { checkboxElement } from "./view-utils";
import { DeviceParameter, Parameter } from "../../nexus/node/contracts";

type Msg =
  | [type: "AttributeChange", name: string, value: unknown]
  | [type: "Input", field: string, value: unknown]
  | [type: "LoadDataFailed"]
  | [type: "LoadDataSuccess", params: InstallationShort[]]
  | [type: "UpdateDataSuccess", params: InstallationShort[]]
  | [type: "SaveChanges"]
  | [type: "SavingFailed"]
  | [type: "SaveSuccess", results: boolean]
  | [type: "ScanFailed"]
  | [type: "ScanParams"]
  | [type: "ScanSuccess", results: number]
  | [type: "SelectInstallation", installationId: string]
  | [type: "FilterDevice", deviceMarker: string]
  | [type: "FilterKind", kind: string];

type InstallationShort = {
  instId: string;
  instName: string;
  instPlatform: string;
  instMachineId: string;
  devices: DeviceParameter[];
};

type Device = {
  marker: string;
  kind: string;
  name: string;
};

type InstallationRequest = {
  id: string;
  name: string;
  machineId: string;
};

type Param = {
  paramId: string;
  param: string;
  deviceName: string;
  deviceMarker: string;
  deviceKind: string;
  isVisible: boolean;
};

interface State {
  installations: InstallationRequest[];
  data: InstallationShort[];
  selectedInstallation: string | null;
  selectedDevice: string | null;
  selectedKind: string | null;
  installationDevices: Record<string, Device[]>;
  isLoading: boolean;
}

const msg = (...args: Msg): Msg => args;
const installationManager = grow.plant("InstallationManager");

stm.component({
  tagName: "fib-tech-params",
  shadow: false,
  debug: false,
  propTypes: {
    installations: Object,
  },
  attributeChangeFactory: (name: string, value: any) => msg("AttributeChange", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state = {
      installations: [],
      data: [],
      selectedInstallation: null,
      selectedDevice: null,
      selectedKind: null,
      installationDevices: {},
      isLoading: false,
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChange", "installations", P.select()], (installations) => {
      if (
        (installations[0] as InstallationRequest).machineId === undefined
      ) {
        return [state, null];
      }

      state.installations = installations as InstallationRequest[];
      const installationsIds = state.installations.map((inst) => inst.id);
      state.isLoading = true;
      return [state, loadData(installationsIds)];
    })
    .with(["SelectInstallation", P.select()], (installationId) => {
      state.selectedInstallation = installationId === "null" ? null : installationId;
      state.selectedDevice = null;
      state.selectedKind = null;

      return [state, null];
    })
    .with(["FilterDevice", P.select()], (device) => {
      state.selectedDevice = device === "null" ? null : device;
      if (state.selectedDevice !== null && state.selectedInstallation) {
        const preselectedDevice = state.installationDevices[state.selectedInstallation].filter(
          (inst) => inst.marker === device,
        )[0];
        state.selectedKind = preselectedDevice.kind;
      }
      return [state, null];
    })
    .with(["FilterKind", P.select()], (deviceKind) => {
      state.selectedKind = deviceKind === "null" ? null : deviceKind;
      if (state.selectedKind !== null) {
        state.selectedDevice = null;
      }
      return [state, null];
    })
    .with(["LoadDataFailed"], () => {
      flashMessage(tr("general.loadingFailed"), "error");
      state.isLoading = false;
      return [state, null];
    })
    .with(["UpdateDataSuccess", P.select()], (params) => {
      state.data = state.data.map((inst) => {
        if (inst.instId === params[0].instId) {
          inst = params[0];
        }
        return inst;
      });

      const installationDevices: Record<string, any[]> = {};
      for (const inst of state.data) {
        const devices = inst.devices.map((d) => {
          return {
            marker: d.deviceMarker,
            kind: d.deviceKind,
            name: d.deviceName,
          };
        });
        installationDevices[inst.instId] = devices;
      }
      state.installationDevices = installationDevices;
      state.isLoading = false;

      state.selectedInstallation = params[0].instId;
      state.selectedDevice = null;
      state.selectedKind = null;
      return [state, null];
    })
    .with(["LoadDataSuccess", P.select()], (params) => {
      state.data = params;

      const installationDevices: Record<string, any[]> = {};
      for (const inst of state.data) {
        const devices = inst.devices.map((d) => {
          return {
            marker: d.deviceMarker,
            kind: d.deviceKind,
            name: d.deviceName,
          };
        });
        installationDevices[inst.instId] = devices;
      }
      state.installationDevices = installationDevices;
      state.isLoading = false;
      return [state, null];
    })
    .with(["SavingFailed"], () => {
      flashMessage(tr("general.saveFailed"), "error");
      return [state, null];
    })
    .with(["SaveSuccess", P.select()], (params) => {
      flashMessage(tr("fib.advanced.saveSuccess"), "success");
      return [state, null];
    })
    .with(["SaveChanges"], () => {
      if (!state.selectedInstallation) {
        flashMessage(tr("fib.advanced.installationNotSelected"), "error");
        return [state, null];
      }
      const data = _.find(state.data, { instId: state.selectedInstallation });

      return [state, data ? saveChanges(data) : null];
    })
    .with(["Input", P.select("prop"), P.select("value")], (prop, value) => {
      const propToChange = prop.prop.split("|");
      const paramId = propToChange[0];
      const deviceMarker = propToChange[1];

      const installation = _.find(state.data, {
        instId: state.selectedInstallation,
      }) as InstallationShort;

      if (installation) {
        const device = _.find(installation.devices, { deviceMarker });

        if (device) {
          const param = _.find(device.params, { param: paramId });

          if (param) {
            _.set(param, "isVisible", !!prop.value);
          }
        }
      }

      return [state, null];
    })
    .with(["ScanFailed"], () => {
      state.isLoading = false;
      flashMessage(tr("general.loadingFailed"), "error");
      return [state, null];
    })
    .with(["ScanParams"], () => {
      const installation = _.find(state.data, {
        instId: state.selectedInstallation,
      }) as InstallationShort;

      if (installation) {
        state.isLoading = true;
        return [
          state,
          scanInstallation({
            id: installation.instId,
            machineId: installation.instMachineId,
            platform: installation.instPlatform,
          }),
        ];
      }
      flashMessage(tr("fib.advanced.installationNotSelected"), "warning");
      return [state, null];
    })
    .with(["ScanSuccess", P.select()], (params) => {
      state.isLoading = false;
      if (params > 0) {
        flashMessage(tr("fib.advanced.scanCompleted", { counter: params }), "info");
      } else {
        flashMessage(tr("fib.advanced.paramsNotFounded"), "info");
      }

      return [
        state,
        params > 0
          ? state.selectedInstallation ? updateData([state.selectedInstallation]) : null
          : null,
      ];
    })
    .with(["AttributeChange", P._, P._], () => [state, null])
    .exhaustive();
}

async function loadData(instId: string[]) {
  try {
    const response = await installationManager.getInstallationsParams(instId);
    return msg("LoadDataSuccess", response);
  } catch (err) {
    return msg("LoadDataFailed");
  }
}

async function updateData(instId: string[]) {
  try {
    const response = await installationManager.getInstallationsParams(instId);
    return msg("UpdateDataSuccess", response);
  } catch (err) {
    return msg("LoadDataFailed");
  }
}

async function saveChanges(data: InstallationShort) {
  try {
    const response = await installationManager.saveParamsDefinition(data);
    return msg("SaveSuccess", response);
  } catch (err) {
    return msg("SavingFailed");
  }
}

async function scanInstallation(
  installation: { id: string; machineId: string; platform: string },
) {
  try {
    const response = await installationManager.searchNewParams(installation);
    return msg("ScanSuccess", response);
  } catch (err) {
    return msg("ScanFailed");
  }
}

function view(state: State): stm.View<Msg> {
  if (state.isLoading) {
    return (
      <div className="loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }

  return (
    <>
      <div className={"fib-tech-params"}>
        <div className={"params-title"}>
          <h1 className={"h400"}>{tr("fib.advanced.paramsList")}</h1>
          <div className={"params-buttons"}>
            <button className={"button-primary"} onClick={() => msg("SaveChanges")}>
              {tr("fib.advanced.saveChanges")}
            </button>
            <button className={"button-primary"} onClick={() => msg("ScanParams")}>
              {tr("fib.advanced.scanParams")}
            </button>
          </div>
        </div>
        <div className={"params-filter"}>
          {renderFilters(state)}
        </div>
        <div className={"buttons"}>
        </div>
        <div class={"params-table"}>
          <h1 className="h300">{tr("fib.advanced.setParamsVisibility")}</h1>
          {renderTable(state)}
        </div>
      </div>
    </>
  );
}

function renderFilters(state: State) {
  let devices: Device[] = [];
  let devicesKind: Device[] = [];
  if (state.selectedInstallation) {
    devices = state.installationDevices[state.selectedInstallation] ?? [];
    devicesKind = _.uniqBy(devices, "kind") ?? [];
  }

  return (
    <>
      <div className={"filter-select"}>
        <div>{tr("fib.advanced.listInstallations")}</div>
        <select
          className={"body-medium"}
          disabled={false}
          onChange={(e: Event) => msg("SelectInstallation", (e.target as HTMLSelectElement).value)}
        >
          <option value="null">{tr("fib.advanced.selectInstallations")}</option>
          <>
            {state.installations.map((elem) => {
              return (
                <option
                  value={elem.id}
                  selected={elem.id === state.selectedInstallation ? true : false}
                >
                  {elem.name}
                </option>
              );
            })}
          </>
        </select>
      </div>
      <div className={"filter-select"}>
        <div>{tr("fib.advanced.listDevices")}</div>
        <select
          className={"body-medium"}
          disabled={false}
          onChange={(e: Event) => msg("FilterDevice", (e.target as HTMLSelectElement).value)}
        >
          <option value="null" selected={state.selectedDevice === null ? true : false}>
            {tr("fib.advanced.selectParamDevice")}
          </option>
          <>
            {devices.map((elem) => {
              return (
                <option
                  value={elem.marker}
                  selected={elem.marker === state.selectedDevice ? true : false}
                >
                  {elem.name}
                </option>
              );
            })}
          </>
        </select>
      </div>
      <div className={"filter-select"}>
        <div>{tr("fib.advanced.devicesKind")}</div>
        <select
          className={"body-medium"}
          disabled={false}
          onChange={(e: Event) => msg("FilterKind", (e.target as HTMLSelectElement).value)}
        >
          <option value="null" selected={state.selectedKind === null ? true : false}>
            {tr("fib.advanced.selectDeviceKind")}
          </option>
          <>
            {devicesKind.map((elem) => {
              return (
                <option
                  value={elem.kind}
                  selected={elem.kind === state.selectedKind ? true : false}
                >
                  {elem.kind}
                </option>
              );
            })}
          </>
        </select>
      </div>
    </>
  );
}

function renderTable(state: State) {
  let paramsLoaded: InstallationShort;
  const lang = tr.getLang();

  if (state.selectedInstallation && state.selectedInstallation !== null) {
    paramsLoaded = state.data.filter((p) => p.instId === state.selectedInstallation)[0];
  }

  const params: Partial<Parameter>[] = [];

  if (!paramsLoaded) {
    return <></>;
  }

  paramsLoaded.devices.forEach((device) => {
    if (state.selectedDevice && device.deviceMarker !== state.selectedDevice) {
      return;
    }
    if (state.selectedKind && device.deviceKind !== state.selectedKind) {
      return;
    }

    if (device.params && device.params.length > 0) {
      device.params.forEach((p) => {
        if (p) {
          params.push({
            "param": p.param,
            "name": { [lang]: p.name[lang] ?? "not defined" },
            "isVisible": p.isVisible,
            "deviceName": device.deviceName,
            "deviceMarker": device.deviceMarker,
            "deviceKind": device.deviceKind,
          });
        }
      });
    }
  });

  return (
    <>
      {params.map((p) => {
        const paramName = p.name[lang] === "not defined"
          ? `not defined - ${p.param}`
          : p.name[lang];
        const name = `${paramName} [ ${p.deviceKind}|${p.deviceMarker} ]`;
        const paramId = `${p.param}|${p.deviceMarker}`;

        return (
          <>
            <div className={"param-line"}>
              <div className={"param-checkbox"}>
                {checkboxElement<Msg>(
                  {
                    field: paramId,
                    label: name,
                    errors: {},
                    data: { [paramId]: p.isVisible },
                    realData: {},
                    isDisabled: false,
                  },
                )}
              </div>
              <div className="param-name">
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}
