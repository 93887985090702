import { stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { ValueSlotConfig, ValueSlotData } from "nexus/node/contracts";
import { unitConversion } from "./view-utils";

type Msg = [type: "Attr", name: string, value: unknown];

interface State {
  valueSlotConfig: ValueSlotConfig;
  valueSlotData: ValueSlotData;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-value-slot",
  shadow: false,
  debug: false,
  propTypes: {
    valueSlotConfig: Object,
    valueSlotData: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      valueSlotConfig: {
        type: "value",
        deviceSn: "",
        order: 0,
        title: "",
        prop: "",
        unit: "",
        subtitle: "",
        color: "",
      },
      valueSlotData: 0,
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "valueSlotConfig", P.select()], (valueSlotConfig) => {
      state.valueSlotConfig = valueSlotConfig as ValueSlotConfig;
      return [state, null];
    })
    .with(["Attr", "valueSlotData", P.select()], (valueSlotData) => {
      state.valueSlotData = valueSlotData as ValueSlotData;
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .exhaustive();
}

function view(state: State) {
  let value = state.valueSlotData;
  let unit = state.valueSlotConfig.unit;

  ({ value: value, unit: unit } = unitConversion(unit, value));

  return (
    <div
      className={state.valueSlotConfig.order > 5
        ? "mgr-dash-side-slot body-large"
        : "mgr-dash-data-slot body-large"}
    >
      <div className={"h500"}>{state.valueSlotConfig.title}</div>
      <div
        className={"mgr-dash-eco-data"}
        style={state.valueSlotConfig.color ? `color: ${state.valueSlotConfig.color}` : ""}
      >
        <div>
          <span className={"mgr-report-value"}>
            {value}
          </span>
          <span className={"mgr-report-unit"}>
            {unit}
          </span>
        </div>
        {state.valueSlotConfig.subtitle && state.valueSlotConfig.prop !== "kobizeSavedCO2" && (
          <div className={"h500"}>{state.valueSlotConfig.subtitle}</div>
        )}
      </div>
    </div>
  );
}
