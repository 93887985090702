import isNaN from "lodash/isNaN";
import { flashMessage, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import type { PropDef, PropType, SelectOption } from "nexus/node/Logger/index";
import { stm } from "@7willows/sw-lib";

type PropDomDef = PropDef & {
  cssClass: string;
};

type State = {
  props: PropDomDef[];
  logs: Primitive[][];
  currentPage: number;
  perPage: number;
  isLoading: boolean;
  forever: boolean;
  dateFrom: number;
  dateTo: number;
};

type Primitive = number | string | boolean;

type Msg =
  | { attr: { [name: string]: unknown } }
  | { loadFailed: true }
  | { loadSuccess: Primitive[][] }
  | { turnPage: number }
  | { setProp: PropDomDef; value: Primitive }
  | { domChildren: HTMLCollection }
  | { download: "request" | "success" | "failed" }
  | { foreverToggle: true }
  | { dateFrom: number }
  | { dateTo: number };

function msg(msg: Msg): Msg {
  return msg;
}

const logger = grow.plant("Logger");

class LogTablePropSelect extends HTMLElement {
  private observer: MutationObserver;

  constructor() {
    super();
    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          this.dispatchEvent(new CustomEvent("children", { bubbles: true }));
        }
      }
    });
  }

  connectedCallback() {
    this.observer.observe(this, { childList: true });
  }

  disconnectedCallback() {
    this.observer.disconnect();
  }
}

if (!window.customElements.get("log-table-prop-select")) {
  window.customElements.define("log-table-prop-select", LogTablePropSelect);
}

stm.component({
  tagName: "log-table",
  shadow: false,
  debug: false,
  propTypes: {
    perPage: Number,
  },
  attributeChangeFactory: (name, value) => msg({ attr: { [name]: value } }),
  init(dispatch: stm.Dispatch<Msg>, onRefChange: any): [State, stm.Cmd<Msg>] {
    onRefChange(function (ref: any) {
      if (!ref) {
        return;
      }
      ref.addEventListener("children", function () {
        dispatch({ domChildren: ref.children });
      });
    });
    return [
      {
        props: [],
        logs: [],
        currentPage: 1,
        perPage: 100,
        isLoading: false,
        forever: true,
        dateFrom: Date.now() - 24 * 60 * 60 * 1000,
        dateTo: Date.now(),
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg): [State, stm.Cmd<Msg>] {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with({ attr: { children: P.select() } }, (content) => {
      state = addPropsFromChildren(state, content as PreactChild);
      if (state.props.length > 0) {
        return [state, loadLogs(state)];
      }
      return [state, null];
    })
    .with({ attr: { currentPage: P.select() } }, (currentPage) => {
      if (currentPage === state.currentPage) {
        return [state, null];
      }

      state.currentPage = parseInt(currentPage as any, 10);

      if (state.props.length > 0) {
        state.isLoading = true;
        return [state, loadLogs(state)];
      }
      return [state, null];
    })
    .with({ attr: { perPage: P.select() } }, (perPage: any) => {
      perPage = parseInt(perPage as any, 10);

      if (isNaN(perPage)) {
        console.error("per-page param is invalid");
        return [state, null];
      }

      state.perPage = perPage;

      if (state.props.length > 0) {
        state.isLoading = true;
        return [state, loadLogs(state)];
      }

      return [state, null];
    })
    .with({ attr: P._ }, () => [state, null])
    .with({ domChildren: P.select() }, (children) => {
      state = addPropsFromDomChildren(state, children);
      if (state.props.length > 0) {
        return [state, loadLogs(state)];
      }
      return [state, null];
    })
    .with({ turnPage: P.select() }, (delta) => {
      if (delta > 0 && isLastPage(state)) {
        // no more logs
        return [state, null];
      }
      let newPage = state.currentPage + delta;
      if (newPage < 1) {
        newPage = 1;
      }

      if (newPage !== state.currentPage) {
        state.currentPage = newPage;
        state.isLoading = true;
        return [state, loadLogs(state)];
      }

      return [state, null];
    })
    .with(
      { setProp: P.select("prop"), value: P.select("value") },
      ({ prop, value }: { prop: PropDomDef; value: Primitive }) => {
        state.props = state.props.map((p) => {
          if (p.name === prop.name) {
            prop.value = value;
            return prop;
          }
          return p;
        });
        state.currentPage = 1;
        state.isLoading = true;
        return [state, loadLogs(state)];
      },
    )
    .with({ loadFailed: true }, () => {
      flashMessage.error("logTable.loadFailed");
      state.isLoading = false;
      return [state, null];
    })
    .with({ loadSuccess: P.select() }, (logs) => [
      { ...state, isLoading: false, logs },
      null,
    ])
    .with({ download: "request" }, () => [
      state,
      download(state.props),
    ])
    .with({ download: "failed" }, () => {
      flashMessage.error(tr("logger.downloadFailed"));
      return [state, null];
    })
    .with({ download: "success" }, () => [state, null])
    .with({ foreverToggle: true }, () => {
      state.forever = !state.forever;

      return [state, null];
    })
    .with({ dateFrom: P.select() }, (dateFrom) => {
      state.dateFrom = dateFrom;
      return [state, loadLogs(state)];
    })
    .with({ dateTo: P.select() }, (dateTo) => {
      state.dateTo = dateTo;
      return [state, loadLogs(state)];
    })
    .exhaustive();
}

function isFirstPage(state: State) {
  return state.currentPage === 1;
}

function isLastPage(state: State) {
  return state.logs.length < state.perPage;
}

async function download(props: PropDomDef[]) {
  try {
    const csvContent = await logger.query(
      props.map((prop) => ({ name: prop.name, value: prop.value })) as any,
    );

    const propsArrayed = props.map((record: Record<string, any>) => {
      return Object.values(record);
    });

    const propsStringified = JSON.stringify([
      propsArrayed,
      { limit: undefined, skip: 0 },
      "csv",
    ]);

    const blob = new Blob([csvContent.map((row) => [...row, "\n"]) as any].flat(), {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "logs.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return msg({ download: "success" });
  } catch (err) {
    console.error("download failed");
    return msg({ download: "failed" });
  }
}

async function loadLogs(state: State): Promise<Msg> {
  try {
    let dateFrom;
    let dateTo;

    if (!state.forever) {
      let d = new Date(state.dateFrom);
      d.setHours(0, 0, 0, 0);
      dateFrom = d.getTime();

      d = new Date(state.dateTo);
      d.setHours(23, 59, 59, 999);
      dateTo = d.getTime();
    }

    const result = await logger.query(state.props, {
      skip: (state.currentPage - 1) * state.perPage,
      limit: state.perPage,
      from: dateFrom,
      to: dateTo,
    });
    return { loadSuccess: result as Primitive[][] };
  } catch (err) {
    console.error("loading logs failed", err);
    return { loadFailed: true };
  }
}

type PreactChild = {
  type: string;
  props: {
    [name: string]: (string | PreactChild)[];
  };
};

function addPropsFromDomChildren(state: State, children: HTMLCollection) {
  const props: PropDomDef[] = Array.from(children).reduce((props, child) => {
    if (!child.tagName.startsWith("LOG-TABLE-PROP")) {
      return props;
    }

    const t = elementToPropType({ type: child.tagName.toLowerCase() });
    const prop: PropDomDef = {
      name: child.getAttribute("name") ?? "",
      value: child.getAttribute("value") ?? "",
      label: child.getAttribute("label") ?? child.getAttribute("name") ?? "",
      format: child.getAttribute("format") ?? "",
      cssClass: child.getAttribute("class") ?? "",
      type: t,
      options: t === "select" ? selectDomChildrenToOptions(child.children as any) : [],
      hidden: child.hasAttribute("hidden"),
    };
    return [...props, prop];
  }, [] as PropDomDef[]);

  return { ...state, props };
}

function selectDomChildrenToOptions(children: HTMLCollection): SelectOption[] {
  const options: SelectOption[] = [];

  Array.from(children).forEach((child) => {
    if (child.tagName === "LOG-TABLE-PROP-SELECT-OPTION") {
      options.push({
        value: child.getAttribute("value") as any,
        label: child.getAttribute("label") as any,
      });
    }
  });

  return options;
}

function addPropsFromChildren(state: State, content: PreactChild) {
  const children = content.props.children as PreactChild[];
  const props: PropDomDef[] = children.reduce((props, child) => {
    if (!child.type?.startsWith("log-table-prop")) {
      return props;
    }
    const t = elementToPropType(child);
    const prop: PropDomDef = {
      name: child.props.name as any as string,
      value: child.props.value as any as string,
      label: (child.props.label ?? child.props.name) as any as string,
      format: child.props.format as any as string,
      type: t,
      options: t === "select" ? selectChildrenToOptions(child.props.children as any) : [],
      hidden: child.props.hidden !== undefined,
      cssClass: (child.props.class as any as string) ?? "",
    };
    return [...props, prop];
  }, [] as PropDomDef[]);

  return { ...state, props };
}

function selectChildrenToOptions(children: PreactChild[]): SelectOption[] {
  const options: SelectOption[] = [];

  children.forEach((child) => {
    if (child && child.type === "log-table-prop-select-option") {
      options.push({
        value: child.props.value as any,
        label: child.props.label as any,
      });
    }
  });

  return options;
}

function elementToPropType(child: { type: string }): PropType {
  switch (child.type) {
    case "log-table-prop-text":
      return "text";
    case "log-table-prop-date":
      return "date";
    case "log-table-prop-select":
      return "select";
    case "log-table-prop-number":
      return "number";
  }
  return "text";
}

function view(state: State) {
  return (
    <div class={"log-table " + (state.isLoading ? "loading" : "")}>
      <div class="logger-buttons">
        <div class="download-button" onclick={msg({ download: "request" })}>
          <i class="icon-download"></i>
          {tr("logger.export")}
        </div>
        <div class="date-filter">
          <label>
            <input type="checkbox" oninput={msg({ foreverToggle: true })} checked={state.forever} />
            {tr("logger.forever")}
          </label>
          {!state.forever && (
            <div class="dates-select">
              <input
                type="date"
                value={new Date(state.dateFrom).toISOString().split("T")[0]}
                max={new Date(state.dateTo).toISOString().split("T")[0]}
                oninput={(event: any) =>
                  msg({
                    dateFrom: event.target.valueAsDate.getTime(),
                  })}
              />
              -
              <input
                type="date"
                min={new Date(state.dateFrom).toISOString().split("T")[0]}
                value={new Date(state.dateTo).toISOString().split("T")[0]}
                oninput={(event: any) =>
                  msg({
                    dateTo: event.target.valueAsDate.getTime(),
                  })}
              />
            </div>
          )}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <>
              {visibleProps(state)
                .map((prop) => (
                  <th class={prop.cssClass}>
                    {match<PropType, View<Msg>>(prop.type)
                      .with("select", () => headerSelectView(state, prop))
                      .with("number", () => headerNumberView(state, prop))
                      .with(P._, () => prop.label)
                      .run()}
                  </th>
                ))}
            </>
          </tr>
        </thead>
        <tbody>
          <>
            {state.logs.map((log) => (
              <tr>
                <>
                  {(log as any).map((propValue: any, i: number) => {
                    const def = visibleProps(state)[i] ?? {};
                    return <td class={def.cssClass ?? ""}>{propValue}</td>;
                  })}
                </>
              </tr>
            ))}
          </>
        </tbody>
        <tfoot>
          <tr>
            <td colspan={visibleProps(state).length}>
              <div class="pagination">
                {!isFirstPage(state) && (
                  <button
                    class="go-left"
                    onClick={msg({ turnPage: -1 })}
                    title={tr("pagination.left")}
                  >
                    <i class="icon-chevron-left"></i>
                  </button>
                )}
                {!isLastPage(state) && (
                  <button
                    class="go-left"
                    onClick={msg({ turnPage: 1 })}
                    title={tr("pagination.right")}
                  >
                    <i class="icon-chevron-right"></i>
                  </button>
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

function visibleProps(state: State) {
  return state.props.filter((prop) => !prop.hidden);
}

function headerNumberView(state: State, prop: PropDomDef) {
  return (
    <input
      type="number"
      class="header-number"
      placeholder={prop.label}
      disabled={state.isLoading}
      onInput={(event: any) =>
        msg({
          setProp: prop,
          value: parseFloat(event.target.value),
        })}
    />
  );
}

function headerSelectView(state: State, prop: PropDomDef) {
  return (
    <select
      class="header-select"
      disabled={state.isLoading}
      onInput={(event: any) => msg({ setProp: prop, value: event.target.value })}
    >
      <option>{prop.label}</option>

      <>
        {(prop.options || []).map((opt) => {
          return <option value={opt.value}>{opt.label}</option>;
        })}
      </>
    </select>
  );
}
