import { v } from "./v";
import { stm, tr, flashMessage } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';
import { setCookie } from "./view-utils";

type Msg
  = [type: 'Submit']
  | [type: 'LoginUpdate', login: string]
  | [type: 'PasswordUpdate', password: string]
  | [type: 'LoginFailed', message: string]
  | [type: 'LoginSuccess', sessionId: string]

type State = {
  login: string;
  password: string;
  error: string;
  isLoading: boolean;
  logoutAll: boolean
};

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: 'sec-login-form',
  shadow: false,
  init: () => [{ login: '', password: '', error: '', isLoading: false, logoutAll: false }, null],
  update,
  view
});

function update(state: State, msg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(msg)
    .with(['LoginUpdate', P.select()], login => [
      { ...state, login, error: '' },
      null
    ])
    .with(['PasswordUpdate', P.select()], password => [
      { ...state, password, error: '' },
      null
    ])
    .with(['Submit'], () => [
      { ...state, isLoading: true, error: '' },
      login(state)
    ])
    .with(['LoginFailed', P.select()], error => {
      flashMessage(tr(error), 'error');
      return [{ ...state, isLoading: false, error }, null];
    })
    .with(['LoginSuccess', P.select()], sessionId => {
      grow.sessionId = sessionId;
      setCookie("grow-session-id", sessionId, 5);

      return [{ ...state, isLoading: false, error: '' }, new CustomEvent('login', { detail: sessionId })];
    })
    .exhaustive()
}

async function login(state: State) {
  try {
    const result = await grow.plant('Security').identityLogin(state.login, state.password)

    if (!result.sessionId) {
      console.error('unknown login error', result);
      return msg('LoginFailed', result.err);
    }
    return msg('LoginSuccess', result.sessionId);
  } catch (err) {
    console.error('login failure', err);
    return msg('LoginFailed', '');
  }
}

function view(state: State) {
  return v<Msg>('.sec-login-form',
    v.form({
      onSubmit: (event: Event): Msg => {
        event.preventDefault();
        return msg('Submit')
      },
      role: 'form',
      ariaLabel: 'Login form',
      className: state.error ? 'error' : ''
    },
      v('.prop-wrapper',
        v.label({
          htmlFor: 'login',
          className: 'h200'
        }, tr('secLoginForm.loginLabel')),
        v.input({
          type: 'email',
          id: 'login',
          value: state.login,
          className: 'body-medium',
          onInput: (event: any) => msg('LoginUpdate', event.target?.value),
          disabled: state.isLoading
        })
      ),
      v('.prop-wrapper',
        v.label({
          htmlFor: 'password',
          className: 'h200'
        }, tr('secLoginForm.passwordLabel')),
        v.input({
          type: 'password',
          id: 'password',
          className: 'body-medium',
          value: state.password,
          onInput: (event: any) => msg('PasswordUpdate', event.target?.value),
          disabled: state.isLoading
        })
      ),
      !!state.error && v('p.error-message', tr(state.error)),

      v('.actions',
        state.isLoading && v('.loader-medium'),
        v.button({
          type: 'submit',
          className: 'button-primary h200',
          disabled: state.isLoading || !state.login || !state.password
        }, tr('secLoginForm.submit'))
      )
    )
  );
}
