import { flashMessage, modal, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import params from "./params.json";
import * as uuid from "uuid";
import _ from "lodash";
import { ChartParameters } from "nexus/node/MetricsAccess";
import { MetricData } from "nexus/node/contracts";

type Msg =
  | [type: "AttributeChange", name: string, value: unknown]
  | [type: "OpenListModal", list: any]
  | [type: "Cancel"]
  | [type: "AddNewList"]
  | [type: "AddNewListSuccess", list: List]
  | [type: "OverwriteListSuccess"]
  | [type: "OpenList", list: List]
  | [type: "CopyList", list: List]
  | [type: "OpenParamModal"]
  | [type: "LoadListsSuccess", list: List[]]
  | [type: "LoadDataFailed"]
  | [type: "LoadDevicesSuccess", devices: any[]]
  | [type: "AddParamToList", param: Param]
  | [type: "RemoveParam", param: Param]
  | [type: "RemoveParamFromGroup", data: { group: Group; param: Param }]
  | [type: "OpenGroupModal", group: Group]
  | [type: "AddParamToGroup", data: { paramName: string; group: Group }]
  | [type: "AddGroup", data: { name: string; parentId: string }]
  | [type: "SavingFailed"]
  | [type: "OpenChart", param: Param]
  | [type: "LoadChartDataSuccess", chartData: ChartData]
  | [type: "SaveNote"]
  | [type: "UpdateNote", data: { param: Param; value: string }];

type Param = {
  paramId: string;
  deviceSn: string;
  param: string;
  installationId: string;
  note: string;
};
type Group = {
  groupId: string;
  name: string;
  params: Param[];
  parentId: string;
  children: Group[];
};
type List = {
  listId: string;
  name: string;
  params: Param[];
  groups: Group[];
};
type ChartData = { chartParameters: ChartParameters; metricData: MetricData };

interface State {
  installationsIds: string[];
  devices: any[];
  lists: List[];
  openedList: List;
  isListOpen: boolean;
  isChartOpen: boolean;
  isChartDataLoading: boolean;
  chartData: ChartData;
  openedChartParamId: string;
}

const msg = (...args: Msg): Msg => args;
const metricsManager = grow.plant("MetricsManager");
const advancedManager = grow.plant("AdvancedManager");

stm.component({
  tagName: "fib-tech-data-points-list",
  shadow: false,
  debug: false,
  propTypes: {
    installationsIds: Object,
  },
  attributeChangeFactory: (name: string, value: any) => msg("AttributeChange", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state = {
      devices: [],
      installationsIds: [],
      lists: [],
      openedList: {
        listId: "",
        name: "",
        params: [],
        groups: [],
      },
      isListOpen: false,
      isChartOpen: false,
      isChartDataLoading: true,
      chartData: {
        chartParameters: {
          installationId: "",
          params: [""],
          sn: "",
          timeFrom: 0,
          timeTo: 0,
        },
        metricData: {
          param: "",
          values: [],
        },
      },
      openedChartParamId: "",
    };
    return [state, loadLists()];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChange", "installationsIds", P.select()], (installationsIds) => {
      state.installationsIds = installationsIds as string[];
      return [state, loadDevices(state.installationsIds)];
    })
    .with(["LoadDataFailed"], () => {
      flashMessage(tr("general.loadingFailed"), "error");
      return [state, null];
    })
    .with(["LoadDevicesSuccess", P.select()], (devices) => {
      state.devices = devices as any[];
      return [state, null];
    })
    .with(["AttributeChange", P._, P._], () => [state, null])
    .with(["LoadListsSuccess", P.select()], (lists) => {
      state.lists = lists as List[];
      return [state, null];
    })
    .with(["AddNewList"], () => {
      const name = prompt(tr("fib.advanced.dataPointsLists.newName"));
      if (!name) {
        return [state, null];
      }

      const newList: List = {
        listId: "",
        name,
        params: [],
        groups: [],
      };
      return [state, saveList(newList)];
    })
    .with(["AddNewListSuccess", P.select()], (newList) => {
      state.lists.push(newList);
      return [state, null];
    })
    .with(["OverwriteListSuccess"], () => [state, null])
    .with(["OpenListModal", P.select()], (list) => [state, openListModal(list)])
    .with(["Cancel"], () => {
      flashMessage(tr("fib.advanced.dataPointsLists.canceled"), "info");
      return [state, null];
    })
    .with(["OpenList", P.select()], (list) => {
      const stateList = state.lists.find((stateList) => stateList.listId === list.listId);
      if (!stateList) {
        return [state, null];
      }

      state.openedList = stateList;
      state.isListOpen = true;
      state.isChartOpen = false;
      return [state, null];
    })
    .with(["CopyList", P.select()], (list) => [state, saveList(list)])
    .with(["OpenParamModal"], () => [state, openParamModal(state)])
    .with(["AddParamToList", P.select()], (param) => {
      const foundParam = state.openedList.params.find((openedListParam) =>
        openedListParam.paramId === param.paramId
      );
      if (foundParam) {
        flashMessage(tr("fib.advanced.dataPointsLists.paramDuplicated"), "warning");
        return [state, null];
      }

      const stateList = state.lists.find((list) => list.listId === state.openedList.listId);
      if (!stateList) {
        return [state, null];
      }

      stateList.params.push(param);
      return [state, saveList(stateList)];
    })
    .with(["RemoveParam", P.select()], (param) => {
      const stateList = state.lists.find((list) => list.listId === state.openedList.listId);
      if (!stateList) {
        flashMessage(tr("general.error"), "error");
        return [state, null];
      }

      stateList.params = state.openedList.params
        .filter((openedListParam) => openedListParam.paramId !== param.paramId);
      return [state, saveList(stateList)];
    })
    .with(["OpenGroupModal", P.select()], (group) => [state, openGroupModal(state, group)])
    .with(["AddGroup", P.select()], ({ name, parentId }) => {
      const stateList = state.lists.find((list) => list.listId === state.openedList.listId);
      if (!stateList) {
        flashMessage(tr("general.error"), "error");
        return [state, null];
      }

      const newGroup = {
        parentId,
        groupId: uuid.v4(),
        name,
        params: [],
        children: [],
      };

      const stateGroup = findGroup(stateList.groups, parentId);
      stateGroup ? stateGroup.children.push(newGroup) : stateList.groups.push(newGroup);

      return [state, saveList(stateList)];
    })
    .with(["AddParamToGroup", P.select()], ({ paramName, group }) => {
      const stateList = state.lists.find((stateList) =>
        stateList.listId === state.openedList.listId
      );
      if (!stateList) {
        return [state, null];
      }

      const foundGroup = findGroup(stateList.groups, group.groupId);
      if (!foundGroup) {
        return [state, null];
      }

      const duplicated = foundGroup.params.find((stateGroupParam) =>
        stateGroupParam.param === paramName
      );
      if (duplicated) {
        flashMessage(tr("fib.advanced.dataPointsLists.paramDuplicated"), "warning");
        return [state, null];
      }

      const foundParam = stateList.params.find((stateListParam) =>
        stateListParam.param === paramName
      );
      if (!foundParam) {
        return [state, null];
      }

      foundGroup.params.push(foundParam);
      return [state, saveList(stateList)];
    })
    .with(["RemoveParamFromGroup", P.select()], ({ group, param }) => {
      const stateList = state.lists.find((stateList) =>
        stateList.listId === state.openedList.listId
      );
      if (!stateList) {
        flashMessage(tr("general.error"), "error");
        return [state, null];
      }

      const foundGroup = findGroup(stateList.groups, group.groupId);
      if (!foundGroup) {
        return [state, null];
      }

      foundGroup.params = foundGroup.params.filter((stateListParam) =>
        stateListParam.paramId !== param.paramId
      );
      return [state, saveList(stateList)];
    })
    .with(["SavingFailed"], () => {
      flashMessage(tr("general.error"), "error");
      return [state, null];
    })
    .with(["OpenChart", P.select()], (param) => {
      state.isChartOpen = true;
      state.isChartDataLoading = true;
      state.openedChartParamId = param.paramId;
      return [state, loadChartData(param)];
    })
    .with(["LoadChartDataSuccess", P.select()], (data) => {
      state.chartData = data;
      state.isChartDataLoading = false;
      return [state, null];
    })
    .with(["UpdateNote", P.select()], (data) => {
      const stateList = state.lists.find((stateList) =>
        stateList.listId === state.openedList.listId
      );
      if (!stateList) {
        flashMessage(tr("general.error"), "error");
        return [state, null];
      }

      const param = stateList.params.find((stateListParam) =>
        stateListParam.paramId === data.param.paramId
      );
      if (!param) {
        flashMessage(tr("general.error"), "error");
        return [state, null];
      }

      param.note = data.value;
      return [state, null];
    })
    .with(["SaveNote"], () => {
      const stateList = state.lists.find((stateList) =>
        stateList.listId === state.openedList.listId
      );
      if (!stateList) {
        flashMessage(tr("general.error"), "error");
        return [state, null];
      }
      return [state, saveList(stateList)];
    })
    .exhaustive();
}

function findGroup(groups: Group[], groupId: string): Group | undefined {
  let found = groups.find((group) => group.groupId === groupId);
  if (found) {
    return found;
  }

  for (let group of groups) {
    found = findGroup(group.children, groupId);
    if (found) {
      return found;
    }
  }

  return found;
}

async function openGroupModal(state: State, group: Group) {
  const paramName = await modal({
    header: tr("select.prompt"),
    large: false,
    body: (close: Function) => (
      <>
        <ul class={"list"}>
          {state.openedList.params.map((param) => (
            <li>
              <button className={"button h300 button-secondary"} onClick={() => close(param.param)}>
                {param.param}
              </button>
            </li>
          ))}
        </ul>
      </>
    ),
  }) as string;

  if (!paramName) {
    return msg("Cancel");
  }

  return msg("AddParamToGroup", { paramName, group });
}

async function saveList(list: List) {
  try {
    const id = await advancedManager.saveList(list);
    if (_.isEmpty(list.listId)) {
      list.listId = id;
      return msg("AddNewListSuccess", list);
    }
    return msg("OverwriteListSuccess");
  } catch (err) {
    console.error("saving list failed", err);
    return msg("SavingFailed");
  }
}

async function loadLists() {
  try {
    const lists = await advancedManager.getAllLists();
    return msg("LoadListsSuccess", lists ?? []);
  } catch (err) {
    console.error("getting lists failed", err);
    return msg("LoadDataFailed");
  }
}

async function loadDevices(installationIds: string[]) {
  try {
    let devices: any[] = [];
    for (const installationId of installationIds) {
      const data = await metricsManager.technicianParams(installationId);
      if (!data) {
        return msg("LoadDataFailed");
      }
      data.devices.map((device: any) => {
        device.installationId = installationId;
        device.params = [];
        Object.keys(device).forEach((key) => {
          const param = params.find((param) => param.param === key);
          if (param) {
            device.params.push(param);
          }
        });
      });
      devices.push(data.devices);
      devices = devices.flat();
    }

    return msg("LoadDevicesSuccess", devices);
  } catch (error) {
    return msg("LoadDataFailed");
  }
}

async function openListModal(list: any) {
  const result = await modal({
    header: tr("select.prompt"),
    large: false,
    body: (close: Function) => (
      <>
        <div className={"dpl-wrapper"}>
          <button className={"button h300 button-primary"} onClick={() => close("open")}>
            {tr("fib.advanced.dataPointsLists.open")}
          </button>
          <button className={"button h300 button-secondary"} onClick={() => close("copy")}>
            {tr("fib.advanced.dataPointsLists.copy")}
          </button>
        </div>
      </>
    ),
  });

  if (!result) {
    return msg("Cancel");
  }
  if (result === "open") {
    return msg("OpenList", list);
  }
  if (result === "copy") {
    const newName = prompt(tr("fib.advanced.dataPointsLists.newName"));

    if (!newName) {
      return msg("Cancel");
    }

    list.name = newName;
    list.listId = "";
    return msg("CopyList", list);
  }

  return msg("Cancel");
}

async function openParamModal(state: State) {
  const devicesExt = { devices: state.devices, params: [] };

  const param = await modal({
    header: tr("select.prompt"),
    large: false,
    body: (close: Function) => (
      <fib-select-parameter devices={devicesExt} closeFn={close}></fib-select-parameter>
    ),
  }) as Param;
  param.paramId = param.deviceSn + param.param;
  param.note = "";

  return !param ? msg("Cancel") : msg("AddParamToList", param);
}

async function loadChartData(param: Param) {
  const chartParameters: ChartParameters = {
    installationId: param.installationId,
    params: [param.param],
    sn: param.deviceSn,
    timeFrom: Date.now() - 1000 * 60 * 60 * 24 * 360,
    timeTo: Date.now(),
  };
  let metricsData;

  try {
    metricsData = await metricsManager.getMetricsInTime(chartParameters);
  } catch (error) {
    return msg("LoadDataFailed");
  }

  return msg("LoadChartDataSuccess", { chartParameters, metricData: metricsData[0] });
}

function view(state: State): stm.View<Msg> {
  return (
    <>
      <h1 class={"h400"}>{tr("fib.advanced.dataPointsLists")}</h1>
      <div class={"dpl-wrapper"}>
        <>
          {state.lists.map((list) => (
            <button
              class={"button button-primary"}
              onClick={() => msg("OpenListModal", list)}
            >
              {list.name}
            </button>
          ))}
        </>
        <button className={"button h200 button-tertiary"} onClick={() => msg("AddNewList")}>
          {tr("fib.advanced.dataPointsLists.new")}
        </button>
      </div>

      {state.isListOpen && (
        <div class={"dpl-container"}>
          <div class={"dpl-lists"}>
            <h1 className={"h400"}>{state.openedList.name}</h1>
            <ul class={"list"}>
              <>
                {state.openedList.params.map((param: Param) => (
                  <li class={"dpl-item"}>
                    <button
                      class={"button button-tertiary"}
                      onClick={() => msg("OpenChart", param)}
                    >
                      {param.param}
                    </button>
                    <button
                      class={"button-tertiary h100 square-button close-button"}
                      onClick={() => msg("RemoveParam", param)}
                    >
                      <i className="icon-cross" />
                    </button>
                  </li>
                ))}
              </>
              <li className={"dpl-item"}>
                <button
                  className={"button button-secondary nowrap"}
                  onClick={() => msg("OpenParamModal")}
                >
                  {tr("fib.advanced.dataPointsLists.pickParam")}
                </button>
              </li>
            </ul>

            {state.openedList.params.length > 0 &&
              (
                <>
                  <h1>{tr("fib.advanced.dataPointsLists.groups")}</h1>
                  <button
                    className={"button button-secondary nowrap"}
                    onClick={() => {
                      const name = prompt(tr("fib.advanced.dataPointsLists.newName"));
                      return name ? msg("AddGroup", { name, parentId: "root" }) : msg("Cancel");
                    }}
                  >
                    {tr("fib.advanced.dataPointsLists.newGroup")}
                  </button>
                </>
              )}

            <>
              {state.openedList.groups.map((group) => renderGroup(group))}
            </>
          </div>

          {state.isChartOpen && (
            <div class={"dpl-chart"}>
              {state.isChartDataLoading
                ? (
                  <div class={"loader-wrapper"}>
                    <div className={"loader-big"} />
                  </div>
                )
                : (
                  <>
                    <fib-chart
                      date-from={state.chartData.chartParameters.timeFrom}
                      date-to={state.chartData.chartParameters.timeTo}
                    >
                      <fib-chart-series
                        param={state.chartData.metricData.param}
                        values={state.chartData.metricData.values.map((v) => v.value).join(",")}
                      />
                    </fib-chart>

                    {renderNotes(state)}
                  </>
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

function renderGroup(group: Group) {
  return (
    <ul className={"list badge-orange dpl-groups-wrapper"}>
      <h3 class={"h200"}>{group.name}</h3>
      <ul class={"list"}>
        <>
          {group.params.map((param) => (
            <li className={"dpl-item"}>
              <div className={"button-tertiary dpl-item-title"}>{param.param}</div>
              <button
                className={"button-tertiary h100 square-button close-button"}
                onClick={() => msg("RemoveParamFromGroup", { group, param })}
              >
                <i className="icon-cross" />
              </button>
            </li>
          ))}
        </>
        <hr />
        <li>
          <button
            className={"button button-primary nowrap"}
            onClick={() => msg("OpenGroupModal", group)}
          >
            {tr("fib.advanced.dataPointsLists.pickParam")}
          </button>
        </li>

        <li>
          <button
            className={"button button-primary nowrap"}
            onClick={() => {
              const name = prompt(tr("fib.advanced.dataPointsLists.newName"));
              return name ? msg("AddGroup", { name, parentId: group.groupId }) : msg("Cancel");
            }}
          >
            {tr("fib.advanced.dataPointsLists.newGroup")}
          </button>
        </li>

        <>
          {group.children.length > 0 &&
            group.children.map((child, index) => (
              <div class={"dpl-subgroup"}>
                {index === 0 && (
                  <h4 class={"h100"}>{tr("fib.advanced.dataPointsLists.subgroup")}</h4>
                )}
                {renderGroup(child)}
              </div>
            ))}
        </>
      </ul>
    </ul>
  );
}

function renderNotes(state: State) {
  const param = state.openedList.params.find((param) => param.paramId === state.openedChartParamId);
  if (!param) {
    return <></>;
  }

  return (
    <div class={"dpl-notes-wrapper"}>
      <label htmlFor="note">{tr("fib.advanced.dataPointsLists.notes")}</label>
      <textarea
        class={"dpl-notes"}
        id="note"
        name="note"
        onInput={(e: Event) =>
          msg("UpdateNote", {
            param,
            value: (e.target as HTMLInputElement).value,
          })}
      >
        {param.note}
      </textarea>
      <button class={"button button-primary"} onClick={() => msg("SaveNote")}>
        {tr("general.saveChanges")}
      </button>
    </div>
  );
}
