import _ from "lodash";
import { router, stm } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { MiniInstallation, UserState } from "nexus/node/MetricsManager";
import * as realtime from "./realtime";

const installationManager = grow.plant("InstallationManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "UpdateInstallation", data: MiniInstallation]
  | [type: "None"]
  | [type: "CurrentStatus", data: MiniInstallation[]];

interface State {
  userState?: UserState;
  route: router.Route;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-tech-panel",
  shadow: false,
  debug: false,
  propTypes: {
    route: Object,
    userState: Object,
  },
  willMount(cmp: any) {
    cmp.unsubs = [];
  },
  willUnmount(cmp: any) {
    cmp.unsubs.forEach((unsub: any) => unsub());
  },
  attributeChangeFactory: (name: string, value: any) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        route: router.getCurrentRoute(),
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg, cmp: any, dispatch: stm.Dispatch<Msg>) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "route", P.select()], (route) => {
      state.route = route as router.Route;
      return [state, state.userState ? updateInstallationState(state) : null];
    })
    .with(["Attr", "userState", P.select()], (userState) => {
      if (typeof userState === "string") {
        return [state, null];
      }

      state.userState = userState as UserState;
      return [state, updateInstallationState(state)];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["UpdateInstallation", P.select()], (insta) => {
      if (!state.userState) {
        return [state, null];
      }

      state.userState = {
        ...state.userState,
        installations: (state.userState?.installations ?? []).map((instaIt) => {
          return instaIt.id === insta.id ? insta : instaIt;
        }),
      };

      return [state, null];
    })
    .with(["None"], () => [state, null])
    .with(["CurrentStatus", P.select()], (insta) => {
      state.userState.installations.map((obj) => {
        const source = _.find(insta, { id: obj.machineId }) ?? {};
        obj.runningStatus = source.online ? "active" : "inactive";
        obj.installationState = source.online
          ? (obj.alerts && obj.alerts.length > 0) ? "error" : "normal"
          : "warning";
        obj.cosinusStatus = source.cosinusPhi ? "active" : "inactive";
        obj.defrostingStatus = source.defrosting ? "active" : "inactive";
        obj.antiPumpStatus = source.antiPump ? "active" : "inactive";
        obj.updatedAt = new Date(source.updatedAt).getTime();
      });
      return [state, null];
    })
    .exhaustive();
}

async function updateInstallationState(state: State) {
  const pagesWithoutStatus = ["techInstallPanel", "techInstallsUsers", "techAdvanced"];

  if (pagesWithoutStatus.includes(state.route.name)) {
    return msg("None");
  }

  const instIds: string[] = Array.from(
    new Set(state.userState.installations.map((obj) => obj.machineId as string)),
  );

  try {
    const results = await installationManager.readInstallationsState(instIds);
    return msg("CurrentStatus", results);
  } catch (err) {
    return msg("None");
  }
}

function view(state: State): stm.View<Msg> {
  if (!state.userState || !state.route) {
    return (
      <div className="loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }

  const installationId = state.route.params["installationId"];
  switch (state.route.name) {
    case "techInstallParameters":
      return <fib-tech-install-panel installation-id={installationId} view-state={"params"} />;
    case "techInstallPanel":
      return (
        <fib-tech-install-panel
          installation-id={installationId}
          view-state={"info"}
        />
      );
    case "techHomePanel":
      return <fib-tech-home-panel installation-id={installationId} view-state={"info"} />;
    case "techHomeThresholds":
      return (
        <fib-tech-home-thresholds
          installation-id={installationId}
          view-state={"thresholds"}
        />
      );
    case "techHomeConfig":
      return <fib-tech-home-config installation-id={installationId} view-state={"config"} />;
    case "techInstallsUsers":
      return <fib-tech-installs-users user-state={state.userState} />;
    case "techUserAdd":
      return <fib-tech-installs-users user-state={state.userState} base-panel="technician" />;
    case "techUserEdit":
      return <fib-user-edit user-id={state.route.params.userId} base-panel="technician" />;
    case "techMapView":
      return <fib-tech-installs-map user-state={state.userState} route={state.route} />;
    case "techMapViewWithAlerts":
      return <fib-tech-installs-map user-state={state.userState} show-alerts route={state.route} />;
    case "techAdvanced":
      return <fib-tech-advanced user-state={state.userState} />;
    case "messagePanel":
      return <fib-tech-message-panel user-state={state.userState} />;
    default:
      return (
        <fib-tech-installs-list
          user-state={state.userState}
          page={state.route.params["page"] ?? "1"}
        />
      );
  }
}
