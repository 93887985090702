import _ from "lodash";
import { flashMessage, modal, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { UserState } from "nexus/node/MetricsManager";
import { AlertWithMessage } from "nexus/node/AlertsAccess";

type AlertWithMessageExt = AlertWithMessage & { isChecked: boolean };

type Msg =
  | [type: "AttributeChange", name: string, value: UserState]
  | [type: "AttributeChange", name: string, value: string]
  | [type: "AlertChecked", alertCode: string]
  | [type: "InstallationChange", selectedInstal: string]
  | [type: "LoadingAlertsSuccess", alerts: AlertWithMessage[]]
  | [type: "OpenAddMessageBox"]
  | [type: "RemoveSelected"]
  | [type: "RemoveSuccess"]
  | [type: "None"]
  | [type: "Error", errMsg: string]
  | [type: "SaveAlertSuccess"];

interface State {
  alerts: AlertWithMessageExt[];
  isLockedBtn: boolean;
  userState?: UserState;
  isLoading: boolean;
  isInProgress: boolean;
  selectedInstal: string;
  errMsg: string;
  alertCode: string[];
}

const msg = (...args: Msg): Msg => args;
const advancedManager = grow.plant("AdvancedManager");
const installationManager = grow.plant("InstallationManager");

stm.component({
  tagName: "fib-alerts-list",
  shadow: false,
  debug: false,
  propTypes: {
    userState: Object,
  },
  attributeChangeFactory: (name: string, value: any) => msg("AttributeChange", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        alerts: [],
        isLockedBtn: true,
        selectedInstal: "",
        isInProgress: false,
        errMsg: "",
        alertCode: [],
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg, cmp: any, dispatch: stm.Dispatch<Msg>) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChange", "userState", P.select()], (userState) => {
      state.userState = userState as UserState;
      state.isLoading = false;
      return [state, null];
    })
    .with(["AttributeChange", P._, P._], () => [state, null])
    .with(["InstallationChange", P.select()], (instal) => {
      state.selectedInstal = instal;
      state.isInProgress = true;
      return [state, loadAlerts(instal)];
    })
    .with(["None"], () => {
      state.isInProgress = false;
      state.alertCode = [];
      state.isLoading = false;
      state.isLockedBtn = true;
      return [state, null];
    })
    .with(["LoadingAlertsSuccess", P.select()], (alerts) => {
      state.isInProgress = false;

      const alertsExt: AlertWithMessageExt[] = [];
      for (let alert of alerts) {
        alertsExt.push({ ...alert, isChecked: false });
      }
      state.alerts = alertsExt;
      state.isLockedBtn = true;
      state.alertCode = [];
      return [state, null];
    })
    .with(["SaveAlertSuccess"], () => {
      flashMessage(tr("gcrud.saveSuccess"), "success");
      state.isInProgress = false;
      state.alertCode = [];
      return [state, loadAlerts(state.selectedInstal)];
    })
    .with(["OpenAddMessageBox"], () => {
      state.isInProgress = true;
      flashMessage(tr("general.inProgress"), "info");
      state.alerts.forEach((alert) => alert.isChecked = false);
      return [
        state,
        saveAlertsMessage(state.selectedInstal, state.alertCode),
      ];
    })
    .with(["RemoveSuccess"], () => {
      flashMessage(tr("gcrud.saveSuccess"), "success");
      state.isInProgress = false;
      state.alerts = state.alerts.filter((alert) => !state.alertCode.includes(alert._id));
      state.alertCode = [];
      state.isLockedBtn = true;
      return [state, null];
    })
    .with(["RemoveSelected"], () => {
      state.isInProgress = true;
      return [
        state,
        state.alertCode.length > 0 ? removeAlerts(state.selectedInstal, state.alertCode) : null,
      ];
    })
    .with(["AlertChecked", P.select()], (alert) => {
      const index = state.alertCode.indexOf(alert);
      if (index !== -1) {
        state.alertCode.splice(index, 1);
      } else {
        state.alertCode.push(alert);
      }
      state.isLockedBtn = state.alertCode.length < 1;
      state.alerts.find((elem) => {
        if (elem._id === alert) {
          elem.isChecked = !elem.isChecked;
        }
      });
      return [state, null];
    })
    .with(["Error", P.select()], (errMsg) => {
      flashMessage(errMsg, "error");
      state.isInProgress = false;
      state.errMsg = errMsg;
      state.alertCode = [];
      return [state, null];
    })
    .exhaustive();
}

async function removeAlerts(installation: string, alerts: string[]) {
  try {
    const settings = await installationManager.removeAlerts(installation, alerts);
    return msg("RemoveSuccess");
  } catch (err) {
    console.error("removing alerts failed", err);
    return msg("Error", tr("gcrud.deletionFailed"));
  }
}

async function loadAlerts(instalId: string) {
  try {
    const alerts = await advancedManager.getInstallationAlerts(instalId);
    return msg("LoadingAlertsSuccess", alerts);
  } catch (err) {
    console.error("loading alerts failed", err);
    return msg("Error", tr("fib.advanced.alertLoadFail"));
  }
}

async function saveAlertsMessage(
  instalId: string,
  alertsId: string[],
) {
  const message = await modal.prompt({
    title: tr("fib.advanced.alertMessage"),
    text: "",
    placeholder: "",
  });

  if (!message) {
    return msg("None");
  }

  try {
    await advancedManager.updateAlertsStatus(instalId, alertsId, message);
    return msg("SaveAlertSuccess");
  } catch (err) {
    console.error("saving alerts messsage failed", err);
    return msg("Error", tr("general.loadFailed"));
  }
}

function view(state: State): stm.View<Msg> {
  return (
    <>
      <div class="fib-advanced-alerts">
        <h4>{tr("fib.advanced.scriptUploadOption")}</h4>
        <select
          class={"body-large"}
          onInput={(e: Event) =>
            msg(
              "InstallationChange",
              (e.target as HTMLSelectElement).value,
            )}
        >
          <option>{tr("fib.advanced.scriptUploadOption")}</option>
          <>
            {(state?.userState?.installations || [])
              .map((instal: any) => {
                return <option value={instal.id}>{instal.name}</option>;
              })}
          </>
        </select>
        <div class={"form-element fib-advanced-alerts-button"}>
          <button
            class={"button-primary"}
            disabled={state.isLockedBtn}
            onClick={() => msg("OpenAddMessageBox")}
          >
            {tr("fib.advanced.alertsConfirmBtn")}
          </button>
          <button
            class={"button-primary"}
            disabled={state.isLockedBtn}
            onClick={() => msg("RemoveSelected")}
          >
            {tr("fib.advanced.delSelected")}
          </button>
        </div>
      </div>
      <div class="installation-alerts">
        {alertsList(state)}
      </div>
    </>
  );
}

function alertsList(state: State) {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{tr("fib.advanced.alertCode")}</th>
            <th>{tr("fib.advanced.alertName")}</th>
            <th>{tr("fib.advanced.alertStatus")}</th>
            <th>{tr("fib.advanced.alertComments")}</th>
            <th>{tr("fib.advanced.alertUpdated")}</th>
          </tr>
        </thead>
        <tbody>
          <>
            {(state.alerts || [])
              .map((alertsList) => {
                return (
                  <tr>
                    <td className="body-large pointer" style="list-style:none;">
                      <input
                        onInput={(e: Event) =>
                          msg(
                            "AlertChecked",
                            (e.target as HTMLInputElement).value,
                          )}
                        type="checkbox"
                        id={alertsList._id}
                        value={alertsList._id}
                        checked={alertsList.isChecked}
                      />
                      {`  ${alertsList.alert.code} - ${alertsList.alert.level}`}
                    </td>
                    <td>{alertsList.alert.name}</td>
                    <td>{alertsList.status}</td>
                    <td>{alertsList.message}</td>
                    <td>{new Date(alertsList.updatedAt).toLocaleString()}</td>
                  </tr>
                );
              })}
          </>
        </tbody>
      </table>
    </>
  );
}
