import { TypeAheadSuggestion } from "./sw-type-ahead";
import { stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { LicenseType } from "nexus/node/contracts";

const resourceManager = grow.plant("ResourceManager");

type Msg =
  | [type: "RemoveUser", userIndex: number]
  | [type: "AddUser"]
  | [type: "UpdateUser", userIndex: number, email: string]
  | [type: "SuggestionsLoadSuccess", userIndex: number, suggestions: TypeAheadSuggestion[]]
  | [type: "SuggestionsLoadFailed", userIndex: number]
  | [type: "AttributeChange", name: string, value: unknown]
  | [type: "DelayedLoadSuggestions", index: number, email: string];

interface User {
  email: string;
}

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  users: User[];
  errors: Record<number, string>;
  suggestions: Record<number, TypeAheadSuggestion[]>;
  disabled: boolean;
  accessType?: LicenseType;
}

stm.component({
  tagName: "fib-users-list",
  shadow: false,
  debug: false,
  propTypes: {
    users: Array,
    disabled: Boolean,
    accessType: String,
  },
  attributeChangeFactory: (name, value) => msg("AttributeChange", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        users: [],
        errors: {},
        suggestions: {},
        disabled: false,
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChange", "users", P.select()], (users) => [
      { ...state, users: users as User[] },
      null,
    ])
    .with(["AttributeChange", "accessType", P.select()], (accessType: any) => {
      const access = ["manager", "user_home", "technician_home"].includes(accessType)
        ? accessType
        : "technician";

      return [{ ...state, accessType: access }, null];
    })
    .with(["AttributeChange", "disabled", P.select()], (disabled) => [
      { ...state, disabled: !!disabled && disabled !== "false" },
      null,
    ])
    .with(["AttributeChange", P._, P._], () => [state, null])
    .with(["UpdateUser", P.select("index"), P.select("email")], ({ index, email }) => {
      const users = state.users.map((u, i) => {
        if (i === index) {
          u.email = email;
        }
        return u;
      });

      const errors = validateAll(users);
      const validUsers = state.users.filter((_user, index) => !errors[index]);

      return [
        { ...state, users, errors },
        new stm.CombinedCmds([
          updateEvent(validUsers),
          delayedLoadSuggestions(index, email),
        ]),
      ];
    })
    .with(["DelayedLoadSuggestions", P.select("index"), P.select("email")], ({ index, email }) => {
      const currentValue = state.users[index]?.email ?? "";

      if (currentValue !== email) {
        return [state, null];
      }

      const usersList: string[] = [];
      state.users.forEach((user) => {
        usersList.push(user.email);
      });

      return [
        state,
        loadSuggestions(index, email, state.accessType || "manager", usersList),
      ];
    })
    .with(["RemoveUser", P.select()], (index) => {
      const users = state.users.filter((_u, i) => i !== index);
      return updateUsers(state, users);
    })
    .with(["AddUser"], () => {
      if (state.disabled) {
        return [state, null];
      }
      const users = [...state.users, { email: "" }];

      return [
        { ...state, users },
        null,
      ];
    })
    .with(
      ["SuggestionsLoadSuccess", P.select("userIndex"), P.select("suggestions")],
      ({ userIndex, suggestions }) => [
        {
          ...state,
          suggestions: {
            [userIndex]: suggestions,
          },
        },
        null,
      ],
    )
    .with(["SuggestionsLoadFailed", P._], () => [
      state,
      null,
    ])
    .exhaustive();
}

async function delayedLoadSuggestions(index: number, email: string) {
  await new Promise((r) => setTimeout(r, 50));
  return msg("DelayedLoadSuggestions", index, email);
}

async function loadSuggestions(
  userIndex: number,
  phrase: string,
  accessType: string,
  emails: string[],
) {
  if (phrase.length < 2) {
    return msg("SuggestionsLoadSuccess", userIndex, []);
  }

  try {
    const result = await resourceManager.suggestUsers(phrase, accessType);

    const suggestions = Object.values(result).map((result: any) => ({
      suggestion: result.representation,
      value: result.email,
    })).filter((res: any) => !emails.includes(res.value));

    return msg("SuggestionsLoadSuccess", userIndex, suggestions);
  } catch (err) {
    console.error("loading suggestions failed", err);
    return msg("SuggestionsLoadFailed", userIndex);
  }
}

function updateUsers(state: State, users: User[]): [State, stm.Cmd<Msg>] {
  const errors = validateAll(users);
  const validUsers = users.filter((_user, index) => !errors[index]);
  1;
  return [
    { ...state, users, errors },
    updateEvent(validUsers),
  ];
}

function validateAll(users: User[]) {
  const errors: Record<number, string> = {};

  users.forEach((user, index) => {
    if (!user.email.includes("@") || !user.email.includes(".")) {
      errors[index] = tr("fibUsersList.notEmail");
    }
  });

  return errors;
}

function updateEvent(validUsers: User[]) {
  return new CustomEvent("update", { bubbles: true, detail: validUsers });
}

declare namespace JSX {
  interface IntrinsicElements {
    "sw-type-ahead": any;
  }
}
function view(state: State) {
  return (
    <div class={"fib-users-list"}>
      <ul>
        <>
          {state.users.map((user, index) => (
            <li>
              <div className="hbox full-width">
                <sw-type-ahead
                  class={"body-medium full-width"}
                  name={Math.random().toString(32)}
                  disabled={state.disabled}
                  value={user.email}
                  placeholder={"email@example.com"}
                  suggestions={state.suggestions[index] ?? []}
                  onupdate={(event: any) => {
                    event.stopPropagation();
                    return msg("UpdateUser", index, event.detail);
                  }}
                />
                <button
                  className="button-tertiary square-button"
                  onClick={msg("RemoveUser", index) as any}
                  disabled={state.disabled}
                >
                  <i class={"icon-trash-fat"} />
                </button>
              </div>
              {!!state.errors[index] && <p className="error body-small">{state.errors[index]}</p>}
            </li>
          ))}
        </>
      </ul>

      <button
        className="button-secondary square-button"
        onClick={msg("AddUser") as any}
        disabled={state.disabled}
      >
        <i className="icon-plus" />
      </button>
    </div>
  );
}
