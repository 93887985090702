import { flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { v } from "./v";
import { match, P } from "ts-pattern";
import cloneDeep from "lodash/cloneDeep";
import { UserState } from "nexus/node/MetricsManager";
import { eraseCookie } from "./view-utils";

const metricsManager = grow.plant("MetricsManager");

type Msg =
  | [type: "LoadUserStateSuccess", identity: UserState]
  | [type: "LoadUserStateFailed"]
  | [type: "UrlChange"]
  | [type: "UpdateLoginStatus"]
  | [type: "UpdateLogoutStatus"]
  | [type: "PassChanged"];

interface State {
  isLoading: boolean;
  isLoggedIn: boolean;
  userState?: UserState;
  route: router.Route;
}

function msg(...args: Msg): Msg {
  return args;
}

stm.component({
  tagName: "fib-main-panel",
  shadow: false,
  debug: false,
  init(dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    router.addEventListener("routeChange", () => dispatch(msg("UrlChange")));
    grow.addEventListener("login", () => dispatch(msg("UpdateLoginStatus")));
    grow.addEventListener("logout", () => dispatch(msg("UpdateLogoutStatus")));
    return actOnLoginStatus();
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["UrlChange"], () => [
      { ...state, route: router.getCurrentRoute() },
      loadUser(),
    ])
    .with(["UpdateLoginStatus"], () => actOnLoginStatus())
    .with(["UpdateLogoutStatus"], () => actOnLogoutStatus())
    .with(["PassChanged"], () => {
      router.navigate("panelSelection");
      return [
        state,
        null,
      ];
    })
    .with(["LoadUserStateFailed"], () => {
      flashMessage(tr("fibMainPanel.accessCheckFailed"), "error");
      state.isLoggedIn = false;
      state.isLoading = false;
      router.navigate("panelSelection");
      eraseCookie("grow-session-id");
      return [state, null];
    })
    .with(["LoadUserStateSuccess", P.select()], (userState) => {
      if (userState.status === "inactive") {
        flashMessage(tr("login.licenseExpired"), "error");
      }
      if (userState.licenseType === "guest" && grow.sessionId) {
        grow.sessionId = null;
        state.isLoggedIn = false;
      }
      state = {
        ...state,
        isLoading: false,
        userState,
        isLoggedIn: userState.licenseType !== "guest",
      };
      setRouteBasedOnAccess(state);

      return [state, null];
    })
    .exhaustive();
}

function setRouteBasedOnAccess(state: State) {
  let panel = router.getCurrentRoute().panel as string;
  const licenseType = state.userState?.licenseType ?? "guest";

  if (licenseType === "user_home") {
    panel = "user_home";
  }

  if (licenseType === "admin" || licenseType === "admin_home") {
    if (
      ["admin", "technician", "manager", "admin_home", "technician_home", "user_home"].includes(
        panel,
      )
    ) {
      return;
    }
    router.navigate("adminListInstallations");
    return;
  }

  if (licenseType === "technician" || licenseType === "technician_home") {
    if (["technician", "manager", "technician_home", "user_home"].includes(panel)) {
      return;
    }
    router.navigate("techInstallsList");
    return;
  }

  if (licenseType === "manager") {
    if (panel === "manager") {
      return;
    }
    router.navigate("managerDashboards", {
      installationId: state.userState?.installations?.[0]?.id ?? "",
    });
    return;
  }

  if (licenseType === "user_home") {
    window.location.href = "/home";
    return;
  }
}

function actOnLoginStatus(): [State, stm.Cmd<Msg>] {
  const isLoggedIn = !!grow.sessionId;
  const route = router.getCurrentRoute();

  if (isLoggedIn) {
    return [
      {
        isLoading: true,
        isLoggedIn,
        route,
      },
      loadUser(),
    ];
  }

  return [
    {
      isLoading: false,
      isLoggedIn,
      route,
    },
    loadUser(),
  ];
}

function actOnLogoutStatus(): [State, stm.Cmd<Msg>] {
  const isLoggedIn = !!grow.sessionId;
  const route = router.getCurrentRoute();
  eraseCookie("grow-session-id");

  return [
    {
      isLoading: false,
      isLoggedIn,
      route,
    },
    null,
  ];
}

async function loadUser(): Promise<Msg> {
  try {
    const userState = await metricsManager.getUserState();
    return msg("LoadUserStateSuccess", userState);
  } catch (err) {
    console.error("access check failed ", err);
    return msg("LoadUserStateFailed");
  }
}

function view(state: State) {
  if (!state.userState) {
    return (
      <div className="loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }
  const panel = state.route.panel;
  const licenseType = state.userState?.licenseType ?? "guest";

  if (panel === "admin" && (licenseType === "admin" || licenseType === "admin_home")) {
    if (state.route.name === "adminInstallation") {
      return <fib-admin-installation installation-id={state.route.params["installationId"]} />;
    } else if (state.route.name === "adminUserEdit") {
      return (
        <fib-user-edit
          user-id={decodeURIComponent(state.route.params["userId"])}
          basePanel="admin"
        />
      );
    } else if (
      state.route.name === "adminOrg" || state.route.name === "adminAddInstallationToOrg"
    ) {
      return <fib-edit-org org-id={state.route.params["orgId"]} />;
    }
    return <fib-admin-panel user-state={state.userState} />;
  }

  if (
    panel === "technician" &&
    (licenseType === "technician" || licenseType === "admin" || licenseType === "technician_home" ||
      licenseType === "admin_home")
  ) {
    // shallow comparison is performed by preact that's why we need to do a deep clone of route
    return (
      <fib-tech-panel
        route={cloneDeep(state.route)}
        user-state={state.userState}
      />
    );
  }

  if (panel === "manager" && licenseType !== "guest") {
    // techHomeDashboards
    if (licenseType.endsWith("_home")) {
      if (state.route.name === "dashFullscreen") {
        return <fib-manager-dashboard-fullscreen user-state={state.userState} />;
      }

      return (
        <fib-tech-home-panel
          view-state={state.route.name}
          installation-id={state.route.params.installationId}
        />
      );
    }
    return <fib-manager-panel route={cloneDeep(state.route)} user-state={state.userState} />;
  }

  if (state.route.name === "dashFullscreen" && licenseType === "guest") {
    return <fib-manager-panel route={cloneDeep(state.route)} user-state={state.userState} />;
  }

  if (licenseType === "user_home") {
    return <></>;
  }

  return (
    <div class="fib-main-panel">
      <div class="bg-img">
        <div class="fib-logo" />
      </div>
      <div class="content">
        {match<State, v.View<Msg>>(state)
          .with({ isLoading: true }, () => <div class="loader-big" />)
          .with({ route: { name: "remindPassword" } }, () => remindPasswordView())
          .with({ route: { name: "remindPasswordChange" } }, () => remindPasswordChangeView(state))
          .with({ route: { name: "userHome" } }, () => runUserHome(state))
          .with(P.when(() => state.userState?.licenseType === "guest"), () => loginFormView())
          .with(P._, () => <p>{tr("fibMainPanel.accessDenied")}</p>)
          .exhaustive()}
      </div>
    </div>
  );
}

function runUserHome(state: State) {
  window.location.href = "/home";
  return <></>;
}

function remindPasswordChangeView(state: State) {
  return (
    <div class="remind-password-change-view">
      <div class="h500">{tr("remindPassword.remindChange")}</div>
      <sec-remind-password-change-form
        identity-id={decodeURIComponent(state.route.params["identityId"])}
        token={state.route.params["token"]}
        onsaved={msg("PassChanged")}
      />
      <a class="text-button-primary h200" href={router.getRouteUrl("panelSelection")}>
        {tr("fibMainPanel.loginTitle")}
      </a>
    </div>
  );
}

function remindPasswordView() {
  return (
    <div class="remind-password-view">
      <div class="h500">{tr("remindPassword.remind")}</div>
      <sec-remind-password-form />
      <a class="text-button-primary h200" href={router.getRouteUrl("panelSelection")}>
        {tr("fibMainPanel.loginTitle")}
      </a>
    </div>
  );
}

function loginFormView() {
  return (
    <div class="login-form-view">
      <div class="h500">{tr("fibMainPanel.loginTitle")}</div>
      <sec-login-form />
      <a class="text-button-primary h200" href={router.getRouteUrl("remindPassword")}>
        {tr("remindPassword.remind")}
      </a>
    </div>
  );
}

function panelSelectionView(state: State) {
  return (
    <ul>
      {(state.userState?.licenseType === "admin" ||
        state.userState?.licenseType === "admin_home") && (
        <li>
          <a class="text-button-primary h300" href={router.getRouteUrl("adminListInstallations")}>
            {tr("fibMainPanel.adminPanel")}
          </a>
        </li>
      )}
      {(state.userState?.licenseType === "technician" ||
        state.userState?.licenseType === "admin" ||
        state.userState?.licenseType === "technician_home" ||
        state.userState?.licenseType === "admin_home") && (
        <li>
          <a class="text-button-primary h300" href={router.getRouteUrl("techInstallsList")}>
            {tr("fibMainPanel.technicianPanel")}
          </a>
        </li>
      )}
    </ul>
  );
}
