import { stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import _ from "lodash";

type Msg = [type: "Attr", name: string, value: unknown];

interface State {
  installationId: string;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-logs",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init() {
    return [
      {
        installationId: "",
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      if (installationId && _.isString(installationId)) {
        state.installationId = installationId;
      }
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .exhaustive();
}

function view(state: State): stm.View<Msg> {
  if (!state.installationId) {
    return (
      <div className="fib-manager-logs loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }

  return (
    <>
      <div class="fib-manager-logs">
        <div class="title-container">
          <h1 class="h600">{tr("general.logs")}</h1>
        </div>
        <div class="log-table">
          <log-table class="" per-page={20}>
            <log-table-prop-text name="installationId" value={state.installationId} hidden={true} />
            <log-table-prop-text name="identityId" label={tr("fib.logs.user")} />
            <log-table-prop-text name="message" label={tr("fib.logs.message")} />
            <log-table-prop-date name="createdAt" label={tr("fib.logs.date")} format="yyyy-mm-dd" />
            <log-table-prop-date name="createdAt" label={tr("fib.logs.time")} format="HH:MM:ss" />
          </log-table>
        </div>
      </div>
    </>
  );
}
