import { dates, flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { Group, Organization } from "nexus/node/OrganizationAccess";
import { match, P } from "ts-pattern";
import { v } from "./v";
import { InstallationAccess, LicenseType } from "nexus/node/contracts";
import { InstallationStored } from "nexus/node/InstallationDetailsAccess";
import { checkboxElement, itemTopBar, modalWrapper, nextYear, textElement } from "./view-utils";
import _ from "lodash";

const resourceManager = grow.plant("ResourceManager");
const installationTreeManager = grow.plant("InstallationTreeManager");

type LoadData = {
  org: Organization;
  installations: InstallationStored[];
  accesses: InstallationAccess[];
};

type Msg =
  | [type: "LoadSuccess", data: LoadData]
  | [type: "CloseAllModals"]
  | [type: "LoadFailed"]
  | [type: "UrlChange"]
  | [type: "Input", field: string, value: unknown]
  | [type: "ActivateOrg"]
  | [type: "DeactivateOrg"]
  | [type: "SetLicenseUntil", until: number]
  | [type: "Save"]
  | [type: "SaveSuccess", data: LoadData]
  | [type: "InstallationCreated"]
  | [type: "SaveFailed", errors: Record<string, string>]
  | [type: "AttributeChanged", name: string, value: string]
  | [type: "Remove"]
  | [type: "RemoveCancelled"]
  | [type: "RemoveFailed"]
  | [type: "RemoveSuccess"]
  | [type: "UpdateTree", data: { tree: Group; parentId: string }]
  | [type: "ExpandGroup", id: string]
  | [type: "AddGroup", id: string]
  | [type: "AddToSelection", id: string]
  | [type: "AssignTo", groupId: string]
  | [type: "AssignSuccess", id: string[]]
  | [type: "DetachInstallation", id: string]
  | [type: "DetachSuccess", data: any]
  | [type: "ChangeGroupName", group: { id: string; name: string }]
  | [type: "GroupNamePrompt", group: { id: string; name: string }]
  | [type: "RenameCancelled"]
  | [type: "UpdateSuccess"]
  | [
    type: "RemoveGroup",
    data: { parentId: string; id: string; installations: string[]; group: Group },
  ]
  | [type: "SetAccess", accessType: LicenseType, users: { email: string }[]];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  data: Record<string, unknown>;
  errors: Record<string, string>;
  org?: Organization;
  installations: InstallationStored[];
  accesses: InstallationAccess[];
  isSaving: boolean;
  isLoading: boolean;
  isRemoving: boolean;
  selectedInstallationIds: string[];
  isUpdated: boolean;
  route: router.Route;

  group: Group | null;
  expandedGroup: string[];
}

stm.component({
  tagName: "fib-edit-org",
  shadow: false,
  debug: false,
  propTypes: {
    orgId: String,
  },
  attributeChangeFactory: (name, value) => msg("AttributeChanged", name, value),
  willMount(cmp: any, dispatch: stm.Dispatch<Msg>) {
    cmp.urlChangeListener = () => dispatch(msg("UrlChange"));
    router.addEventListener("routeChange", cmp.urlChangeListener);
  },
  willUnmount(cmp: any) {
    router.removeEventListener("routeChange", cmp.urlChangeListener);
  },
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        data: {},
        errors: {},
        isSaving: false,
        isLoading: false,
        isRemoving: false,
        isUpdated: false,
        installations: [],
        accesses: [],
        selectedInstallationIds: [],
        route: router.getCurrentRoute(),
        group: {
          parentId: "top",
          groupId: "top",
          groupName: tr("fibEditOrg.addGroup"),
          position: 1,
          children: [],
        } as Group,
        expandedGroup: [],
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChanged", "orgId", P.select()], (orgId) => [
      { ...state, isLoading: true },
      loadData(orgId),
    ])
    .with(["UrlChange"], () => [
      { ...state, route: router.getCurrentRoute() },
      null,
    ])
    .with(["InstallationCreated"], () => {
      router.navigate("adminOrg", { orgId: state.org?.id ?? "" });
      return [state, loadData(state.org?.id ?? "")];
    })
    .with(["AttributeChanged", P._, P._], () => [state, null])
    .with(["CloseAllModals"], () => {
      router.navigate("adminOrg", { orgId: state.org?.id ?? "" });
      return [state, null];
    })
    .with(["LoadFailed"], () => {
      flashMessage(tr("fibEditOrg.loadFailed"), "error");
      return [state, null];
    })
    .with(["LoadSuccess", P.select()], (data) => {
      const servicesDetails: Record<string, number> = data.accesses.reduce((acc, obj) => {
        const extObj = acc[obj.installationId];
        if (obj.accessType.startsWith("techn") && (!extObj || obj.validUntil > extObj.validUntil)) {
          acc[obj.installationId] = obj.validUntil;
        }
        return acc;
      }, {});

      const installations = data.installations.map((inst) => {
        return { ...inst, servicedUntil: servicesDetails[inst._id] ?? 0 };
      });

      return [
        {
          ...state,
          isLoading: false,
          org: data.org,
          group: data.org.group as Group,
          installations: installations,
          accesses: data.accesses,
          data,
        },
        null,
      ];
    })
    .with(["Input", P.select("prop"), P.select("value")], ({ prop, value }) => {
      (state.data as any)[prop] = value;
      state.isUpdated = true;
      delete state.errors[prop];
      return [state, null];
    })
    .with(["SetLicenseUntil", P.select()], (until) => {
      state.data.licenseUntil = until;
      state.data.licenseActive = until > Date.now();
      if (state.data.licenseActive) {
        state.data.status = "active";
      } else {
        state.data.status = "inactive";
      }
      state.isUpdated = true;
      return [state, null];
    })
    .with(["ActivateOrg"], () => {
      state.data.licenseUntil = nextYear().getTime();
      state.data.status = "active";
      state.data.licenseActive = true;
      state.isUpdated = true;
      return [state, null];
    })
    .with(["DeactivateOrg"], () => {
      state.data.status = "inactive";
      state.data.licenseActive = false;
      state.isUpdated = true;
      return [state, null];
    })
    .with(["Save"], () => [
      { ...state, isSaving: true },
      save(state),
    ])
    .with(["SaveFailed", P.select()], (errors) => {
      flashMessage(tr("fibEditOrg.saveFailed"), "error");
      return [{ ...state, isSaving: false, errors }, null];
    })
    .with(["SaveSuccess", P.select()], (data) => [
      {
        ...state,
        isSaving: false,
        errors: {},
        data: {},
        org: data.org,
        installations: data.installations,
        accesses: data.accesses,
        isUpdated: false,
      },
      null,
    ])
    .with(["Remove"], () => {
      if (!state.org) {
        return [state, null];
      }
      return [
        { ...state, isRemoving: true },
        remove(state.org),
      ];
    })
    .with(["RemoveCancelled"], () => [
      { ...state, isRemoving: false },
      null,
    ])
    .with(["RemoveFailed"], () => {
      flashMessage.error(tr("general.removingFailed"));
      return [
        { ...state, isRemoving: false },
        null,
      ];
    })
    .with(["RemoveSuccess"], () => {
      router.navigate("adminListUsers");

      return [
        { ...state, isRemoving: false },
        null,
      ];
    })
    .with(["AddGroup", P.select()], (data) => {
      if (!state.expandedGroup.includes(data)) {
        state.expandedGroup.push(data);
      }

      return [state, state.org?.id ? addGroup(state.org.id, data) : null];
    })
    .with(["RemoveGroup", P.select()], (data) => {
      state.expandedGroup = state.expandedGroup.filter(
        (expandedId) => expandedId !== data.id,
      );
      const subGroup = collectSubGroupIds(data.group);

      const installations = state.installations
        .filter((inst) => subGroup.includes(inst.parentGroup))
        .map((inst) => inst._id) as string[];

      return [
        state,
        state.org?.id
          ? removeGroup(
            state.org.id,
            data.id,
            data.parentId,
            installations,
          )
          : null,
      ];
    })
    .with(["UpdateTree", P.select()], (data) => {
      state.group = data.tree;

      if (!state.expandedGroup.includes(data.parentId)) {
        state.expandedGroup.push(data.parentId);
      }
      return [state, state.org?.id ? loadData(state.org?.id) : null];
    })
    .with(["AddToSelection", P.select()], (id) => {
      if (state.selectedInstallationIds.includes(id)) {
        state.selectedInstallationIds = state.selectedInstallationIds.filter(
          (insta) => id !== insta,
        );
      } else {
        state.selectedInstallationIds.push(id);
      }
      return [state, null];
    })
    .with(["AssignTo", P.select()], (groupId) => {
      if (!state.expandedGroup.includes(groupId)) {
        state.expandedGroup.push(groupId);
      }

      return [
        state,
        state.selectedInstallationIds.length > 0 && state.org?.id
          ? assignToGroup(state.selectedInstallationIds, groupId, state.org?.id)
          : null,
      ];
    })
    .with(["AssignSuccess", P.select()], (data) => {
      state.selectedInstallationIds.forEach((inst) => {
        const checkbox = document.getElementById(inst) as HTMLInputElement;
        if (checkbox) {
          checkbox.checked = false;
        }
      });
      state.selectedInstallationIds = state.selectedInstallationIds
        .filter((inst) => !data.includes(inst));

      return [state, state.org?.id ? loadData(state.org?.id) : null];
    })
    .with(["DetachSuccess", P.select()], (data) => {
      return [state, state.org?.id ? loadData(state.org?.id) : null];
    })
    .with(["DetachInstallation", P.select()], (instaId) => {
      return [state, detachInstallation(instaId)];
    })
    .with(["GroupNamePrompt", P.select()], (group) => {
      if (!state.org) {
        return [state, null];
      }

      return [state, namePrompt(group.id, group.name)];
    })
    .with(["ChangeGroupName", P.select()], (group) => {
      if (group.name === null || !state.org?.id) return [state, null];
      return [state, updateGroupName(state.org?.id, group.id, group.name)];
    })
    .with(["ExpandGroup", P.select()], (groupId) => {
      state.expandedGroup.includes(groupId)
        ? state.expandedGroup = state.expandedGroup.filter((id) => id !== groupId)
        : state.expandedGroup.push(groupId);

      return [state, null];
    })
    .with(["UpdateSuccess"], () => {
      return [state, state.org?.id ? loadData(state.org?.id) : null];
    })
    .with(P._, () => {
      window.onbeforeunload = () => !_.isEmpty(state.data) ? "" : null;
      return [state, null];
    })
    .exhaustive();
}

async function remove(org: Organization) {
  const confirmed = await modal.confirm({
    title: tr("general.confirm", org as any),
    text: tr("fibEditOrg.confirmRemoval", org as any),
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  if (!confirmed) {
    return msg("RemoveCancelled");
  }

  try {
    await resourceManager.removeOrg(org.id);
    return msg("RemoveSuccess");
  } catch (err) {
    console.error("removing failed", err);
    return msg("RemoveFailed");
  }
}

async function save(state: State) {
  try {
    const result = await resourceManager.saveOrgWithAccesses({
      ...state.org,
      ...state.data,
      id: state.org?.id,
    });
    if ("errors" in result) {
      return msg("SaveFailed", result.errors);
    }
    return msg("SaveSuccess", result);
  } catch (err) {
    console.error("saving failed", err);
    return msg("SaveFailed", {});
  }
}

async function assignToGroup(
  installationIds: string[],
  groupId: string,
  orgId: string,
) {
  try {
    const result = await installationTreeManager.assignToGroup(
      orgId,
      {
        fromGroup: "",
        groupId,
        installationIds,
      },
    );
    return msg("AssignSuccess", installationIds);
  } catch (err) {
    console.error("assign to group failed", err);
    return msg("SaveFailed", {});
  }
}

async function detachInstallation(instId: string) {
  try {
    const result = await installationTreeManager.detachFromGroup(instId);
    return msg("DetachSuccess", result);
  } catch (err) {
    console.error("detach failed", err);
    return msg("SaveFailed", {});
  }
}

async function updateGroupName(
  orgId: string,
  groupId: string,
  message: string,
) {
  try {
    const result = await installationTreeManager.changeName(
      orgId,
      {
        groupId,
        groupName: message,
      },
    );
    return msg("UpdateSuccess");
  } catch (err) {
    console.error("detach failed", err);
    return msg("SaveFailed", {});
  }
}

async function loadData(orgId: string) {
  try {
    const result = await resourceManager.showOrg(orgId);
    return msg("LoadSuccess", result);
  } catch (err) {
    console.error("loading data failed", err);
    return msg("LoadFailed");
  }
}

function view(state: State) {
  if (state.isLoading || !state.org) {
    return (
      <div class="fib-edit-org">
        <div class="loader-big"></div>
      </div>
    );
  }

  const licenseActive = state.data.licenseActive === undefined
    ? state.org.licenseActive
    : state.data.licenseActive;

  const licenseUntil = state.data.licenseUntil === undefined
    ? state.org.licenseUntil
    : state.data.licenseUntil;

  const groupTop = {
    parentId: "top",
    groupId: "top",
    groupName: tr("fibEditOrg.addGroup"),
    position: 1,
    children: [],
  } as Group;

  const groupChildren: Group[] = (state.group === null || state.group === undefined)
    ? [] as Group[]
    : state.group.children;

  groupTop.children.push(...groupChildren);

  return (
    <div class="fib-edit-org vbox center">
      {state.route.name === "adminAddInstallationToOrg" && modalWrapper<Msg>(
        <fib-create-installation
          oncancel={msg("CloseAllModals")}
          oncreated={msg("InstallationCreated")}
          org-id={state.route.params.orgId ?? ""}
        />,
      )}
      {itemTopBar({
        goBackUrl: router.getRouteUrl("adminListOrgs"),
        goBackLabel: "fibEditOrg.listUsers",
        isSaveEnabled: state.isUpdated && Object.keys(state.data).length > 0 &&
          !state.isSaving &&
          Object.keys(state.errors).length === 0,
        isSaving: state.isSaving,
        title: state.org.name,
      })}

      <div class="box12 org-title">
        <h2 class="h600 title-container">{tr("general.mainInfo")}</h2>
        <div class="">
          <button class="button-tertiary h300" onClick={() => msg("Remove")}>
            <i class="icon-trash"></i>
            <span>{tr("fibEditOrg.removeClient")}</span>
          </button>
        </div>
      </div>

      <div class="client-info">
        <div class="overall-info">
          <div class="left-panel">
            {textElement<Msg>({
              field: "name",
              label: "fibEditOrg.orgName",
              errors: state.errors,
              data: state.data,
              realData: state.org,
              isDisabled: state.isSaving,
            })}
            {textElement<Msg>({
              field: "address",
              label: "fibCreateOrg.orgAddress",
              errors: state.errors,
              data: state.data,
              realData: state.org,
              isDisabled: state.isSaving,
            })}
            {textElement<Msg>({
              field: "phone",
              label: "fibCreateOrg.orgPhone",
              errors: state.errors,
              data: state.data,
              realData: state.org,
              isDisabled: state.isSaving,
            })}
            {textElement<Msg>({
              field: "nip",
              label: "fibCreateOrg.orgNip",
              errors: state.errors,
              data: state.data,
              realData: state.org,
              isDisabled: state.isSaving,
            })}
          </div>
          <div class="right-panel">
            <fib-license
              is-valid={licenseActive}
              valid-until={licenseUntil}
              disabled={false}
              onswitch={(event: any) => {
                if (event.detail) {
                  return msg("ActivateOrg");
                }
                return msg("DeactivateOrg");
              }}
              ondate={(event: any) => msg("SetLicenseUntil", event.detail)}
            />
            {checkboxElement<Msg>({
              field: "notifyAboutLicense",
              label: "fibEditOrg.notifyAboutLicense",
              errors: state.errors,
              data: state.data,
              realData: state.org,
              isDisabled: state.isSaving,
            })}
          </div>
        </div>
        <div class="client-assignments">
          <div class="user-assign">
            <div class="user-add">
              <h3 class="h400 subsection-title">
                {tr("fibEditOrg.installationUsers")}
              </h3>
            </div>
            <div class="users-list">
              {usersList(state.accesses)}
            </div>
          </div>
          <div class="installation-assign">
            <div class="installation-add">
              <h3 class="h400 subsection-title">
                {tr("fibEditOrg.subjectInstallations")}
              </h3>
              <a
                class="button-primary h200"
                href={router.getRouteUrl("adminAddInstallationToOrg", {
                  orgId: state.org.id,
                })}
                disabled={state.isSaving}
              >
                <span>{tr("fibInstallationList.createNewInstallation")}</span>
                <i className={"icon-plus"} />
              </a>
            </div>
            <ul class="list">
              {unassignedInstallations(state)}
            </ul>
            <div class="new-tree">
              {displayTree(
                groupTop,
                state.installations,
                state.selectedInstallationIds.length > 0 ?? false,
                state.expandedGroup,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function namePrompt(groupId: string, groupName: string) {
  let content = groupName;
  const message = await modal({
    large: true,
    header: tr("fibEditOrg.changeGroupName"),
    body: () => (
      <>
        <input
          type="text"
          required
          value={groupName}
          placeholder={groupName}
          onInput={(event: any) => content = event.target.value}
          autofocus
        />
      </>
    ),
    footer: (close) => (
      <>
        <button
          class={"button button-secondary h300"}
          onClick={() => close(false)}
        >
          {tr("general.cancel")}
        </button>
        <button
          class={"button button-primary h300"}
          onClick={() => close(true)}
        >
          {tr("general.saveChanges")}
        </button>
      </>
    ),
  }) as boolean;

  if (!message) {
    return msg("RenameCancelled");
  }
  return msg("ChangeGroupName", { id: groupId, name: content });
}

function installationsNotAssigned(
  groupTree: Group[],
  groupId: string,
): boolean {
  for (const group of groupTree) {
    if (group.groupId === groupId) {
      return true;
    }

    if (installationsNotAssigned(group.children, groupId)) {
      return true;
    }
  }
  return false;
}

function unassignedInstallations(state: State) {
  const ungrouped = state.installations
    .filter((ins) => {
      if (!ins.parentGroup || ins.parentGroup === "top") {
        return true;
      }
      if (state.group) {
        return !installationsNotAssigned([state.group], ins.parentGroup);
      }
    });

  if (ungrouped.length === 0) {
    return <></>;
  }
  return (
    <>
      <li class="unassigned">{tr("fibEditOrg.installationsUnassigned")}</li>
      <>
        {ungrouped.map((insta) => (
          <li
            class={"unassigned well body-large "}
          >
            <input
              id={insta._id}
              type="checkbox"
              class="inst-checkboxes"
              onClick={() => msg("AddToSelection", insta._id)}
            >
            </input>
            {insta.name}
            <div class="text-button-primary list-action">
              <sl-icon name="trash" class="h400"></sl-icon>
              <div class="icon-check"></div>
            </div>
          </li>
        ))}
      </>
    </>
  );
}

async function addGroup(orgId: string, parentId: string) {
  let content = tr("fibEditOrg.newGroupName");
  const message = await modal({
    large: true,
    header: tr("fibEditOrg.createGroupName"),
    body: () => (
      <>
        <input
          type="text"
          required
          value={tr("fibEditOrg.newGroupName")}
          placeholder={tr("fibEditOrg.newGroupName")}
          onInput={(event: any) => content = event.target.value}
          autofocus
          class={"add-group"}
        />
      </>
    ),
    footer: (close) => (
      <>
        <button
          class={"button button-secondary h300"}
          onClick={() => close(false)}
        >
          {tr("general.cancel")}
        </button>
        <button
          class={"button button-primary h300"}
          onClick={() => close(true)}
        >
          {tr("general.saveChanges")}
        </button>
      </>
    ),
  }) as boolean;

  if (!message) {
    return msg("RenameCancelled");
  }

  const tree = await installationTreeManager.addGroup(
    orgId,
    { groupId: parentId, parentId, groupName: content },
  );
  return msg("UpdateTree", { tree, parentId });
}

async function removeGroup(
  orgId: string,
  groupId: string,
  parentId: string,
  installations: string[],
) {
  const message = tr("fibEditOrg.removeGroupMessage");
  const result = await modal.confirm({
    text: message,
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  if (result) {
    const tree = await installationTreeManager.removeGroup(
      orgId,
      { groupId, parentId, installations },
    );
    return msg("UpdateTree", { tree, parentId });
  }
  return msg("RemoveCancelled");
}

function usersList(accesses: InstallationAccess[]) {
  const uniqUsers = _.uniqBy(accesses, "identityId");
  return (
    <>
      {uniqUsers
        .map((access) => (
          <div class="user-line">
            <a
              href={router.getRouteUrl("adminUserEdit", {
                userId: encodeURIComponent(access.identityId),
                basePanel: "admin",
              })}
            >
              {`${access.firstName} ${access.lastName}`}
            </a>
            <div class="license-type">
              {match<string, v.View<Msg>>(access.accessType)
                .with(
                  "admin",
                  () => <span class="body-large">{tr("access.admin")}</span>,
                )
                .with(
                  "admin_home",
                  () => <span class="body-large">{tr("access.adminHome")}</span>,
                )
                .with(
                  "technician",
                  () => (
                    <div class="badge-gray body-large">
                      {tr("fibOrgList.technician")}
                    </div>
                  ),
                )
                .with(
                  "technician_home",
                  () => (
                    <div class="badge-gray body-large">
                      {tr("access.technicianHome")}
                    </div>
                  ),
                )
                .with(
                  "manager",
                  () => <span class="body-large">{tr("fibOrgList.manager")}</span>,
                )
                .with(
                  "user_home",
                  () => <span class="body-large">{tr("access.userHome")}</span>,
                )
                .with(P._, () => <span></span>)
                .exhaustive()}
            </div>
          </div>
        ))}
    </>
  );
}

function displayTree(
  group: Group,
  installations: InstallationStored[],
  isMoveAvailable: boolean,
  expandedList: string[],
) {
  if (!group || !Object.keys(group).includes("children")) return <></>;

  const reducedList = installations
    .filter((i) => i.parentGroup !== "");
  return (
    <div class="tree">
      <div class="h400">
        {tr("fibAdminUser.installationStructure")}
        <span class="add-group" onClick={() => msg("AddGroup", group.groupId)}>
          <sl-icon name="plus-square-dotted"></sl-icon>
        </span>
      </div>
      {generateTree(group, reducedList, isMoveAvailable, expandedList)}
    </div>
  );
}

function generateTree(
  group: Group,
  installations: InstallationStored[],
  isMoveAvailable: boolean,
  expandedList: string[],
) {
  if (
    !group || !Object.keys(group).includes("children") ||
    group.children.length === 0
  ) return <></>;

  const instaIds = installations.map((instal) => instal._id);

  group.children = group.children.slice().sort((a: Group, b: Group) => {
    return a.groupName.localeCompare(b.groupName);
  });

  return (
    <ul class="">
      {group.children.length > 0 &&
          Object.keys(group.children[0]).includes("groupId")
        ? group.children.map((g: Group) => (
          <li>
            <details>
              <summary onClick={() => msg("ExpandGroup", g.groupId)}>
                {`${g.groupName} `}
                <span onClick={() => msg("AddGroup", g.groupId)}>
                  <sl-icon name="plus-square-dotted"></sl-icon>
                </span>
                <span
                  onClick={() => msg("GroupNamePrompt", { id: g.groupId, name: g.groupName })}
                >
                  <sl-icon name="pencil-square"></sl-icon>
                </span>
                <span
                  onClick={() =>
                    msg("RemoveGroup", {
                      parentId: g.parentId,
                      id: g.groupId,
                      installations: instaIds,
                      group: g,
                    })}
                >
                  <sl-icon name="trash"></sl-icon>
                </span>
                {isMoveAvailable && (
                  <span onClick={() => msg("AssignTo", g.groupId)}>
                    <sl-icon name="cart-plus"></sl-icon>
                  </span>
                )}
              </summary>
              <ul class="">
                {installationsList(installations, g)}
                {g.children.length > 0
                  ? generateTree(
                    g,
                    installations,
                    isMoveAvailable,
                    expandedList,
                  )
                  : ""}
              </ul>
            </details>
          </li>
        ))
        : <></>}
    </ul>
  );
}

function installationsList(
  installations: InstallationStored[],
  group: Group,
) {
  return (
    <>
      {installations
        .filter((i) => i.parentGroup === group.groupId)
        .map((insta) => {
          // isServiced = has technician and date below is until technician license finish
          let formattedDate = `[0000-00-00]`;
          if (insta.servicedUntil > 0) {
            const date = new Date(insta.servicedUntil);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            formattedDate = `[${year}-${month}-${day}]`;
          }
          return { ...insta, formattedDate };
        })
        .map((insta) => (
          <li>
            <div class="instal-line">
              <a
                href={router.getRouteUrl("adminInstallation", {
                  installationId: insta._id,
                })}
              >
                {insta.name}
              </a>
              <div class="insta-details">
                <div class={`instal-status ${insta.status === "active" ? "" : "not-active"}`}>
                  {insta.status === "active"
                    ? tr("fibEditOrg.installationActive")
                    : tr("fibEditOrg.installationNotActive")}
                </div>
                <div
                  class={`instal-service ${insta.servicedUntil > 0 ? "" : "not-active"}`}
                >
                  {insta.servicedUntil > 0
                    ? `${tr("fibEditOrg.installationServised")} ${insta.formattedDate}`
                    : `${tr("fibEditOrg.installationNotServised")}`}
                </div>
                <div onClick={() => msg("DetachInstallation", insta._id)}>
                  <sl-icon name="trash"></sl-icon>
                </div>
              </div>
            </div>
            <div class="break-space"></div>
          </li>
        ))}
    </>
  );
}

function collectSubGroupIds(
  root: Group,
): string[] {
  const nestedGroupIds: string[] = [];

  function traverse(node: Group) {
    nestedGroupIds.push(node.groupId);

    for (const child of node.children) {
      traverse(child);
    }
  }

  traverse(root);
  return nestedGroupIds;
}
