import _ from "lodash";
import { v } from "./v";
import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { Organization } from "nexus/node/OrganizationAccess";
import { match, P } from "ts-pattern";
import { InstallationStored } from "nexus/node/InstallationDetailsAccess";
import { checkboxElement, nextYear, textElement } from "./view-utils";
import { SaveUserData, SaveUserErrors } from "nexus/node/ResourceManager";
import {
  userGeneralInfo,
  userInstallations,
  userLicenseSwitch,
  userLicenseType,
} from "./user-elements";
import { AvailableTransport } from "nexus/node/contracts";

const resourceManager = grow.plant("ResourceManager");

type LoadedData = UnPromisify<ReturnType<typeof resourceManager.createUserPage>>;
type ValueOf<T> = T[keyof T];

type Errors = SaveUserErrors;
type Data = Partial<SaveUserData>;

type Step =
  | "Data"
  | "License"
  | "Installations";

type Msg =
  | [type: "AttributeChanged", name: string, value: unknown]
  | [type: "LoadDataSuccess", data: LoadedData]
  | [type: "LoadDataFailed"]
  | [type: "Save"]
  | [type: "SaveFailed", errors: Errors]
  | [type: "SaveSuccess"]
  | [type: "Cancel"]
  | [type: "Input", field: string, value: ValueOf<Data>]
  | [type: "GeneratePassword"]
  | [type: "NextStep"]
  | [type: "PreviousStep"]
  | [type: "ChangeSelectedOrg", selectedOrgId: string]
  | [type: "ChangeSelectedLang", selectedLang: string]
  | [type: "ActivateLicense"]
  | [type: "LoadInstallationsSuccess", installations: InstallationStored[]]
  | [type: "LoadInstallationsFailed"]
  | [type: "UnsavedChanges"]
  | [type: "EmailAvailability", isAvailable: boolean];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  isLoading: boolean;
  isSaving: boolean;
  org?: Organization; // data about chosen/preselected org - to check if needed -
  orgs: Organization[]; // list of all orgs connected to technician
  installations: InstallationStored[];
  data: Data; // data collected from user include installationsIds
  errors: Errors;
  step: Step;
  selectedOrgId: string; // last selected org
  areInstallationsLoading: boolean;
  basePanel: string; // show source component
  installationsAll: InstallationStored[]; // data about all installations connected to technician
  installationsByOrg: InstallationStored[]; // data about installations for selected org
  accessType: string;
}

stm.component({
  tagName: "fib-user-create",
  shadow: false,
  debug: false,
  propTypes: {
    orgId: String,
    basePanel: String,
  },
  willUnmount: () => {
    window.onbeforeunload = () => null;
  },
  attributeChangeFactory: (name, value) => msg("AttributeChanged", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        isSaving: false,
        selectedOrgId: "",
        orgs: [],
        installations: [],
        data: initialData(),
        errors: {},
        step: "Data",
        areInstallationsLoading: false,
        basePanel: "technician",
        installationsAll: [],
        installationsByOrg: [],
        accessType: "manager",
      },
      null,
    ];
  },
  update,
  view,
});

function initialData(): Data {
  return {
    sendChangePasswordEmail: true,
    sendDetailsEmail: true,
    licenseNotify: true,
  };
}

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChanged", "orgId", P.select()], (orgId) => {
      if (!_.isString(orgId)) {
        return [state, null];
      }
      return [
        { ...state, selectedOrgId: orgId },
        loadData(orgId),
      ];
    })
    .with(["AttributeChanged", "basePanel", P.select()], (basePanel) => [
      { ...state, basePanel: basePanel as string },
      null,
    ])
    .with(["AttributeChanged", P._, P._], () => [state, null])
    .with(["LoadDataFailed"], () => {
      flashMessage.error(tr("general.loadFailed"));
      return [state, null];
    })
    .with(["LoadDataSuccess", P.select()], (data: any) => [
      {
        // get & set data about all installations & orgs belongs to technician
        ...state,
        org: data.filteredOrgs.find((org: any) => org?.id === state.selectedOrgId),
        orgs: _.sortBy(data.filteredOrgs, "name"),
        installationsAll: data.filteredInstallations,
        accessType: data.accessType,
      },
      null,
    ])
    .with(["Cancel"], () => {
      const unsaved = !_.isEqual(state.data, initialData());
      return [state, new CustomEvent("cancel", { bubbles: true, detail: { unsaved } })];
    })
    .with(["Save"], () => {
      const installationIds = (state.data as any).installationIds || [];
      const notificationType: AvailableTransport[] = [];
      if (state.data.notifyByEmail) {
        notificationType.push("email");
      }
      if (state.data.notifyBySMS) {
        notificationType.push("sms");
      }

      state.data.notificationType = notificationType;

      if (
        installationIds.length === 0 &&
        !["guest", "admin", "admin_home"].includes(state.data.licenseType)
      ) {
        state.errors["installationIds"] = tr("fibCreateUser.title");
        return [state, null];
      }
      if (state.isSaving) {
        return [state, null];
      }
      return [
        { ...state, isSaving: true },
        save(state.data),
      ];
    })
    .with(["SaveFailed", P.select()], (errors) => {
      flashMessage.error(tr("general.saveFailed"));
      _.each(errors, (err, field) => {
        flashMessage.error(tr(err ?? "", { name: field as string }));
      });
      return [{ ...state, isSaving: false, errors }, null];
    })
    .with(["SaveSuccess"], () => [
      { ...state, errors: {}, isSaving: false, data: initialData() },
      new CustomEvent("saved", { bubbles: true }),
    ])
    .with(["Input", "sendChangePasswordEmail", P.select()], (sendChangePasswordEmail) => {
      state.data.sendChangePasswordEmail = sendChangePasswordEmail as boolean;
      if (!state.data.sendChangePasswordEmail) {
        delete state.data.password;
        delete state.data.sendDetailsEmail;
      }
      return [state, unsavedChanges()];
    })
    .with(["Input", "installations", P.select()], (installationIds) => {
      if (!Array.isArray(installationIds)) {
        return [state, null];
      }

      state.data.installationIds = _.uniq([...installationIds]);
      delete state.errors["installationIds"];

      return [state, unsavedChanges()];
    })
    .with(["Input", P.select("name"), P.select("value")], ({ name, value }) => {
      _.set(state.data, name, value);
      name === "licenseType" ? delete (state.data.installationIds) : "";
      delete (state.errors as any)[name];
      return [state, unsavedChanges()];
    })
    .with(["ActivateLicense"], () => {
      state.data.validUntil = nextYear().getTime();
      state.data.status = "active";
      return [state, null];
    })
    .with(["GeneratePassword"], () => {
      state.data.password = generateRandomPassword();
      return [state, null];
    })
    .with(["PreviousStep"], () => {
      state.step = state.step === "License" ? "Data" : "License";
      return [state, null];
    })
    .with(["EmailAvailability", P.select("isAvailable")], ({ isAvailable }) => {
      if (!isAvailable) {
        const errors: Errors = {};
        errors.email = tr("validation.emailNotUnique");
        return [{ ...state, errors }, null];
      }
      return [
        { ...state, step: nextStep(state.step) },
        null,
      ];
    })
    .with(["NextStep"], () => {
      if (state.step === "Data") {
        const errors = validateData(state.data);
        if (Object.keys(errors).length > 0) {
          return [{ ...state, errors }, null];
        }
        return [state, checkEmailAvailability(state.data.email)];
      }

      if (state.step === "License" && !state.data.licenseType) {
        state.errors.licenseType = "validation.notEmpty";
        return [state, null];
      }

      return [
        { ...state, step: nextStep(state.step) },
        null,
      ];
    })
    .with(["ChangeSelectedOrg", P.select()], (selectedOrgId) => [
      { ...state, selectedOrgId },
      loadInstallations(state, selectedOrgId),
    ])
    .with(["ChangeSelectedLang", P.select()], (selectedLang) => {
      state.data.lang = selectedLang;
      return [state, null];
    })
    .with(["LoadInstallationsSuccess", P.select()], (installations) => [
      {
        ...state,
        areInstallationsLoading: false,
        installationsByOrg: installations,
      },
      null,
    ])
    .with(["LoadInstallationsFailed"], () => {
      flashMessage.error(tr("general.loadingFailed"));
      return [
        { ...state, isRemoving: false },
        null,
      ];
    })
    .with(["UnsavedChanges"], () => {
      window.onbeforeunload = () => !_.isEqual(state.data, initialData()) ? "" : null;
      return [state, null];
    })
    .exhaustive();
}

function unsavedChanges() {
  return msg("UnsavedChanges");
}

function validateData(data: Data): Errors {
  const errors: Errors = {};

  if (!_.isString(data.email) || _.isEmpty(data.email)) {
    errors.email = tr("validation.notEmpty", { name: tr("identity.email") });
  }
  return errors;
}

async function checkEmailAvailability(email: string): Promise<Msg> {
  try {
    const isAvailable = await resourceManager.isEmailAvailable(email);
    return msg("EmailAvailability", isAvailable);
  } catch (err) {
    console.error("checking email availability failed", err);
    return msg("EmailAvailability", false);
  }
}

function nextStep(step: Step): Step {
  return match<Step, Step>(step)
    .with("Data", () => "License")
    .with("License", () => "Installations")
    .with(P._, () => "Installations")
    .exhaustive();
}

function generateRandomString(length: number, prefix = ""): string {
  const str = prefix + Math.random().toString(36).slice(2);

  if (str.length > length) {
    return str.slice(0, length);
  }

  return generateRandomString(length, str);
}

function generateRandomPassword(passLength = 12) {
  let password = generateRandomString(passLength);
  _.range(0, _.random(0, passLength - 1)).forEach(() => {
    const pos = _.random(0, passLength - 1);
    const up = password[pos].toUpperCase();
    password = password.slice(0, pos) + up + password.slice(pos + 1);
  });

  return password;
}

function loadInstallations(state: State, orgId: string) {
  const result = _.filter(state.installationsAll, { orgId: orgId });
  return msg("LoadInstallationsSuccess", result);
}

async function save(data: Data) {
  try {
    const result = await resourceManager.saveUser(data);
    if (result && "errors" in result) {
      return msg("SaveFailed", result.errors);
    }
    return msg("SaveSuccess");
  } catch (err) {
    console.error("saving data failed", err);
    return msg("SaveFailed", {});
  }
}

async function loadData(orgId: string) {
  try {
    const result = await resourceManager.createUserPage(orgId);
    return msg("LoadDataSuccess", result);
  } catch (err) {
    console.error("loading data failed", err);
    return msg("LoadDataFailed");
  }
}

function view(state: State) {
  if (state.isLoading) {
    return (
      <>
        <form className={"fib-create-user"}>
          <div class={"loader-big"} />
        </form>
      </>
    );
  }

  return (
    <form class={"fib-create-user"} onSubmit={(e: any) => e.preventDefault()}>
      <div
        className={"button-tertiary h100 square-button close-button"}
        onClick={() => msg("Cancel")}
      >
        <i className={"icon-cross"} />
      </div>

      <h1 class={"h500 modal-title"}>{tr("fibCreateUser.title")}</h1>

      <div class={"grid center"}>
        <div class={"box4"}>
          <>
            {...match<Step, (v.View<Msg> | false)[]>(state.step)
              .with("Data", () => dataStepView(state))
              .with("License", () => licenseStepView(state))
              .with("Installations", () =>
                userInstallations<Msg>({
                  isDisabled: state.isSaving,
                  selectedOrgId: state.selectedOrgId,
                  orgs: state.orgs, // all orgs
                  data: state.data, // all data inputed by user in modal forms
                  realData: {},
                  errors: state.errors,
                  installations: state.installationsByOrg,
                  allInstallations: state.installationsAll,
                  changeSelectedOrgMsg: (val) => msg("ChangeSelectedOrg", val as string),
                  updateInstallationsMsg: (val) => msg("Input", "installations", val),
                  isLoading: state.areInstallationsLoading,
                }))
              .exhaustive()}
          </>
        </div>
      </div>
      <div class={"actions hbox"}>
        <div
          class={"button-tertiary h300"}
          onClick={() => msg(state.step === "Data" ? "Cancel" : "PreviousStep")}
        >
          {state.step === "Data" ? tr("general.cancel") : tr("general.previous")}
        </div>
        <div
          className={`button-primary h300 ${state.isSaving ? "disabled" : ""}`}
          onClick={() => msg(state.step === "Installations" ? "Save" : "NextStep")}
        >
          {state.step === "Installations" ? tr("fibCreateUser.create") : tr("general.next")}
        </div>
        {state.isSaving && <div class={"loader-mini"} />}
      </div>
    </form>
  );
}

function licenseStepView(state: State): v.View<Msg>[] {
  return [
    v(
      ".container.full-width",
      ...userLicenseSwitch<Msg>({
        isDisabled: state.isSaving,
        data: state.data,
        errors: state.errors,
        realData: {},
        activateMsg: () => msg("ActivateLicense"),
        deactivateMsg: () => msg("Input", "status", "inactive"),
        dateChangeMsg: (date: number) => msg("Input", "validUntil", date),
      }),
    ),
    v(
      ".container.full-width",
      ...userLicenseType<Msg>({
        isDisabled: state.isSaving,
        showAllOptions: state.basePanel === "admin" || false, //state.accessType == 'admin', from techpanel only manager/guest role can be created
        data: state.data,
        errors: state.errors,
        realData: {},
        isHome: state.accessType === "admin_home" ? true : false,
      }),
    ),
  ];
}

function dataStepView(state: State): v.View<Msg>[] {
  return [
    v(
      ".container.full-width",
      ...userGeneralInfo<Msg>({
        isDisabled: state.isSaving,
        isDisabledEmail: state.isSaving,
        data: state.data,
        errors: state.errors,
        realData: {},
        changeSelectedLangMsg: (val) => msg("ChangeSelectedLang", val as string),
      }),
    ),
    v(
      ".container",
      checkboxElement({
        field: "sendChangePasswordEmail",
        label: "fibCreateUser.sendChangePasswordEmail",
        data: state.data,
        realData: {},
        errors: state.errors,
        isDisabled: state.isSaving,
      }),
      !state.data.sendChangePasswordEmail && textElement({
        field: "password",
        label: "identity.password",
        size: "small",
        realData: {},
        data: state.data,
        errors: state.errors,
        isDisabled: state.isSaving,
        rightSlot: v(
          ".button-secondary.h100",
          { onClick: msg("GeneratePassword") },
          tr("general.generate"),
        ),
      }),
      !state.data.sendChangePasswordEmail && checkboxElement({
        field: "sendDetailsEmail",
        label: "fibCreateUser.sendLoginDetails",
        data: state.data,
        realData: {},
        errors: state.errors,
        isDisabled: state.isSaving,
      }),
    ),
  ];
}
