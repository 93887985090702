import _ from 'lodash';
import { v } from "./v";
import { stm } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';
import type { InstallationStored } from 'nexus/node/InstallationDetailsAccess';

type Msg
  = [type: 'AttributeChanged', name: string, value: unknown]
  | [type: 'AddInstallationRequest']
  | [type: 'AddInstallation', installationId: string]
  | [type: 'RemoveInstallation', installationId: string]

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  installations: InstallationStored[];
  allInstallations: InstallationStored[];
  disabled: boolean;
  isAddSelectVisible: boolean;
  limit: number;

  installationByOrg: InstallationStored[];
}

stm.component({
  tagName: 'fib-edit-installations-list',
  shadow: false,
  debug: false,
  propTypes: {
    installations: Array,
    allInstallations: Array,
    installationsByOrg: Array,
    disabled: Boolean,
    limit: Number
  },
  attributeChangeFactory: (name, value) => msg('AttributeChanged', name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        installations: [],
        allInstallations: [],
        disabled: false,
        isAddSelectVisible: false,
        limit: Infinity,

        installationByOrg: [],
      },
      null
    ];
  },
  update,
  view
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(['AttributeChanged', 'installations', P.select()], installations => {

      const changedList: InstallationStored[] = [];

      if (state.installations.length > 0) {
        (installations as InstallationStored[]).forEach(instal => {
          changedList.push(state.installations.find(instalList => {
            return instal._id === instalList._id
          }) as InstallationStored);
        })

        if (changedList[0] !== undefined) {
          state.installations = _.uniq([
            ...changedList
          ]);
        }
      } else {
        state.installations = installations as InstallationStored[];
      }

      return [state, null];
    })
    .with(['AttributeChanged', 'allInstallations', P.select()], allInstallations => [

      {
        ...state,
        allInstallations: allInstallations as InstallationStored[],
        isAddSelectVisible: false,
      },
      null
    ])
    .with(['AttributeChanged', 'installationsByOrg', P.select()], installationByOrg => [
      {
        ...state,
        installationByOrg: installationByOrg as InstallationStored[]
      },
      null
    ])
    .with(['AttributeChanged', 'limit', P.select()], limit => [
      { ...state, limit: limit as number },
      null
    ])
    .with(['AttributeChanged', 'disabled', P.select()], disabled => [
      { ...state, disabled: !!disabled || disabled !== 'false' },
      null
    ])
    .with(['AttributeChanged', P._, P._], () => [state, null])
    .with(['AddInstallationRequest'], () => [
      { ...state, isAddSelectVisible: true },
      null
    ])
    .with(['AddInstallation', P.select()], instaId => {
      const installation = state.allInstallations.find(insta => insta._id === instaId);

      if (!installation) {
        return [state, null];
      }

      state.installations = _.uniq([...state.installations, installation]);
      state.isAddSelectVisible = false;

      return [
        state,
        new CustomEvent('update', { bubbles: true, detail: state.installations })
      ]
    })
    .with(['RemoveInstallation', P.select()], instaId => {
      state.installations = state.installations.filter(insta => insta._id !== instaId);

      return [
        state,
        new CustomEvent('update', { bubbles: true, detail: state.installations })
      ]
    })
    .exhaustive();
}

function view(state: State) {
  const availableInstallations = state.installationByOrg.filter(instAll => {
    return !state.installations.find(iterInstalls => iterInstalls._id === instAll._id);
  })

  return v<Msg>('.fib-edit-installations-list',
    v('ul.list.margins',
      ...state.installations.map(insta => v<Msg>('li.well.body-large.hbox',
        v.span(insta.name),
        v('.text-button-primary.list-action',
          { onClick: msg('RemoveInstallation', insta._id) },
          v('i.icon-trash')
        )
      ))
    ),
    state.isAddSelectVisible && v('select.body-medium.full-width', {
      onInput: (event: any) => msg('AddInstallation', event.target.value),
      disabled: state.disabled
    },
      v.option(),
      ...state.installationByOrg
        .filter(insta => {
          return !state.installations.find(iterator => iterator._id === insta._id);
        })
        .map(insta => v.option<Msg>(
          { value: insta._id },
          insta.name
        ))
    ),
    !state.isAddSelectVisible
    && availableInstallations.length > 0
    && state.installations.length < state.limit
    && v('button.button-secondary.square-button.h100', {
      onClick: (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        return msg('AddInstallationRequest')
      },
      disabled: state.disabled
    },
      v('i.icon-plus')
    )
  );
}
