import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { DoughnutSlotConfig, DoughnutSlotData } from "nexus/node/contracts";

type Msg = [type: "Attr", name: string, value: unknown];

interface State {
  doughnutSlotConfig?: DoughnutSlotConfig;
  doughnutSlotData?: DoughnutSlotData;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-inverters-dough-slot",
  shadow: false,
  debug: false,
  propTypes: {
    doughnutSlotConfig: Object,
    doughnutSlotData: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      doughnutSlotConfig: {
        type: "doughnut",
        order: 0,
        title: "title",
        subtitle: "",
        prop: "",
        deviceKind: "",
        deviceMarkers: [],
        unit: "",
        color: "",
      },
      doughnutSlotData: [
        {
          label: "",
          backgrounds: [],
          data: [],
        },
      ],
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "doughnutSlotConfig", P.select()], (doughnutSlotConfig: any) => {
      if (doughnutSlotConfig.hasOwnProperty("prop")) {
        state.doughnutSlotConfig = doughnutSlotConfig as DoughnutSlotConfig;
      }
      return [state, null];
    })
    .with(["Attr", "doughnutSlotData", P.select()], (doughnutSlotData: any) => {
      if (doughnutSlotData.hasOwnProperty("backgrounds")) {
        state.doughnutSlotData = doughnutSlotData as DoughnutSlotData;
      }
      if (doughnutSlotData === "" || state.doughnutSlotData.length < 1) {
        flashMessage(tr("fibAdminInstallation.loadDataFailed"), "error");
      }
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .exhaustive();
}

function view(state: State) {
  if (state.doughnutSlotData.length < 1 || state.doughnutSlotConfig.length < 1) {
    return <></>;
  }

  if (!(state.doughnutSlotData.hasOwnProperty("data"))) {
    return <></>;
  }

  return (
    <div className="mgr-dash-data-slot mgr-dash-data-inverters-dough body-large">
      <>
        {state.doughnutSlotData.data.map((inverter, index) => {
          const hasAlerts = "alerts" in state.doughnutSlotData.data[index] &&
            state.doughnutSlotData.data[index].length;
          const noProduction = inverter.value === 0;
          let name = "";
          // if (inverter.deviceSn.includes("inverter")) {
          name = tr("fib.dash.template.inverter");
          // }
          // if (inverter.deviceSn.includes("meter")) {
          //   name = tr("fib.dash.template.meter");
          // }
          // if (inverter.deviceSn.includes("plc")) {
          //   name = tr("fib.dash.template.plc");
          // }

          return (
            <div className="mgr-dash-inverter-wrapper">
              {/* <span className={"nowrap uppercase"}>{name} {index + 1}</span> */}

              <div
                class={hasAlerts ? "mgr-dash-inverter-nok" : "mgr-dash-inverter-ok"}
                style={`background-color:${state.doughnutSlotData.backgrounds[index]}`}
              >
              </div>
              <span className={noProduction ? "mgr-dash-inverter-noproduction" : ""}>
                {inverter.label}[ {inverter.value} kW ]
              </span>
              <div class={hasAlerts ? "mgr-dash-inverter-nok-dough" : ""}></div>
            </div>
          );
        })}
      </>
    </div>
  );
}
