import _ from "lodash";
import { flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { User, UserErrors } from "nexus/node/ResourceManager";
import { itemTopBar, nextYear } from "./view-utils";
import {
  userGeneralInfo,
  userInstallations,
  userLicenseSwitch,
  userLicenseType,
} from "./user-elements";
import { Organization } from "nexus/node/OrganizationAccess";
import { InstallationStored } from "nexus/node/InstallationDetailsAccess";
import { AvailableTransport, LicenseType } from "nexus/node/contracts";

/* component for user edit in admin/technician panel */

const resourceManager = grow.plant("ResourceManager");

const security = grow.plant("Security");

type Data = Partial<User>;
type Errors = UserErrors;
type LoadData = any;

type Msg =
  | [type: "AttributeChanged", name: string, value: unknown]
  | [type: "LoadDataSuccess", data: LoadData]
  | [type: "LoadDataFailure"]
  | [type: "ChangeSelectedOrg", selectedOrgId: string]
  | [type: "ChangeSelectedLang", selectedLang: string]
  | [type: "Input", field: string, value: unknown]
  | [type: "ActivateLicense"]
  | [type: "Remove"]
  | [type: "RemoveCancelled"]
  | [type: "RemoveFailed"]
  | [type: "RemoveSuccess"]
  | [type: "ResetPassConfirmation", email: string]
  | [type: "ResetPassCancelled"]
  | [type: "ResetPassSuccess"]
  | [type: "ResetPassFailed"]
  | [type: "Save"]
  | [type: "SaveFailed", errors: Errors]
  | [type: "SaveSuccess", data: LoadData]
  | [type: "UnsavedChanges"];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  isLoading: boolean;
  isSaving: boolean;
  user?: User;
  data: Data;
  errors: Errors;
  selectedOrgId: string;
  selectedLang: string;
  orgs: Organization[];
  installations: InstallationStored[];
  installationsAll: InstallationStored[];
  panel: string;
  editorLicenseType: LicenseType;
}

stm.component({
  tagName: "fib-user-edit",
  shadow: false,
  debug: false,
  propTypes: {
    userId: String,
    basePanel: String,
  },
  willUnmount: () => {
    window.onbeforeunload = () => null;
  },
  attributeChangeFactory: (name, value) => msg("AttributeChanged", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        isSaving: false,
        data: {},
        errors: {},
        panel: "technician",
        orgs: [],
        installations: [],
        selectedOrgId: "",
        selectedLang: "pl",
        installationsAll: [],
        editorLicenseType: "technician",
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChanged", "userId", P.select()], (userId) => [
      { ...state, isLoading: true },
      loadData(userId as string),
    ])
    .with(["AttributeChanged", "basePanel", P.select()], (panel) => [
      { ...state, panel: panel as string },
      null,
    ])
    .with(["AttributeChanged", P._, P._], () => [state, null])
    .with(["LoadDataSuccess", P.select()], (loadedData: any) => {
      loadedData.user.notifyBySMS = (loadedData.user.notificationType as AvailableTransport[])
        .includes(
          "sms",
        );
      loadedData.user.notifyByEmail = (loadedData.user.notificationType as AvailableTransport[])
        .includes(
          "email",
        );
      return [
        {
          ...state,
          isLoading: false,
          user: loadedData.user,
          orgs: loadedData.orgs,
          selectedOrgId: loadedData.selectedOrgId,
          selectedLang: loadedData.user.lang,
          installationsAll: loadedData.filteredInstallations,
          installations: _.filter(loadedData.filteredInstallations, {
            orgId: loadedData.selectedOrgId,
          }),
          editorLicenseType: loadedData.licenseType,
        },
        null,
      ];
    })
    .with(["LoadDataFailure"], () => {
      flashMessage.error(tr("general.loadFailed"));
      return [state, null];
    })
    .with(
      ["Save"],
      () => [{ ...state, isSaving: true }, save(state.user, state.data)],
    )
    .with(["SaveFailed", P.select()], (errors) => {
      flashMessage.error(tr("general.saveFailed"));
      return [
        { ...state, isSaving: false, errors },
        null,
      ];
    })
    .with(["SaveSuccess", P.select()], (loadedData: any) => {
      loadedData.user.notifyBySMS = (loadedData.user.notificationType as AvailableTransport[])
        .includes(
          "sms",
        );
      loadedData.user.notifyByEmail = (loadedData.user.notificationType as AvailableTransport[])
        .includes(
          "email",
        );
      return [
        {
          ...state,
          isSaving: false,
          errors: {},
          data: {},
          user: loadedData.user,
          orgs: loadedData.orgs,
          selectedOrgId: loadedData.selectedOrgId,
          selectedLang: loadedData.user.lang,
        },
        null,
      ];
    })
    .with(["ChangeSelectedOrg", P.select()], (selectedOrgId) => {
      state.selectedOrgId = selectedOrgId;
      state.installations = _.filter(state.installationsAll, {
        orgId: selectedOrgId,
      });
      return [state, null];
    })
    .with(["ChangeSelectedLang", P.select()], (selectedLang) => {
      state.selectedLang = selectedLang;
      state.data.lang = selectedLang;
      return [state, unsavedChanges()];
    })
    .with(["Input", "licenseType", P.select()], (licenseType: any) => {
      state.data.licenseType = licenseType;
      const installationIds = (state as any).data.installationIds ||
        state.user?.installationIds || [];
      if (licenseType === "manager" && installationIds.length > 1) {
        state.data.installationIds = [installationIds[0]];
        state.installations = [installationIds[0]];
        (state as any).user.installationIds = [installationIds[0]];
      } else {
        state.data.installationIds = state.user?.installationIds;
      }
      return [state, unsavedChanges()];
    })
    .with(
      ["Input", P.select("field"), P.select("value")],
      ({ field, value }) => {
        _.set(state.data, field, value);
        delete (state as any).errors[field];
        return [state, unsavedChanges()];
      },
    )
    .with(["Remove"], () => {
      if (!state.user) {
        return [state, null];
      }
      return [
        { ...state, isRemoving: true },
        remove(state.user),
      ];
    })
    .with(["RemoveCancelled"], () => [
      { ...state, isRemoving: false },
      null,
    ])
    .with(["RemoveFailed"], () => {
      flashMessage.error(tr("general.removingFailed"));
      return [
        { ...state, isRemoving: false },
        null,
      ];
    })
    .with(["RemoveSuccess"], () => {
      router.navigate(
        state.panel === "admin" ? "adminListUsers" : "techInstallsUsers",
      );

      return [
        { ...state, isRemoving: false },
        null,
      ];
    })
    .with(["ResetPassConfirmation", P.select()], (email) => {
      return [state, resetPass(email)];
    })
    .with(["ResetPassCancelled"], () => [state, null])
    .with(["ResetPassSuccess"], () => {
      flashMessage.success(tr("fibAdminUser.resetPassConfirm"));
      return [state, null];
    })
    .with(["ResetPassFailed"], () => {
      flashMessage.error(tr("secRemindPasswordForm.initFailed"));
      return [state, null];
    })
    .with(["ActivateLicense"], () => {
      state.data.validUntil = nextYear().getTime();
      state.data.status = "active";
      return [state, null];
    })
    .with(["UnsavedChanges"], () => {
      window.onbeforeunload = () => !_.isEmpty(state.data) ? "" : null;
      return [state, null];
    })
    .with(P._, () => {
      window.onbeforeunload = () => !_.isEmpty(state.data) ? "" : null;
      return [state, null];
    }) // its workaround to issue #206
    .exhaustive();
}

function unsavedChanges() {
  return msg("UnsavedChanges");
}

async function loadData(userId: string) {
  try {
    const result = await resourceManager.editUserPage(userId);
    return msg("LoadDataSuccess", result);
  } catch (err) {
    console.error("loading data failed", err);
    return msg("LoadDataFailure");
  }
}

async function resetPass(email: string): Promise<Msg> {
  const result = await modal.confirm({
    text: tr("secRemindPasswordForm.confirmPassReset", { email }),
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  if (!result) {
    return msg("ResetPassCancelled");
  }

  try {
    await security.initiateRemindPassword(email);
    return msg("ResetPassSuccess");
  } catch (err) {
    console.error("initiating remind password failed", err);
    return msg("ResetPassFailed");
  }
}

async function remove(user: User) {
  const confirmed = await modal.confirm({
    title: tr("general.confirm", {
      name: user.firstName + " " + user.lastName,
    }),
    text: tr("fibAdminUser.confirmRemoval", user as any),
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  if (!confirmed || !user.id) {
    return msg("RemoveCancelled");
  }

  try {
    await resourceManager.removeUser(user.id);
    return msg("RemoveSuccess");
  } catch (err) {
    console.error("removing failed", err);
    return msg("RemoveFailed");
  }
}

async function save(user: User | undefined, data: Record<string, unknown>) {
  if (!user) {
    return msg("SaveFailed", {});
  }

  const notificationType: AvailableTransport[] = [];
  if ("notifyByEmail" in data) {
    data.notifyByEmail === true && notificationType.push("email");
  } else {
    (user.notificationType as AvailableTransport[]).includes("email") &&
      notificationType.push("email");
  }
  if ("notifyBySMS" in data) {
    data.notifyBySMS === true && notificationType.push("sms");
  } else {
    (user.notificationType as AvailableTransport[]).includes("sms") &&
      notificationType.push("sms");
  }

  data.notificationType = notificationType;

  try {
    const modules = {
      ...user.modules,
      ...(data.modules as {}),
    };

    const result = await resourceManager.saveUser({
      ...user,
      ...data,
      modules,
      id: user.id,
    });
    if ("errors" in result) {
      return msg("SaveFailed", result.errors);
    }

    return msg("SaveSuccess", result);
  } catch (err) {
    console.error("saving data failed", err);
    return msg("SaveFailed", {});
  }
}

function view(state: State) {
  if (state.isLoading || !state.user) {
    return (
      <div class="fib-tech-installs-users">
        <div class="loader-big"></div>
      </div>
    );
  }
  return (
    <>
      {topBar(state)}
      {userDetails(state)}
    </>
  );
}

function topBar(state: State) {
  return (
    <div class="fib-tech-user-details vbox center">
      {itemTopBar({
        goBackUrl: router.getRouteUrl(
          state.panel === "admin" ? "adminListUsers" : "techInstallsUsers",
        ),
        goBackLabel: "fibAdminPanel.navUsers",
        isSaveEnabled: Object.keys(state.data).length > 0 &&
          !state.isSaving &&
          Object.keys(state.errors).length === 0,
        isSaving: state.isSaving,
        title: state.user?.firstName + " " + state.user?.lastName,
      })}
    </div>
  );
}

function userDetails(state: State) {
  return (
    <>
      <div class="grid title-container">
        <h1 class="box12 h600">{tr("general.mainInfo")}</h1>
      </div>
      <div class="grid">
        <div class="box4">
          <div class="container">
            {userGeneralInfo<Msg>({
              isDisabled: state.isSaving,
              isDisabledEmail: true,
              realData: state.user,
              data: state.data,
              errors: state.errors,
              changeSelectedLangMsg: (val) => msg("ChangeSelectedLang", val as string),
            })}
          </div>
          <div class="container">
            <div
              class="button-secondary h100"
              onClick={msg(
                "ResetPassConfirmation",
                state.user?.email as string,
              ) as any}
            >
              {tr("fibAdminUser.resetPassword")}
            </div>
          </div>
          <div class="button-tertiary h100" onClick={msg("Remove") as any}>
            <i class="icon-trash"></i>
            <span>{tr("fibAdminUser.removeUser")}</span>
          </div>
        </div>
        <div class="box4">
          <h3 class="h400 subsection-title">{tr("fibAdminUser.license")}</h3>
          <div class="container">
            {userLicenseSwitch<Msg>({
              isDisabled: state.isSaving,
              data: state.data,
              errors: state.errors,
              realData: state.user,
              activateMsg: () => msg("ActivateLicense"),
              deactivateMsg: () => msg("Input", "status", "inactive"),
              dateChangeMsg: (date: number) => msg("Input", "validUntil", date),
            })}
          </div>
          <div class="container">
            {userLicenseType<Msg>({
              isDisabled: state.isSaving,
              showAllOptions: state.panel === "admin" || false,
              data: state.data,
              errors: state.errors,
              realData: state.user,
              isHome: state.editorLicenseType === "admin_home" ? true : false,
            })}
          </div>
        </div>
        <div class="box4">
          <h3 class="h400 subsection-title">
            {tr("access.subordinateInstallations")}
          </h3>
          <>
            {userInstallations<Msg>({
              isDisabled: state.isSaving,
              selectedOrgId: state.selectedOrgId,
              orgs: state.orgs,
              data: state.data,
              realData: state.user,
              errors: state.errors,
              installations: state.installations,
              allInstallations: state.installationsAll,
              changeSelectedOrgMsg: (val) => msg("ChangeSelectedOrg", val as string),
              updateInstallationsMsg: (val) => msg("Input", "installationIds", val),
              isLoading: false,
            })}
          </>
        </div>
      </div>
    </>
  );
}
