import _ from "lodash";
import * as realtime from "./realtime";
import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { UserState } from "nexus/node/MetricsManager";

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "SelectInstallation", installationId: string]
  | [type: "LoadScriptFailed"]
  | [type: "None"]
  | [type: "LoadScriptSuccess", scriptContent: string]
  | [type: "Input", scriptContent: string]
  | [type: "SaveScript"]
  | [type: "SaveFailed"];

interface State {
  userState?: UserState;
  installationId?: string;
  isLoading: boolean;
  isSaving: boolean;
  canBeSave: boolean;
  scriptContent?: string;
}

const msg = (...args: Msg): Msg => args;

const installationManager = grow.plant("InstallationManager");

stm.component({
  tagName: "fib-runtime-scripts",
  shadow: false,
  debug: false,
  propTypes: {
    userState: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        isSaving: false,
        canBeSave: false
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg, cmp: any, dispatch: stm.Dispatch<Msg>) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "userState", P.select()], (userState) => {
      state.userState = userState as UserState;
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["SelectInstallation", P.select()], (installationId) => {
      if (!installationId) {
        return [state, null];
      }
      state.installationId = installationId;
      state.isLoading = true;
      state.canBeSave = false;
      const instDetails = state.userState.installations.find((obj) => obj.id === installationId);
      return [state, loadScript(instDetails.machineId)];
    })
    .with(["LoadScriptFailed"], () => {
      flashMessage.error(tr("general.loadFailed"));
      state.isLoading = false;
      state.scriptContent = "";
      return [state, null];
    })
    .with(["LoadScriptSuccess", P.select()], (scriptContent) => {
      state.isLoading = false;
      state.isSaving = false;
      state.scriptContent = scriptContent;
      state.canBeSave = false;
      return [state, null];
    })
    .with(["Input", P.select()], (scriptContent) => {
      state.scriptContent = scriptContent;
      state.canBeSave = true;
      return [state, null];
    })
    .with(["SaveScript"], () => {
      if (state.isSaving) {
        return [state, null];
      }
      state.isSaving = true;
      const instDetails = state.userState.installations.find((obj) =>
        obj.id === state.installationId
      );
      return [state, saveScript(instDetails.machineId ?? "", state.scriptContent ?? "")];
    })
    .with(["SaveFailed"], () => {
      flashMessage.error(tr("general.saveFailed"));
      state.isSaving = false;
      return [state, null];
    })
    .with(["None"], () => {
      state.isSaving = false;
      state.canBeSave = false;
      return [state, null];
    })
    .exhaustive();
}

async function saveScript(installationId: string, scriptContent: string) {
  try {
    const result = await installationManager.replaceScript(installationId, scriptContent);
    return msg("None");
  } catch (err) {
    console.error("requesting script failed", err);
    return msg("LoadScriptFailed");
  }
}

async function loadScript(installationId: string) {
  try {
    const result = await installationManager.showScript(installationId);
    if (!result) {
      return msg("LoadScriptFailed");  
    }
    return msg("LoadScriptSuccess", result);
  } catch (err) {
    console.error("requesting script failed", err);
    return msg("LoadScriptFailed");
  }
}

function view(state: State): stm.View<Msg> {
  return (
    <div class="fib-runtime-scripts">
      <h2 class="h400">{tr("fibRuntimeScripts.title")}</h2>

      <div class="hbox op-panel">
        <select
          class={"body-medium"}
          disabled={state.isLoading}
          onChange={(e: Event) => msg("SelectInstallation", (e.target as HTMLSelectElement).value)}
        >
          <option value={""}>{tr("fib.advanced.scriptUploadOption")}</option>
          <>
            {state.userState?.installations.map((elem) => {
              return <option value={elem.id}>{elem.name}</option>;
            })}
          </>
        </select>

        <div>
          {state.isSaving && <div class="loader-mini"></div>}
          {!state.isLoading && state.scriptContent !== undefined && (
            <button
              class="button-primary"
              disabled={state.isSaving || !state.canBeSave}
              onClick={msg("SaveScript") as any}
            >
              {tr("general.saveChanges")}
            </button>
          )}
        </div>
      </div>
      <div class="vbox">
        {state.isLoading && <div class="loader-big"></div>}
        {!state.isLoading && state.scriptContent !== undefined && (
          <textarea
            class="script-editor"
            value={state.scriptContent}
            spellcheck={false}
            onInput={(event: any) => msg("Input", event.target.value)}
          >
          </textarea>
        )}
      </div>
    </div>
  );
}
