import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default function makeLog(ns, forceDebugMode = false) {
  return function (msg, ...args) {
    if (window.debug || forceDebugMode) {
      console.log(ns + ": " + msg, ...args);
    }
  };
}

const log = makeLog("fib-doughnut-chart");
const backgroundColors = [
  "rgba(11,180,255,.7)",
  "rgba(230,0,73,.7)",
  "rgba(80,233,145,.7)",
  "rgba(232,217,0,.7)",
  "rgba(155,25,245,.7)",
  "rgba(255,163,0,.7)",
  "rgba(220,10,180,.7)",
  "rgba(179,212,255,.7)",
  "rgba(0,191,160,.7)",
  "rgba(234,94,69,.7)",
  "rgba(100,181,234,.7)",
  "rgba(143,210,67,.7)",
  "rgba(229,202,85,.7)",
  "rgba(179,61,198,.7)",
  "rgba(239,155,32,.7)",
  "rgba(244,106,155,.7)",
  "rgba(155,186,236,.7)",
  "rgba(0,191,111,.7)",
  "rgba(237,191,51,.7)",
  "rgba(189,207,50,.7)",
];
// const borderColors = [
//   "rgb(11,180,255)",
//   "rgb(230,0,73)",
//   "rgb(80,233,145)",
//   "rgb(232,217,0)",
//   "rgb(155,25,245)",
//   "rgb(255,163,0)",
//   "rgb(220,10,180)",
//   "rgb(179,212,255)",
//   "rgb(0,191,160)",
//   "rgb(234,94,69)",
//   "rgb(100,181,234)",
//   "rgb(143,210,67)",
//   "rgb(229,202,85)",
//   "rgb(179,61,198)",
//   "rgb(239,155,32)",
//   "rgb(244,106,155)",
//   "rgb(155,186,236)",
//   "rgb(0,191,111)",
//   "rgb(237,191,51)",
//   "rgb(189,207,50)",
// ];

class FibDoughnutChart extends HTMLElement {
  static get observedAttributes() {
    return ["source", "config"];
  }

  constructor() {
    super();
    this.myAttrs = {};
    this.total = 0;
    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          this.syncSeries();
        }
      }
    });
    this.onValuesChange = this.onValuesChange.bind(this);
  }

  onValuesChange() {
    this.syncSeries();
  }

  attributeChangedCallback(attrName, _previous, current) {
    if (attrName === "source" || attrName === "config") {
      try {
        this.myAttrs[attrName] = JSON.parse(current);
      } catch (error) {
        // console.log("cant process source data! ", attrName);
      }
    }
    this.syncSeries();
  }

  connectedCallback() {
    this.addEventListener("values", this.onValuesChange);
    this.observer.observe(this, { childList: true });

    // create an element
    if (this.querySelector("canvas")) {
      return;
    }
    this.canvas = document.createElement("canvas");
    this.canvas.id = "doughnut-chart";
    this.container = document.createElement("div");
    this.container.style = "position: relative; height: 50vh; padding: 0.5em;";
    this.container.append(this.canvas);
    this.append(this.container);

    // create a chart
    const dataSetup = {
      labels: [],
      datasets: [{
        label: "loading",
        data: [{ value: 1, label: "total" }],
        backgroundColor: backgroundColors,
        hoverOffset: 9,
      }],
      total: 1,
    };

    const doughnutLabel = {
      id: "doughnutLabel",
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, data } = chart;
        ctx.save();
        const xCoor = chart.getDatasetMeta(0).data[0].x;
        const yCoor = chart.getDatasetMeta(0).data[0].y;
        ctx.font = "bold 30px sans-serif";
        ctx.fillStyle = "rgba(54,162,235,1)";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          `${data.total.toFixed(2)} kW`,
          xCoor,
          yCoor,
        );
      },
    };

    const config = {
      type: "doughnut",
      data: dataSetup,
      options: {
        cutout: "70%",
        animation: { animateRotate: true },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                var label = context.dataset.data[context.dataIndex].label;
                var value = context.formattedValue;
                return label + " : " + value + " kW";
              },
            },
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "ActivePower",
            color: "rgba(54,162,235,1)",
            font: {
              size: 20,
            },
          },
        },
        responsive: true,
        events: ["click", "mousemove"],
      },
      plugins: [doughnutLabel],
    };

    const ctx = this.canvas.getContext("2d");
    this.chart = new Chart(ctx, config);
  }

  disconnectedCallback() {
    this.observer.disconnect();
    this.removeEventListener("values", this.onValuesChange);
    delete this.chart;
    this.removeChild(this.container);
    delete this.canvas;
    delete this.container;
  }

  syncSeries() {
    if (!this.chart) {
      return;
    }

    const incomingData = this.myAttrs["source"].data;
    const valueInMiddle = incomingData.find((l) => l.label === "total").value;
    const filteredData = incomingData.filter((d) => d.label !== "total");
    this.chart.data.datasets[0].data = filteredData;
    this.chart.data.total = valueInMiddle;
    const configData = this.myAttrs["config"];
    this.chart.config.options.plugins.title.text =
      this.myAttrs["config"] && "title" in this.myAttrs["config"]
        ? this.myAttrs["config"].title
        : "";
    this?.chart.update();
    return;
  }
}

const isElementDefined = customElements.get("fib-doughnut-chart");

if (!isElementDefined) {
  customElements.define("fib-doughnut-chart", FibDoughnutChart);
}
