import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { DoughnutSlotConfig, DoughnutSlotData } from "nexus/node/contracts";

type Msg = [type: "Attr", name: string, value: unknown];

interface State {
  doughnutSlotConfig?: DoughnutSlotConfig;
  doughnutSlotData?: DoughnutSlotData;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-doughnut-chart",
  shadow: false,
  debug: false,
  propTypes: {
    doughnutSlotConfig: Object,
    doughnutSlotData: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      doughnutSlotConfig: {
        type: "doughnut",
        order: 1,
        title: "",
        subtitle: "",
        prop: "",
        deviceKind: "",
        deviceMarkers: [],
        unit: "",
        color: "",
      },
      doughnutSlotData: {
        label: "",
        backgrounds: [],
        data: [{ value: 1, label: "" }],
      },
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "doughnutSlotConfig", P.select()], (cfg: any) => {
      if (cfg.hasOwnProperty("prop")) {
        state.doughnutSlotConfig = cfg as DoughnutSlotConfig;
      }
      return [state, null];
    })
    .with(["Attr", "doughnutSlotData", P.select()], (slotData: any) => {
      if (slotData.hasOwnProperty("data") && slotData.data.length > 0) {
        const dataRecalculated: { value: number; label: string }[] = [];
        const total = slotData.data.reduce((acc, currentObj) => acc + currentObj.value, 0);
        dataRecalculated.push({ value: total > 0 ? total : 1, label: "total" });
        dataRecalculated.push(...slotData.data);

        const dataToSend = {
          ...slotData,
          data: dataRecalculated,
        };

        state.doughnutSlotData = JSON.stringify(dataToSend as DoughnutSlotData);
      }
      if (slotData === "" || state.doughnutSlotData.length < 1) {
        flashMessage(tr("fibAdminInstallation.loadDataFailed"), "error");
      }
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .exhaustive();
}

function view(state: State) {
  if (
    !state.doughnutSlotData ||
    state.doughnutSlotData.length < 1 ||
    !state.doughnutSlotConfig || state.doughnutSlotConfig.length < 1
  ) {
    return <></>;
  }
  return (
    <fib-doughnut-chart
      source={state.doughnutSlotData}
      config={JSON.stringify(state.doughnutSlotConfig)}
    >
    </fib-doughnut-chart>
  );
}
