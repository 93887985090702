import { v } from "./v";
import { stm, tr } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';

type Msg
  = [type: 'Submit']
  | [type: 'OldPasswordUpdate', value: string]
  | [type: 'PasswordUpdate', value: string]
  | [type: 'RePasswordUpdate', value: string]
  | [type: 'Failed', reason: string]
  | [type: 'ChangeSuccess']
  | [type: 'Cancel']

type State = {
  oldPassword: string
  password: string;
  rePassword: string;
  error: string;
  isLoading: boolean;
};

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: 'sec-profile-password-change-form',
  shadow: false,
  debug: false,
  init: () => [
    {
      oldPassword: '',
      password: '',
      rePassword: '',
      error: '',
      isLoading: false
    },
    null
  ],
  update: (state: State, msg: Msg) => {
    return match<Msg, [State, stm.Cmd<Msg>]>(msg)
      .with(['OldPasswordUpdate', P.select()], value => [
        { ...state, oldPassword: value },
        null
      ])
      .with(['PasswordUpdate', P.select()], value => [
        { ...state, password: value },
        null
      ])
      .with(['RePasswordUpdate', P.select()], value => [
        { ...state, rePassword: value },
        null
      ])
      .with(['Submit'], () => {
        if (state.isLoading) {
          console.warn('submit is already in progress');
          return [state, null];
        }
        return [
          { ...state, isLoading: true },
          changePasswordRequest(state)
        ]
      })
      .with(['Failed', P.select()], error => [
        { ...state, isLoading: false, error },
        null
      ])
      .with(['ChangeSuccess'], () => [
        { ...state, isLoading: false },
        new CustomEvent('saved')
      ])
      .with(['Cancel'], () => [
        state,
        new CustomEvent('cancel')
      ])
      .exhaustive();
  },
  view
});

async function changePasswordRequest(state: State): Promise<Msg> {
  try {
    const result = await grow.plant('Security').changePassword(
      { oldPassword: state.oldPassword },
      state.password,
      state.rePassword
    );
    if (result.err) {
      return msg('Failed', result.err);
    }
    return msg('ChangeSuccess');
  } catch (err: any) {
    console.error('changing password failed', err);
    return msg('Failed', err.message);
  }
}

function view(state: State) {
  return v<Msg>('.sec-profile-password-change-form',
    v.form({
      onSubmit: event => {
        event.preventDefault();
        return msg('Submit')
      },
      role: 'form',
      ariaLabel: tr('secProfilePasswordChangeForm.title')
    },
      v('.form-element',
        v.label({ htmlFor: 'old-password-input' },
          tr('secProfilePasswordChangeForm.oldPasswordLabel')),

        v.input({
          type: 'password',
          id: 'old-password-input',
          value: state.oldPassword,
          onInput: (event: any) => msg('OldPasswordUpdate', event.target.value)
        })
      ),
      v('.form-element',
        v.label({ htmlFor: 'password-input' },
          tr('secProfilePasswordChangeForm.passwordLabel')),

        v.input({
          type: 'password',
          id: 'password-input',
          onInput: (event: any) => msg('PasswordUpdate', event.target.value),
          value: state.password
        })
      ),
      v('.form-element',
        v.label({ htmlFor: 'repassword-input' },
          tr('secProfilePasswordChangeForm.rePasswordLabel')),

        v.input({
          type: 'password',
          id: 'repassword-input',
          onInput: (event: any) => msg('RePasswordUpdate', event.target.value),
          value: state.rePassword
        })
      ),

      !!state.error && v('p.error', tr(state.error, {
        minPasswordLength: 8
      })),

      v('.form-actions',
        v('hr'),
        v('.hbox',
          v('div',
            v('.text-button.cancel-button', {
              onClick: msg('Cancel')
            }, tr('general.cancel')),
          ),
          v('button.button', { type: 'submit' }, tr('secProfilePasswordChangeForm.submit'))
        )
      )
    )
  );
}
