import { stm } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import type { ChartSlotConfig, ChartSlotData } from "nexus/node/contracts";

type Msg = [type: "Attr", name: string, value: unknown];

interface State {
  chartSlotConfig: ChartSlotConfig;
  chartSlotData: ChartSlotData;
  dataJson: string;
  paramTitle: string;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-chart-slot",
  shadow: false,
  debug: false,
  propTypes: {
    chartSlotConfig: Object,
    chartSlotData: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      paramTitle: "",
      dataJson: "{}",
      chartSlotConfig: {
        type: "chart",
        order: 0,
        title: "",
        deviceSn: "",
        unit: "",
        prop: "",
        color: "",
        duration: 0,
      },
      chartSlotData: {},
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "chartSlotConfig", P.select()], (chartSlotConfig) => {
      state.chartSlotConfig = chartSlotConfig as ChartSlotConfig;
      state.paramTitle = `${state.chartSlotConfig.title} [${state.chartSlotConfig.unit}]`;
      return [state, null];
    })
    .with(["Attr", "chartSlotData", P.select()], (chartSlotData) => {
      state.chartSlotData = chartSlotData as ChartSlotData;

      if (state.chartSlotData && state.chartSlotData.values) {
        state.dataJson = JSON.stringify(state.chartSlotData.values.map((v) => {
          return { x: v.timestamp, y: v.value };
        }));
      } else {
        console.error("missing chart data");
      }

      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .exhaustive();
}

function view(state: State) {
  let dateFrom = Date.now() - state.chartSlotConfig.duration;
  return (
    <fib-chart date-from={dateFrom} date-to={Date.now()}>
      <fib-chart-series param={state.paramTitle} values={state.dataJson} />
    </fib-chart>
  );
}
