import { v } from "./v";
import { tr } from "@7willows/sw-lib";
import { Organization } from "nexus/node/OrganizationAccess";
import _ from "lodash";
import { InstallationStored } from "nexus/node/InstallationDetailsAccess";
import { checkboxElement, selectElement, textElement } from "./view-utils";

export function userGeneralInfo<Msg>(config: {
  isDisabled: boolean;
  isDisabledEmail: boolean;
  data: any;
  errors: any;
  realData: any;
  changeSelectedLangMsg: (selectedLang: unknown) => Msg;
}): v.View<Msg>[] {
  const langOptions = [
    { value: "pl", label: tr("general.langPl") },
    { value: "en", label: tr("general.langEn") },
    { value: "uk", label: tr("general.langUk") },
  ];

  return [
    textElement({
      field: "email",
      label: "identity.email",
      isDisabled: config.isDisabledEmail,
      realData: config.realData,
      data: config.data,
      errors: config.errors,
    }),
    textElement({
      field: "firstName",
      label: "identity.firstName",
      realData: config.realData,
      data: config.data,
      errors: config.errors,
      isDisabled: config.isDisabled,
      size: "small",
    }),
    textElement({
      field: "lastName",
      label: "identity.lastName",
      realData: config.realData,
      data: config.data,
      errors: config.errors,
      isDisabled: config.isDisabled,
      size: "small",
    }),
    textElement({
      className: "box2",
      field: "phoneNumber",
      label: "identity.phoneNumber",
      realData: config.realData,
      data: config.data,
      errors: config.errors,
      isDisabled: config.isDisabled,
      size: "small",
    }),
    selectElement({
      field: "selectedLang",
      label: "general.chooseLang",
      data: { selectedLang: config.realData.lang },
      msgFactory: config.changeSelectedLangMsg,
      errors: {},
      realData: {},
      isDisabled: config.isDisabled,
      options: langOptions,
    }),
    <p class="h200 margin-bottom">{tr("identity.notificationType")}</p>,
    checkboxElement({
      field: "notifyByEmail",
      label: "identity.notifyByEmail",
      data: config.data,
      realData: config.realData,
      errors: config.errors,
      isDisabled: config.isDisabled,
    }),

    checkboxElement({
      field: "notifyBySMS",
      label: "identity.notifyBySMS",
      data: config.data,
      realData: config.realData,
      errors: config.errors,
      isDisabled: config.isDisabled,
    }),
  ];
}

export function userLicenseSwitch<Msg>(config: {
  isDisabled: boolean;
  data: any;
  errors: any;
  realData: any;
  activateMsg: () => Msg;
  deactivateMsg: () => Msg;
  dateChangeMsg: (date: number) => Msg;
}): v.View<Msg>[] {
  return [
    v.fibLicense({
      isValid: config.data.status === undefined
        ? config.realData.status === "active"
        : config.data?.status === "active",
      validUntil: config.data.validUntil || config.realData.validUntil,
      disabled: config.isDisabled,
      onswitch: (event: any) => {
        if (event.detail) {
          return config.activateMsg();
        }
        return config.deactivateMsg();
      },
      ondate: (event: any) => config.dateChangeMsg(event.detail),
    }),
    checkboxElement({
      field: "licenseNotify",
      label: "identity.licenseNotify",
      data: config.data,
      realData: config.realData,
      errors: config.errors,
      isDisabled: config.isDisabled,
    }),
  ];
}

export function userInstallations<Msg>(config: {
  isDisabled: boolean;
  selectedOrgId: string;
  orgs: Organization[];
  data: any;
  realData: any;
  errors: any;
  installations: InstallationStored[]; // list of installations connected to chosen Org
  allInstallations: InstallationStored[]; // all installations
  changeSelectedOrgMsg: (selectedOrg: unknown) => Msg;
  updateInstallationsMsg: (installationIds: string[]) => Msg;
  isLoading: boolean;
}): (v.View<Msg> | false)[] {
  let limit = Infinity;

  const installationIds = config.data.installationIds || config.realData.installationIds ||
    config.data.installation || [];
  const licenseType = config.data.licenseType || config.realData.licenseType;
  if (config.selectedOrgId === "all") {
    config.orgs = _.sortBy(config.orgs, "name");
    config.selectedOrgId = config.orgs?.[0]?.id ?? "";
  }
  
  const installationsListByOrg = config.allInstallations.filter((instAll) => {
    return config.selectedOrgId === instAll.orgId;
  });

  if (licenseType === "manager") {
    limit = 1;
    const countInstallations = installationIds?.length ?? 0;

    if (countInstallations >= limit) {
      limit = 0;
    } else {
      limit = 1;
    }
  }

  let showInstallations = !!config.selectedOrgId && !config.isLoading;

  if (licenseType === "admin" || licenseType === "guest") {
    showInstallations = false;
  }

  return [
    v(
      ".container.full-width",
      selectElement({
        field: "selectedOrgId",
        label: "access.client",
        data: { selectedOrgId: config.selectedOrgId },
        msgFactory: config.changeSelectedOrgMsg,
        errors: {},
        realData: {},
        isDisabled: config.isDisabled,
        options: config.orgs.map((org) => ({
          value: org.id,
          label: org.name,
        })),
      }),
    ),
    config.isLoading && v(".loader-small"),

    showInstallations && v("h3.h400", tr("access.subordinateInstallations")),
    showInstallations && licenseType === "manager" &&
    v(".body-small.margins", tr("access.subordinateInstallationsManager", { limit })),

    showInstallations && v.fibEditInstallationsList({
      installations: installationIds
        .map((instaId: string) => config.allInstallations.find((insta) => insta._id === instaId))
        .filter((x: any) => x), // list selected Installations
      allInstallations: config.allInstallations, //  installations all
      installationsByOrg: installationsListByOrg, //config.installations // list limited byOrg
      onupdate: (event: any) => {
        const installationIds = event.detail.map((insta: any) => insta._id);
        return config.updateInstallationsMsg(installationIds);
      },
      limit,
    }),

    !!config.errors.installationIds &&
    v(".form-element.error", v("p.error", tr(config.errors.installationIds))),
  ];
}

export function userLicenseType<Msg>(config: {
  isDisabled: boolean;
  showAllOptions: boolean;
  data: any;
  errors: any;
  realData: any;
  isHome?: boolean;
}): (v.View<Msg> | false)[] {
  const licenseType = config.data.licenseType || config.realData.licenseType || "licenseType";

  let options = [
    { value: "manager", label: tr("access.manager") },
  ];
  if (config.showAllOptions) {
    options.push({ value: "technician", label: tr("access.technician") });
    options.push({ value: "admin", label: tr("access.admin") });
  }

  if (config.isHome) {
    options = [
      { value: "admin_home", label: tr("access.adminHome") },
      { value: "technician_home", label: tr("access.technicianHome") },
      { value: "user_home", label: tr("access.userHome") },
    ];
  }

  return [
    selectElement({
      field: "licenseType",
      label: tr("identity.licenseType"),
      options: options,
      data: config.data,
      realData: config.realData,
      errors: config.errors,
      isDisabled: config.isDisabled,
    }),
    licenseType === "manager" &&
    v(
      ".checkbox-list",
      v("p.h200.margin-bottom", tr("access.modules")),
      checkboxElement({
        field: "modules.dashboards",
        label: tr("access.dashboards"),
        data: config.data,
        realData: config.realData,
        errors: config.errors,
        isDisabled: config.isDisabled,
      }),
      checkboxElement({
        field: "modules.reports",
        label: tr("access.reports"),
        data: config.data,
        realData: config.realData,
        errors: config.errors,
        isDisabled: config.isDisabled,
      }),
      checkboxElement({
        field: "modules.defrosting",
        label: tr("access.defrosting"),
        data: config.data,
        realData: config.realData,
        errors: config.errors,
        isDisabled: config.isDisabled,
      }),
      checkboxElement({
        field: "modules.lighting",
        label: tr("access.lighting"),
        data: config.data,
        realData: config.realData,
        errors: config.errors,
        isDisabled: config.isDisabled,
      }),
    ),
  ];
}
