import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { CirclesSlotConfig, CirclesSlotData } from "nexus/node/contracts";
import { unitConversion } from "./view-utils";

type Msg = [type: "Attr", name: string, value: unknown];

interface State {
  circlesSlotConfig: CirclesSlotConfig;
  circlesSlotData: CirclesSlotData;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-circles-slot",
  shadow: false,
  debug: false,
  propTypes: {
    circlesSlotConfig: Object,
    circlesSlotData: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      circlesSlotConfig: {
        type: "circles",
        order: 0,
        title: "title",
        props: [
          {
            deviceSn: "",
            prop: "",
            unit: "kWh",
            label: "",
          },
          {
            deviceSn: "",
            prop: "",
            unit: "",
            label: "",
          },
          {
            deviceSn: "",
            prop: "",
            unit: "",
            label: "",
          },
        ],
      },
      circlesSlotData: [
        {
          alerts: [],
          value: 0,
          length: 1,
          speed: 1,
        },
        {
          alerts: [],
          value: 0,
          length: 1,
          speed: 1,
        },
      ],
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "circlesSlotConfig", P.select()], (circlesSlotConfig) => {
      state.circlesSlotConfig = circlesSlotConfig as CirclesSlotConfig;
      return [state, null];
    })
    .with(["Attr", "circlesSlotData", P.select()], (circlesSlotData) => {
      state.circlesSlotData = circlesSlotData as CirclesSlotData;
      if (circlesSlotData === "" || state.circlesSlotData.length < 1) {
        flashMessage(tr("fibAdminInstallation.loadDataFailed"), "error");
      }
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .exhaustive();
}

function view(state: State) {
  if (
    !state.circlesSlotData || !Array.isArray(state.circlesSlotConfig.props)
  ) {
    return <></>;
  }
  let valueInMiddle = state.circlesSlotData.reduce((p, n) => p + n.value, 0);
  let valueUnit = state.circlesSlotConfig.props[0].unit;

  ({ value: valueInMiddle, unit: valueUnit } = unitConversion(valueUnit, valueInMiddle));

  return (
    <div class={"mgr-dash-circle-slot"}>
      <div className={"h500 text-center"}>{state.circlesSlotConfig.title}</div>

      <div class={"mgr-dash-circles-wrapper"}>
        {state.circlesSlotConfig.props.map((_circle, index) => {
          const speed = state.circlesSlotData[index].speed;
          const flag = state.circlesSlotData[index].alerts.length;
          const length = state.circlesSlotData[index].length;
          const propsLength = state.circlesSlotConfig.props.length;
          let r = 19 + index * 10;
          let x = 67 + index * 10;
          if (propsLength === 1) {
            r += 20;
            x += 20;
          }
          if (propsLength === 2) {
            r += 10;
            x += 10;
          }

          return (
            <>
              <svg viewBox="0 0 100 100" class={"circle"} style={`animation-duration: ${speed}s;`}>
                <circle cx="50" cy="50" r={r} fill="none" stroke-width="6" stroke="#565555" />
                <circle
                  cx="50"
                  cy="50"
                  r={r}
                  fill="none"
                  stroke-width="6"
                  pathLength="100"
                  stroke={flag ? "var(--nok-color)" : "var(--ok-color)"}
                  style={`stroke-dashoffset: calc(100% - ${length}%);`}
                  className="percent"
                />
              </svg>

              <svg
                viewBox="0 0 100 100"
                class="circle-text"
                font-family="Inter, sans-serif"
                fill={"ghostwhite"}
              >
                <text x={x} y="53">{index + 1}</text>
              </svg>
            </>
          );
        })}

        <svg viewBox="0 0 100 100">
          <foreignObject width="100%" height="100%">
            <div className={"mgr-dash-circles-value-wrapper"}>
              <span className={"h700"}>
                {valueInMiddle}
              </span>
              {valueUnit}
            </div>
          </foreignObject>
        </svg>
      </div>
    </div>
  );
}
