import { flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { LoadedDashboard } from "nexus/node/contracts";
import { GlobalId } from "../../nexus/node/contracts";

const dashboardManager = grow.plant("DashboardManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "LoadTemplatesSuccess", templates: Partial<LoadedDashboard>[]]
  | [type: "CopyToClipboard", href: string]
  | [type: "Delete", key: GlobalId]
  | [type: "DeleteConfirmed", key: GlobalId]
  | [type: "ArchiveSuccess", key: GlobalId]
  | [type: "CopyToClipboardSuccess"]
  | [type: "None"]
  | [type: "GeneralError"];

interface State {
  dashboards: Partial<LoadedDashboard>[];
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-dashboards",
  shadow: false,
  debug: false,
  attributeChangeFactory: (name: string, value: any) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      dashboards: [],
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", P._, P._], () => {
      return [state, loadTemplates()];
    })
    .with(["LoadTemplatesSuccess", P.select()], (dashboards) => {
      state.dashboards = dashboards;
      return [state, null];
    })
    .with(["CopyToClipboard", P.select()], (href) => {
      return [state, copyToClipboard(href)];
    })
    .with(["Delete", P.select()], (key) => {
      if (key.itemId === "" || key.workspaceId === "") {
        flashMessage(tr("general.loadingFailed"), "error");
        return [state, null];
      }
      return [state, hideConfirmation(key)];
    })
    .with(["DeleteConfirmed", P.select()], (key) => {
      return [state, hideDashboard(key)];
    })
    .with(["CopyToClipboardSuccess"], () => {
      flashMessage(tr("general.copied"), "success");
      return [state, null];
    })
    .with(["GeneralError"], () => {
      flashMessage(tr("general.loadingFailed"), "error");
      return [state, null];
    })
    .with(["None"], () => {
      return [state, null];
    })
    .with(["ArchiveSuccess", P.select()], (key: GlobalId) => {
      state.dashboards = state.dashboards.filter((dash) => dash.dashboardId !== key.itemId);
      return [state, null];
    })
    .exhaustive();
}

async function copyToClipboard(href: string) {
  try {
    await navigator.clipboard.writeText(href);
    return msg("CopyToClipboardSuccess");
  } catch (err) {
    console.error("copying error", err);
    return msg("GeneralError");
  }
}

async function hideDashboard(key: GlobalId) {
  try {
    await dashboardManager.hideDashboard(key);
    return msg("ArchiveSuccess", key);
  } catch (err) {
    return msg("GeneralError");
  }
}

async function hideConfirmation(key: GlobalId) {
  const confirmed = await modal.confirm({
    title: "",
    text: tr("fib.dash.deleteConfirmation"),
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });
  if (!confirmed) {
    return msg("None");
  } else {
    return msg("DeleteConfirmed", key);
  }
}

async function loadTemplates() {
  const installationId = router.getCurrentRoute().params.installationId;
  try {
    let dashboards = await dashboardManager.loadDashboards(installationId, {
      fields: ["representation", "token"],
      includeHidden: false,
    });

    dashboards = dashboards.filter((dash: any) => dash.dashboardId !== "reports");

    return msg("LoadTemplatesSuccess", dashboards);
  } catch (err) {
    console.error("loading dashboards error", err);
    return msg("GeneralError");
  }
}

function view(state: State) {
  const installationId = router.getCurrentRoute().params.installationId;
  const url = new URL(window.location.href);

  return (
    <div class={"mgr-dash-list-wrapper"}>
      <div class={"mgr-dash-title-row"}>
        <h2 class={"h500"}>{tr("fib.dash.templates")}</h2>
        <a href={router.getRouteUrl("managerDashboardsBuilder", { installationId })}>
          <div
            class={"button-secondary mgr-dash-button-wrapper"}
          >
            <i class={"icon-circle"} />
            <span class={"h200"}>{tr("fib.dash.newTemplate")}</span>
          </div>
        </a>
      </div>

      <div class={"mgr-dash-list-row"}>
        <>
          {state.dashboards.map(({ dashboardId, representation, token }) => {
            const url = new URL(window.location.href);
            url.hash = router.getRoutePath("dashFullscreen", {
              installationId,
              dashId: dashboardId as string ?? "",
              token: token as string ?? "",
            });
            const href = url.toString();

            return (
              <div className={"mgr-dash-template-wrapper"}>
                <div
                  className={"mgr-dash-template-img"}
                  style={`background: url(data:${representation?.contentType};base64,${representation?.data}); background-size: cover;`}
                >
                </div>
                <div className={"mgr-dash-template-captions"}>
                  <div
                    className={"mgr-dash-template-caption"}
                    onClick={() => msg("CopyToClipboard", href)}
                    title={tr("fib.dash.copyLink")}
                  >
                    <span className={"h200"}>{tr("fib.dash.copyLink")}</span>
                    <i className={"icon-dupe"} />
                  </div>
                  <a href={href} class={"text-color-primary50"}>
                    <div className={"mgr-dash-template-caption"} title={tr("fib.dash.open")}>
                      <span className={"h200"}>{tr("fib.dash.open")}</span>
                      <i className={"icon-open-fat"} />
                    </div>
                  </a>
                  <div
                    className={"mgr-dash-template-caption"}
                    onClick={() =>
                      msg("Delete", { workspaceId: installationId, itemId: dashboardId })}
                    title={tr("fib.dash.delete")}
                  >
                    <span className={"h200"}>{tr("fib.dash.delete")}</span>
                    <i className={"icon-trash"} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
}
