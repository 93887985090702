import { flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { DashboardOpener } from "nexus/node/contracts";
import { UserState } from "nexus/node/MetricsManager";

const dashboardManager = grow.plant("DashboardManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "LoadDashboardSuccess", dashboard: DashboardOpener]
  | [type: "RefreshData"]
  | [type: "GeneralError"];

interface State {
  installationName: string;
  userState: UserState;
  dashboard?: DashboardOpener;
  counter: number;
  weatherRefresh: boolean;
  isZiebice: boolean;
  isLublin: boolean;
}

const msg = (...args: Msg): Msg => args;

const weatherTimer = 1000 * 60 * 60 * 30;

stm.component({
  tagName: "fib-manager-dashboard-fullscreen",
  shadow: false,
  debug: false,
  propTypes: {
    userState: Object,
  },
  willMount(cmp: any, dispatch: stm.Dispatch<Msg>) {
    cmp.tick = setInterval(() => dispatch(msg("RefreshData")), 10000);
  },
  willUnmount(cmp: any) {
    clearInterval(cmp.tick);
  },
  attributeChangeFactory: (name: string, value: any) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      installationName: "",
      counter: 1,
      weatherRefresh: false,
      isZiebice: false,
      isLublin: false,
      userState: {},
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "userState", P.select()], (userState) => {
      const { installationId } = router.getCurrentRoute().params;

      state.userState = userState;
      const inst = state.userState.installations.find((inst) => inst.id === installationId);
      state.installationName = inst.name;
      // state.isZiebice = false; //inst.machineId === "2eed28ecc2751a48";
      state.isZiebice = inst.machineId === "2eed28ecc2751a48";
      state.isLublin = inst.machineId === "2eed28ecc2751a47";

      return [state, null];
    })
    .with(["Attr", P._, P._], () => {
      if (Object.keys(state.userState).length > 0) {
        return [state, loadDashboard()];
      } else {
        return [state, null];
      }
    })
    .with(["LoadDashboardSuccess", P.select()], (dashboard) => {
      if (state.weatherRefresh) {
        state.weatherRefresh = false;
      }
      state.dashboard = dashboard;
      return [state, null];
    })
    .with(["GeneralError"], () => {
      flashMessage(tr("general.loadingFailed"), "error");
      return [state, null];
    })
    .with(["RefreshData"], () => {
      // limit refresh weather to every 30 min

      state.counter += 10000;
      if (state.counter > weatherTimer) {
        state.weatherRefresh = true;
        state.counter = 1;
      }
      return [state, loadDashboard()];
    })
    .exhaustive();
}

async function loadDashboard() {
  const { dashId, token, installationId } = router.getCurrentRoute().params;

  try {
    let dashboard = await dashboardManager.openDashboard({
      itemId: dashId,
      workspaceId: installationId,
    }, token);

    return msg("LoadDashboardSuccess", dashboard);
  } catch (err) {
    console.error("loading dashboard error", err);
    return msg("GeneralError");
  }
}

function view(state: State) {
  if (!state.dashboard?.config.type) {
    return <div class={"loader-big"}></div>;
  }

  if (state.dashboard.config.type === "reports") {
    return <fib-404></fib-404>;
  }

  return (
    <fib-dashboard
      name={state.dashboard.config.dashboardName}
      type={state.dashboard.config.type}
      bg-image={state.dashboard.config.bgImage}
      slots={JSON.stringify(state.dashboard.config.slots)}
      slots-data={JSON.stringify(state.dashboard.data)}
      stream-key={state.dashboard.streamId}
      weather-timer={state.weatherRefresh}
      is-ziebice={state.isZiebice}
      is-lublin={state.isLublin}
    />
  );
}
