import _ from "lodash";
import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { v } from "./v";
import { match, P } from "ts-pattern";
import { nextYear, textElement } from "./view-utils";

const resourceManager = grow.plant("ResourceManager");

type Step =
  | "Data"
  | "Users";

interface User {
  email: string;
}

type Msg =
  | [type: "NextStep"]
  | [type: "Cancel"]
  | [type: "Input", propName: string, propValue: unknown]
  | [type: "SaveSuccess", installationId: string]
  | [type: "SaveFailed", errors: Record<string, string>]
  | [type: "UsersChanged", accessType: "technician" | "manager", users: User[]]
  | [type: "ActivateInstallation"]
  | [type: "AttributeChanged", name: string, value: string]
  | [type: "SearchAddress"]
  | [type: "UnsavedChanges"];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  step: Step;
  data: Record<string, unknown>;
  errors: Record<string, string>;
  isSaving: boolean;
  isSearchClicked: boolean;
}

stm.component({
  tagName: "fib-create-installation",
  shadow: false,
  debug: false,
  propTypes: {
    orgId: String,
  },
  willUnmount: () => {
    window.onbeforeunload = () => null;
  },
  attributeChangeFactory: (name, value) => msg("AttributeChanged", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      step: "Data",
      data: {},
      errors: {},
      isSaving: false,
      isSearchClicked: false,
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChanged", "orgId", P.select()], (orgId) => {
      state.data.orgId = orgId;
      return [state, null];
    })
    .with(["AttributeChanged", P._, P._], () => [state, null])
    .with(["UsersChanged", "technician", P.select()], (technicianUsers) => {
      state.data["technicians"] = technicianUsers;
      return [state, null];
    })
    .with(["UsersChanged", "manager", P.select()], (managerUsers) => {
      state.data["managers"] = managerUsers;
      return [state, null];
    })
    .with(["NextStep"], () => {
      if (state.step === "Data") {
        const errors = validate(state.data);
        if (Object.keys(errors).length > 0) {
          return [
            { ...state, errors },
            null,
          ];
        }
        state.errors = {};
      }

      if (state.step === "Users") {
        state.data["installationState"] = "normal";
        state.data["runningStatus"] = "active";
        return [
          { ...state, isSaving: true },
          save(state.data),
        ];
      }

      return [
        { ...state, step: getNextStep(state.step) },
        null,
      ];
    })
    .with(["SaveFailed", P.select()], (errors) => {
      flashMessage(tr("fibCreateInstallation.saveFailed"), "error");
      return [
        { ...state, isSaving: false, errors },
        null,
      ];
    })
    .with(["SaveSuccess", P.select()], (installationId) => {
      return [
        { ...state, isSaving: false },
        new CustomEvent("created", {
          bubbles: true,
          detail: installationId,
        }),
      ];
    })
    .with(["Cancel"], () => {
      const unsaved = !_.isEmpty(state.data);
      return [state, new CustomEvent("cancel", { bubbles: true, detail: { unsaved } })];
    })
    .with(["Input", P.select("propName"), P.select("propValue")], ({ propName, propValue }) => {
      if (propName === "coords") {
        state.isSearchClicked = false;
      }

      state = {
        ...state,
        data: {
          ...state.data,
          [propName]: propValue,
        },
        errors: {
          ...state.errors,
          [propName]: "",
        },
      };
      return [state, unsavedChanges()];
    })
    .with(["ActivateInstallation"], () => {
      state.data.licenseUntil = nextYear().getTime();
      state.data.status = "active";
      return [state, null];
    })
    .with(["SearchAddress"], () => {
      state.isSearchClicked = true;
      return [state, null];
    })
    .with(["UnsavedChanges"], () => {
      window.onbeforeunload = () => !_.isEmpty(state.data) ? "" : null;
      return [state, null];
    })
    .exhaustive();
}

function unsavedChanges() {
  return msg("UnsavedChanges");
}

async function save(data: Record<string, unknown>) {
  try {
    const result = await resourceManager.saveInstallation(data);
    if ("id" in result) {
      return msg("SaveSuccess", result.id);
    }

    return msg("SaveFailed", result.errors);
  } catch (err) {
    console.error("saving failed", err);
    return msg("SaveFailed", {});
  }
}

function validate(data: Record<string, unknown>) {
  const errors: Record<string, string> = {};

  if (_.isEmpty(data["name"])) {
    errors["name"] = tr("fibCreateInstallation.emptyName");
  }

  if (_.isEmpty(data["orgId"])) {
    errors["orgId"] = tr("fibCreateInstallation.emptyOrd");
  }

  return errors;
}

function getNextStep(step: Step): Step {
  return match<Step, Step>(step)
    .with("Data", () => "Users")
    .with("Users", () => "Users")
    .exhaustive();
}

function view(state: State) {
  return (
    <div class={"fib-create-installation full"}>
      <div class={"button-tertiary h100 square-button close-button"} onClick={() => msg("Cancel")}>
        <i class={"icon-cross"} />
      </div>

      {match<Step, v.View<Msg>>(state.step)
        .with("Data", () => stepDataView(state))
        .with("Users", () => stepUsersView(state))
        .exhaustive()}
    </div>
  );
}

function stepUsersView(state: State) {
  return v<Msg>(
    ".step",
    v("h2.h500.step-title", "2. " + tr("fibCreateInstallation.installationUsers")),
    v(
      ".vbox",
      v(
        ".grid.step-content.step-content-small",
        v.div(
          v("h3.h200", tr("fibCreateInstallation.managerAccess")),
          v.fibUsersList({
            onupdate: (event: any) =>
              msg("Input", "identitiesByAccessType", {
                ...((state as any).data.identitiesByAccessType || {}),
                manager: event.detail,
              }),
            accessType: "manger",
          }),
        ),
      ),
      v(
        ".grid.step-content.step-content-small",
        v.div(
          v("h3.h200", tr("fibCreateInstallation.technicianAccess")),
          v.fibUsersList({
            onupdate: (event: any) =>
              msg("Input", "identitiesByAccessType", {
                ...((state as any).data.identitiesByAccessType || {}),
                technician: event.detail,
              }),
            accessType: "technician",
          }),
        ),
      ),
    ),
    v(".grid.vbox.center", buttons(state)),
  );
}

function stepDataView(state: State) {
  //  default position if installation hasn't any data
  let positionLat = "53.35391721448532";
  let positionLng = "18.43217104687567";
  let searchAddress = "";

  if (state.data?.coords) {
    positionLat = (state as any).data?.coords.lat ?? positionLat;
    positionLng = (state as any).data?.coords.lng ?? positionLng;
  }

  if (state.isSearchClicked) {
    let street = (state as any).data?.street ?? "";
    let houseNumber = (state as any).data?.houseNumber ?? "";
    let postCode = (state as any).data?.postcode ?? "";
    let city = (state as any).data?.city ?? "";

    if (postCode !== undefined && postCode.length > 0) {
      searchAddress += "postalCode=" + postCode + ";";
    }

    if (city !== undefined && city.length > 0) {
      searchAddress += "city=" + city + ";";
    }

    if (street !== undefined && street.length > 0) {
      searchAddress += "street=" + street + ";";
    }

    if (houseNumber !== undefined && houseNumber.length > 0) {
      searchAddress += "houseNumber=" + houseNumber + ";";
    }

    searchAddress = searchAddress.slice(0, -1);
  }

  const installationFormElement = (field: string, label: string, className: string = "") => {
    return textElement<Msg>({
      field,
      label,
      data: state.data,
      errors: state.errors,
      className,
      realData: {},
      isDisabled: false,
    });
  };

  return v<Msg>(
    "form.step",
    v("h2.h500.modal-title", "1. " + tr("fibCreateInstallation.generalData")),
    v(
      ".grid.vbox.center",
      v(
        ".box4.container",
        v(
          ".form-element",
          v("label.h200", { htmlFor: "name" }, tr("fibCreateInstallation.installationName")),
          v("input.body-medium.full-width#name", {
            type: "text",
            value: state.data["name"],
            onInput: (event: any) => msg("Input", "name", event.target.value),
          }),
          v("p.error.body-small", state.errors["name"]),
        ),
        v("fib-org-select.body-medium.full-width#org-id", {
          orgId: state.data["orgId"],
          onInput: (event: any) => msg("Input", "orgId", event.target.value),
        }),
        installationFormElement("street", "fibCreateInstallation.installationStreet"),
        installationFormElement(
          "houseNumber",
          "fibCreateInstallation.installationHouseNumber",
          "box1",
        ),
        installationFormElement("postcode", "fibCreateInstallation.installationPostcode", "box1"),
        installationFormElement("city", "fibCreateInstallation.installationCity"),
        <div class="form-element">
          <div
            class="button-secondary h200 map-margin-bottom"
            onClick={msg("SearchAddress") as any}
          >
            <span>{tr("fibCreateInstallation.installationSearch")}</span>
          </div>
          <label class="h200" for="installation-coords">
            {tr("fibCreateInstallation.installationCoords")}
          </label>
          <sw-map
            apiKey="i_61udh7zXb43IvndBBV7qK5meZDBQJSaqlG5ycp074"
            style="height:300px"
            zoom={12}
            search={searchAddress.length > 5 ? searchAddress : ""}
            selectable={positionLat + "x" + positionLng}
            onupdate={(event: any) => msg("Input", "coords", event.detail?.value)}
          >
          </sw-map>
        </div>,
      ),
    ),
    v(
      ".grid.vbox.center",
      v(
        ".box4.container",
        v.fibLicense({
          isValid: state.data.status === "active",
          validUntil: state.data.licenseUntil,
          disabled: state.isSaving,
          onswitch: (event: any) => {
            if (event.detail) {
              return msg("ActivateInstallation");
            }
            return msg("Input", "status", "inactive");
          },
          ondate: (event: any) => msg("Input", "licenseUntil", event.detail),
        }),
      ),
    ),
    buttons(state),
  );
}

function buttons(state: State) {
  return v<Msg>(
    ".actions.hbox",
    v(".button-tertiary.h300", {
      onclick: msg("Cancel"),
    }, tr("general.cancel")),
    v(
      ".button-primary.h300",
      {
        onclick: msg("NextStep"),
      },
      state.step === "Users"
        ? tr("fibCreateInstallation.createInstallationButton")
        : tr("fibCreateInstallation.next"),
    ),
  );
}
