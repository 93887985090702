import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { CirclesSlotConfig, CirclesSlotData } from "nexus/node/contracts";

type Msg = [type: "Attr", name: string, value: unknown];

interface State {
  circlesSlotConfig?: CirclesSlotConfig;
  circlesSlotData?: CirclesSlotData;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-inverters-slot",
  shadow: false,
  debug: false,
  propTypes: {
    circlesSlotConfig: Object,
    circlesSlotData: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      circlesSlotConfig: {
        type: "circles",
        order: 0,
        title: "title",
        props: [
          {
            deviceSn: "",
            prop: "",
            unit: "",
            label: "",
          },
        ],
      },
      circlesSlotData: [
        {
          alerts: [],
          value: 1,
          length: 1,
          speed: 1,
        },
      ],
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "circlesSlotConfig", P.select()], (circlesSlotConfig: any) => {
      if (circlesSlotConfig.hasOwnProperty("props")) {
        state.circlesSlotConfig = circlesSlotConfig as CirclesSlotConfig;
      }
      return [state, null];
    })
    .with(["Attr", "circlesSlotData", P.select()], (circlesSlotData: any) => {
      state.circlesSlotData = circlesSlotData as CirclesSlotData;

      if (circlesSlotData === "" || state.circlesSlotData.length < 1) {
        flashMessage(tr("fibAdminInstallation.loadDataFailed"), "error");
      }
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .exhaustive();
}

function view(state: State) {
  if (
    !state.circlesSlotData || !Array.isArray(state.circlesSlotConfig.props) ||
    !state.circlesSlotConfig.props[0].prop
  ) {
    return <></>;
  }

  return (
    <div className="mgr-dash-data-slot mgr-dash-data-inverters body-large">
      <>
        {state.circlesSlotConfig.props.map((inverter, index) => {
          const hasAlerts = state.circlesSlotData[index]?.alerts.length ?? false;
          let name = "";
          if (inverter.deviceKind.includes("inverter")) {
            name = tr("fib.dash.template.inverter");
          }
          if (inverter.deviceKind.includes("meter")) {
            name = tr("fib.dash.template.meter");
          }
          if (inverter.deviceKind.includes("plc")) {
            name = tr("fib.dash.template.plc");
          }

          return (
            <div className="mgr-dash-inverter-wrapper">
              <span className={"nowrap uppercase"}>{name} {index + 1}</span>
              <div class={hasAlerts ? "mgr-dash-inverter-nok" : "mgr-dash-inverter-ok"}></div>
              {inverter.label}
            </div>
          );
        })}
      </>
    </div>
  );
}
