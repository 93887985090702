import _ from "lodash";
import { flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { Device, LatestMetrics, MetricsStored } from "nexus/node/MetricsAccess";
import type { DeviceInfo } from "nexus/deno/installation_access/internal_types.ts";
import type { ActionsType, Threshold } from "nexus/deno/threshold_access/internal_types.ts";
import { DeviceParameter, LicenseType, Parameter } from "nexus/node/contracts";

type LoadData = { devices: DeviceInfo[]; thresholds: Threshold[]; parameters: DeviceParameter[] };

const installationManager = grow.plant("InstallationManager");
const lang = tr.getLang();

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "SelectDevice", marker: string]
  | [type: "LoadDataFailed", errMsg: string]
  | [type: "LoadDataSuccess", LoadData]
  | [type: "UpdateSuccess", Threshold[]]
  | [type: "Input", field: string, value: unknown]
  | [type: "SwitchThreshold", id: string]
  | [type: "EditThreshold", id: string]
  | [type: "RemoveThreshold", id: string]
  | [type: "RemoveSuccess", id: string]
  | [type: "SaveCondition"]
  | [type: "UnsavedChanges"]
  | [type: "None"];

function msg(...args: Msg): Msg {
  return args;
}

const thresholdActions: { option: ActionsType; desc: string }[] = [
  { option: "increase", desc: tr("fibTechPanel.thresholdsIncrease") },
  { option: "decrease", desc: tr("fibTechPanel.thresholdsDecrease") },
  // { option: "off", desc: tr("fibTechPanel.thresholdsOff") },
  // { option: "on", desc: tr("fibTechPanel.thresholdsOn") },
  // { option: "sent", desc: tr("fibTechPanel.thresholdsSent") },
  { option: "none", desc: tr("fibTechPanel.thresholdsNoAction") },
];

const thresholdFrequency = [
  { freq: "5 sec", value: "5000" },
  { freq: "10 sec", value: "10000" },
  { freq: "1 min", value: "60000" },
  { freq: "10 min", value: "600000" },
];

const thresholdConditionsDef = [
  { condition: "min", desc: tr("fibTechPanel.thresholdsMin") },
  { condition: "max", desc: tr("fibTechPanel.thresholdsMax") },
];

type SaveOption = "new" | "update";
type Data = Partial<Threshold>;
type Errors = Partial<
  {
    [T in keyof Threshold]: string;
  }
>;

interface State {
  isLoading: boolean;
  installationId: string;
  viewState: "params" | "info" | "logs" | "thresholds" | "config";
  deviceMarked: string;
  loadedData?: LoadData;
  selectedThreshold: Threshold;
  data: Data;
  canBeSave: boolean;
  errors: Errors;
}

stm.component({
  tagName: "fib-tech-home-thresholds",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
    viewState: String,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        installationId: "",
        viewState: "info",
        deviceMarked: "",
        selectedThreshold: {} as Threshold,
        data: {},
        canBeSave: false,
        errors: {},
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      if (!_.isString(installationId)) {
        return [state, null];
      }
      state.isLoading = true;
      state.installationId = installationId;
      return [state, loadData(installationId)];
    })
    .with(["Attr", "viewState", P.select()], (viewState: any) => [
      {
        ...state,
        viewState: ["params", "info", "logs", "thresholds", "config"].includes(viewState)
          ? viewState
          : "info",
      },
      null,
    ])
    .with(["Attr", P._, P._], () => [state, null])
    .with(["None"], () => {
      state.isLoading = false;
      return [state, null];
    })
    .with(["SelectDevice", P.select()], (device) => {
      state.isLoading = false;
      if (state.deviceMarked === device) {
        state.deviceMarked = "";
      } else {
        state.deviceMarked = device;
      }
      state.data = {
        deviceMarkers: [device],
        docType: "threshold",
        installationId: state.installationId,
        isActive: false,
        name: "",
        parameter: { paramId: "", unit: "" },
        sendNotification: false,
        condition: {},
        actions: [{
          marker: device,
        }],
      };

      state.errors = {
        name: "",
        frequency: "",
        "parameter.paramId": "",
        "condition.measureType": "",
        "condition.value": "",
        "actions[0].actionType": "",
        "actions[0].param": "",
        "actions[0].value": "",
      };
      state.selectedThreshold = {};
      state.canBeSave = false;
      return [state, null];
    })
    .with(["LoadDataFailed", P.select()], (errMsg) => {
      state.isLoading = false;
      flashMessage(errMsg, "error");
      state.canBeSave = true;
      return [state, null];
    })
    .with(["UpdateSuccess", P.select()], (thresholds) => {
      state.isLoading = false;
      if (!thresholds) {
        flashMessage(tr("fibTechPanel.notConnectedString"), "error");
      } else {
        flashMessage(tr("fibTechHomePanel.thresholdUpdateSuccess"), "info");
        if (state.loadedData) {
          state.loadedData.thresholds = thresholds;
        }
      }
      return [state, null];
    })
    .with(["LoadDataSuccess", P.select()], (loadedData) => {
      state.isLoading = false;
      if (!loadedData || loadedData.devices.length === 0) {
        flashMessage(tr("fibTechPanel.notConnectedString"), "error");
        state.loadedData = { devices: [], thresholds: [], parameters: [] };
        return [state, null];
      }

      state.loadedData = {
        devices: loadedData.devices,
        thresholds: loadedData.thresholds,
        parameters: loadedData.parameters,
      };

      return [state, null];
    })
    .with(["SwitchThreshold", P.select()], (id) => {
      const threshold = state.loadedData?.thresholds.find((thresh: Threshold) => thresh._id === id);
      const updateData = {
        threshId: id,
        installationId: state.installationId,
        isActive: !threshold.isActive,
      };
      state.isLoading = true;
      return [state, saveThreshold(updateData, "update")];
    })
    .with(["EditThreshold", P.select()], (id: string) => {
      state.selectedThreshold = state.loadedData?.thresholds.find((thresh) =>
        thresh.threshId === id || thresh._id === id
      );

      state.data = {};
      state.errors = {};
      return [state, null];
    })
    .with(["RemoveThreshold", P.select()], (id) => {
      state.selectedThreshold = {};
      state.data = {};
      state.isLoading = true;
      const threshold = state.loadedData?.thresholds.find((thresh) => thresh._id);

      return [state, removeThreshold(id, state.installationId, threshold.name)];
    })
    .with(["RemoveSuccess", P.select()], (id) => {
      if (state.loadedData) {
        state.loadedData.thresholds = state.loadedData?.thresholds.filter((thresh) =>
          thresh._id !== id
        );
      }
      state.isLoading = false;
      return [state, null];
    })
    .with(["SaveCondition"], () => {
      let dataToSave = null;
      state.canBeSave = false;
      let option = "new" as SaveOption;
      if (Object.keys(state.data).length > 0 && state.data.name.length > 0) {
        dataToSave = state.data;
      }

      if (Object.keys(state.selectedThreshold).length > 0) {
        dataToSave = state.selectedThreshold;
        option = "update";
      }

      if (Object.keys(state.errors).length > 0) {
        flashMessage(tr("fibTechPanel.thresholdsErrors"), "error");
        state.canBeSave = true;
        return [state, null];
      }
      state.isLoading = true;
      return [state, saveThreshold(dataToSave, option)];
    })
    .with(["Input", P.select("field"), P.select("value")], ({ field, value }) => {
      // actions[] is array due to future plans to introduce chained actions
      if (["frequency", "condition.value", "actions[0].value"].includes(field)) {
        value = parseFloat(value as string) ?? 0;
        if (isNaN(value)) {
          value = "";
        }
      }
      if (state.selectedThreshold._id) {
        _.set(state.selectedThreshold, field, value);
      } else {
        _.set(state.data, field, value);
      }
      state.canBeSave = true;

      if (value === "") {
        state.errors[field] = "";
      } else {
        delete (state as any).errors[field];
        // special case when only notification is sent as action
        if (field === "actions[0].actionType") {
          if (value === "sent" || value === "none") {
            delete (state as any).errors["actions[0].param"];
            delete (state as any).errors["actions[0].value"];
          } else {
            if (state.selectedThreshold._id) {
              if (!state.selectedThreshold.actions[0].param) {
                state.errors["actions[0].param"] = "";
              }
              if (!state.selectedThreshold.actions[0].value) {
                state.errors["actions[0].value"] = "";
              }
            } else {
              if (!state.data.actions[0].param) {
                state.errors["actions[0].param"] = "";
              }
              if (!state.data.actions[0].value) {
                state.errors["actions[0].value"] = "";
              }
            }
          }
        }
      }

      return [state, null];
    })
    .with(["UnsavedChanges"], () => {
      window.onbeforeunload = () => !_.isEmpty(state.data) ? "" : null;
      return [state, null];
    })
    .with(P._, () => {
      window.onbeforeunload = () => !_.isEmpty(state.loadedData) ? "" : null;
      return [state, null];
    }) // its workaround to issue #206
    .exhaustive();
}

function unsavedChanges() {
  return msg("UnsavedChanges");
}

async function loadData(installationId: string): Promise<Msg> {
  try {
    const devicesList = await installationManager.getDevicesList(installationId);

    if (!devicesList) {
      return msg("LoadDataFailed", tr("fibAdminInstallation.loadDataFailed"));
    }

    const paramConfig = {
      instId: installationId,
      platform: "home",
      onlyVisible: true,
    };
    const parameters = await installationManager.readInstallationParams(paramConfig);

    const thresholdsList = await installationManager.getThresholdsList(installationId);
    if (thresholdsList) {
      thresholdsList.threshId = thresholdsList._id;
    }

    return msg("LoadDataSuccess", { devices: devicesList, thresholds: thresholdsList, parameters });
  } catch (error) {
    return msg("LoadDataFailed", tr("fibAdminInstallation.loadDataFailed"));
  }
}

async function saveThreshold(data: any, option: SaveOption): Promise<Msg> {
  try {
    if (data._id) {
      data.threshId = data._id;
    }

    const thresholdsList = await installationManager.saveThreshold(data, option);
    return msg("UpdateSuccess", thresholdsList);
  } catch (error) {
    console.error("saving threshold errors:", error);
    return msg("LoadDataFailed", tr("fibAdminInstallation.loadDataFailed"));
  }
}

async function removeThreshold(id: string, instId: string, threshName: string): Promise<Msg> {
  const confirmed = await modal.confirm({
    title: tr("fibHome.confirmRemoval", { name: threshName }),
    text: "",
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  if (!confirmed) {
    return msg("None");
  }

  try {
    const thresholdsList = await installationManager.removeThreshold(id, instId);
    return msg("RemoveSuccess", id);
  } catch (error) {
    console.error("removing threshold errors:", error);
    return msg("LoadDataFailed", tr("fibAdminInstallation.loadDataFailed"));
  }
}

function view(state: State): stm.View<Msg> {
  if (state.isLoading) {
    return (
      <>
        <div class="loader-big"></div>
      </>
    );
  }
  let threshold = state.selectedThreshold && state.selectedThreshold._id
    ? state.selectedThreshold
    : state.data;
  let thresholdList: Threshold[] = [];
  if (state.loadedData?.thresholds) {
    thresholdList = state.loadedData.thresholds.filter((thresh: Threshold) =>
      thresh.deviceMarkers.includes(state.deviceMarked)
    );
  }

  if (Object.keys(threshold).length === 0) {
    threshold = undefined;
  }

  const data = {
    thresh: threshold as Threshold,
    parameters: (state.loadedData as LoadData).parameters ?? {},
    deviceMarked: state.deviceMarked,
    canBeSave: state.canBeSave,
    errors: state.errors,
  };

  return (
    <div class="tech-home-panel">
      <div className="install-params-main">
        <ul className="list tech-list-devices thresholds">
          <div className="h300">{tr("fibTechPanel.devicesList")}</div>
          {renderDevices(state)}
        </ul>
        <div className="list tech-thresholds">
          <div className="notsaved-warning">
            {state.canBeSave
              ? Object.keys(state.errors).length > 0
                ? tr("fibTechPanel.thresholdsErrors")
                : tr("fibTechPanel.thresholdsWarning")
              : ""}
          </div>

          <div className="hbox">
            {state.deviceMarked && (
              <>
                <div className="h400">
                  {tr("fibTechPanel.thresholdsDevice")}{"  "}
                  <span class="threshold-device">
                    {getSelectedDevice(state).modelName}
                  </span>
                </div>
              </>
            )}

            {!state.deviceMarked && (
              <div className="h400">
                {tr("fibTechPanel.selectDevice")}
              </div>
            )}
          </div>
          {state.deviceMarked && (
            <>
              <div className="threshold-setup">
                {thresholdForm(data)}
              </div>
              <div className="tech-list-thresholds">
                <span class="h400">{tr("fibTechPanel.thresholdsList")}</span>
                {thresholdsList(thresholdList)}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function renderDevices(state: State) {
  let devicesList = state.loadedData?.devices ?? [];

  return (
    <>
      {devicesList.map((device: Device) => {
        const flag = device.marker === state.deviceMarked;
        const deviceName = `${device.modelName}`;
        const deviceType = tr(`fib.dash.template.${device.kind}`);
        if (["summary"].includes(device.kind)) {
          return <></>;
        }
        return (
          <li
            class={`well ${flag ? "h300 active" : "body-large"} device-title-box`}
            onClick={msg("SelectDevice", device.marker) as any}
          >
            <div className="device-name-line">
              <span>
                {deviceName.charAt(0).toUpperCase() + deviceName.slice(1)}
              </span>
              {flag && <i className="icon-check-fat margin-left"></i>}
            </div>
            <div className="device-kind-line">[{deviceType}]</div>
          </li>
        );
      })}
    </>
  );
}

function getSelectedDevice(state: State): Device {
  return (state.loadedData?.devices ?? []).find((
    device: any,
  ) => (device.marker === state.deviceMarked)) ?? {
    modelName: "",
    sn: "",
    kind: "",
    deviceStatus: "inactive",
    deviceMarker: "m1",
  };
}

function thresholdsList(thresholdList: Threshold[]) {
  return (
    <>
      {thresholdList.map((thresh: Threshold) => (
        <li>
          <div class="well body-medium">
            <div
              className={`switch-alt switch-medium ${thresh.isActive ? "switch-on" : "switch-off"}`}
              onClick={() => msg("SwitchThreshold", thresh._id)}
            >
            </div>
            <div class="body-large threshold-name ">
              {`${thresh.name}`}
            </div>
            <div class="threshold-actions">
              <i
                class="icon-pen"
                onClick={() => msg("EditThreshold", thresh._id)}
              >
              </i>
              <i class="icon-trash" onClick={() => msg("RemoveThreshold", thresh._id)}></i>
            </div>
          </div>
        </li>
      ))}
    </>
  );
}

function thresholdForm(data: {
  thresh: Threshold;
  parameters: DeviceParameter[];
  deviceMarked: string;
  canBeSave: boolean;
  errors: Errors;
}) {
  const { thresh, parameters, deviceMarked, canBeSave, errors } = data;

  const selectedDevParams = parameters.find((param) =>
    param.deviceMarker === deviceMarked
  ) as DeviceParameter;

  let paramUnit = "";
  if (thresh && Object.keys(thresh).length > 0 && thresh.parameter && thresh.parameter.paramId) {
    const parameter = selectedDevParams.params.find((param: Parameter) =>
      param.param === thresh.parameter.paramId
    );

    if (parameter) {
      paramUnit = parameter.unit;
    }
  }

  const config = {
    selectedDevParams,
    thresh,
    errors,
  };

  return (
    <>
      <div class="threshold-param">
        <div class="h300">{tr("fibTechPanel.thresholdsName")}</div>
        <input
          id="name"
          type="text"
          class={`${errors.hasOwnProperty("name") ? "error" : ""} body-large `}
          value={!thresh ? "" : thresh.name}
          onInput={(event: any) => ["Input", "name", event.target.value] as any as Msg}
        />
      </div>

      {addInputParameter(config)}
      {addInputCondition({ thresh, paramUnit, errors })}
      {addFrequency(thresh, errors)}
      {addOutputActions(config)}
      {/* {addNotificationRequest(thresh)} */}
      <div
        class={`button-primary h300 ${canBeSave ? "" : "disabled"}`}
        onClick={() => msg("SaveCondition")}
      >
        {tr("fibTechPanel.thresholdsSave")}
      </div>
    </>
  );
}

function addInputParameter(
  config: { thresh: Threshold; selectedDevParams: DeviceParameter; errors: Errors },
) {
  const { thresh, selectedDevParams, errors } = config;
  return (
    <div class="threshold-param">
      <label for="param" class="h300">
        {tr("fibTechPanel.thresholdsParam")}:
      </label>
      {
        <select
          id="param"
          name="param"
          class={`${errors.hasOwnProperty("parameter.paramId") ? "error" : ""} body-large hbox`}
          onInput={(event: any) => ["Input", "parameter.paramId", event.target.value] as any as Msg}
        >
          <option value=""></option>
          {selectedDevParams.params.map((parameter: Parameter) => {
            return (
              <option
                value={parameter.param}
                selected={thresh && parameter.param === thresh.parameter.paramId ? true : false}
              >
                {parameter.name[lang] ?? "not defined"}
              </option>
            );
          })}
        </select>
      }
    </div>
  );
}

function addInputCondition(config: {
  thresh: Threshold;
  paramUnit: string;
  errors: Errors;
}) {
  const { thresh, paramUnit, errors } = config;

  return (
    <div class="threshold-condition">
      <div class="threshold-param">
        <label for="condition" class="h300">
          {tr("fibTechPanel.thresholdsCondition")}:
        </label>
        <select
          id="condition"
          name="condition"
          class={`${errors.hasOwnProperty("condition.measureType") ? "error" : ""} body-large hbox`}
          onInput={(event: any) =>
            ["Input", "condition.measureType", event.target.value] as any as Msg}
        >
          <option value=""></option>
          {thresholdConditionsDef.map((definition) => {
            return (
              <option
                value={definition.condition}
                selected={thresh && thresh.condition &&
                    thresh.condition.measureType === definition.condition
                  ? true
                  : false}
              >
                {definition.desc}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <div class="h300">{tr("fibTechPanel.thresholdsValue")}</div>
        <input
          type="text"
          size={8}
          class={`${errors.hasOwnProperty("condition.value") ? "error" : ""} body-large`}
          value={thresh && thresh.condition?.value ? thresh.condition.value : 0}
          id="value"
          onInput={(event: any) => ["Input", "condition.value", event.target.value] as any as Msg}
        />
        <span class="unit-margin">[{paramUnit}]</span>
      </div>
    </div>
  );
}

function addFrequency(thresh: Threshold, errors: Errors) {
  return (
    <div class="threshold-param">
      <label for="frequency" class="h300">
        {tr("fibTechPanel.thresholdsFrequency")}:
      </label>
      {
        <select
          id="frequency"
          name="frequency"
          class={`${errors.hasOwnProperty("frequency") ? "error" : ""} body-large hbox`}
          onInput={(event: any) => ["Input", "frequency", event.target.value] as any as Msg}
        >
          <option value=""></option>
          {thresholdFrequency.map((frequency) => {
            return (
              <option
                value={frequency.value}
                selected={thresh && parseFloat(frequency.value) === thresh.frequency ? true : false}
              >
                {frequency.freq}
              </option>
            );
          })}
        </select>
      }
    </div>
  );
}

function addNotificationRequest(thresh: Threshold) {
  // temporary disabled, probably require in next release
  return (
    <div class="threshold-param">
      <label for="notification" class="h300">
        {tr("Wyslij powiadomienie")}:
      </label>
      <input
        id="notification"
        name="notification"
        type="checkbox"
        value={!thresh ? "false" : thresh.sendNotification}
        checked={!thresh ? false : thresh.sendNotification}
        className={"body-large hbox checkbox-medium"}
        onChange={(event: any) => ["Input", "sendNotification", event.target.checked] as any as Msg}
      />
    </div>
  );
}

function addOutputActions(config: {
  selectedDevParams: DeviceParameter;
  thresh: Threshold;
  errors: Errors;
}) {
  const { selectedDevParams, thresh, errors } = config;

  return (
    <div class="threshold-condition">
      <div class="threshold-param">
        <label for="action" class="h300">
          {tr("fibTechPanel.thresholdsAction")}:
        </label>
        {
          <select
            name="action"
            class={`${
              errors.hasOwnProperty("actions[0].actionType") ? "error" : ""
            } body-large hbox`}
            id="option"
            onInput={(event: any) =>
              [
                "Input",
                `actions[0].actionType`,
                event.target.value,
              ] as any as Msg}
          >
            <option value=""></option>
            {thresholdActions.map((action) => {
              return (
                <option
                  value={action.option}
                  selected={(
                      thresh &&
                      thresh.actions &&
                      thresh.actions[0] &&
                      thresh.actions[0].actionType ===
                        action.option as ActionsType
                    )
                    ? true
                    : false}
                >
                  {action.desc}
                </option>
              );
            })}
          </select>
        }
      </div>

      <div class="threshold-param">
        <label for="paramAction" class="h300">
          {tr("fibTechPanel.thresholdsActionParam")}:
        </label>
        <select
          id="paramAction"
          name="paramAction"
          className={`${errors.hasOwnProperty("actions[0].param") ? "error" : ""} body-large hbox `}
          onInput={(event: any) =>
            [
              "Input",
              `actions[0].param`,
              event.target.value,
            ] as any as Msg}
          disabled={["sent", "none"].includes(thresh?.actions[0].actionType) ? true : false}
        >
          <option value="">{tr("fibTechPanel.thresholdsParam")}</option>
          {selectedDevParams.params
            .filter((param: Parameter) => param.isWritable)
            .map(
              (parameter: Parameter) => {
                return (
                  <option
                    value={parameter.param}
                    selected={thresh && thresh.actions &&
                        thresh.actions[0].param === parameter.param
                      ? true
                      : false}
                  >
                    {parameter.name[lang] ?? "not defined"}
                  </option>
                );
              },
            )}
        </select>
      </div>
      <div class="threshold-param">
        <label for="action" class="h300">
          {tr("fibTechPanel.thresholdsActionValue")}:
        </label>
        <input
          id="name"
          type="text"
          size={8}
          class={`${errors.hasOwnProperty("actions[0].value") ? "error" : ""} body-large`}
          value={!thresh ? "0" : thresh.actions && thresh.actions[0] &&
              thresh.actions[0].value
            ? thresh.actions[0].value
            : "0"}
          disabled={["sent", "none"].includes(thresh?.actions[0].actionType) ? true : false}
          onInput={(event: any) =>
            [
              "Input",
              `actions[0].value`,
              event.target.value,
            ] as any as Msg}
        />
      </div>
    </div>
  );
}
