import { v } from "./v";
import { stm, tr } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';

type Msg
  = [type: 'Submit']
  | [type: 'PasswordUpdate', value: string]
  | [type: 'RepasswordUpdate', value: string]
  | [type: 'AttributeChange', name: string, value: string]
  | [type: 'Failed', reason: string]
  | [type: 'ChangeSuccess']

type State = {
  password: string;
  repassword: string;
  error: string;
  identityId: string;
  token: string;
  isLoading: boolean;
};

const msg = (...args: Msg): Msg => args;

const propTypes = {
  identityId: String,
  token: String
};

stm.component({
  tagName: 'sec-remind-password-change-form',
  shadow: false,
  debug: false,
  propTypes,
  attributeChangeFactory: (name, value) => msg('AttributeChange', name, value),
  init: () => [
    {
      password: '',
      repassword: '',
      identityId: '',
      token: '',
      error: '',
      isLoading: false
    },
    null
  ],
  update: (state: State, msg: Msg) => {
    return match<Msg, [State, stm.Cmd<Msg>]>(msg)
      .with(['AttributeChange', P.select('name'), P.select('value')], ({ name, value }) => {
        if (!(propTypes as any)[name]) {
          return [state, null];
        }
        return [
          { ...state, [name]: value },
          null
        ]
      })
      .with(['PasswordUpdate', P.select()], value => [
        { ...state, password: value },
        null
      ])
      .with(['RepasswordUpdate', P.select()], value => [
        { ...state, repassword: value },
        null
      ])
      .with(['Submit'], () => {
        if (state.isLoading) {
          console.warn('submit is already in progress');
          return [state, null];
        }
        return [
          { ...state, isLoading: true },
          changePasswordRequest(state)
        ]
      })
      .with(['Failed', P.select()], error => [
        { ...state, isLoading: false, error },
        null
      ])
      .with(['ChangeSuccess'], () => [
        { ...state, isLoading: false },
        new CustomEvent('saved')
      ])
      .exhaustive();
  },
  view
});

async function changePasswordRequest(state: State): Promise<Msg> {
  try {
    const result = await grow.plant('Security').changePassword(
      {
        identityId: decodeURIComponent(state.identityId),
        token: decodeURIComponent(state.token)
      },
      state.password,
      state.repassword
    );
    if (result.err) {
      return msg('Failed', result.err);
    }
    return msg('ChangeSuccess');
  } catch (err: any) {
    console.error('changing password failed', err);
    return msg('Failed', err.message);
  }
}

function view(state: State) {
  return v<Msg>('.sec-remind-password-change-form',
    v.form({
      onSubmit: event => {
        event.preventDefault();
        return msg('Submit')
      },
      className: state.error ? 'error' : '',
      role: 'form',
      ariaLabel: tr('secRemindPasswordChangeForm.title')
    },
      v('.prop-wrapper',
        v.label({
          htmlFor: 'password',
          className: 'h200'
        }, tr('secRemindPasswordChangeForm.passwordLabel')),
        v.input({
          type: 'password',
          id: 'password',
          className: 'body-medium',
          value: state.password,
          onInput: (event: any) => msg('PasswordUpdate', event.target?.value),
          disabled: state.isLoading
        })
      ),
      v('.prop-wrapper',
        v.label({
          htmlFor: 'repassword',
          className: 'h200'
        }, tr('secRemindPasswordChangeForm.repasswordLabel')),
        v.input({
          type: 'password',
          id: 'repassword',
          className: 'body-medium',
          value: state.repassword,
          onInput: (event: any) => msg('RepasswordUpdate', event.target?.value),
          disabled: state.isLoading
        })
      ),
      !!state.error && v('p.error', tr(state.error, {
        minPasswordLength: 8
      })),

      (state.password !== state.repassword) && v('p.error', tr('security.passwordsDontMatch')),

      v('.actions',
        state.isLoading && v('.loader-medium'),
        v.button({
          type: 'submit',
          className: 'button-primary h200',
          disabled: state.isLoading || !state.password || !state.repassword || state.password !== state.repassword
        }, tr('secRemindPasswordChangeForm.submit'))
      )
    )
  );
}
