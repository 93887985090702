import { stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { Device } from "nexus/node/MetricsAccess";
import type { Param } from "nexus/node/contracts";

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "SelectDevice", deviceSn: string]
  | [type: "SearchPhrase", phrase: string]
  | [
    type: "SelectParam",
    value: { deviceSn: string; param: string; installationId: string; marker: string },
  ];

interface State {
  isLoading: boolean;
  chartId: string;
  devices: any[];
  params: string[];
  paramsDescription: any[];
  deviceMarkerMarked: string;
  searchPhrase: string;
  paramSelectedId: string;
  closeFn: any;
}

function msg(...args: Msg): Msg {
  return args;
}

stm.component({
  tagName: "fib-select-parameter",
  shadow: false,
  debug: false,
  propTypes: {
    devices: Object,
    closeFn: Function,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [{
      isLoading: true,
      chartId: "",
      devices: [],
      params: [],
      paramsDescription: [],
      deviceMarkerMarked: "",
      searchPhrase: "",
      paramSelectedId: "",
      closeFn: null,
    }, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "devices", P.select()], (devices) => {
      state.devices = (devices as any).devices ?? [];
      state.paramsDescription = (devices as any).params ?? [];
      state.isLoading = false;
      return [state, null];
    })
    .with(["Attr", "closeFn", P.select()], (closeFn) => {
      state.closeFn = closeFn as any;
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["SelectDevice", P.select()], (deviceMarker) => {
      state.deviceMarkerMarked = state.deviceMarkerMarked === deviceMarker ? "" : deviceMarker;
      return [state, null];
    })
    .with(["SearchPhrase", P.select()], (searchPhrase) => {
      state.searchPhrase = searchPhrase;
      return [state, null];
    })
    .with(["SelectParam", P.select()], ({ deviceSn, param, installationId, marker }) => {
      const paramSelectedId = marker + param;
      state.paramSelectedId = state.paramSelectedId !== paramSelectedId ? paramSelectedId : "";
      state.closeFn({ marker, deviceSn, param, installationId });
      return [state, null];
    })
    .exhaustive();
}

function view(state: State) {
  return state.isLoading
    ? <div class={"loader-big"}></div>
    : (
      <div className="grid hbox install-params-main">
        <ul className="box2 list tech-list-devices">
          <>
            {(state.devices ?? []).map((device) => {
              const flag = device.marker === state.deviceMarkerMarked;
              return (
                <li
                  class={`well ${flag ? "h300 active" : "body-large"}`}
                  onClick={msg("SelectDevice", device.marker) as any}
                >
                  {device.modelName}
                  {flag && <i className="icon-check-fat margin-left"></i>}
                </li>
              );
            })}
          </>
        </ul>
        <ul className="box4 list tech-list-params">
          <span className="tech-params input-icon-after ">
            <input
              className="body-large list margins"
              type="text"
              value={state.searchPhrase}
              placeholder={tr("fibTechPanel.searchParam")}
              onInput={(event: any) => msg("SearchPhrase", event.target.value)}
            >
            </input>
            <i className="icon-search"></i>
          </span>
          {renderParameters(state)}
        </ul>
      </div>
    );
}

function getSelectedDevice(state: State): Device {
  return (state.devices ?? []).find((device) => device.marker === state.deviceMarkerMarked) ?? {
    modelName: "",
    sn: "",
    kind: "",
    deviceStatus: "inactive",
    marker: "null",
  };
}

function renderParameters(state: State) {
  type DevParam = { device: any; param: Param };
  const markedDevice = getSelectedDevice(state);
  const lang = tr.getLang();

  const propsToRender = state.paramsDescription
    .filter((p) => p.deviceKind === markedDevice.kind)[0]?.params
    .filter((withChart: any) => withChart.chart)
    .map((param: any) => {
      return { device: markedDevice, param };
    })
    .sort((a: DevParam, b: DevParam) => {
      if (a.param.chart !== b.param.chart) {
        return Number(a.param.chart) - Number(b.param.chart);
      }
      return (a.param.name[lang] ?? "").localeCompare(b.param.name[lang] ?? "");
    });

  if (!propsToRender) {
    return <></>;
  }
  return (
    <>
      {propsToRender.map(({ device, param }: DevParam) => {
        const paramSelected = state.paramSelectedId === device.marker + param.param;

        // if (!device[param.param] || !param.isVisible) return <></>;
        if (!param.isVisible) return <></>;
        return param.name[tr.getLang()].toLowerCase().includes(state.searchPhrase)
          ? (
            <li
              class={`well body-large ${paramSelected ? "active" : ""}`}
              onClick={() =>
                msg("SelectParam", {
                  deviceSn: device.sn,
                  param: param.param,
                  installationId: device.installationId,
                  marker: device.marker,
                })}
            >
              {param.name[tr.getLang()] === "not defined"
                ? `[${param.param}]`
                : param.name[tr.getLang()]}
              <span>
                {/* {findValue(state, param)} */}
                {` [${param.unit}]`}
                {paramSelected ? <i class="icon-check-fat margin-left"></i> : ""}
              </span>
            </li>
          )
          : <></>;
      })}
    </>
  );
}

function findValue(state: State, param: Param) {
  const device: any = (state.devices ?? []).find((d) => d.kind === param.deviceKind) ?? {};

  if (!device || !device[param.param]) {
    return param.zeroValue.toString();
  }
  return (Number.parseFloat(device[param.param]).toFixed(2) ?? param.zeroValue).toString();
}
