import { v } from "./v";
import { router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { modalWrapper } from "./view-utils";
import { UserState } from "nexus/node/MetricsManager";

type Msg =
  | [type: "CloseAllModals"]
  | [type: "CloseAllModalsAndReloadOrgList"]
  | [type: "UrlChange"]
  | [type: "OrgCreated", orgId: string]
  | [type: "InstallationCreated", installationId: string]
  | [type: "Logout"]
  | [type: "Attr", name: string, value: unknown]
  | [type: "CancelClose"];

interface State {
  route: router.Route;
  userState?: UserState;
}

function msg(...args: Msg): Msg {
  return args;
}

stm.component({
  tagName: "fib-admin-panel",
  shadow: false,
  debug: false,
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  propTypes: {
    userState: Object,
  },
  willMount(cmp: any, dispatch: stm.Dispatch<Msg>) {
    cmp.urlChangeListener = () => dispatch(msg("UrlChange"));
    router.addEventListener("routeChange", cmp.urlChangeListener);
  },
  willUnmount(cmp: any) {
    router.removeEventListener("routeChange", cmp.urlChangeListener);
  },
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        route: router.getCurrentRoute(),
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["CloseAllModals"], () => {
      navigateToClosedModals(state.route.name);
      return [state, null];
    })
    .with(["Logout"], () => {
      router.navigate(router.findDefaultRoute().name);
      grow.sessionId = null;
      return [state, null];
    })
    .with(["Attr", "userState", P.select()], (userState) => [
      { ...state, userState: userState as UserState },
      null,
    ])
    .with(["Attr", P._, P._], () => [state, null])
    .with(["CloseAllModalsAndReloadOrgList"], () => {
      navigateToClosedModals(state.route.name);
      return [state, null];
    })
    .with(["UrlChange"], () => [
      { ...state, route: router.getCurrentRoute() },
      null,
    ])
    .with(["OrgCreated", P.select()], (orgId) => {
      router.navigate("adminOrg", { orgId });
      return [state, null];
    })
    .with(["InstallationCreated", P.select()], (installationId) => {
      router.navigate("adminInstallation", { installationId });
      return [state, null];
    })
    .with(["CancelClose"], () => [state, null])
    .exhaustive();
}

function navigateToClosedModals(routeName: string) {
  const newRouteName = match<string, string | null>(routeName)
    .with("adminAddInstallation", () => "adminListInstallations")
    .with("adminUserAdd", () => "adminListUsers")
    .with("adminAddOrg", () => "adminListOrgs")
    .with(P._, () => null)
    .exhaustive();

  if (newRouteName) {
    router.navigate(newRouteName);
  }
}

function view(state: State) {
  if (!state.userState || !state.route) {
    return (
      <div className="loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }

  if (state.route.name === "adminAdvanced") {
    return (
      <>
        {mainNavView(state)}
        <fib-admin-advanced user-state={state.userState} />
      </>
    );
  }

  return v<Msg>(
    ".fib-admin-panel",
    mainNavView(state),
    mainContentView(state),
    state.route.name === "adminAddInstallation" && modalWrapper<Msg>(
      v.fibCreateInstallation({
        oncancel: (e: Event) =>
          (e as CustomEvent).detail.unsaved
            ? confirm(tr("general.unsaved")) ? msg("CloseAllModals") : msg("CancelClose")
            : msg("CloseAllModals"),
        oncreated: (event: any) => msg("InstallationCreated", event.detail),
      }),
    ),
    state.route.name === "adminUserAdd" && modalWrapper<Msg>(
      v.fibUserCreate({
        orgId: state.route.params["orgId"],
        basePanel: "admin",
        oncancel: (e: Event) =>
          (e as CustomEvent).detail.unsaved
            ? confirm(tr("general.unsaved")) ? msg("CloseAllModals") : msg("CancelClose")
            : msg("CloseAllModals"),
        onsaved: msg("CloseAllModalsAndReloadOrgList"),
      }),
    ),
    state.route.name === "adminAddOrg" && modalWrapper<Msg>(
      v.fibCreateOrg({
        oncancel: (e: Event) =>
          (e as CustomEvent).detail.unsaved
            ? confirm(tr("general.unsaved")) ? msg("CloseAllModals") : msg("CancelClose")
            : msg("CloseAllModals"),
        oncreated: (event: any) => msg("OrgCreated", event.detail),
      }),
    ),
  );
}

function mainContentView(state: State) {
  return match<string, v.View<Msg>>(state.route.name)
    .with(
      "adminListUsers",
      "adminListUsersByOrg",
      "adminAddOrg",
      "adminUserAdd",
      () => v.fibOrgList<Msg>(),
    )
    .with(
      "adminListOrgs",
      () => v.fibAdminOrg<Msg>(),
    )
    .with(
      "adminListListallations",
      P._,
      () => v.fibInstallationList<Msg>(),
    )
    .exhaustive();
}

function mainNavView(state: State) {
  return v<Msg>(
    "nav.main-nav",
    { role: "menu" },
    v("div.fib-logo-container", v(".fib-logo")),
    v(
      "ul.main-menu.h300",
      v.li(
        v.a({
          className: ["adminListOrgs"].includes(state.route.name) ? "active" : "",
          href: router.getRouteUrl("adminListOrgs"),
        }, tr("fibAdminPanel.navOrgs")),
      ),
      v.li(
        v.a({
          className: [
              "adminListInstallations",
              "adminListInstallationsByOrg",
            ].includes(state.route.name)
            ? "active"
            : "",
          href: router.getRouteUrl("adminListInstallations"),
        }, tr("fibAdminPanel.navInstallationList")),
      ),
      v.li(
        v.a({
          className: ["adminListUsers", "adminListUsersByOrg"].includes(state.route.name)
            ? "active"
            : "",
          href: router.getRouteUrl("adminListUsers"),
        }, tr("fibAdminPanel.navUsers")),
      ),
    ),
    v(
      "ul.alt-menu",
      { role: "menu" },
      (state.userState.licenseType === "admin" || state.userState.licenseType === "admin_home") &&
        v.li(
          v("a.active", { href: router.getRouteUrl("adminAdvanced") }, v("i.icon-dots")),
        ),
      // v.li(
      //     v.a({
      //         href: router.getRouteUrl('adminListInstallations')
      //     }, v('i.icon-bell'))
      // ),
      <li className="dropdown-trigger">
        <a href="">
          <i className="icon-profile" />
        </a>
        <fib-user-menu user-state={state.userState} panel="admin" />
      </li>,
    ),
  );
}
