import { modal, router, stm, tr } from "@7willows/sw-lib";
import _, { now } from "lodash";
import { match, P } from "ts-pattern";
import { InstallationStatus, InstallationStored } from "nexus/node/InstallationDetailsAccess";
import { MiniInstallation, UserState } from "nexus/node/MetricsManager";
import type { InstallationStatuses } from "nexus/deno/installation_manager_home/internal_types";
import { techNav } from "./view-utils";

const installationManager = grow.plant("InstallationManager");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "SearchPhrase", phrase: string]
  | [type: "TechInstallSwitch", install: MiniInstallation]
  | [type: "onOffConfirmed", id: string]
  | [type: "onOffResign"]
  | [type: "ToggleAll"]
  | [type: "ToggleErrors"]
  | [type: "ToggleBreakdowns"];

const PER_PAGE = 3;

interface Install {
  status: InstallationStatus;
}

export interface InstallationDetails extends InstallationStored, Install {
}

interface State {
  userState?: UserState;
  isBreakdownVisible: boolean;
  isErrorVisible: boolean;
  isAllVisible: boolean;
  searchPhrase: string;
  installationSwitch: boolean;
  installationsStatuses: InstallationStatuses[];
  /* for pagination usage */
  currentPage: number;
}

function msg(...args: Msg): Msg {
  return args;
}

stm.component({
  tagName: "fib-tech-installs-list",
  shadow: false,
  debug: false,
  propTypes: {
    page: String,
    userState: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        isBreakdownVisible: true,
        isErrorVisible: true,
        isAllVisible: true,
        searchPhrase: "",
        installationSwitch: true,
        installationsStatuses: [],
        currentPage: 1,
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "page", P.select()], (page) => [
      {
        ...state,
        currentPage: parseInt(page as string) ?? 1,
      },
      null,
    ])
    .with(["Attr", "userState", P.select()], (userState) => {
      state.userState = userState as UserState;

      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["ToggleAll"], () => {
      state.isAllVisible = !state.isAllVisible;
      if (!state.isAllVisible) {
        state.isBreakdownVisible = false;
        state.isErrorVisible = false;
      } else {
        state.isBreakdownVisible = true;
        state.isErrorVisible = true;
      }
      return [state, null];
    })
    .with(["ToggleBreakdowns"], () => {
      state.isBreakdownVisible = !state.isBreakdownVisible;
      state.isBreakdownVisible ? (state.isErrorVisible = false, state.isAllVisible = false) : "";
      return [state, null];
    })
    .with(["ToggleErrors"], () => {
      state.isErrorVisible = !state.isErrorVisible;
      state.isErrorVisible ? (state.isBreakdownVisible = false, state.isAllVisible = false) : "";
      return [state, null];
    })
    .with(["TechInstallSwitch", P.select()], (install) => {
      state.installationSwitch = !state.installationSwitch;
      return [state, installToggleConfirmation(install)];
    })
    .with(["onOffConfirmed", P.select()], (id) => {
      state.userState?.installations.forEach((insta) => {
        if (insta.id === id) {
          insta.runningStatus = "pending";
        }
      });
      shutdownInstallation(id);
      return [state, null];
    })
    .with(["SearchPhrase", P.select()], (searchPhrase) => [
      { ...state, searchPhrase },
      null,
    ])
    .with(P._, () => [
      { ...state },
      null,
    ])
    .exhaustive();
}

async function shutdownInstallation(installationid: string): Promise<void> {
  try {
    await installationManager.toggleIsRunning(installationid);
  } catch (err) {
    console.error("shutdown installation failed", err);
  }
}

function view(state: State): stm.View<Msg> {
  if (!state.userState) {
    return <div class="loader-big"></div>;
  }
  return (
    <>
      {techNav(state.userState)}
      {techInstallations(state)}
    </>
  );
}

function techInstallations(state: State) {
  return (
    <>
      <main class="vbox fib-installation-list">
        <div class="grid">
          <div class="box1"></div>
          <nav class="secondary-nav box10">
            <h1 class="h600 install-list-margin">
              {tr("fibTechPanel.installations")}
            </h1>
          </nav>
          <div class="box1"></div>
        </div>
        <div class="grid">
          <div class="box1"></div>
          <div class="box10">
            <div class="hbox installation-filters-tech install-list-margin">
              <div class="installation-filters-group">
                <div
                  class={state.isAllVisible ? "chip body-small active" : "chip body-small"}
                  onClick={msg("ToggleAll") as any}
                >
                  {tr("fibTechPanel.allInstalls")}
                </div>
                <div
                  class={state.isBreakdownVisible ? "chip body-small active" : "chip body-small"}
                  onClick={msg("ToggleBreakdowns") as any}
                >
                  {tr("fibTechPanel.showBreakdowns")}
                </div>
                <div
                  class={state.isErrorVisible ? "chip body-small active" : "chip body-small"}
                  onClick={msg("ToggleErrors") as any}
                >
                  {tr("fibTechPanel.showErrors")}
                </div>
              </div>
              <span class="input-icon-after box3 ">
                <input
                  class="body-large"
                  type="text"
                  value={state.searchPhrase}
                  placeholder={tr("general.search")}
                  onInput={(event: any) => msg("SearchPhrase", event.target.value)}
                />
                <i class="icon-search"></i>
              </span>
            </div>
            {techInstallationsList(state)}
          </div>
          <div class="box1"></div>
        </div>
      </main>
    </>
  );
}

function techInstallationsList(state: State) {
  return (
    <>
      <div class="client-installations">
        <ul class="installation-list list">
          <>
            {(state.userState?.installations || [])
              .filter(createFilter(state))
              .slice((state.currentPage - 1) * PER_PAGE, state.currentPage * PER_PAGE)
              .map((installation) =>
                ["admin_home", "technician_home"].includes(state.userState?.licenseType as string)
                  ? installationHomeRow(state, installation)
                  : installationRow(state, installation)
              )}
          </>
        </ul>
      </div>
      <div>
        {nofPages(state) > 1 && (
          <sw-pagination
            currentPage={state.currentPage}
            numberOfPages={nofPages(state)}
            routeName="techInstallsListPage"
            routeParams={{}}
          />
        )}
      </div>
    </>
  );
}

function installationHomeRow(state: State, installation: MiniInstallation) {
  // installacja moze byc runningState: online/offline
  // lub status: aktywna/nieaktywna
  // lub installationState: normal/warning/error

  let runningState = installation.runningStatus === "active"
    ? tr("fibTechPanel.installationIsWorking")
    : tr("fibTechPanel.installationIsOffline");
  let isActive = true;

  const timeOffline = Date.now() - (1000 * 60 * 5);
  if (installation.updatedAt < timeOffline || installation.status !== "active") {
    isActive = false;
  }

  if (installation?.alerts && installation?.alerts.length > 0) {
    // TODO need to know structure of alerts to display correct information
    // installationStatus = tr("fibTechPanel.breakdown");
  }
  if (["error", "warning"].includes(installation.installationState)) {
    // installationStatus = tr("fibTechPanel.breakdown");
  }

  return (
    <li>
      <div
        title={installation.installationState === "warning"
          ? tr("fibTechPanel.installationWarning")
          : ""}
        className={`install-list-issue ${
          (installation.installationState === "warning" || installation.alerts.length > 0) &&
          "yellow-triangle"
        }`}
      >
      </div>
      <div class="well body-large">
        <a
          href={router.getRouteUrl("techHomePanel", { installationId: installation.id })}
          className={`title ${isActive ? "" : "install-disabled"}`}
        >
          {installation.name}
        </a>
        <div className={"customer-buttons"}>
          <a
            class="button-secondary h100 hbox"
            href="/home"
          >
            {tr("fibTechPanel.goToCustomer")}
          </a>
          <div class={`${!isActive ? "install-offline" : "install-online"}`}>
            {!isActive && `[${tr("fibHome.installationNotActive")}] `}
            {runningState}
          </div>
        </div>
      </div>
    </li>
  );
}

function installationRow(state: State, installation: MiniInstallation) {
  let status = installation.runningStatus;
  return (
    <li>
      <div
        title={installation.installationState === "warning"
          ? tr("fibTechPanel.installationWarning")
          : ""}
        className={`aa install-list-issue ${
          // installation.installationState === "error" && "red-square" ||
          (installation.installationState === "warning" || installation.alerts.length > 0) &&
          "yellow-triangle"}`}
      >
      </div>
      <div class="well body-large">
        <a
          href={router.getRouteUrl("techInstallPanel", { installationId: installation.id })}
          className={`title ${status !== "active" && "install-disabled"}`}
        >
          {installation.name}
          {status !== "active" &&
            (
              <div class="badge-gray body-small install-disabled">
                {status === "pending"
                  ? tr("fibTechPanel.installationPending")
                  : tr("fibTechPanel.disableInstallationStatus")}
              </div>
            )}
        </a>
        <div class="actions techpanel hbox ">
          <a
            class="button-secondary h100 hbox"
            href={router.getRouteUrl("managerDashboards", {
              installationId: installation.id,
            })}
          >
            {tr("fibTechPanel.goToManager")}
          </a>
          <div class="button-secondary h100 hbox">
            <a
              className="hbox"
              href={router.getRouteUrl("messagePanel", {
                installationId: installation.id,
              })}
            >
              <div class="techpanel-icon">
                <i class="icon-envelope" id="fix-icon"></i>
              </div>
              <div>{tr("fibTechPanel.sendEmail")}</div>
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}

function nofPages(state: State) {
  return Math.ceil(
    (state.userState?.installations?.filter(createFilter(state)).length ?? 1) / PER_PAGE,
  );
}

function createFilter(state: State) {
  return function (installation: MiniInstallation) {
    if (
      state.searchPhrase &&
      !installation.name?.toLowerCase().includes(state.searchPhrase.toLowerCase())
    ) {
      return false;
    }

    // TODO add condition based on data from database

    if (installation.runningStatus === "active") {
      if (installation.installationState === "warning" && state.isBreakdownVisible) {
        return true;
      }
      if (installation.installationState === "error" && state.isErrorVisible) {
        return true;
      }
      if (
        !state.isBreakdownVisible && !state.isErrorVisible &&
        installation.installationState !== "error" && installation.installationState !== "warning"
      ) {
        return true;
      }
      return state.isAllVisible &&
        (installation.installationState === "normal" || !installation.installationState);
    }
    return (state.isBreakdownVisible || state.isErrorVisible);
  };
}

async function installToggleConfirmation(data: MiniInstallation): Promise<Msg> {
  const message = data.runningStatus === "active"
    ? tr("fibTechPanel.confirmShutdown", { name: data.name ?? "" as any })
    : tr("fibTechPanel.confirmStartup", { name: data.name ?? "" as any });

  const result = await modal.confirm({
    text: message,
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  return result ? msg("onOffConfirmed", data.id) : msg("onOffResign");
}
