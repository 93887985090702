import { v } from "./v";
import { dates, flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { Organization } from "nexus/node/OrganizationAccess";
import { match, P } from "ts-pattern";
import { InstallationAccess } from "nexus/node/contracts";

const resourceManager = grow.plant("ResourceManager");

type Msg =
  | [type: "LoadOrgsFailed"]
  | [type: "LoadOrgsSuccess", orgs: Organization[]]
  | [type: "ChangeFilterOrgs", phrase: string]
  | [type: "ChangeFilterAccesses", phrase: string]
  | [type: "UrlChange"]
  | [type: "LoadAccessesFailed"]
  | [type: "LoadAccessesSuccess", acceses: InstallationAccess[]]
  | [type: "SortBy", sortedBy: string[]];

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  isOrgsLoading: boolean;
  isAccessesLoading: boolean;
  orgs: Organization[];
  accesses: InstallationAccess[];
  selectedOrg?: Organization;
  filterOrgs: string;
  filterAccesses: string;
  sortedBy: string[];
}

stm.component({
  tagName: "fib-org-list",
  shadow: false,
  debug: false,
  willMount(cmp: any, dispatch: stm.Dispatch<Msg>) {
    cmp.urlChangeListener = () => dispatch(msg("UrlChange"));
    router.addEventListener("routeChange", cmp.urlChangeListener);
  },
  willUnmount(cmp: any) {
    router.removeEventListener("routeChange", cmp.urlChangeListener);
  },
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isOrgsLoading: false,
        isAccessesLoading: false,
        orgs: [],
        accesses: [],
        filterOrgs: "",
        filterAccesses: "",
        sortedBy: ["accessType"],
      },
      loadOrgs(),
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["UrlChange"], () => {
      const route = router.getCurrentRoute();

      if (route.name !== "adminListUsersByOrg" && route.name !== "adminListUsers") {
        return [state, null];
      }

      const orgId = route.params["orgId"];
      const org = state.orgs.find((org: any) => org.id === orgId);

      if (!org) {
        delete state.selectedOrg;
        return [{ ...state, isAccessesLoading: true }, loadUnassignedUsers()];
      }

      return [
        { ...state, selectedOrg: org, isAccessesLoading: true },
        loadAccesses(orgId),
      ];
    })
    .with(["ChangeFilterOrgs", P.select()], (filterOrgs) => [
      { ...state, filterOrgs },
      null,
    ])
    .with(["ChangeFilterAccesses", P.select()], (filterAccesses) => [
      { ...state, filterAccesses },
      null,
    ])
    .with(["SortBy", P.select()], (sortedBy) => [
      {
        ...state,
        sortedBy,
      },
      null,
    ])
    .with(["LoadOrgsFailed"], () => {
      if (grow.sessionId !== null) {
        flashMessage(tr("fibOrgList.loadOrgsFailed"), "error");
      }
      return [
        { ...state, isOrgsLoading: false },
        null,
      ];
    })
    .with(["LoadOrgsSuccess", P.select()], (orgs) => [
      {
        ...state,
        isOrgsLoading: false,
        orgs,
      },
      msg("UrlChange"),
    ])
    .with(["LoadAccessesFailed"], () => {
      flashMessage(tr("fibOrgList.loadAccessesFailed"), "error");

      return [
        { ...state, isAccessesLoading: false },
        null,
      ];
    })
    .with(["LoadAccessesSuccess", P.select()], (accesses) => [
      {
        ...state,
        isAccessesLoading: false,
        accesses,
      },
      null,
    ])
    .exhaustive();
}

async function loadOrgs() {
  try {
    const result = await resourceManager.listAllOrgs();
    return msg("LoadOrgsSuccess", result);
  } catch (err) {
    console.error("loading orgs failed", err);
    return msg("LoadOrgsFailed");
  }
}

async function loadAccesses(orgId: string) {
  try {
    const result = await resourceManager.listOrgAccesses(orgId);
    return msg("LoadAccessesSuccess", result);
  } catch (err) {
    console.error("loading accesses failed", err);
    return msg("LoadAccessesFailed");
  }
}

async function loadUnassignedUsers() {
  try {
    const result = await resourceManager.listUnassignedUsers();
    return msg("LoadAccessesSuccess", result);
  } catch (err) {
    console.error("loading accesses failed", err);
    return msg("LoadAccessesFailed");
  }
}

function view(state: State) {
  return (
    <div class="fib-org-list vbox center">
      <nav class="grid secondary-nav">
        <div class="box12">
          <h1 class="h600">
            {tr("fibOrgList.title")}
          </h1>
        </div>
      </nav>
      <div class="grid">
        <div class="box2 vbox">
          <div class="search hbox">
            <span class="input-icon-after">
              <input
                class="body-medium"
                type="text"
                value={state.filterOrgs}
                onInput={(event: any) => msg("ChangeFilterOrgs", event.target.value)}
                placeholder={tr("general.search")}
              />
              <i class="icon-search"></i>
            </span>
            <a class="button-secondary square-button" href={router.getRouteUrl("adminAddOrg")}>
              <i class="icon-plus-bare"></i>
            </a>
          </div>

          <ul class="list box2 org-list">
            <>
              {state.orgs
                .filter((org) => {
                  if (state.filterOrgs) {
                    return org.name.toLowerCase().includes(state.filterOrgs.toLowerCase());
                  }
                  return true;
                })
                .map((org) => (
                  <li>
                    <a
                      class={"well hbox " +
                        (state.selectedOrg?.id === org.id ? "active h300" : "body-large")}
                      href={state.selectedOrg?.id === org.id
                        ? router.getRouteUrl("adminListUsers")
                        : router.getRouteUrl("adminListUsersByOrg", { orgId: org.id })}
                    >
                      {org.name}
                      <a
                        class="text-button"
                        href={router.getRouteUrl("adminOrg", { orgId: org.id })}
                      >
                        <i class="icon-pen"></i>
                      </a>
                      <i class="icon-check"></i>
                    </a>
                  </li>
                ))}
            </>
          </ul>
        </div>
        <div class="box10">
          {accessesView(state)}
        </div>
      </div>
    </div>
  );
}

function accessesView(state: State) {
  if (state.isAccessesLoading) {
    return <div class="loader-big"></div>;
  }
  return (
    <div>
      <div>
        <h3 class="h400 sub-title">
          {!!state.selectedOrg && tr("fibOrgList.users") + " - " + state.selectedOrg?.name}
          {!state.selectedOrg && tr("fibOrgList.unassignedUsers")}
        </h3>
        <div class="hbox">
          <span class="input-icon-after box2">
            <input
              class="body-medium"
              type="text"
              value={state.filterAccesses}
              onInput={(event: any) => msg("ChangeFilterAccesses", event.target.value)}
              placeholder={tr("general.search")}
            />
            <i class="icon-search"></i>
          </span>
          <a
            class="button-secondary h200"
            href={router.getRouteUrl("adminUserAdd", {
              orgId: state.selectedOrg?.id ?? "all",
              basePanel: "admin",
            })}
          >
            {tr("fibOrgList.addUser")}
            <i class="icon-plus"></i>
          </a>
        </div>
      </div>
      <table>
        <thead>
          <tr class="h200">
            <th>
              <div class="hbox">
                {tr("fibOrgList.user")}
                <i
                  class="icon-sort"
                  onClick={msg("SortBy", ["firstName", "lastName", "email"]) as any}
                >
                </i>
              </div>
            </th>
            <th>
              <div class="hbox">
                {tr("fibOrgList.installation")}
                <i class="icon-sort" onClick={msg("SortBy", ["installationName"]) as any}></i>
              </div>
            </th>
            <th class="license-type">
              <div class="hbox">
                {tr("fibOrgList.licenseType")}
                <i class="icon-sort" onClick={msg("SortBy", ["accessType"]) as any}></i>
              </div>
            </th>
            <th class="license">
              <div class="hbox">
                {tr("fibOrgList.license")}
                <i class="icon-sort" onClick={msg("SortBy", ["validUntil"]) as any}></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="body-large">
          <>
            {state.accesses
              .filter(createFilter(state))
              .map((access) => (
                <tr>
                  <td>
                    <a
                      href={router.getRouteUrl("adminUserEdit", {
                        userId: encodeURIComponent(access.identityId),
                        basePanel: "admin",
                      })}
                    >
                      {`${access.firstName} ${access.lastName} <${access.email}>`}
                    </a>
                  </td>
                  <td>{access.installationName}</td>
                  <td class="license-type">
                    {match<string, v.View<Msg>>(access.accessType)
                      .with(
                        "technician",
                        () => <div class="badge-gray body-small">{tr("fibOrgList.technician")}
                        </div>,
                      )
                      .with(
                        "technician_home",
                        () => <div class="badge-gray body-small">{tr("access.technicianHome")}
                        </div>,
                      )
                      .with(
                        "user_home",
                        () => <div class="badge-gray body-small">{tr("access.userHome")}</div>,
                      )
                      .with(
                        "manager",
                        () => <span class="badge-gray body-small">{tr("fibOrgList.manager")}</span>,
                      )
                      .with(
                        "admin",
                        () => <span class="badge-gray body-small">{tr("access.admin")}</span>,
                      )
                      .with(
                        "admin_home",
                        () => <span class="badge-gray body-small">{tr("access.adminHome")}</span>,
                      )
                      .with(P._, () => <span></span>)
                      .exhaustive()}
                  </td>
                  <td class="license">
                    {access.validUntil < Date.now() &&
                      <div class="badge-red body-small">{tr("license.inactive")}</div>}

                    {access.validUntil + twoWeeks() < Date.now() &&
                      access.validUntil > Date.now() &&
                      (
                        <div class="badge-orange body-small">
                          {dates.formatDate(access.validUntil)}
                        </div>
                      )}

                    {access.validUntil + twoWeeks() >= Date.now() &&
                      (
                        <div class="badge-green body-small">
                          {dates.formatDate(access.validUntil)}
                        </div>
                      )}
                  </td>
                </tr>
              ))}
          </>
        </tbody>
      </table>
    </div>
  );
}

function twoWeeks() {
  return 14 * 24 * 60 * 60 * 1000;
}

function createFilter(state: State) {
  const phrase = state.filterAccesses.toLowerCase();

  return function (users: InstallationAccess) {
    if (
      !phrase ||
      users.firstName.toLowerCase().includes(phrase) ||
      users.lastName.toLowerCase().includes(phrase) ||
      users.email.toLowerCase().includes(phrase)
    ) {
      return true;
    }
    return false;
  };
}
