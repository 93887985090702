import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { WeatherSlotConfig } from "nexus/node/contracts";

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "LoadingWeatherSuccess", weather: Weather]
  | [type: "LoadingWeatherError"];

type Weather = {
  id: number;
  main: string;
  desc: string;
  icon: string;
};

interface State {
  weather: Weather;
  weatherSlotConfig: WeatherSlotConfig;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-manager-weather-slot",
  shadow: false,
  debug: false,
  propTypes: {
    weatherSlotConfig: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      weather: {
        desc: "",
        icon: "",
        main: "",
        id: 0,
      },
      weatherSlotConfig: {
        type: "weather",
        title: tr("params.weather"),
        order: 0,
        refresh: true,
      },
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "weatherSlotConfig", P.select()], (weatherSlotConfig) => {
      state.weatherSlotConfig = weatherSlotConfig as WeatherSlotConfig;
      return [state, state.weatherSlotConfig.refresh ? loadWeather() : null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["LoadingWeatherSuccess", P.select()], (weather) => {
      state.weather = weather;
      return [state, null];
    })
    .with(["LoadingWeatherError"], () => {
      flashMessage(tr("general.loadFailed"), "error");
      return [state, null];
    })
    .exhaustive();
}

async function loadWeather() {
  // todo(karol): generate Fibrain's appid and set it as a environment variable
  const appid = "5dcdbb6df36458d5e138d0e16fc5965e";
  const { coords: { latitude, longitude } } = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  }) as GeolocationPosition;
  let weather;
  try {
    const response = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${appid}&lang=pl`,
    );
    weather = await response.json();
  } catch (err) {
    return msg("LoadingWeatherError");
  }

  return msg("LoadingWeatherSuccess", weather.weather[0]);
}

function view(state: State) {
  const { order, title } = state.weatherSlotConfig;
  return (
    <div className={`body-large ${order > 4 ? "mgr-dash-side-slot" : "mgr-dash-data-slot"}`}>
      <div className={"h500"}>{title}</div>
      <div className={"mgr-dash-eco-data"}>
        <div>
          {renderWeatherIcon(state)}
        </div>
      </div>
    </div>
  );
}

function renderWeatherIcon(state: State) {
  const { desc, id } = state.weather;
  const path = "./static/weather-icons/";
  if (id === 0) {
    return <div class={"loader-big"} />;
  }

  // https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2
  switch (id) {
    case 801:
      return <img src={`${path}cloudy-day-1.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    case 802:
      return <img src={`${path}cloudy-day-2.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    case 803:
      return <img src={`${path}cloudy-day-3.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    case 804:
      return <img src={`${path}cloudy.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    case 800:
      return <img src={`${path}day.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    case 500:
      return <img src={`${path}rainy-4.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    case 511:
      return <img src={`${path}rainy-7.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    case 600:
      return <img src={`${path}snowy-1.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    case 622:
      return <img src={`${path}snowy-6.svg`} alt={desc} class={"weather-icon"} title={desc} />;
    default:
      if (id >= 200 && id <= 299) {
        return <img src={`${path}thunder.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
      if (id >= 300 && id <= 310) {
        return <img src={`${path}rainy-2.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
      if (id >= 311 && id <= 399) {
        return <img src={`${path}rainy-3.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
      if (id >= 520 && id <= 599) {
        return <img src={`${path}rainy-6.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
      if (id >= 501 && id <= 510) {
        return <img src={`${path}rainy-6.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
      if (id >= 601 && id <= 602) {
        return <img src={`${path}snowy-2.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
      if (id >= 611 && id <= 615) {
        return <img src={`${path}snowy-4.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
      if (id >= 616 && id <= 621) {
        return <img src={`${path}snowy-5.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
      if (id >= 701 && id <= 799) {
        return <img src={`${path}cloudy.svg`} alt={desc} class={"weather-icon"} title={desc} />;
      }
  }

  return <></>;
}
