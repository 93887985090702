import { router } from "@7willows/sw-lib";

export type Unsubscribe = () => void;
export type Handler = (props: any, data: any) => void;

// let primus!: typeof Primus;
const subscriptions: { [topic: string]: Handler[] } = {};

export function init() {
  const url = new URL(window.location.href);
  url.port = "8090";
  url.search = `sessionId=${grow.sessionId}&client=web`;

  const route = router.getCurrentRoute();

  if (route.name === "dashFullscreen") {
    url.search += "&dashboard=" + route.params.dashId +
      "&token=" + route.params.token +
      "&installationId=" + route.params.installationId;
  }

  // primus = Primus.connect(url.toString());

  // primus.on("reconnected", function () {
  //   Object.keys(subscriptions).forEach((msgType) => {
  //     if (subscriptions[msgType].length > 0) {
  //       primus.write({
  //         type: "addMessageInterest",
  //         msgType,
  //       });
  //     }
  //   });
  // });
}

export async function subscribe(
  topic: string,
  handler: Handler,
): Promise<Unsubscribe> {
  // primus.write({ type: "addMessageInterest", topic });

  subscriptions[topic] = subscriptions[topic] || [];
  subscriptions[topic].push(handler);

  return () => {
    // primus.write({ type: "removeMessageInterest", topic });
    subscriptions[topic] = subscriptions[topic].filter((h) => h !== handler);
  };
}

export async function waitFor(
  topic: string,
  timeout: number = 10_000,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const unsubPromise = subscribe(topic, (_props, data) => {
      resolve(data);
    });

    setTimeout(() => {
      unsubPromise.then((unsub) => unsub());
      reject(new Error(`Timeout exceeded (${timeout} ms)`));
    }, timeout);
  });
}
