import { flashMessage, modal, router, stm, tr } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';
import { accessesPhoneNumbers } from './view-utils';
import { Message } from 'nexus/node/contracts';
import { UserState } from 'nexus/node/MetricsManager';
import _ from 'lodash';

const metricsManager = grow.plant('MetricsManager');

const messageManager = grow.plant('MessageManager');

type LoadData = any;
type Msg = [type: 'Attr', name: string, value: unknown]
  | [type: 'LoadDataFailed']
  | [type: 'LoadDataSuccess', data: LoadData]
  | [type: 'InsertSubject', subject: string]
  | [type: 'InsertContent', message: string]
  | [type: 'InsertDate', plannedDate: string]
  | [type: 'InsertTime', plannedTime: string]
  | [type: 'SendMessage']
  | [type: 'SendMessageCanceled']
  | [type: 'SendMessageFailed']
  | [type: 'SendMessageSuccess']

interface State {
  userState?: UserState;
  installationId: string;
  installation?: LoadData;
  subject: string;
  message: string;
  isInProgress: boolean;
  reset: boolean;
  plannedDate: string;
  plannedTime: string;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: 'fib-tech-message-panel',
  shadow: false,
  debug: false,
  propTypes: {
    userState: Object
  },
  attributeChangeFactory: (name: string, value: any) => msg('Attr', name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state: State = {
      installationId: router.getCurrentRoute().params.installationId,
      subject: '',
      message: '',
      isInProgress: false,
      reset: false,
      plannedDate: '',
      plannedTime: ''
    };
    return [state, loadData(state.installationId)];
  },
  update,
  view
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(['Attr', 'userState', P.select()], userState => {
      state.userState = userState as UserState;
      return [state, null];
    })
    .with(['Attr', P._, P._], () => {
      return [state, null];
    })
    .with(['LoadDataFailed'], () => {
      flashMessage(tr('fibAdminInstallation.loadDataFailed'), 'error');
      return [{ ...state, isLoading: false }, null];
    })
    .with(['LoadDataSuccess', P.select()], installation => {
      state.installation = installation;
      return [state, null];
    })
    .with(['InsertSubject', P.select()], subject => {
      state.subject = subject;
      return [state, null];
    })
    .with(['InsertContent', P.select()], message => {
      state.message = message;
      state.reset = false;
      return [state, null];
    })
    .with(['InsertDate', P.select()], data => {
      state.plannedDate = data;
      return [state, null];
    })
    .with(['InsertTime', P.select()], data => {
      state.plannedTime = data;
      return [state, null];
    })
    .with(['SendMessage'], () => {
      state.isInProgress = true;
      return [state, sendMessage(state)];
    })
    .with(['SendMessageCanceled'], () => {
      state.isInProgress = false;
      flashMessage(tr('fibTechPanel.sendMessageCanceled'), 'info');
      return [state, null];
    })
    .with(['SendMessageFailed'], () => {
      state.isInProgress = false;
      flashMessage(tr('fibTechPanel.sendMessageFailed'), 'error');
      return [state, null];
    })
    .with(['SendMessageSuccess'], () => {
      flashMessage(tr('fibTechPanel.sendMessageSuccess'), 'success');
      state.subject = '';
      state.message = '';
      state.isInProgress = false;
      state.reset = true;
      return [state, null];
    })
    .exhaustive();
}

async function sendMessage(state: State) {
  const { subject, message: body } = state;

  if (!subject && !await renderModal(subject, tr('fibTechPanel.emptySubject'))) {
    return msg('SendMessageCanceled');
  }

  const el = document.createElement('div');
  el.innerHTML = body;
  const text = el.innerText;
  const modalBody = tr('fibTechPanel.emptyMessage');

  if ((!body || body === '<p><br/></p>' || _.isEmpty(text)) && !await renderModal(body, modalBody)) {
    return msg('SendMessageCanceled');
  }

  const message: Message = {
    subject,
    body,
    transport: 'email',
    sender: {
      fullName: state.userState?.userName,
      address: state.userState?.identityId as string
    },
    targets: [{
      workspaceId: state.installationId,
      fields: {}
    }],
    templateId: 'fib-tech-message-panel'
  };

  let planToSend;

  if (state.plannedDate !== '') {
    planToSend = new Date(state.plannedDate).getTime();
  }

  if (state.plannedTime !== '') {
    if (state.plannedDate !== '') {
      planToSend = new Date(state.plannedDate + ' ' + state.plannedTime).getTime();
    } else {
      const timeSplit = state.plannedTime.split(':');
      planToSend = new Date(Date.now() + (parseInt(timeSplit[0]) * 3600 * 1000) + (parseInt(timeSplit[1]) * 60 * 1000)).getTime();
    }
  }

  try {
    await messageManager.send(message, (typeof planToSend === 'number' ? planToSend : 'now'));
    return msg('SendMessageSuccess');
  } catch (err) {
    return msg('SendMessageFailed');
  }
}

async function renderModal(element: string, body: string) {
  return await modal({
    large: false,
    header: tr('fibTechPanel.warning'),
    body: body,
    footer: close => <>
      <button class={'button button-primary h300'} onClick={() => close(true)}>{tr('general.yes')}</button>
      <button class={'button button-secondary h300'} onClick={() => close(false)}>{tr('general.no')}</button>
    </>
  }) as boolean;
}

async function loadData(installationId: string): Promise<Msg> {
  try {
    const result = await metricsManager.installationWithAlarms(installationId);
    return msg('LoadDataSuccess', result);
  } catch (err) {
    return msg('LoadDataFailed');
  }
}

function view(state: State) {
  return <>
    {renderNav(state)}
    <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
    <div className="grid install-details">
      <div className="box1"></div>
      <div className="box10">
        <div className="vbox">
          <div className="container message-wrapper">
            <h1 class={'h400'}>{tr('fibTechPanel.composeSubject')}</h1>
            <input onInput={(e: Event) => msg('InsertSubject', (e.target as HTMLInputElement).value)}
              value={state.subject} class={'body-large message-subject'} type={'text'}
              placeholder={tr('fibTechPanel.composeSubjectPlaceholder')} />
            <h1 className={'h400'}>{tr('fibTechPanel.composeContent')}</h1>
            <fib-quill onupdate={(e: CustomEvent) => msg('InsertContent', e.detail)} reset={state.reset} />
            <div className="message-timing">
              <h4 className="message-timing-header">{tr('fibTechPanel.planSendTiming')}</h4>
              <input onInput={(e: any) => msg('InsertDate', (e.target as HTMLInputElement).value)} type={'date'} name={'messageDate'} min={Date.now()} max="2050-12-31" class={'message-date'} />
              <i className="icon-calendar margin-left " />
              <input onInput={(e: any) => msg('InsertTime', (e.target as HTMLInputElement).value)} type={'time'} name={'messageTime'} min="00:00" max="23:59" class={'message-time'} />
            </div>
            <button onClick={() => msg('SendMessage')} disabled={state.isInProgress}
              class={'button button-primary h200 button-message'}>
              {tr('fibTechPanel.composeSend')}</button>
          </div>
        </div>
      </div>
      <div className="box1"></div>
    </div>
  </>;
}

function renderNav(state: State) {
  return <>
    <nav class="main-nav">
      <div class="hbox left tech-panel">
        <a href={router.getRouteUrl('techInstallsList')}
          class="square-button button-primary">
          <i class="icon-chevron-left"></i>
        </a>
        <div class="vbox left">
          <div class="h400">{state.installation?.name}</div>
          <div class="body-medium">
            <div class="hbox left">
              <div>
                <span class="margin-right">{state.installation?.street ?? ''}</span>
                <span class="margin-right">{state.installation?.houseNumber ?? ''},</span>
                <span class="margin-right">{state.installation?.postcode ?? ''}</span>
                <span>{state.installation?.city ?? ''}</span>
              </div>
              <div class="tech-panel-space">
                <i class="icon-phone"></i>
                {accessesPhoneNumbers(state.installation?.managers || [])}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center menu-center">
        <div class={'h400'}>{tr('fibTechPanel.sendingMessage')}</div>
      </div>
      <div class="right-empty-box"></div>
    </nav>
  </>;
}

