import { dates, flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import _ from "lodash";

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "AutoControl"]
  | [type: "ForceControl"]
  | [type: "LoadError"]
  | [type: "ToggleInstallation"]
  | [
    type: "LoadControlsSuccess",
    data: {
      autoControl: boolean;
      forceControl: boolean;
      lightingState: boolean;
      duskBegin: string;
      duskEnd: string;
      online: boolean;
      lastUpdate: number;
    },
  ]
  | [type: "SaveControlError", data: { auto?: boolean; force?: boolean }]
  | [type: "SaveControlSuccess", data: { auto?: string; force?: string }]
  | [type: "SunsetSunrise", data: { sunrise: string; sunset: string }];

interface State {
  installationId: string;
  installationActive: boolean;
  isZiebice: boolean;
  autoControl: boolean;
  forceControl: boolean;
  coords: { lat: number; lng: number };
  sunrise: string;
  sunset: string;
  lightingState: boolean;
  online: boolean;
  lastUpdate: number;
}

const msg = (...args: Msg): Msg => args;

const installationManager = grow.plant("InstallationManager");

stm.component({
  tagName: "fib-manager-illuminations",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
    ziebiceOptions: String,
    coords: Object,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init() {
    const state: State = {
      installationId: "",
      autoControl: false,
      forceControl: false,
      coords: {
        lat: 0,
        lng: 0,
      },
      sunrise: "",
      sunset: "",
      lightingState: false,
      online: false,
      lastUpdate: 0,
      installationActive: localStorage.getItem("installationActive") === "1",
      isZiebice: false,
    };

    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installationId", P.select()], (installationId) => {
      if (installationId && _.isString(installationId)) {
        state.installationId = installationId;
      }
      return [state, loadControls(state.installationId)];
    })
    .with(["Attr", "ziebiceOptions", P.select()], (ziebice) => {
      state.isZiebice = ziebice === "true";
      return [state, null];
    })
    .with(["Attr", "coords", P.select()], (coords) => {
      state.coords = coords;

      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["ToggleInstallation"], () => {
      const value = localStorage.getItem("installationActive") === "1";
      if (value) {
        localStorage.setItem("installationActive", "0");
      } else {
        localStorage.setItem("installationActive", "1");
      }
      state.installationActive = value;
      return [state, null];
    })
    .with(["AutoControl"], () => {
      state.autoControl = !state.autoControl;
      if (state.autoControl) {
        state.forceControl = false;
      }
      return [state, saveAutoControl(state.installationId, state.autoControl)];
    })
    .with(["ForceControl"], () => {
      state.forceControl = !state.forceControl;
      const value = localStorage.getItem("installationActive") === "1";
      if (value) {
        localStorage.setItem("installationActive", "0");
      } else {
        localStorage.setItem("installationActive", "1");
      }
      state.installationActive = value;
      return [state, saveForceControl(state.installationId, state.forceControl)];
    })
    .with(["LoadError"], () => {
      flashMessage(tr("general.loadFailed"), "error");
      return [state, null];
    })
    .with(["LoadControlsSuccess", P.select()], (data) => {
      state.autoControl = data.autoControl;
      state.forceControl = data.forceControl;
      state.lightingState = data.lightingState;

      state.sunrise = data.duskEnd;
      state.sunset = data.duskBegin;

      state.online = data.online;
      state.lastUpdate = data.lastUpdate;

      return [
        state,
        data.duskEnd === "0001-01-01T00:00:00Z" ? loadSunsetSunrise(state.coords) : null,
      ];
    })
    .with(["SaveControlError", P.select()], (data) => {
      if (data) {
        const lightingKey = Object.keys(data)[0];
        state[`${lightingKey}Control`] = data[lightingKey];
      }

      flashMessage(tr("general.saveFailed"), "error");
      return [state, null];
    })
    .with(["SaveControlSuccess", P.select()], (data) => {
      if (data.auto) {
        state.autoControl = data.auto === "enabled" ? true : false;
      }
      if (data.force) {
        state.forceControl = data.force === "enabled" ? true : false;
      }

      flashMessage(tr("general.saveSuccess"), "success");

      return [state, null];
    })
    .with(["SunsetSunrise", P.select()], (data) => {
      state.sunrise = data.sunrise;
      state.sunset = data.sunset;
      return [state, null];
    })
    .exhaustive();
}

async function loadControls(installationId: string) {
  try {
    // todo(): add here simple userState where are details only for manager and where lighting is readed as well
    const data = await installationManager.getInstallationDetails(installationId);
    const last5min = Date.now() - 1000 * 60 * 5;
    const lastUpdate = new Date(data.updatedAt).getTime();
    const isOnline = lastUpdate > last5min;

    const res = {
      autoControl: data.lightingAuto,
      forceControl: data.lightingForce,
      lightingState: data.lighting,
      duskBegin: data.duskBegin === "0001-01-01T00:00:00Z"
        ? data.duskBegin
        : new Date(data.duskBegin).toLocaleTimeString("pl"),
      duskEnd: data.duskEnd === "0001-01-01T00:00:00Z"
        ? data.duskEnd
        : new Date(data.duskEnd).toLocaleTimeString("pl"),
      online: isOnline ? data.online : false,
      lastUpdate: data.updatedAt,
    };

    return msg("LoadControlsSuccess", res);
  } catch (err) {
    return msg("LoadError");
  }
}

async function saveAutoControl(instId: string, value: boolean) {
  try {
    const res = await installationManager.toggleAutoLight(instId, value);
    if (!res.auto) {
      return msg("SaveControlError", { auto: !value });
    }
    return msg("SaveControlSuccess", res);
  } catch (err) {
    return msg("SaveControlError", { auto: !value });
  }
}

async function saveForceControl(instId: string, value: boolean) {
  try {
    const res = await installationManager.toggleForceLight(instId, value);
    if (!res.force) {
      return msg("SaveControlError", { force: !value });
    }
    return msg("SaveControlSuccess", res);
  } catch (err) {
    return msg("SaveControlError", { force: !value });
  }
}

async function loadSunsetSunrise(coords: { lat: number; lng: number }) {
  try {
    const res = await fetch(
      `https://api.sunrise-sunset.org/json?lat=${coords.lat}&lng=${coords.lng}&formatted=0`,
    );
    const data = await res.json();
    const sunset = new Date(data.results.sunset).toLocaleTimeString("pl");
    const sunrise = new Date(data.results.sunrise).toLocaleTimeString("pl");

    return msg("SunsetSunrise", { sunset, sunrise });
  } catch (err) {
    return msg("LoadError");
  }
}

function view(state: State): stm.View<Msg> {
  if (!state.installationId) {
    return (
      <div className="fib-manager-logs loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }

  const isManualSwitchAvailable = state.online && !state.autoControl;
  const isOnline = state.online ? "" : tr("fib.manager.installationOffline");
  const manualSwitchAlert = (!state.autoControl && !state.online)
    ? tr("fib.manager.illuminationsForceControlNotAvailable")
    : "";

  return (
    <div class="fib-manager-logs">
      <div class="grid title-container">
        <div class="box3"></div>
        <div class="box6 vbox">
          <h1 class="h600">
            {!state.isZiebice ? tr("general.illuminations") : "Sterowanie instalacją"}
          </h1>
        </div>
        <div class="box3"></div>
      </div>

      <div class="grid">
        <div class="box3"></div>

        <div class="box6 vbox">
          {state.isZiebice
            ? ziebiceOptions(state, { isManualSwitchAvailable, isOnline, manualSwitchAlert })
            : lublinOptions(state, { isManualSwitchAvailable, isOnline, manualSwitchAlert })}
        </div>
        <div class="box3"></div>
      </div>
    </div>
  );
}

function ziebiceOptions(state: State, cfg: {
  isManualSwitchAvailable: boolean;
  isOnline: string;
  manualSwitchAlert: string;
}) {
  // temporary only for 1 installation

  return (
    <>
      <div class={`lighting-state ${state.online ? "on" : "off"}`}>
        <span>{cfg.isOnline}</span>
        <span>{cfg.manualSwitchAlert}</span>
      </div>
      <div class="switch-panel">
        <div class="switch-panel-left">
          <span class="switch-state-on">
            Sterowanie ręczne wyłączone
          </span>
          <span class={`switch-state-${cfg.isManualSwitchAvailable ? "on" : "off"}`}>
            Instalacja wyłaczona
          </span>
        </div>
        <div class="switch-panel-center">
          <div
            class={`switch-big ${!state.autoControl ? "switch-on" : "switch-off"} switch-state-on`}
            onClick={() => msg("AutoControl")}
          >
          </div>
          <div
            class={`switch-big ${state.lightingState ? "switch-on" : "switch-off"} switch-state-${
              cfg.isManualSwitchAvailable ? "on" : "off"
            }`}
            onClick={cfg.isManualSwitchAvailable ? () => msg("ForceControl") : ""}
          >
          </div>
        </div>
        <div class="switch-panel-right">
          <span class="switch-state-on">
            Sterowanie ręczne włączone
          </span>
          <span class={`switch-state-${cfg.isManualSwitchAvailable ? "on" : "off"}`}>
            Instalacja włączona
          </span>
        </div>
      </div>
    </>
  );
}

function lublinOptions(
  state: State,
  cfg: {
    isManualSwitchAvailable: boolean;
    isOnline: string;
    manualSwitchAlert: string;
  },
) {
  const lightingState = state.lightingState
    ? tr("fib.manager.lightingStateOn")
    : tr("fib.manager.lightingStateOff");
  const lastUpdate = dates.formatDateTime(state.lastUpdate);

  return (
    <>
      <div class="defrosting-activate-form box4 fib-advanced-col">
        <div class="hbox">
          <span>{tr("fib.manager.sunrise")}:</span>
          <h6>{state.sunrise}</h6>
        </div>

        <div class="hbox">
          <span>{tr("fib.manager.sunset")}:</span>
          <h6>{state.sunset}</h6>
        </div>
        <div class="hbox">
          <span>{tr("fib.manager.illuminationState")}</span>
          <h6 class={`${state.lightingState ? "ilumination-on" : "ilumination-off"}`}>
            {lightingState}
          </h6>
        </div>
        <div class="hbox">
          <span>{tr("fib.manager.lastUpdate")}</span>
          <h6 class={`${state.online ? "ilumination-on" : "ilumination-off"} updated`}>
            {lastUpdate}
          </h6>
        </div>
      </div>
      <div class="defrosting-activate-form box4">
        <div class={`lighting-state ${state.online ? "on" : "off"}`}>
          <span>{cfg.isOnline}</span>
          <span>{cfg.manualSwitchAlert}</span>
        </div>
        <div class="switch-panel">
          <div class="switch-panel-left">
            <span class="switch-state-on">
              {tr("fib.manager.illuminationsForceControl")}
            </span>
            <span class={`switch-state-${cfg.isManualSwitchAvailable ? "on" : "off"}`}>
              {tr("fib.manager.lightingStateOffSwitch")}
            </span>
          </div>
          <div class="switch-panel-center">
            <div
              class={`switch-big ${state.autoControl ? "switch-on" : "switch-off"} switch-state-on`}
              onClick={() => msg("AutoControl")}
            >
            </div>
            <div
              class={`switch-big ${state.lightingState ? "switch-on" : "switch-off"} switch-state-${
                cfg.isManualSwitchAvailable ? "on" : "off"
              }`}
              onClick={cfg.isManualSwitchAvailable ? () => msg("ForceControl") : ""}
            >
            </div>
          </div>
          <div class="switch-panel-right">
            <span class="switch-state-on">
              {tr("fib.manager.illuminationsAutoControl")}
            </span>
            <span class={`switch-state-${cfg.isManualSwitchAvailable ? "on" : "off"}`}>
              {tr("fib.manager.lightingStateOnSwitch")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
