import { v } from "./v";
import { stm, tr, flashMessage } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';

type Msg
  = [type: 'Submit']
  | [type: 'EmailUpdate', email: string]
  | [type: 'Failed', error: string]
  | [type: 'Success']

type State = {
  email: string;
  error: string;
  isLoading: boolean;
  success: boolean;
};

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: 'sec-remind-password-form',
  propTypes: {},
  shadow: false,
  init: () => [
    {
      email: '',
      error: '',
      isLoading: false,
      success: false
    },
    null
  ],
  update,
  view
});

function update(state: State, msg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(msg)
    .with(['EmailUpdate', P.select()], email => [
      { ...state, email },
      null
    ])
    .with(['Submit'], () => [
      { ...state, isLoading: true },
      initPasswordChange(state.email)
    ])
    .with(['Success'], () => [
      { ...state, isLoading: false, success: true },
      null
    ])
    .with(['Failed', P.select()], error => {
      flashMessage(tr('secRemindPasswordForm.initFailed'), 'error');
      return [
        { ...state, isLoading: false, error },
        null
      ];
    })
    .exhaustive();
}

async function initPasswordChange(email: string) {
  try {
    const result = await grow.plant('Security').initiateRemindPassword(email);
    if (result.err) {
      console.error('server returned an error', result.err);
      return msg('Failed', result.err);
    }
    return msg('Success');
  } catch (err) {
    console.error('initiating pass change failed', err);
    return msg('Failed', '');
  }
}

function view(state: State) {
  return v<Msg>('.sec-remind-password-form',
    state.success
      ? v('.h200.success', tr('secRemindPasswordForm.success'))
      : v.form({
        onSubmit: event => {
          event.preventDefault();
          return msg('Submit')
        },
        role: 'form',
        ariaLabel: 'Login form',
        className: state.error ? 'error' : ''
      },
        v('.prop-wrapper',
          v.label({
            htmlFor: 'email',
            className: 'h200'
          }, tr('secRemindPasswordForm.emailLabel')),
          v.input({
            type: 'email',
            id: 'email',
            className: 'body-medium',
            value: state.email,
            onInput: (event: any) => msg('EmailUpdate', event.target.value)
          })
        ),
        !!state.error && v('p.error', tr(state.error)),

        v('.actions',
          state.isLoading && v('.loader-medium'),
          v.button({
            type: 'submit',
            className: 'button-primary h200',
            disabled: !state.email || state.isLoading
          }, tr('secRemindPasswordForm.submit'))
        )
      )
  );
}
