import { flashMessage, modal, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { Param } from "../../nexus/node/contracts";
import { UserState } from "nexus/node/MetricsManager";
import _ from "lodash";
import { checkboxElement, itemTopBar, modalWrapper, nextYear, textElement } from "./view-utils";

type Msg =
  | [type: "AttributeChange", name: string, value: unknown]
  | [type: "LoadDataFailed"]
  | [type: "LoadDataSuccess", params: Param[]]
  | [type: "SavingSuccess"]
  | [type: "SavingFailed"]
  | [type: "SelectInstallation", installationId: string]
  | [type: "SortingBy", prop: string]
  | [type: "EditParam", props: Param]
  | [type: "EditCancelled"]
  | [type: "EditConfirmed", props: Param]
  | [type: "FilterKind", kind: string];

type Device = {
  kind: string;
  name: string;
};

type InstallationRequest = {
  id: string;
  name: string;
  machineId: string;
};

interface State {
  installations: InstallationRequest[];
  data: Param[];
  devicesKind: Device[];
  sortingBy: string;
  isLoading: boolean;
  platform: string;
  userState?: UserState;

  selectedInstallation: string | null;
  selectedDevice: string | null;
  selectedKind: string | null;
  installationDevices: Record<string, Device[]>;
}

const msg = (...args: Msg): Msg => args;
const installationManager = grow.plant("InstallationManager");

stm.component({
  tagName: "fib-admin-params",
  shadow: false,
  debug: false,
  propTypes: {
    platform: String,
    userState: Object,
  },
  attributeChangeFactory: (name: string, value: any) => msg("AttributeChange", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state = {
      installations: [],
      data: [],
      selectedInstallation: null,
      selectedDevice: null,
      selectedKind: null,
      installationDevices: {},
      devicesKind: [],
      sortingBy: "param",
      isLoading: false,
      platform: "fibrain",
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChange", "platform", P.select()], (platform) => {
      state.platform = platform as string;
      return [state, loadData(state.platform)];
    })
    .with(["AttributeChange", "userState", P.select()], (userState) => [
      { ...state, userState: userState as UserState },
      null,
    ])
    .with(["SelectInstallation", P.select()], (installationId) => {
      state.selectedInstallation = installationId === "null" ? null : installationId;
      return [state, null];
    })
    .with(["SortingBy", P.select()], (prop) => {
      state.sortingBy = prop;
      return [state, null];
    })
    .with(["EditParam", P.select()], (prop) => {
      state.isLoading = true;
      return [state, paramPrompt(prop)];
    })
    .with(["EditCancelled"], () => {
      state.isLoading = false;
      return [state, null];
    })
    .with(["EditConfirmed", P.select()], (prop) => {
      flashMessage(tr("fib.advanced.savePending"), "info");

      state.data.map((p) => {
        if (p.param === prop.param && p.deviceKind === prop.deviceKind) {
          p.name = prop.name;
          p.unit = prop.unit;
          p.zeroValue = prop.zeroValue;
          p.chart = prop.chart;
          p.isWritable = prop.isWritable;
        }
        return p;
      });

      return [state, saveChanges(state)];
    })
    .with(["FilterKind", P.select()], (deviceKind) => {
      state.selectedKind = deviceKind === "null" ? null : deviceKind;
      return [state, null];
    })
    .with(["LoadDataFailed"], () => {
      flashMessage(tr("general.loadingFailed"), "error");
      return [state, null];
    })
    .with(["LoadDataSuccess", P.select()], (params) => {
      state.data = params;

      const devices: { kind: string; name: string }[] = Array
        .from(
          new Map(
            state.data.map((obj) => [
              obj.deviceKind,
              { kind: obj.deviceKind, name: obj.deviceKind },
            ]),
          ).values(),
        );

      state.devicesKind = devices;

      return [state, null];
    })
    .with(["SavingSuccess"], () => {
      flashMessage(tr("fib.advanced.saveSuccess"), "success");
      state.isLoading = false;
      return [state, null];
    })
    .with(["SavingFailed"], () => {
      flashMessage(tr("general.saveFailed"), "error");
      state.isLoading = false;
      return [state, null];
    })
    .with(["AttributeChange", P._, P._], () => [state, null])
    .exhaustive();
}

async function loadData(platform: string) {
  try {
    const response = await installationManager.readDefaultParams(platform);
    return msg("LoadDataSuccess", response);
  } catch (err) {
    return msg("LoadDataFailed");
  }
}

async function saveChanges(state: State) {
  try {
    const dataToUpdate = {
      instId: "default",
      instPlatform: state.platform,
      params: state.data,
    };

    await installationManager.saveParamsDefinition(dataToUpdate);

    return msg("SavingSuccess");
  } catch (err) {
    return msg("SavingFailed");
  }
}

function view(state: State): stm.View<Msg> {
  return (
    <>
      <div className={"fib-tech-params"}>
        <div className={"params-title"}>
          <h1 className={"h400"}>{tr("fib.advanced.paramsList")}</h1>
        </div>
        <div className={"params-filter"}>
          {renderFilters(state)}
        </div>
        <div className={"buttons"}>
        </div>
        <div class={"params-table"}>
          {renderTable(state)}
        </div>
      </div>
    </>
  );
}

function renderFilters(state: State) {
  const devices = state.devicesKind;
  return (
    <>
      <div className={"filter-select"}>
        <div>{tr("fib.advanced.devicesKindFilter")}</div>
        <select
          className={"body-medium"}
          disabled={false}
          onChange={(e: Event) => msg("FilterKind", (e.target as HTMLSelectElement).value)}
        >
          <option value="null">{tr("fib.advanced.selectDeviceKind")}</option>
          <>
            {devices.map((elem) => {
              return <option value={elem.kind}>{elem.name}</option>;
            })}
          </>
        </select>
      </div>
    </>
  );
}

function renderTable(state: State) {
  const propsHeaders = [
    { key: "param", name: tr("fib.advanced.paramId") },
    { key: "name.pl", name: tr("fib.advanced.paramName") },
    { key: "deviceKind", name: tr("fib.advanced.paramKind") },
    { key: "unit", name: tr("fib.advanced.paramUnit") },
    { key: "chart", name: tr("fib.advanced.paramChart") },
    { key: "zeroValue", name: tr("fib.advanced.paramZeroValue") },
    { key: "isWritable", name: tr("fib.advanced.paramEditable") },
  ];

  let data = state.data;
  if (state.selectedKind) {
    data = data.filter((param) => param.deviceKind === state.selectedKind);
  }

  data = _.sortBy(data, [state.sortingBy]);

  return (
    <table>
      <thead>
        <tr class="table-header">
          {propsHeaders.map((prop) => (
            <th onClick={() => msg("SortingBy", prop.key)}>
              {prop.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <>
          {data.map((prop) => (
            <tr onClick={() => msg("EditParam", prop)}>
              <td>{prop.param}</td>
              <td>{prop.name.pl}</td>
              <td>{prop.deviceKind}</td>
              <td>{prop.unit}</td>
              <td>{prop.chart ? "true" : "false"}</td>
              <td>{prop.zeroValue}</td>
              <td>{prop.isWritable ? "true" : "false"}</td>
            </tr>
          ))}
        </>
      </tbody>
    </table>
  );
}

async function paramPrompt(prop: any) {
  let content = prop;

  const message = await modal({
    large: true,
    header: tr("fib.advanced.paramEdition", { param: prop.param }),
    body: () => (
      <>
        <div className={"params"}>
          <div className={"param-desc"}>
            <div className={"param-title"}>{tr("fib.advanced.paramName")}</div>
            <div className={"param-value"}>
              <input
                type="text"
                autofocus
                required
                value={prop.name.pl}
                placeholder={prop.name.pl}
                onInput={(event: any) => content.name.pl = event.target.value}
              />
            </div>
          </div>
          <div className={"param-desc"}>
            <div className={"param-title"}>{tr("fib.advanced.paramUnit")}</div>
            <div className={"param-value"}>
              <input
                type="text"
                required
                value={prop.unit}
                placeholder={prop.unit}
                onInput={(event: any) => content.unit = event.target.value}
              />
            </div>
          </div>
          <div className={"param-desc"}>
            <div className={"param-title"}>{tr("fib.advanced.paramZeroValue")}</div>
            <div className={"param-value"}>
              <input
                type="text"
                required
                value={prop.zeroValue}
                placeholder={prop.zeroValue}
                onInput={(event: any) => content.zeroValue = event.target.value}
              />
            </div>
          </div>
          <div className={"param-desc"}>
            <div className={"param-title"}>
            </div>
            <div className={"param-value"}>
              <input
                type="checkbox"
                value={prop.chart}
                checked={prop.chart}
                name={"chart"}
                onChange={(event: any) => content.chart = event.target.checked}
              />
              {tr("fib.advanced.paramIsChart")}
            </div>
          </div>
          <div className={"param-desc"}>
            <div className={"param-title"}>
            </div>
            <div className={"param-value"}>
              <input
                type="checkbox"
                value={prop.isWritable}
                checked={prop.isWritable}
                name={"writable"}
                onChange={(event: any) => content.isWritable = event.target.checked}
              />
              {tr("fib.advanced.paramIsWritable")}
            </div>
          </div>
        </div>
      </>
    ),
    footer: (close) => (
      <>
        <button
          class={"button button-secondary h300"}
          onClick={() => close(false)}
        >
          {tr("general.cancel")}
        </button>
        <button
          class={"button button-primary h300"}
          onClick={() => close(true)}
        >
          {tr("general.saveChanges")}
        </button>
      </>
    ),
  }) as boolean;

  if (!message) {
    return msg("EditCancelled");
  }
  return msg("EditConfirmed", prop);
}
