import _ from "lodash";
import { router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { MiniInstallation, UserStateWithMap } from "nexus/node/MetricsManager";
import { techNav } from "./view-utils";

let firstTick = true;

type Msg =
  | [type: "AttributeChange", name: string, value: unknown]
  | [type: "InstallationSelected", value: string]
  | [type: "CloseDetails", e: KeyboardEvent]
  | [type: "SearchPhrase", value: string]
  | [type: "SwitchAutoScroll", value: boolean]
  | [type: "Tick"];

interface InstallationWithHaversine extends MiniInstallation {
  haversine?: number;
}

interface State {
  route: router.Route;
  isLoading: boolean;
  installationList: InstallationWithHaversine[];
  isAutoScrollEnabled: boolean;
  userState?: UserStateWithMap;
  pointerId: string;
  pointerCoords: {
    lat: number;
    lng: number;
  };
  installationListToDisplay: InstallationWithHaversine[];
  installationPointer?: InstallationWithHaversine;
  isDetailsOn: boolean;
  searchPhrase: string;
  isShowAlertsOn: boolean;
}

const msg = (...args: Msg): Msg => args;

stm.component({
  tagName: "fib-tech-installs-map",
  shadow: false,
  debug: false,
  propTypes: {
    installations: Object,
    showAlerts: Boolean,
    userState: Object,
    route: Object,
  },
  willMount(cmp: any, dispatch: stm.Dispatch<Msg>) {
    cmp.closeDetailsListener = (e: KeyboardEvent) => dispatch(msg("CloseDetails", e));
    document.addEventListener("keyup", cmp.closeDetailsListener);
    cmp.tick = setInterval(() => dispatch(msg("Tick")), 5000);
  },
  willUnmount(cmp: any) {
    document.removeEventListener("routeChange", cmp.closeDetailsListener);
    clearInterval(cmp.tick);
  },
  attributeChangeFactory: (name: string, value: any) => msg("AttributeChange", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [{
      route: router.getCurrentRoute(),
      isLoading: true,
      installationList: [],
      installationListToDisplay: [],
      isAutoScrollEnabled: false,
      pointerId: "",
      pointerCoords: {
        lat: Infinity,
        lng: Infinity,
      },
      isDetailsOn: false,
      searchPhrase: "",
      isShowAlertsOn: false,
    }, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChange", "userState", P.select()], (userState) => {
      if (!userState || !_.isObject(userState)) {
        return [state, null];
      }
      state.userState = userState as any;
      const installations = state.userState?.installations ?? [];

      state.installationList = installations;
      state.isLoading = false;
      state.installationListToDisplay = state.installationList;

      const pointerId = router.getCurrentRoute()?.params?.installationId;
      if (pointerId && pointerId !== "all") {
        state.installationPointer = installations.find((installation) =>
          installation.id === pointerId
        );
        state.isDetailsOn = true;
      } else {
        state.installationPointer = { ...state.installationListToDisplay[0] };
      }

      if (firstTick) {
        const pointerLat: number = state.installationPointer?.coords?.lat ?? 0;
        const pointerLng: number = state.installationPointer?.coords?.lng ?? 0;
        state.installationListToDisplay.forEach((installation) => {
          /**
           * according to: https://www.movable-type.co.uk/scripts/latlong.html
           * Haversine formula:
           * a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
           * c = 2 ⋅ atan2(√a, √(1−a))
           * d = R ⋅ c
           */
          const earthRadius = 6371e3;
          const phi1 = pointerLat * Math.PI / 180;
          const phi2 = installation.coords.lat * Math.PI / 180;
          const deltaPhi = (installation.coords.lat - pointerLat) * Math.PI /
            180;
          const deltaLambda = (installation.coords.lng - pointerLng) * Math.PI /
            180;

          const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
            Math.cos(phi1) * Math.cos(phi2) *
              Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          installation.haversine = earthRadius * c;
        });

        state.installationListToDisplay.sort((a, b) => {
          return a?.haversine && b?.haversine ? a.haversine < b.haversine ? -1 : 1 : 0;
        });
      }
      return [state, null];
    })
    .with(["AttributeChange", "route", P.select()], (route) => [
      { ...state, route: route as any },
      null,
    ])
    .with(["AttributeChange", "showAlerts", P.select()], (showAlerts) => {
      return [
        { ...state, isShowAlertsOn: !!showAlerts },
        null,
      ];
    })
    .with(["AttributeChange", P._, P._], () => [state, null])
    .with(["InstallationSelected", P.select()], (id) => {
      state.installationPointer = state.installationListToDisplay
        .find((installation) => installation.id === id);
      state.isDetailsOn = true;
      router.navigate(state.route.name, {
        installationId: state.installationPointer?.id ?? "",
      });
      return [state, null];
    })
    .with(["CloseDetails", P.select()], (e) => {
      if (e.code === "Escape") {
        state.isDetailsOn = false;
        state.searchPhrase = "";
        state.installationListToDisplay = state.installationList;
        router.navigate("techMapView", { installationId: "all" });
      }
      return [state, null];
    })
    .with(["SearchPhrase", P.select()], (phrase) => {
      const byName = state.installationList
        .filter((installation) => installation.name.toLowerCase().includes(phrase.toLowerCase()));
      const byStreet = state.installationList
        .filter((installation) => installation.street.toLowerCase().includes(phrase.toLowerCase()));
      const byCity = state.installationList
        .filter((installation) => installation.city.toLowerCase().includes(phrase.toLowerCase()));
      state.installationListToDisplay = Array.from(
        new Set(byName.concat(byStreet).concat(byCity)),
      );
      state.searchPhrase = phrase;
      return [state, null];
    })
    .with(["SwitchAutoScroll", P.select()], (value) => {
      state.isAutoScrollEnabled = value;
      return [state, null];
    })
    .with(["Tick"], () => {
      if (state.isAutoScrollEnabled && state.installationListToDisplay.length) {
        const currentIndex = state.installationListToDisplay
          .findIndex((installation) => installation.id === state.installationPointer?.id);
        const nextIndex = currentIndex + 1 >= state.installationListToDisplay.length
          ? 0
          : currentIndex + 1;
        let nextId;
        nextId = state.installationListToDisplay[firstTick ? currentIndex : nextIndex]
          .id;
        firstTick = false;
        router.navigate("techMapView", { installationId: nextId });
        return [state, msg("InstallationSelected", nextId)];
      }
      return [state, null];
    })
    .exhaustive();
}

function view(state: State): stm.View<Msg> {
  if (!state.userState || state.isLoading) {
    return (
      <div className="loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }
  return (
    <>
      {techNav(state.userState)}
      {state.installationList.length > 0 && renderMap(state)}
    </>
  );
}

function renderMap(state: State) {
  const pointer = state.installationPointer;
  return (
    <main className="fib-map-main">
      {state.isDetailsOn && pointer && (
        <div className="fib-map-details-wrapper grid">
          <fib-tech-install-details
            installation-id={pointer.id}
            isHomeVersion={state.userState.licenseType.endsWith("_home") ? true : false}
          />
        </div>
      )}

      <div className="fib-map-wrapper">
        <div className="fib-map-search-wrapper">
          <input
            className="body-large"
            type="text"
            value={state.searchPhrase}
            placeholder={tr("general.search")}
            onInput={(e: Event) => msg("SearchPhrase", (e.target as HTMLInputElement).value)}
          />
          <i className="icon-search" />
        </div>
        <div className="fib-map-switcher-wrapper">
          <div
            className={`switch-big ${state.isAutoScrollEnabled ? "switch-on" : "switch-off"}`}
            onClick={() => msg("SwitchAutoScroll", !state.isAutoScrollEnabled)}
          />
          <span>{tr("fib.map.switcher")}</span>
        </div>

        <fib-map
          className="fib-map-map"
          apiKey={state.userState?.mapApiSecret}
          zoom={13}
          centerLat={pointer?.coords?.lat ?? Infinity}
          centerLng={pointer?.coords?.lng ?? Infinity}
          onselect={(e: CustomEvent) => msg("InstallationSelected", e.detail)}
        >
          <>
            {state.installationListToDisplay.map(
              ({ id, name, installationState, coords }) => {
                const iconType = state.isDetailsOn && pointer?.id === id
                  ? "selected"
                  : installationState;
                return (
                  <>
                    <fib-map-marker
                      lat={coords.lat}
                      lng={coords.lng}
                      mid={id + "-label"}
                      icontype={iconType}
                      iconlabel={name}
                      status={installationState}
                      resize={true}
                    />

                    <fib-map-marker
                      lat={coords.lat}
                      lng={coords.lng}
                      mid={id}
                      icontype={iconType}
                      iconlabel={null}
                      status={installationState}
                    />
                  </>
                );
              },
            )};
          </>

          {state.isShowAlertsOn && <fib-map-marker />}
        </fib-map>
      </div>

      {state.isShowAlertsOn && (
        <div className="fib-map-notifications-wrapper grid">
          <div className="box3 fib-map-details">
            <div className="fib-map-details-row">
              <p className="h300">{tr("fib.map.alerts")}</p>
              <ul className="list">
                <>
                  {state.installationListToDisplay.map((installation) =>
                    installation.installationState === "warning" ||
                      installation.installationState === "error"
                      ? (
                        <li
                          className="well body-large pointer"
                          onClick={() => msg("InstallationSelected", installation.id)}
                        >
                          <div className="small-red-dot" />
                          {installation.name}
                        </li>
                      )
                      : <></>
                  )}
                </>
              </ul>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
