import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { UserState } from "nexus/node/MetricsManager";

type Msg =
  | [type: "AttributeChange", name: string, value: string]
  | [type: "Tab", value: string]
  | [type: "LoadUsersSuccess", users: any]
  | [type: "LoadingFailed"]
  | [type: "Cancel"]
  | [type: "SelectUser", copyId: { fromUser: string; toUser: string }]
  | [type: "CopyPrivileges", fromUser: any]
  | [type: "Success"]
  | [type: "MergePrivileges", fromUser: any]
  | [type: "SelectUserToEditPrivileges", userIdentityId: string];

interface State {
  tab: string;
  users: any[];
  copyId: { fromUser: string; toUser: string };
  userToEditPrivileges: string;
  userState?: UserState;
}

const msg = (...args: Msg): Msg => args;
const resourceManager = grow.plant("ResourceManager");

stm.component({
  tagName: "fib-admin-advanced",
  shadow: false,
  debug: false,
  propTypes: { userState: Object },
  attributeChangeFactory: (name: string, value: any) => msg("AttributeChange", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    const state = {
      tab: "",
      users: [],
      copyId: { fromUser: "", toUser: "" },
      userToEditPrivileges: "",
    };
    return [state, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChange", "userState", P.select()], (userState) => [
      { ...state, userState: userState as UserState },
      null,
    ])
    .with(["AttributeChange", P._, P._], () => [state, null])
    .with(["Tab", P.select()], (tab) => {
      state.tab = tab;
      if (state.tab === "copy-privileges" || state.tab === "edit-privileges") {
        return [state, loadRegisteredUsers()];
      }
      return [state, null];
    })
    .with(["LoadUsersSuccess", P.select()], (users) => {
      state.users = users;
      return [state, null];
    })
    .with(["LoadingFailed"], () => {
      flashMessage(tr("general.loadingFailed"), "error");
      return [state, null];
    })
    .with(["Cancel"], () => {
      flashMessage(tr("fib.advanced.dataPointsLists.canceled"), "info");
      return [state, null];
    })
    .with(["SelectUser", P.select()], (copyId) => {
      state.copyId = copyId;
      return [state, null];
    })
    .with(["CopyPrivileges", P.select()], (fromUser) => {
      const roles = (fromUser as any).roles;
      if (state.copyId.fromUser === (fromUser as any).identityId) {
        return [state, copyPrivileges(roles, state.copyId.toUser)];
      }
      flashMessage(tr("fib.admin.advanced.privilegesNoUser"), "error");
      return [state, null];
    })
    .with(["Success"], () => {
      flashMessage(tr("gcrud.saveSuccess"), "success");
      return [state, loadRegisteredUsers()];
    })
    .with(["MergePrivileges", P.select()], (fromUser) => {
      const roles = (fromUser as any).roles;

      if (state.copyId.fromUser === (fromUser as any).identityId) {
        return [state, mergePrivileges(roles, state.copyId.toUser)];
      }
      flashMessage(tr("fib.admin.advanced.privilegesNoUser"), "error");
      return [state, null];
    })
    .with(["SelectUserToEditPrivileges", P.select()], (userIdentityId) => {
      state.userToEditPrivileges = userIdentityId;
      return [state, null];
    })
    .exhaustive();
}

async function mergePrivileges(roles: string[], toUser: string) {
  try {
    await resourceManager.mergeRoles(roles, toUser);
    return msg("Success");
  } catch (err) {
    return msg("LoadingFailed");
  }
}

async function copyPrivileges(roles: string[], toUser: string) {
  try {
    await resourceManager.copyRoles(roles, toUser);
    return msg("Success");
  } catch (err) {
    return msg("LoadingFailed");
  }
}

async function loadRegisteredUsers() {
  try {
    const users = await resourceManager.getRegisteredUsers();
    return msg("LoadUsersSuccess", users ?? []);
  } catch (err) {
    return msg("LoadingFailed");
  }
}

function view(state: State): stm.View<Msg> {
  return (
    <main class={"vbox"}>
      <div className="grid fib-advanced">
        <div className="box4 fib-advanced-col">
          <p class={"h500"}>{tr("fib.advanced.advanced")}</p>
          <ul className="list">
            <li
              className="well body-large pointer"
              onClick={() => msg("Tab", "access-groups")}
            >
              {tr("fib.admin.advanced.accessGroups")}
            </li>
            <li
              className="well body-large pointer"
              onClick={() => msg("Tab", "copy-privileges")}
            >
              {tr("fib.admin.advanced.copyPrivileges")}
            </li>
            <li
              className="well body-large pointer"
              onClick={() => msg("Tab", "edit-privileges")}
            >
              {tr("fib.admin.advanced.editPrivileges")}
            </li>
            <li
              className="well body-large pointer"
              onClick={() => msg("Tab", "params")}
            >
              {tr("fib.advanced.manageDefaultParams")}
            </li>
          </ul>
        </div>

        <div className="box10 fib-advanced-col">
          <div class={"fib-advanced-content"}>
            {renderContent(state)}
          </div>
        </div>
      </div>
    </main>
  );
}

function renderContent(state: State) {
  const platform = state.userState.licenseType.includes("_home") ? "home" : "fibrain";
  switch (state.tab) {
    case "params":
      return (
        <div class={"fib-advanced-wrapper"}>
          <fib-admin-params platform={platform} user-state={state.userState}></fib-admin-params>
        </div>
      );
    case "access-groups":
      return (
        <div class={"fib-advanced-wrapper"}>
          <h1 class={"h400"}>{tr("fib.admin.advanced.manageGroups")}</h1>
          <sec-role-group-form />
        </div>
      );
    case "copy-privileges":
      return (
        <div class={"fib-advanced-wrapper"}>
          <h1 class={"h400"}>{tr("fib.admin.advanced.copyPrivileges")}</h1>
          <ul class={"list aa-list"}>
            <>
              {state.users.map((user) => (
                <li class={"aa-list-item"}>
                  <h1 class={"h400"}>{user.firstName} {user.lastName}</h1>

                  <h3 className={"h300"}>
                    {tr("fib.admin.advanced.privilegesList")}
                  </h3>
                  <ul class={"list"}>
                    <>
                      {user.roles.map((role: string) => <li>{role}</li>)}
                    </>
                  </ul>

                  <h3 className={"h300"}>
                    {tr("fib.admin.advanced.privilegesUser")}
                  </h3>
                  <div class={"aa-options"}>
                    <select
                      class="fib-org-select full-width body-medium text-color-primary50"
                      onChange={(e: Event) =>
                        msg("SelectUser", {
                          fromUser: user.identityId,
                          toUser: (e.target as HTMLSelectElement).value,
                        })}
                    >
                      <option selected={true} disabled={true}>
                        {tr("select.prompt")}
                      </option>

                      <>
                        {state.users
                          .filter((userList) => userList.identityId !== user.identityId)
                          .map((userList) => (
                            <option value={userList.identityId}>
                              {userList.firstName} {userList.lastName}
                            </option>
                          ))}
                      </>
                    </select>
                  </div>

                  <h3 className={"h300"}>{tr("gcrud.actions")}</h3>
                  <div className={"aa-action-buttons"}>
                    <button
                      className={"button button-primary"}
                      onClick={() => msg("CopyPrivileges", user)}
                    >
                      {tr("fib.admin.advanced.copyPrivileges")}
                    </button>

                    <button
                      className={"button button-primary"}
                      onClick={() => msg("MergePrivileges", user)}
                    >
                      {tr("fib.admin.advanced.mergePrivileges")}
                    </button>
                  </div>
                </li>
              ))}
            </>
          </ul>
        </div>
      );
    case "edit-privileges":
      return (
        <div class={"fib-advanced-wrapper"}>
          <h1 class={"h400"}>{tr("fib.admin.advanced.editPrivileges")}</h1>

          <h1 className={"h300"}>{tr("fib.admin.advanced.privilegesUser")}</h1>
          <select
            className="fib-org-select full-width body-medium text-color-primary50"
            onChange={(e: Event) =>
              msg(
                "SelectUserToEditPrivileges",
                (e.target as HTMLSelectElement).value,
              )}
          >
            <option selected={true} disabled={true}>
              {tr("select.prompt")}
            </option>

            <>
              {state.users.map((userList) => {
                return (
                  <option value={userList.identityId}>
                    {userList.firstName} {userList.lastName}
                  </option>
                );
              })}
            </>
          </select>

          <sec-identity-roles-form identityId={state.userToEditPrivileges} />
        </div>
      );
    default:
      return <></>;
  }
}
