import { flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { InstallationStored } from "nexus/node/InstallationDetailsAccess";
import * as Security from "nexus/node/Security";
import * as OrganizationAccess from "nexus/node/OrganizationAccess";
import { itemTopBar, nextYear, textElement } from "./view-utils";
import { LicenseType } from "nexus/node/contracts";
import _ from "lodash";

type Organization = OrganizationAccess.Organization;

type Identity = Security.Identity;

type LoadData = any;

const resourceManager = grow.plant("ResourceManager");
const installationManager = grow.plant("InstallationManager");

type Msg =
  | [type: "LoadDataFailed"]
  | [type: "LoadDataSuccess", data: LoadData]
  | [type: "AttributeChange", name: string, value: string]
  | [type: "Input", field: string, value: unknown]
  | [type: "Save"]
  | [type: "SaveFailed", errors: Record<string, string>]
  | [type: "SaveSuccess", installation: InstallationStored]
  | [type: "ActivateInstallation"]
  | [type: "SearchAddress"]
  | [type: "UnsavedChanges"]
  | [type: "LoadUserStateFailed"]
  | [type: "Connect"]
  | [type: "LoadUserStateSuccess", identity: { id: string; licenseType: string }]
  | [type: "PairSuccessful"]
  | [type: "PairUnSuccessful"]
  | [type: "DisconnectSuccessful"]
  | [type: "None"];

function msg(...args: Msg): Msg {
  return args;
}

type AvailableInstallations = {
  id: string;
  ip: string;
  nexusId: string;
  online: boolean;
  updatedAt: string;
};
interface State {
  installation?: InstallationStored;
  org?: Organization;
  identitiesByAccessType: Record<string, Identity[]>;
  isLoading: boolean;
  isSaving: boolean;
  data: Record<string, unknown>;
  errors: Record<string, string>;
  isSearchClicked: boolean;
  mapApiKey: string;
  licenseType: LicenseType;
  isConnected: boolean;
  availableInstallations: AvailableInstallations[];
}

stm.component({
  tagName: "fib-admin-installation",
  shadow: false,
  debug: false,
  propTypes: {
    installationId: String,
  },
  willUnmount: () => {
    window.onbeforeunload = () => null;
  },
  attributeChangeFactory: (name, value) => msg("AttributeChange", name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        isSaving: false,
        identitiesByAccessType: {},
        data: {},
        errors: {},
        isSearchClicked: false,
        mapApiKey: "",
        licenseType: "admin",
        isConnected: false,
        availableInstallations: [],
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(
      ["AttributeChange", "installationId", P.select()],
      (installationId) => [
        { ...state, isLoading: true },
        loadData(installationId),
      ],
    )
    .with(["AttributeChange", P._, P._], () => [state, null])
    .with(
      ["Input", P.select("field"), P.select("value")],
      ({ field, value }) => {
        if (field === "coords") state.isSearchClicked = false;

        state.data[field] = value;
        delete state.errors[field];

        return [state, unsavedChanges()];
      },
    )
    .with(["LoadDataFailed"], () => {
      flashMessage(tr("fibAdminInstallation.loadDataFailed"), "error");
      return [
        { ...state, isLoading: false },
        loadUserLicense(),
      ];
    })
    .with(["LoadDataSuccess", P.select()], (data: any) => [
      {
        ...state,
        identitiesByAccessType: data.result.identitiesByAccessType,
        installation: data.result.installation,
        org: data.result.org,
        isLoading: false,
        loadedInstallationId: undefined,
        mapApiKey: data.result.mapApiSecret,
        isConnected: data.isConnected,
        availableInstallations: data.installationList,
      },
      loadUserLicense(),
    ])
    .with(["Save"], () => [
      { ...state, isSaving: true },
      save(state),
    ])
    .with(["SaveFailed", P.select()], (errors) => {
      flashMessage(tr("fibCreateInstallation.saveFailed"), "error");
      return [
        { ...state, isSaving: false, errors },
        null,
      ];
    })
    .with(["SaveSuccess", P.select()], (installation) => {
      return [
        {
          ...state,
          isSaving: false,
          isLoading: true,
          errors: {},
          data: {},
          installation,
        },
        loadData(state.installation._id),
      ];
    })
    .with(["ActivateInstallation"], () => {
      state.data.licenseUntil = nextYear().getTime();
      state.data.status = "active";
      return [state, null];
    })
    .with(["SearchAddress"], () => {
      state.isSearchClicked = true;
      return [state, null];
    })
    .with(["UnsavedChanges"], () => {
      window.onbeforeunload = () => !_.isEmpty(state.data) ? "" : null;
      return [state, null];
    })
    .with(["LoadUserStateSuccess", P.select()], (userLicense) => {
      state.licenseType = userLicense.licenseType;
      return [state, null];
    })
    .with(["LoadUserStateFailed"], () => {
      return [state, null];
    })
    .with(["Connect"], () => {
      return [
        state,
        state.isConnected
          ? disconnect(state.installation?._id, state.installation?.secret)
          : pairInstallation(state.installation?._id, state.installation?.secret),
      ];
    })
    .with(["PairSuccessful"], () => {
      state.isConnected = true;
      return [state, null];
    })
    .with(["PairUnSuccessful"], () => {
      flashMessage(tr("fibAdminPanel.connectionRefused"), "error");
      state.isConnected = false;
      return [state, null];
    })
    .with(["DisconnectSuccessful"], () => {
      state.isConnected = false;
      return [state, null];
    })
    .with(["None"], () => {
      return [state, null];
    })
    .with(P._, () => {
      window.onbeforeunload = () => !_.isEmpty(state.data) ? "" : null;
      return [state, null];
    })
    .exhaustive();
}

function unsavedChanges() {
  return msg("UnsavedChanges");
}

async function loadUserLicense(): Promise<Msg> {
  try {
    const license = await resourceManager.getUserLicense();
    return msg("LoadUserStateSuccess", license);
  } catch (err) {
    console.error("access check failed ", err);
    return msg("LoadUserStateFailed");
  }
}

async function loadData(installationId: string) {
  try {
    const result = await resourceManager.showInstallation(installationId);

    let isConnected = false;
    result.installation.ip = "";

    const installationList = await installationManager.allInstallations();

    if (result.installation && result.installation.secret && installationList) {
      const selectedInstallation = installationList.find((inst: any) =>
        inst.id === result.installation.secret
      );
      if (selectedInstallation) {
        isConnected = selectedInstallation.online;
        result.installation.ip = selectedInstallation.ip;
      }
    }

    return msg("LoadDataSuccess", {
      result,
      isConnected,
      installationList: installationList ?? [],
    });
  } catch (err) {
    console.error("loading installation failed", err);
    return msg("LoadDataFailed");
  }
}

async function save(state: State) {
  if (!state.installation) {
    return msg("SaveFailed", {});
  }

  try {
    const installation: InstallationStored = {
      ...state.installation,
      ...state.data,
      platform: state.org.platform ?? null,
    };

    const result = await resourceManager.saveInstallation(
      installation as any as Record<string, unknown>,
    );

    if ("id" in result) {
      installation._rev = result.rev;
      return msg("SaveSuccess", installation);
    }

    console.error("validation errors");
    return msg("SaveFailed", result.errors);
  } catch (err) {
    console.error("saving failed", err);
    return msg("SaveFailed", {});
  }
}

async function pairInstallation(installId: string, secret: string) {
  const message = tr("fibAdminPanel.connectModal");
  const result = await modal.confirm({
    text: message,
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  if (!result) {
    return msg("None");
  }

  try {
    const response = await installationManager.connectInstallation(installId, secret);

    if (response.status !== 202 && response.status !== 200) {
      return msg("PairUnSuccessful");
    }
    return msg("PairSuccessful");
  } catch (error) {
    return msg("None");
  }
}
async function disconnect(installId: string, machineId: string) {
  const message = tr("fibAdminPanel.disconnectModal");
  const result = await modal.confirm({
    text: message,
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  if (!result) {
    return msg("None");
  }

  try {
    const response = await installationManager.disconnectInstallation(installId, machineId);
    return msg("DisconnectSuccessful");
  } catch (error) {
    return msg("None");
  }
}

function view(state: State) {
  const isConnected = state.isConnected;
  //  default position if installation hasn't any data
  let positionLat = "53.35391721448532";
  let positionLng = "18.43217104687567";

  if (state.data?.coords) {
    positionLat = (state as any).data?.coords.lat;
    positionLng = (state as any).data?.coords.lng;
  } else if (state.installation?.coords) {
    positionLat = (state as any).installation?.coords.lat;
    positionLng = (state as any).installation?.coords.lng;
  }

  let searchAddress = "";

  if (state.isSearchClicked) {
    let street = (state as any).data?.street ??
      (state as any).installation?.street;
    let houseNumber = (state as any).data?.houseNumber ??
      (state as any).installation?.houseNumber;
    let postCode = (state as any).data?.postcode ??
      (state as any).installation?.postcode;
    let city = ((state as any).data?.city ?? (state as any).installation?.city) ?? "";

    if (postCode !== undefined && postCode.length > 0) {
      searchAddress += "postalCode=" + postCode + ";";
    }

    if (city !== undefined && city.length > 0) {
      searchAddress += "city=" + city + ";";
    }

    if (street !== undefined && street.length > 0) {
      searchAddress += "street=" + street + ";";
    }

    if (houseNumber !== undefined && houseNumber.length > 0) {
      searchAddress += "houseNumber=" + houseNumber + ";";
    }

    searchAddress = searchAddress.slice(0, -1);
  }

  if (state.isLoading || !state.installation) {
    return (
      <div class="fib-admin-installation">
        <div class="loader-big"></div>
      </div>
    );
  }

  const installationFormElement = (field: string, label: string) =>
    textElement<Msg>({
      field,
      label,
      data: state.data,
      errors: state.errors,
      realData: state.installation as InstallationStored,
      isDisabled: state.isSaving,
    });

  return (
    <div class="fib-admin-installation vbox center">
      {itemTopBar({
        goBackUrl: router.getRouteUrl("adminListInstallations"),
        goBackLabel: "fibAdminInstallation.installationsList",
        isSaveEnabled: Object.keys(state.data).length > 0 && !state.isSaving,
        isSaving: state.isSaving,
        title: state.installation?.name ?? "",
      })}

      <div class="grid title-container">
        <div class="box1"></div>
        <h1 class="box11 h600">{tr("general.mainInfo")}</h1>
      </div>
      <div class="grid">
        <div class="box1"></div>
        <div class="box4">
          <div class="container license">
            <fib-license
              is-valid={state.installation.status === "active" || state.data.status === "active"}
              valid-until={state.data.licenseUntil
                ? state.data.licenseUntil
                : state.installation.licenseUntil}
              onswitch={(event: any) => {
                if (event.detail) {
                  return msg("ActivateInstallation");
                }
                return msg("Input", "status", "inactive");
              }}
              ondate={(event: any) => msg("Input", "licenseUntil", event.detail)}
            >
            </fib-license>
            <div class="container data">
              {installationFormElement(
                "name",
                "fibCreateInstallation.installationName",
              )}
              <div
                class={`button-secondary h200 connect-install ${isConnected ? "disabled" : ""}`}
                onClick={() => msg("Connect")}
              >
                {isConnected ? tr("fibAdminPanel.disconnect") : tr("fibAdminPanel.connect")}
              </div>
              <div class="connect-status">
                {isConnected
                  ? tr("fibAdminPanel.connectionSuccess")
                  : tr("fibAdminPanel.connectionFailure")}
              </div>
              <div class="connect-status">
                {`${tr("fibCreateInstallation.installationIp")} `}
                {state.installation.ip ?? ""}
              </div>
            </div>
            <fib-org-select
              id="orgId"
              class="body-medium"
              org-id={state.data["orgId"] ?? state.installation?.orgId}
              initial-org-id={state.installation?.orgId}
              onupdate={(event: any) => msg("Input", "orgId", event.detail)}
              disabled={state.isSaving}
              error={state.errors["orgId"]}
            />
            {installationSecretList(state?.availableInstallations, state.installation.secret)}

            {installationFormElement(
              "defrostingCode",
              "fibCreateInstallation.defrostingCode",
            )}
            {installationFormElement(
              "defrostingTemp",
              "fibCreateInstallation.defrostingTemp",
            )}
            {installationFormElement(
              "street",
              "fibCreateInstallation.installationStreet",
            )}
            {installationFormElement(
              "houseNumber",
              "fibCreateInstallation.installationHouseNumber",
            )}
            {installationFormElement(
              "postcode",
              "fibCreateInstallation.installationPostcode",
            )}
            {installationFormElement(
              "city",
              "fibCreateInstallation.installationCity",
            )}
            <div class="form-element">
              <div
                class="button-secondary h200 map-margin-bottom"
                onClick={msg("SearchAddress") as any}
              >
                <span>{tr("fibCreateInstallation.installationSearch")}</span>
              </div>
              <label class="h200" for="installation-coords">
                {tr("fibCreateInstallation.installationCoords")}
              </label>
              <sw-map
                apiKey={state.mapApiKey}
                style="height:300px"
                zoom={12}
                search={searchAddress.length > 5 ? searchAddress : ""}
                selectable={positionLat + "x" + positionLng}
                onupdate={(event: any) => msg("Input", "coords", event.detail?.value)}
              >
              </sw-map>
            </div>
          </div>
        </div>
        <div class="box5">
          <h3 class="h400 users-title">{tr("fibAdminInstallation.users")}</h3>

          <div class="container manager-users">
            <h3 class="h200">
              {state.licenseType === "admin_home"
                ? tr("fibCreateInstallation.userAccess")
                : tr("fibCreateInstallation.managerAccess")}
            </h3>
            {state.licenseType === "admin_home"
              ? (
                <fib-users-list
                  users={(state.data.identitiesByAccessType as any)
                    ?.user_home ??
                    state.identitiesByAccessType?.user_home ?? []}
                  onupdate={(event: any) =>
                    msg("Input", "identitiesByAccessType", {
                      ...state.identitiesByAccessType,
                      ...((state.data as any).identitiesByAccessType || {}),
                      user_home: event.detail,
                    })}
                  disabled={state.isSaving}
                  accessType="user_home"
                />
              )
              : (
                <fib-users-list
                  users={(state.data.identitiesByAccessType as any)?.manager ??
                    state.identitiesByAccessType?.manager ?? []}
                  onupdate={(event: any) =>
                    msg("Input", "identitiesByAccessType", {
                      ...state.identitiesByAccessType,
                      ...((state.data as any).identitiesByAccessType || {}),
                      manager: event.detail,
                    })}
                  disabled={state.isSaving}
                  accessType="manager"
                />
              )}
          </div>
          <div class="container technician-users">
            <h3 class="h200">{tr("fibCreateInstallation.technicianAccess")}</h3>
            {state.licenseType === "admin_home"
              ? (
                <fib-users-list
                  users={(state.data.identitiesByAccessType as any)
                    ?.technician_home ??
                    state.identitiesByAccessType?.technician_home ?? []}
                  onupdate={(event: any) =>
                    msg("Input", "identitiesByAccessType", {
                      ...state.identitiesByAccessType,
                      ...((state.data as any).identitiesByAccessType || {}),
                      technician_home: event.detail,
                    })}
                  disabled={state.isSaving}
                  accessType="technician_home"
                />
              )
              : (
                <fib-users-list
                  users={(state.data.identitiesByAccessType as any)
                    ?.technician ??
                    state.identitiesByAccessType?.technician ?? []}
                  onupdate={(event: any) =>
                    msg("Input", "identitiesByAccessType", {
                      ...state.identitiesByAccessType,
                      ...((state.data as any).identitiesByAccessType || {}),
                      technician: event.detail,
                    })}
                  disabled={state.isSaving}
                  accessType="technician"
                />
              )}
          </div>
        </div>
        <div class="box2"></div>
      </div>
    </div>
  );
}

function installationSecretList(installations: any, selectedInst: string) {
  return (
    <>
      <div class="form-element">
        <label for="secret" class="h200">
          {tr("fibCreateInstallation.installationSecret")}:
        </label>
        {
          <select
            name="secret"
            class={`fib-org-select full-width body-medium`}
            id="option"
            onInput={(event: any) =>
              [
                "Input",
                "secret",
                event.target.value,
              ] as any as Msg}
          >
            <option value=""></option>
            {installations.map((inst) => {
              return (
                <option
                  value={inst.id}
                  selected={(inst.id === selectedInst) ? true : false}
                >
                  {`${inst.id} - [${inst.ip} - ${
                    inst.nexusId
                      ? tr("fibCreateInstallation.usedSecret")
                      : tr("fibCreateInstallation.freeSecret")
                  }]`}
                </option>
              );
            })}
          </select>
        }
      </div>
    </>
  );
}
