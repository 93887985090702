import _ from 'lodash';
import { stm, tr, flashMessage } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';

const resourceManager = grow.plant('ResourceManager');

type Msg
  = [type: 'Input', propName: string, propValue: unknown]
  | [type: 'Cancel']
  | [type: 'Submit']
  | [type: 'SaveFailed', errors: Record<string, string>]
  | [type: 'SaveSuccess', id: string]
  | [type: 'UnsavedChanges']

function msg(...args: Msg): Msg {
  return args;
}

interface State {
  isSaving: boolean;
  data: Record<string, unknown>;
  errors: Record<string, string>;
}

stm.component({
  tagName: 'fib-create-org',
  shadow: false,
  debug: false,
  willUnmount: () => {
    window.onbeforeunload = () => null;
  },
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [{ isSaving: false, data: {}, errors: {} }, null];
  },
  update,
  view
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(['Cancel'], () => {
      const unsaved = !_.isEmpty(state.data);
      return [state, new CustomEvent('cancel', { bubbles: true, detail: { unsaved } })];
    })
    .with(['Input', P.select('propName'), P.select('propValue')], ({ propName, propValue }) => [
      {
        ...state,
        data: {
          ...state.data,
          [propName]: propValue
        },
        errors: {
          ...state.errors,
          [propName]: ''
        }
      },
      unsavedChanges()
    ])
    .with(['Submit'], () => {

      const errors = validate(state.data);

      if (Object.keys(errors).length > 0) {
        return [{ ...state, errors }, null];
      }

      return [
        { ...state, isSaving: true },
        save(state.data)
      ];
    })
    .with(['SaveFailed', P.select()], errors => {
      flashMessage(tr('fibCreateOrg.saveFailed'), 'error');

      return [
        { ...state, isSaving: false, errors },
        null
      ];
    })
    .with(['SaveSuccess', P.select()], id => [
      { ...state, isSaving: false, data: {}, errors: {} },
      new CustomEvent('created', { bubbles: true, detail: id })
    ])
    .with(['UnsavedChanges'], () => {
      window.onbeforeunload = () => !_.isEmpty(state.data) ? '' : null;
      return [state, null];
    })
    .exhaustive();
}

function unsavedChanges() {
  return msg('UnsavedChanges');
}

async function save(data: Record<string, unknown>) {
  try {
    const result = await resourceManager.saveOrg(data);
    if ('id' in result) {
      return msg('SaveSuccess', result.id);
    }
    return msg('SaveFailed', result.errors);
  } catch (err) {
    console.error('saving org failed', err);
    return msg('SaveFailed', {});
  }
}

function validate(data: Record<string, unknown>) {
  const errors: Record<string, string> = {};
  const fields = ['name','address','phone','nip'];

  fields.forEach(e => {
    if (_.isEmpty(data[e])) {
      const missingField = e[0].toUpperCase() + e.slice(1);
      errors[e] = tr(`fibCreateOrg.empty${missingField}`);
    }
  })

  return errors;
}

function view(state: State) {
  return <form class={'fib-create-org'} disabled={state.isSaving} onSubmit={(event: any) => event.preventDefault()}>
    <div className={'button-tertiary h100 square-button close-button'}
      onClick={() => msg('Cancel')}>
      <i className={'icon-cross'} />
    </div>

    <h2 class={'h500 form-title'}>{tr('fibCreateOrg.title')}</h2>

    <div class={'grid hbox'}>
      <div class={'box6 org-form'}>
        <div class={'form-element'}>
          <label class={'h200'} htmlFor={'name'}>{tr('fibCreateOrg.orgName')}</label>
          <input class={'body-medium box6'} id={'name'} type={'text'} disabled={state.isSaving}
            value={state.data['name'] as string}
            onInput={(event: any) => msg('Input', 'name', event.target.value)} />
        </div>
        <p class={'error body-small'}>{state.errors['name']}</p>
        <div class={'form-element'}>
          <label class={'h200'} htmlFor={'address'}>{tr('fibCreateOrg.orgAddress')}</label>
          <input class={'body-medium box6'} id={'address'} type={'text'} disabled={state.isSaving}
            value={state.data['address'] as string}
            onInput={(event: any) => msg('Input', 'address', event.target.value)} />
        </div>
        <p class={'error body-small'}>{state.errors['address']}</p>
        <div class={'form-element'}>
          <label class={'h200'} htmlFor={'phone'}>{tr('fibCreateOrg.orgPhone')}</label>
          <input class={'body-medium box6'} id={'phone'} type={'text'} disabled={state.isSaving}
            value={state.data['phone'] as string}
            onInput={(event: any) => msg('Input', 'phone', event.target.value)} />
        </div>
        <p class={'error body-small'}>{state.errors['phone']}</p>
        <div class={'form-element'}>
          <label class={'h200'} htmlFor={'nip'}>{tr('fibCreateOrg.orgNip')}</label>
          <input class={'body-medium box6'} id={'nip'} type={'text'} disabled={state.isSaving}
            value={state.data['nip'] as string}
            onInput={(event: any) => msg('Input', 'nip', event.target.value)} />
        </div>
        <p class={'error body-small'}>{state.errors['nip']}</p>
      </div>
    </div>

    <div class={'actions hbox center'}>
      <button class={'button-tertiary h300'} disabled={state.isSaving} onClick={() => msg('Cancel')}>
        {tr('general.cancel')}</button>
      <button class={'button-primary h300'} disabled={state.isSaving} onClick={() => msg('Submit')}>
        {tr('fibCreateOrg.createOrg')}</button>
    </div>
  </form>;
}
