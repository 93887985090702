import { v } from "./v";
import { flashMessage, router, stm, tr } from "@7willows/sw-lib";
import { Organization } from "nexus/node/OrganizationAccess";
import { match, P } from "ts-pattern";
import { InstallationStored } from "nexus/node/InstallationDetailsAccess";

const resourceManager = grow.plant("ResourceManager");

type Msg =
  | [type: "LoadDataFailed"]
  | [type: "LoadDataSuccess", data: {
    installations: InstallationStored[];
    orgs: Organization[];
  }]
  | [type: "UrlChange"]
  | [type: "SearchPhrase", phrase: string]
  | [type: "ToggleActiveVisible"]
  | [type: "ToggleInactiveVisible"]
  | [type: "RemoveInstallation", installation: InstallationStored]
  | [type: "RemoveInstallationSuccess", installation: InstallationStored]
  | [type: "RemoveInstallationFailed", installation: InstallationStored];

interface State {
  isLoading: boolean;
  orgs: Organization[];
  installations: InstallationStored[];
  selectedOrgId: string;
  activeVisible: boolean;
  inactiveVisible: boolean;
  searchPhrase: string;
  beingRemoved: Set<string>;
}

function msg(...args: Msg): Msg {
  return args;
}

stm.component({
  tagName: "fib-installation-list",
  shadow: false,
  debug: false,
  init(dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    router.addEventListener("routeChange", () => dispatch(msg("UrlChange")));

    return [
      {
        isLoading: true,
        orgs: [],
        installations: [],
        selectedOrgId: "",
        activeVisible: true,
        inactiveVisible: true,
        searchPhrase: "",
        beingRemoved: new Set<string>(),
      },
      loadData(),
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["UrlChange"], () => {
      const route = router.getCurrentRoute();

      if (route.name === "adminListInstallations") {
        return [
          { ...state, selectedOrgId: "" },
          null,
        ];
      }

      if (route.name !== "adminListInstallationsByOrg") {
        return [state, null];
      }

      const orgId = route.params["orgId"];

      return [
        { ...state, selectedOrgId: orgId },
        null,
      ];
    })
    .with(["ToggleActiveVisible"], () => [
      { ...state, activeVisible: !state.activeVisible },
      null,
    ])
    .with(["ToggleInactiveVisible"], () => [
      { ...state, inactiveVisible: !state.inactiveVisible },
      null,
    ])
    .with(["SearchPhrase", P.select()], (searchPhrase) => [
      { ...state, searchPhrase },
      null,
    ])
    .with(["LoadDataFailed"], () => {
      if (grow.sessionId !== null) {
        flashMessage(tr("fibInstallationList.loadFailed"), "error");
      } else {
        flashMessage(tr("general.logoutSession"), "error");
      }
      return [
        { ...state, isLoading: true },
        null,
      ];
    })
    .with(["LoadDataSuccess", P.select()], (data) => [
      {
        ...state,
        isLoading: false,
        installations: data.installations,
        orgs: data.orgs,
      },
      msg("UrlChange"),
    ])
    .with(["RemoveInstallation", P.select()], (installation) => {
      const isApproved = confirm(tr("fibInstallationList.confirmRemoval", installation as any));

      if (!isApproved) {
        return [state, null];
      }

      state.beingRemoved.add(installation._id);

      return [
        state,
        removeInstallation(installation),
      ];
    })
    .with(["RemoveInstallationFailed", P.select()], (installation) => {
      flashMessage(tr("fibInstallationList.removalFailed", installation as any), "error");

      state.beingRemoved.delete(installation._id);

      return [state, null];
    })
    .with(["RemoveInstallationSuccess", P.select()], (installation) => {
      state.beingRemoved.delete(installation._id);
      state.installations = state.installations.filter((inst) => inst._id !== installation._id);
      return [state, null];
    })
    .exhaustive();
}

async function removeInstallation(installation: InstallationStored) {
  try {
    await resourceManager.removeInstallation(installation._id);
    return msg("RemoveInstallationSuccess", installation);
  } catch (err) {
    console.error("removing failed", err);
    return msg("RemoveInstallationFailed", installation);
  }
}

async function loadData() {
  try {
    const data = await resourceManager.listInstallations();
    return msg("LoadDataSuccess", data);
  } catch (err) {
    console.error("loading data failed", err);
    return msg("LoadDataFailed");
  }
}

function isInstallationLicenseActive(state: State, installation: InstallationStored) {
  return installation.status === "active" && installation.licenseUntil > Date.now();
}

function view(state: State) {
  if (state.isLoading) {
    return <div class={"loader-big"} />;
  }

  return (
    <main class={"vbox fib-installation-list"}>
      <div class="grid">
        <div class="box1" />
        <nav class="secondary-nav box10">
          <h1 class={"h600"}>{tr("fibAdminPanel.navInstallationList")}</h1>
          <ul class={"sub-menu"}>
            <li>
              <a class={"button-primary h200"} href={router.getRouteUrl("adminAddInstallation")}>
                <span>{tr("fibInstallationList.createNewInstallation")}</span>
                <i className={"icon-plus"} />
              </a>
            </li>
          </ul>
        </nav>
        <div class="box1"></div>
      </div>

      <div class="grid">
        <div class="box1"></div>

        <div class="box3">
          <ul class="list clients-menu">
            <>
              {state.orgs.map((org) => (
                <li>
                  <a
                    class={(org.id === state.selectedOrgId ? "active " : "") + "well h300"}
                    href={org.id === state.selectedOrgId
                      ? router.getRouteUrl("adminListInstallations")
                      : router.getRouteUrl("adminListInstallationsByOrg", { orgId: org.id })}
                  >
                    <span>{org.name}</span>
                    <i class={"icon-check"} />
                  </a>
                </li>
              ))}
            </>
          </ul>
        </div>

        <div class="box7">
          <div class="hbox installation-filters">
            <span class="input-icon-after body-large">
              <input
                class={"body-large"}
                type="text"
                value={state.searchPhrase}
                onInput={(e: Event) => msg("SearchPhrase", (e.target as HTMLInputElement).value)}
                placeholder={tr("general.search")}
              />
              <i class="icon-search" />
            </span>
            <div class={"h200"}>{tr("fibAdminPanel.licenseFilter") + ":"}</div>
            <div
              class={(state.activeVisible ? "active " : "") + "chip body-small"}
              onClick={() => msg("ToggleActiveVisible")}
            >
              {tr("fibAdminPanel.licenseActive")}
            </div>
            <div
              class={(state.inactiveVisible ? "active " : "") + "chip body-small"}
              onClick={() => msg("ToggleInactiveVisible")}
            >
              {tr("fibAdminPanel.licenseInactive")}
            </div>
          </div>
          {clientInstallationsView(state)}
        </div>

        <div class="box1"></div>
      </div>
    </main>
  );
}

function clientInstallationsView(state: State) {
  return v<Msg>(
    ".client-installations",
    v(
      "ul.spider-list.list",
      ...state.installations.filter(createFilter(state)).map((installation) => {
        const isBeingRemoved = state.beingRemoved.has(installation._id);
        return v.li<Msg>(
          isInstallationLicenseActive(state, installation)
            ? v(
              ".well.body-large",
              { className: isBeingRemoved ? "disabled" : "" },
              v("a.title", {
                disabled: isBeingRemoved,
                href: router.getRouteUrl("adminInstallation", { installationId: installation._id }),
              }, installation.name),
              v(
                ".actions.hbox",
                // v('.text-button-primary.h300', tr('fibInstallationList.loginAsTechnician')),
                // v('button.text-button-secondary.h300',
                //     { disabled: isBeingRemoved },
                //     v('.icon-dupe'),
                //     v.span(tr('fibInstallationList.duplicateInstallation'))
                // ),
                v("a.text-button-secondary.h300", {
                  disabled: isBeingRemoved,
                  href: router.getRouteUrl("adminInstallation", {
                    installationId: installation._id,
                  }),
                }, v("i.icon-pen")),
                v("button.text-button-secondary.h300", {
                  disabled: isBeingRemoved,
                  onClick: msg("RemoveInstallation", installation),
                }, v("i.icon-trash")),
              ),
            )
            : v(
              ".well.body-large.disabled.licensed-expired",
              v("a.title.full-width", {
                href: router.getRouteUrl("adminInstallation", { installationId: installation._id }),
              }, installation.name),
              v(".badge-gray.body-small", tr("fibLicense.installationLicenseInactive")),
            ),
        );
      }),
    ),
  );
}

function createFilter(state: State) {
  return function (installation: InstallationStored) {
    if (state.selectedOrgId && installation.orgId !== state.selectedOrgId) {
      return false;
    }

    if (
      state.searchPhrase &&
      !installation.name?.toLowerCase().includes(state.searchPhrase.toLowerCase()) &&
      !installation.street?.toLowerCase().includes(state.searchPhrase.toLowerCase()) &&
      !installation.houseNumber?.toLowerCase().includes(state.searchPhrase.toLowerCase()) &&
      !installation.postcode?.toLowerCase().includes(state.searchPhrase.toLowerCase())
    ) {
      return false;
    }

    if (!state.activeVisible && isInstallationLicenseActive(state, installation)) {
      return false;
    }

    if (!state.inactiveVisible && !isInstallationLicenseActive(state, installation)) {
      return false;
    }

    return true;
  };
}
