import _ from "lodash";
import { flashMessage, modal, router, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
var yaml = require("js-yaml");

type Msg =
  | [type: "Attr", name: string, value: unknown]
  | [type: "SelectInstallation", installationId: string]
  | [type: "SelectDefaultConfig", configId: string]
  | [type: "SelectDevice", marker: string]
  | [type: "LoadConfigFailed"]
  | [type: "None"]
  | [type: "LoadConfigSuccess", scriptContent: string]
  | [type: "LoadDefaultConfigSuccess", scriptContent: string]
  | [type: "LoadAvailableDefaultConfigSuccess", configs: DefaultConfiguration[]]
  | [type: "Input", configContent: string]
  | [type: "LoadDevicesListFailed"]
  | [type: "LoadDevicesListSuccess", devices: InstallationRequest[]]
  | [type: "LoadConfig"]
  | [type: "LoadDefaultConfig"]
  | [type: "SaveConfig"]
  | [type: "SaveSuccess"]
  | [type: "SaveFailed"];

type ConfigType = "registers" | "devices" | null;

type InstallationRequest = {
  id: string;
  name: string;
  machineId: string;
  dev?: Device[];
};

type Device = {
  kind: string;
  marker: string;
  modelName: string;
};

type DefaultConfiguration = {
  id: string;
  name: string;
};

interface State {
  installations: InstallationRequest[];
  selectedInstallation: string;
  selectedMarker: string;
  isConfigLoading: boolean;
  isLoading: boolean;
  isSaving: boolean;
  configContent?: string;
  configType: ConfigType;
  canBeSaved: boolean;
  selectedConfiguration: string;
  defaultConfigs: DefaultConfiguration[];
}

const msg = (...args: Msg): Msg => args;

const installationManager = grow.plant("InstallationManager");

stm.component({
  tagName: "fib-config-editor",
  shadow: false,
  debug: false,
  propTypes: {
    installations: Object,
    config: String,
  },
  attributeChangeFactory: (name, value) => msg("Attr", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        isConfigLoading: false,
        isSaving: false,
        installations: [],
        selectedInstallation: "",
        selectedMarker: "",
        configType: null,
        canBeSaved: false,
        selectedConfiguration: "",
        defaultConfigs: [],
      },
      null,
    ];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg, cmp: any, dispatch: stm.Dispatch<Msg>) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["Attr", "installations", P.select()], (installations) => {
      if (
        Array.isArray(installations) &&
        (installations[0] as InstallationRequest).machineId === undefined
      ) {
        return [state, null];
      }
      state.configContent = "";
      state.installations = installations as InstallationRequest[];
      state.isLoading = true;
      return [state, loadDevicesList(state.installations)];
    })
    .with(["Attr", "config", P.select()], (configType) => {
      state.configType = configType as ConfigType;
      state.configContent = "";
      state.canBeSaved = false;
      return [state, null];
    })
    .with(["Attr", P._, P._], () => [state, null])
    .with(["SelectInstallation", P.select()], (installationId) => {
      state.selectedInstallation = installationId ?? "";
      state.selectedMarker = "";
      state.configContent = "";
      state.canBeSaved = false;
      state.isLoading = true;
      return [state, loadAvailableDefaultConfig()];
    })
    .with(["SelectDefaultConfig", P.select()], (configId) => {
      state.selectedConfiguration = configId ?? "";
      state.canBeSaved = true;
      return [state, null];
    })
    .with(["SelectDevice", P.select()], (marker) => {
      state.selectedMarker = marker;
      state.canBeSaved = false;
      state.configContent = "";
      return [state, null];
    })
    .with(["LoadConfigFailed"], () => {
      flashMessage.error(tr("general.loadFailed"));
      state.isLoading = false;
      state.isConfigLoading = false;
      state.configContent = "";
      state.canBeSaved = false;
      return [state, null];
    })
    .with(["LoadConfigSuccess", P.select()], (scriptContent) => {
      state.isLoading = false;
      state.isConfigLoading = false;
      state.configContent = scriptContent;
      state.canBeSaved = false;
      return [state, null];
    })
    .with(["LoadDefaultConfigSuccess", P.select()], (scriptContent) => {
      state.isLoading = false;
      state.isConfigLoading = false;
      state.configContent = scriptContent;
      return [state, null];
    })
    .with(["LoadAvailableDefaultConfigSuccess", P.select()], (configs) => {
      state.isLoading = false;
      state.isConfigLoading = false;
      state.defaultConfigs = configs;
      state.canBeSaved = true;
      return [state, null];
    })
    .with(["Input", P.select()], (configContent) => {
      state.configContent = configContent;
      state.canBeSaved = true;
      return [state, null];
    })
    .with(["SaveConfig"], () => {
      if (state.isSaving || state.configContent === "") {
        return [state, null];
      }

      const installation = state.installations.find((inst) =>
        inst.id === state.selectedInstallation
      );
      if (!installation) {
        return [state, null];
      }

      const device = {
        instId: state.selectedInstallation,
        marker: state.selectedMarker,
        machineId: installation.machineId,
        name: installation.name,
      };

      const isCorrectYaml = validateYaml(state.configContent ?? "");

      if (!isCorrectYaml) {
        return [state, null];
      }

      state.isSaving = true;
      return [state, saveConfig(device, state.configContent ?? "", state.configType)];
    })
    .with(["SaveFailed"], () => {
      flashMessage.error(tr("general.saveFailed"));
      state.isSaving = false;
      return [state, null];
    })
    .with(["SaveSuccess"], () => {
      state.isSaving = false;
      state.canBeSaved = false;
      flashMessage.info(tr("general.saveSuccess"));
      return [state, null];
    })
    .with(["LoadDefaultConfig"], () => {
      state.isLoading = true;
      return [state, loadDefaultConfig(state.selectedConfiguration)];
    })
    .with(["LoadConfig"], () => {
      if (
        !state.selectedInstallation || (state.configType === "registers" && !state.selectedMarker)
      ) {
        return [state, null];
      }
      state.isConfigLoading = true;
      const installation = state.installations.find((inst) =>
        inst.id === state.selectedInstallation
      );

      if (!installation) {
        return [state, null];
      }

      const device = {
        instId: state.selectedInstallation,
        marker: state.selectedMarker,
        machineId: installation.machineId,
      };
      return [state, loadConfig(device, state.configType)];
    })
    .with(["LoadDevicesListSuccess", P.select()], (data) => {
      state.isLoading = false;
      if (data) {
        state.installations = data;
      }
      state.configContent = "";
      state.canBeSaved = false;
      return [state, null];
    })
    .with(["LoadDevicesListFailed"], () => {
      flashMessage(tr("general.loadingFailed"), "error");
      state.isLoading = false;
      return [state, null];
    })
    .with(["None"], () => {
      state.isSaving = false;
      return [state, null];
    })
    .exhaustive();
}

async function loadDevicesList(installations: InstallationRequest[]) {
  try {
    const response = await installationManager.getInstallationsDevices(installations);
    return msg("LoadDevicesListSuccess", response);
  } catch (err) {
    return msg("LoadDevicesListFailed");
  }
}

async function loadDefaultConfig(configId: string) {
  try {
    const response = await installationManager.readDefaultConfig(configId);
    return msg("LoadDefaultConfigSuccess", response);
  } catch (err) {
    return msg("LoadDevicesListFailed");
  }
}

async function loadAvailableDefaultConfig() {
  try {
    const response = await installationManager.loadAvailableConfigurations();
    return msg("LoadAvailableDefaultConfigSuccess", response);
  } catch (err) {
    return msg("LoadDevicesListFailed");
  }
}

async function loadConfig(device: any, configType: ConfigType) {
  try {
    if (configType === "registers") {
      const response = await installationManager.getDriverConfig(device);
      return msg("LoadConfigSuccess", response);
    }

    if (configType === "devices") {
      const response = await installationManager.getInstallationConfig({
        instId: device.instId,
        machineId: device.machineId,
      });
      return msg("LoadConfigSuccess", response);
    }
  } catch (err) {
    console.error("loading configuration failed", err);
  }
  return msg("LoadConfigFailed");
}

async function saveConfig(device: any, configContent: string, configType: ConfigType) {
  const confirmed = await modal.confirm({
    title: tr("fibDeviceConfig.savingConfirm"),
    text: tr("fib.advanced.confirmConfigSaving", { inst: device.name }),
    okLabel: tr("general.yes"),
    cancelLabel: tr("general.no"),
  });

  if (!confirmed) {
    return msg("None");
  }

  try {
    if (configType === "registers") {
      const result = await installationManager.saveDriverConfig({
        ...device,
        config: configContent,
      });
      return msg("SaveSuccess");
    }
    if (configType === "devices") {
      const result = await installationManager.saveInstallationConfig({
        instId: device.instId,
        machineId: device.machineId,
        config: configContent,
      });
      return msg("SaveSuccess");
    }
  } catch (err) {
    console.error("requesting script failed", err);
  }
  return msg("SaveFailed");
}

function validateYaml(input: string) {
  try {
    yaml.load(input);
    return true;
  } catch (e) {
    alert(tr("fib.advanced.wrongYAML") + e);
    return false;
  }
}

function view(state: State): stm.View<Msg> {
  return (
    <div class="fib-tech-config">
      <h2 className="warning">{state.canBeSaved ? tr("fibDeviceConfig.warning") : " "}</h2>
      <div className={"config-header"}>
        <h2 class="h400">{tr("fibDeviceConfig.title")}</h2>
        <button
          class="button-primary"
          disabled={state.isSaving ||
            !state.canBeSaved ||
            !state.selectedInstallation ||
            (state.configType === "registers" && !state.selectedMarker) ||
            (state.configType === "devices" && state.configContent === "") ||
            state.isConfigLoading}
          onClick={msg("SaveConfig") as any}
        >
          {tr("fibDeviceConfig.saveConfig")}
        </button>
      </div>

      <div className={"config-filter"}>
        {installationList(state.installations, state.selectedInstallation)}

        {state.configType === "registers" && devicesList(state)}

        {loadConfigButton(state, false)}

        {state.configType !== "registers" &&
          defaultConfigurationList(
            state.defaultConfigs,
            state.selectedConfiguration,
            state.selectedInstallation,
          )}

        {state.configType !== "registers" && loadConfigButton(state, true)}
      </div>
      <div class="vbox">
        {state.isConfigLoading && <div class="loader-big"></div>}
        {!state.isConfigLoading && state.configContent !== undefined && (
          <textarea
            class="script-editor"
            id="script-editor"
            value={state.configContent}
            spellcheck={false}
            onInput={(event: any) => msg("Input", event.target.value)}
          >
          </textarea>
        )}
      </div>
    </div>
  );
}

function devicesList(state: State) {
  let devices: Device[] = [];

  if (state.selectedInstallation) {
    devices = state.installations.find((ins) => ins.id === state.selectedInstallation)?.dev ?? [];
  }

  return (
    <div className={"filter-select"}>
      <div>{tr("fib.advanced.listDevices")}</div>
      <select
        className={"body-medium"}
        disabled={!state.selectedInstallation ? true : false}
        onChange={(e: Event) => msg("SelectDevice", (e.target as HTMLSelectElement).value)}
      >
        <option value="" selected={state.selectedMarker === null ? true : false}>
          {tr("fib.advanced.selectParamDevice")}
        </option>
        <>
          {devices.map((elem) => {
            return (
              <option
                value={elem.marker}
                selected={elem.marker === state.selectedMarker ? true : false}
              >
                {elem.modelName}
              </option>
            );
          })}
        </>
      </select>
    </div>
  );
}

function defaultConfigurationList(
  defaultInstallations: DefaultConfiguration[],
  selectedConfiguration: string,
  selectedInstallation: string,
) {
  return (
    <div className={"filter-select"}>
      <div>{tr("fibDeviceConfig.selectDefaultConfig")}</div>
      <select
        className={"body-medium"}
        disabled={!selectedInstallation ? true : false}
        onChange={(e: Event) => msg("SelectDefaultConfig", (e.target as HTMLSelectElement).value)}
      >
        <option value="">{tr("fib.advanced.selectInstallations")}</option>
        <>
          {defaultInstallations.map((elem) => {
            return (
              <option
                value={elem.id}
                selected={elem.id === selectedConfiguration ? true : false}
              >
                {elem.name}
              </option>
            );
          })}
        </>
      </select>
    </div>
  );
}

function installationList(installations: InstallationRequest[], selectedInstallation: string) {
  return (
    <div className={"filter-select"}>
      <div>{tr("fibDeviceConfig.loadInstallationConfig")}</div>
      <select
        className={"body-medium"}
        disabled={false}
        onChange={(e: Event) => msg("SelectInstallation", (e.target as HTMLSelectElement).value)}
      >
        <option value="">{tr("fib.advanced.selectInstallations")}</option>
        <>
          {installations.map((elem) => {
            return (
              <option
                value={elem.id}
                selected={elem.id === selectedInstallation ? true : false}
              >
                {elem.name}
              </option>
            );
          })}
        </>
      </select>
    </div>
  );
}

function loadConfigButton(state: State, isDefaultConfig: boolean) {
  return (
    <div>
      {state.isConfigLoading && <div class="loader-mini"></div>}
      {!state.isConfigLoading && (
        <button
          class="button-primary"
          disabled={state.isSaving ||
            state.isConfigLoading ||
            (state.configType === "registers" && !state.selectedMarker) ||
            !state.selectedInstallation}
          onClick={isDefaultConfig ? msg("LoadDefaultConfig") as any : msg("LoadConfig") as any}
        >
          {isDefaultConfig
            ? tr("fibDeviceConfig.loadDefaultConfig")
            : tr("fibDeviceConfig.loadConfig")}
        </button>
      )}
    </div>
  );
}
