import _ from 'lodash';
import { v } from "./v";
import { router, stm, tr, flashMessage, modal } from '@7willows/sw-lib';
import { match, P } from 'ts-pattern';
import { User, UserErrors } from 'nexus/node/ResourceManager';
import { itemTopBar, nextYear } from './view-utils';
import { userGeneralInfo, userInstallations, userLicenseSwitch, userLicenseType } from './user-elements';
import { Organization } from 'nexus/node/OrganizationAccess';
import { InstallationStored } from 'nexus/node/InstallationDetailsAccess';
const security = grow.plant('Security');

type Data = Partial<User>
type Errors = UserErrors;

type LoadData = any;

type Msg
  = [type: 'AttributeChanged', name: string, value: unknown]
  | [type: 'LoadDataFailure']
  | [type: 'LoadDataSuccess', data: LoadData]
  | [type: 'Input', field: string, value: unknown]
  | [type: 'Save']
  | [type: 'SaveFailed', errors: Errors]
  | [type: 'SaveSuccess', data: LoadData]
  | [type: 'Remove']
  | [type: 'RemoveCancelled']
  | [type: 'RemoveFailed']
  | [type: 'RemoveSuccess']
  | [type: 'ActivateLicense']
  | [type: 'ChangeSelectedOrg', selectedOrgId: string]
  | [type: 'LoadInstallationsSuccess', installations: InstallationStored[]]
  | [type: 'LoadInstallationsFailed']
  | [type: 'ResetPassCancelled']
  | [type: 'ResetPassSuccess']
  | [type: 'ResetPassFailed']
  | [type: 'ResetPassConfirmation', email: string]


function msg(...args: Msg): Msg {
  return args;
}

const resourceManager = grow.plant('ResourceManager')

interface State {
  isLoading: boolean;
  isSaving: boolean;
  user?: User;
  data: Data;
  errors: Errors;
  orgs: Organization[];
  installations: InstallationStored[];
  selectedOrgId: string;
  areInstallationsLoading: boolean;
  installationsFilter: InstallationStored[];
}

stm.component({
  tagName: 'fib-tech-user-details',
  shadow: false,
  debug: false,
  propTypes: {
    userId: String
  },
  attributeChangeFactory: (name, value) => msg('AttributeChanged', name, value),
  init(): [State, stm.Cmd<Msg>] {
    return [
      {
        isLoading: false,
        isSaving: false,
        data: {},
        errors: {},
        orgs: [],
        installations: [],
        selectedOrgId: '',
        areInstallationsLoading: false,
        installationsFilter: []
      },
      null
    ];
  },
  update,
  view
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(['AttributeChanged', 'userId', P.select()], userId => [
      { ...state, isLoading: true },
      loadData(userId as string)
    ])
    .with(['AttributeChanged', P._, P._], () => [state, null])
    .with(['ChangeSelectedOrg', P.select()], selectedOrgId => [
      { ...state, selectedOrgId, areInstallationsLoading: true },
      loadInstallations(selectedOrgId)
    ])
    .with(['Input', 'licenseType', P.select()], licenseType => {
      state.data.licenseType = licenseType === 'manager' ? 'manager' : 'technician';
      const installationIds = (state as any).data.installationIds || state.user?.installationIds || [];
      if (licenseType === 'manager' && installationIds.length > 1) {
        state.data.installationIds = [installationIds[0]];
      } else {
        state.data.installationIds = state.user?.installationIds;
      }
      return [state, null];
    })
    .with(['Input', P.select('field'), P.select('value')], ({ field, value }) => {
      _.set(state.data, field, value);
      delete (state as any).errors[field];
      return [state, null];
    })
    .with(['ActivateLicense'], () => {
      state.data.validUntil = nextYear().getTime();
      state.data.status = 'active';
      return [state, null];
    })
    .with(['LoadDataFailure'], () => {
      flashMessage.error(tr('general.loadFailed'));
      return [state, null];
    })
    .with(['LoadDataSuccess', P.select()], (loadedData: any) => [
      {
        ...state,
        isLoading: false,
        user: loadedData.user,
        orgs: loadedData.orgs,
        selectedOrgId: loadedData.selectedOrgId,
        installationsFilter: loadedData.filteredInstallations,
      },
      loadedData.selectedOrgId ? loadInstallations(loadedData.selectedOrgId) : null
    ])
    .with(['Save'], () => [{ ...state, isSaving: true }, save(state.user, state.data)])
    .with(['SaveFailed', P.select()], errors => {
      flashMessage.error(tr('generl.saveFailed'));
      return [
        { ...state, isSaving: false, errors },
        null
      ];
    })
    .with(['SaveSuccess', P.select()], (loadedData: any) => {
      return [
        {
          ...state,
          isSaving: false,
          errors: {},
          data: {},
          user: loadedData.user,
          orgs: loadedData.orgs,
          selectedOrgId: loadedData.selectedOrgId,
        },
        null
      ]
    })
    .with(['Remove'], () => {
      if (!state.user) {
        return [state, null];
      }
      return [
        { ...state, isRemoving: true },
        remove(state.user)
      ]
    })
    .with(['RemoveCancelled'], () => [
      { ...state, isRemoving: false },
      null
    ])
    .with(['RemoveFailed'], () => {
      flashMessage.error(tr('general.removingFailed'));
      return [
        { ...state, isRemoving: false },
        null
      ]
    })
    .with(['RemoveSuccess'], () => {
      router.navigate('techInstallsUsers');

      return [
        { ...state, isRemoving: false },
        null
      ]
    })
    .with(['LoadInstallationsSuccess', P.select()], installations => [
      { ...state, areInstallationsLoading: false, installations },
      null
    ])
    .with(['LoadInstallationsFailed'], () => {
      flashMessage.error(tr('general.loadingFailed'));
      return [
        { ...state, isRemoving: false },
        null
      ]
    })
    .with(['ResetPassCancelled'], () => [state, null])
    .with(['ResetPassSuccess'], () => {
      flashMessage.warning(tr('fibAdminUser.resetPassConfirm'));
      return [state, null]
    })
    .with(['ResetPassFailed'], () => {
      flashMessage.error(tr('secRemindPasswordForm.initFailed'));
      return [state, null]
    })
    .with(['ResetPassConfirmation', P.select()], (email) => {
      return [state, resetPass(email)]
    })
    .exhaustive();
}

async function remove(user: User) {
  const confirmed = await modal.confirm({
    title: tr('general.confirm', {
      name: user.firstName + ' ' + user.lastName
    }),
    text: tr('fibAdminUser.confirmRemoval', user as any),
    okLabel: tr('general.yes'),
    cancelLabel: tr('general.no')
  });

  if (!confirmed || !user.id) {
    return msg('RemoveCancelled');
  }

  try {
    await resourceManager.removeUser(user.id);
    return msg('RemoveSuccess');
  } catch (err) {
    console.error('removing failed', err);
    return msg('RemoveFailed');
  }
}


async function resetPass(email: string): Promise<Msg> {
  const result = await modal.confirm({
    text: tr('secRemindPasswordForm.confirmPassReset', { email }),
    okLabel: tr('general.yes'),
    cancelLabel: tr('general.no')
  });

  if (!result) {
    return msg('ResetPassCancelled')
  }

  try {
    await security.initiateRemindPassword(email);
    return msg('ResetPassSuccess');
  } catch (err) {
    console.error('initiating remind password failed', err);
    return msg('ResetPassFailed');
  }
}


async function loadInstallations(orgId: string) {
  try {
    const result = await resourceManager.listOrgInstallations(orgId);
    return msg('LoadInstallationsSuccess', result);
  } catch (err) {
    console.error('loading data failed', err);
    return msg('LoadInstallationsFailed');
  }
}

async function loadData(userId: string) {
  try {
    const result = await resourceManager.editUserPage(userId);
    return msg('LoadDataSuccess', result);
  } catch (err) {
    console.error('loading data failed', err);
    return msg('LoadDataFailure');
  }
}

async function save(user: User | undefined, data: Record<string, unknown>) {
  if (!user) {
    return msg('SaveFailed', {});
  }

  try {
    const result = await resourceManager.saveUser({
      ...user,
      ...data,
      id: user.id
    });
    if ('errors' in result) {
      return msg('SaveFailed', result.errors);
    }
    return msg('SaveSuccess', result);
  } catch (err) {
    console.error('saving data failed', err);
    return msg('SaveFailed', {});
  }
}

function view(state: State) {
  if (state.isLoading || !state.user) {
    return v<Msg>('.fib-tech-user-details',
      v('.loader-big')
    );
  }
  return v<Msg>('.fib-tech-user-details.vbox.center',
    itemTopBar({
      goBackUrl: router.getRouteUrl('techInstallsUsers'),
      goBackLabel: 'fibAdminPanel.navUsers',
      isSaveEnabled: Object.keys(state.data).length > 0 &&
        !state.isSaving &&
        Object.keys(state.errors).length === 0,
      isSaving: state.isSaving,
      title: state.user.firstName + ' ' + state.user.lastName
    }),
    v('.grid.title-container',
      v('h1.box12.h600',
        tr('general.mainInfo')
      )
    ),
    v('.grid',
      v('.box4',
        v('.container',
          userGeneralInfo<Msg>({
            isDisabled: state.isSaving,
            isDisabledEmail: true,
            realData: state.user,
            data: state.data,
            errors: state.errors
          })
        ),
        v('.container',
          v('button.button-secondary.h100',
            { onClick: msg('ResetPassConfirmation', state.user.email) },
            tr('fibAdminUser.resetPassword'),
          ),
        ),
        v('.full-width',
          v('button.button-tertiary.h300',
            { onClick: msg('Remove') },
            v('i.icon-trash'),
            v.span(tr('fibAdminUser.removeUser'))
          )
        )
      ),
      v('.box4',
        v('h3.h400.subsection-title', tr('fibAdminUser.license')),
        v('.container',
          ...userLicenseSwitch<Msg>({
            isDisabled: state.isSaving,
            data: state.data,
            errors: state.errors,
            realData: state.user,
            activateMsg: () => msg('ActivateLicense'),
            deactivateMsg: () => msg('Input', 'status', 'inactive'),
            dateChangeMsg: (date: number) => msg('Input', 'validUntil', date)
          })
        ),
        v('.container',
          ...userLicenseType<Msg>({
            isDisabled: state.isSaving,
            showAllOptions: false,
            data: state.data,
            errors: state.errors,
            realData: state.user
          })
        )
      ),
      v('.box4',

        v('h3.h400.subsection-title', tr('access.subordinateInstallations')),
        ...userInstallations<Msg>({
          isDisabled: state.isSaving,
          selectedOrgId: state.selectedOrgId,
          orgs: state.orgs,
          data: state.data,
          realData: state.user,
          errors: state.errors,
          installations: state.installations,
          allInstallations: state.installationsFilter,
          changeSelectedOrgMsg: val => msg('ChangeSelectedOrg', val as string),
          updateInstallationsMsg: val => msg('Input', 'installationIds', val),
          isLoading: state.areInstallationsLoading
        })
      )
    )
  );
}
