import { flashMessage, stm, tr } from "@7willows/sw-lib";
import { match, P } from "ts-pattern";
import { UserState } from "nexus/node/MetricsManager";
import { techNav } from "./view-utils";
import { AdvancedSettings } from "nexus/node/InstallationManager";

type Msg =
  | [type: "AttributeChange", name: string, value: UserState]
  | [type: "AttributeChange", name: string, value: string]
  | [type: "Tab", name: string]
  | [type: "InputChange", name: string, value: string]
  | [type: "SaveSpeed"]
  | [type: "SaveSuccess"]
  | [type: "Error", errMsg: string]
  | [type: "LoadingAdvancedSettingsSuccess", settings: AdvancedSettings];

interface State {
  userState?: UserState;
  isLoading: boolean;
  settings: AdvancedSettings;
  tab: string;
  inputs: {
    [key: string]: string;
  };
  isInProgress: boolean;
  errMsg: string;
  scriptInstallationId: string;
  scriptLoaded: string;
  scriptFile: string;
}

const msg = (...args: Msg): Msg => args;
const installationManager = grow.plant("InstallationManager");

const advancedManager = grow.plant("AdvancedManager");

stm.component({
  tagName: "fib-tech-advanced",
  shadow: false,
  debug: false,
  propTypes: {
    userState: Object,
    installationId: String,
  },
  attributeChangeFactory: (name: string, value: any) => msg("AttributeChange", name, value),
  init(_dispatch: stm.Dispatch<Msg>): [State, stm.Cmd<Msg>] {
    return [{
      isLoading: true,
      tab: "",
      inputs: {},
      isInProgress: false,
      errMsg: "",
      settings: {
        drainSpeed: 0,
      },
      scriptInstallationId: "",
      scriptLoaded: "",
      scriptFile: "",
    }, null];
  },
  update,
  view,
});

function update(state: State, incomingMsg: Msg) {
  return match<Msg, [State, stm.Cmd<Msg>]>(incomingMsg)
    .with(["AttributeChange", "userState", P.select()], (userState) => {
      state.userState = userState as UserState;
      state.isLoading = false;
      return [state, loadAdvancedSettings()];
    })
    .with(["AttributeChange", P._, P._], () => [state, null])
    .with(
      ["LoadingAdvancedSettingsSuccess", P.select()],
      (settings: AdvancedSettings) => {
        if (!settings.drainSpeed) {
          flashMessage(tr("fib.advanced.drainSpeedError"), "error");
          return [state, null];
        }
        state.inputs.speed = settings.drainSpeed.toString();
        return [state, null];
      },
    )
    .with(["Tab", P.select()], (tab) => {
      state.tab = tab;
      state.errMsg = "";
      return [state, null];
    })
    .with(["InputChange", P._, P._], ([, name, value]) => {
      state.inputs[name] = value;
      state.errMsg = "";
      return [state, null];
    })
    .with(["SaveSpeed"], () => {
      state.isInProgress = true;
      flashMessage(tr("general.inProgress"), "info");
      return [state, saveSpeed(state, state.inputs.speed)];
    })
    .with(["SaveSuccess"], () => {
      flashMessage(tr("gcrud.saveSuccess"), "success");
      state.isInProgress = false;
      return [state, null];
    })
    .with(["Error", P.select()], (errMsg) => {
      flashMessage(errMsg, "error");
      state.isInProgress = false;
      state.errMsg = errMsg;
      return [state, null];
    })
    .exhaustive();
}

async function loadAdvancedSettings() {
  try {
    const settings = await installationManager.displayAdvancedSettings();
    return msg("LoadingAdvancedSettingsSuccess", settings);
  } catch (err) {
    console.error("loading settings failed", err);
    return msg("Error", tr("general.saveFailed"));
  }
}

async function saveSpeed(state: State, value: string) {
  try {
    await installationManager.setDrainSpeed(Number(value));
    return msg("SaveSuccess");
  } catch (err) {
    console.error("saving drain speed failed", err);
    return msg("Error", tr("general.saveFailed"));
  }
}

function view(state: State): stm.View<Msg> {
  if (!state.userState || state.isLoading) {
    return (
      <div className="loader-wrapper">
        <div className="loader-big" />
      </div>
    );
  }
  return (
    <>
      {techNav(state.userState)}
      {renderAdvanced(state)}
    </>
  );
}

function renderAdvanced(state: State) {
  return (
    <>
      <main class={"vbox"}>
        <div className="grid fib-advanced">
          <div className="box4 fib-advanced-col">
            <p class={"h500"}>{tr("fib.advanced.advanced")}</p>
            <ul className="list">
              <li className="well body-large pointer" onClick={() => null}>
                {tr("fib.advanced.measurement")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "script")}
              >
                {tr("fibRuntimeScripts.title")}
              </li>
              <li className="well body-large pointer" onClick={() => null}>
                {tr("fib.advanced.logs")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "speed")}
              >
                {tr("fib.advanced.speed")}
              </li>
              <li className="well body-large pointer" onClick={() => null}>
                {tr("fib.advanced.notes")}
              </li>
              <li className="well body-large pointer" onClick={() => null}>
                {tr("fib.advanced.detectors")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "alerts")}
              >
                {tr("fib.advanced.alertsConfirm")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "dataPointsLists")}
              >
                {tr("fib.advanced.dataPointsLists")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "registers")}
              >
                {tr("fib.advanced.manageRegisters")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "devices")}
              >
                {tr("fib.advanced.manageDevices")}
              </li>
              <li
                className="well body-large pointer"
                onClick={() => msg("Tab", "params")}
              >
                {tr("fib.advanced.manageParams")}
              </li>
            </ul>
          </div>

          <div className="box8 fib-advanced-col">
            <div className="fib-advanced-search-wrapper">
              <input
                className="body-medium"
                type="text"
                placeholder={tr("general.search")}
                onInput={() => null}
              />
              <i className="icon-search" />
            </div>

            <div class={"fib-advanced-content"}>
              <form
                class={"fib-advanced-form"}
                encType="multipart/form-data"
                onSubmit={(event: Event) => event.preventDefault()}
              >
                {renderContent(state)}
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

function renderContent(state: State) {
  const installationIds = state.userState?.installations.map((installation) => installation.id);
  const installationIdsExt = state.userState?.installations.map((installation) => {
    return {
      id: installation.id,
      name: installation.name,
      machineId: installation.machineId,
    };
  });

  switch (state.tab) {
    case "speed":
      return (
        <>
          <div class={"form-element"}>
            <p className={"h400"}>{tr("fib.advanced.speedHeading")}</p>
          </div>
          <div class={"form-element"}>
            <input
              class={"body-large"}
              type="number"
              placeholder={tr("fib.advanced.inputPlaceholder")}
              min={1}
              step={.1}
              value={state.inputs.speed}
              onInput={(e: Event) =>
                msg(
                  "InputChange",
                  "speed",
                  (e.target as HTMLInputElement).value,
                )}
            />
          </div>
          <div className={"form-element"}>
            <span className={"error"}>{state.errMsg}</span>
          </div>
          <div class={"form-element"}>
            <button
              class={"button-primary"}
              disabled={state.isInProgress}
              onClick={() => msg("SaveSpeed")}
            >
              {tr("general.saveChanges")}
            </button>
          </div>
        </>
      );
    case "script":
      return <fib-runtime-scripts user-state={state.userState}></fib-runtime-scripts>;
    case "alerts":
      return <fib-alerts-list user-state={state.userState}></fib-alerts-list>;
    case "dataPointsLists":
      return (
        <fib-tech-data-points-list
          installationsIds={installationIds}
        />
      );
    case "params":
      return <fib-tech-params installations={installationIdsExt}></fib-tech-params>;
    case "registers":
      return (
        <fib-config-editor installations={installationIdsExt} config={"registers"}>
        </fib-config-editor>
      );
    case "devices":
      return (
        <fib-config-editor installations={installationIdsExt} config={"devices"}>
        </fib-config-editor>
      );
  }
}
